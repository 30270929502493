import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import EmailIcon from "@mui/icons-material/Email";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import LogoutIcon from "@mui/icons-material/Logout";
import vslogo from "../../assets/venturesathi-logo.png";
import vslogowhite from "../../assets/vsathiWhitelogo.png";
import { Badge, Switch } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import noprofile from "../../assets/no-profile.png";
import { imspage } from "../../Constants/api";
import { AuthContext } from "../../Context/AuthContextProvider";

const others = [
  {
    name: "Notifications",
    icon: <NotificationsIcon />,
    path: "/notifications",
  },
  {
    name: "Messages",
    icon: <MapsUgcIcon />,
    path: "/messages",
  },
];

function Navbar({ theme, toggleTheme }) {
  const isProduction = process.env.NODE_ENV === 'production';
  // console.log("header page ", isProduction)
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [profileEl, setProfileEl] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const {authContext,setAuthContext} = React.useContext(AuthContext);

  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenDetailsMenu = (event) => {
    setProfileEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseDetailsMenu = () => {
    setProfileEl(null);
  };

  const navigateTo = (url) => {
    navigate(url);
  };

  const handleLogout = () => {
    Cookies.remove("companytoken",
      {
        domain: isProduction ? '.vliv.app' : 'localhost',
        path: '/'
      });
    Cookies.remove("userinfo",
      {
        domain: isProduction ? '.vliv.app' : 'localhost',
        path: '/'
      });
    navigate("/signin");
  };

  return (
    <div
      style={{
        // backgroundColor: "F4FAFF",
        // boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
        width: "100%",
      }}
      className="section-navbar"
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 0, // Remove padding on x-axis
          }}
        >
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
              ml: 0, // Remove left margin
            }}
          >
            <img
              src={theme ? vslogowhite : vslogo}
              alt="vs-logo"
              width={"30%"}
              height={"auto"}
              onClick={() => window.location.assign(`${imspage}/frontend/apps`)}
            />
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
              ml: 0, // Remove left margin
            }}
          >
            <img
              src={vslogo}
              alt="vs-logo"
              width={"30%"}
              height={"auto"}
              onClick={() => window.location.assign(imspage)}
            />
          </Typography>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            {others?.map((other, ind) => (
              <IconButton
                key={ind}
                sx={{
                  mr: 1,
                  color: theme ? "white" : "black",
                  backgroundColor:
                    location.pathname === other.path && !theme
                      ? "azure"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                  border:
                    location.pathname === other.path && theme
                      ? "1px solid white"
                      : "",
                }}
                onClick={() => navigateTo(other.path)}
              >
                {other.name == "Notifications" ? (
                  <Badge badgeContent={""}>{other.icon}</Badge>
                ) : (
                  other.icon
                )}
              </IconButton>
            ))}

            {/* <Switch
              checked={theme}
              onChange={toggleTheme}
              inputProps={{ "aria-label": "controlled" }}
            /> */}
            <IconButton onClick={handleOpenUserMenu}>
              <Avatar
                alt="Profile Image"
                src={!authContext.userData.clientimage ? noprofile : authContext.userData.clientimage}
              />
            </IconButton>
               <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              sx={{ mt: "7px" }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <div
                style={{
                  width: "280px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "9px",
                }}
                onClick={() => {
                  handleCloseUserMenu();
                  // navigate("/profile/details");
                }}
              >
                <div>
                  <Typography>
                    <img
                      src={
                        !authContext.userData.clientimage ? noprofile : authContext.userData.clientimage
                      }
                      alt="user-profile"
                      style={{ borderRadius: "50%" }}
                      height={100}
                      width={100}
                    />
                  </Typography>
                </div>
                {/* <h3>
                  {userData.userrole == "masteradmin"
                    ? userData.clientname
                    : userData.username}
                </h3> */}
                 <h3>
                  {authContext.userData.username || ""}
                </h3>
                <p>
                  {authContext.userData.userrole == "masteradmin"
                    ? authContext.userData.clientemail
                    : authContext.userData.email}
                </p>
                {/* <p>Emp: {userData.username ? userData.username : "N/A"}</p> */}
                <p>Role: {authContext.userData.userrole ? authContext.userData.userrole : "N/A"}</p>
                <Button
                  variant="outlined"
                  endIcon={<LogoutIcon />}
                  sx={{ borderRadius: "10px", color: "#0B4A6F" }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none", justifyContent: "flex-end" },
              flexGrow: 1,
            }}
          >
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenDetailsMenu} sx={{ p: 0 }}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={profileEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(profileEl)}
              onClose={handleCloseDetailsMenu}
            >
              {others?.map((other, ind) => (
                <MenuItem key={ind}>
                  <Button
                    onClick={() => {
                      navigate(other.path);
                      handleCloseDetailsMenu();
                    }}
                    sx={{
                      color: "black",
                      backgroundColor:
                        location.pathname === other.path
                          ? "azure"
                          : "transparent",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  >
                    {other.icon} &nbsp; {other.name}
                  </Button>
                </MenuItem>
              ))}
              {/* <MenuItem>
                <Switch
                  checked={theme}
                  onChange={toggleTheme}
                  inputProps={{ "aria-label": "controlled" }}
                />{" "}
                <span>Change Theme</span>
              </MenuItem> */}
              <MenuItem>
                <Button
                  sx={{ color: "black" }}
                >
                  <Avatar
                    alt="Profile Image"
                    src={
                      !authContext.userData.clientimage ? noprofile : authContext.userData.clientimage
                    }
                  />{" "}
                  &nbsp;
                  <span>{authContext.userData.userrole == "masteradmin" ? authContext.userData.clientname : authContext.userData.username}</span>
                </Button>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Button>
                  <LogoutIcon />
                  <span>Logout</span>
                </Button>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </div>
  );
}

export default Navbar;
