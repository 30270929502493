export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function formatTime(timeStr) {
  const [hours, minutes] = timeStr.split(":");
  const formattedHours = parseInt(hours) % 12 || 12; // Convert hours to 12-hour format
  const period = parseInt(hours) >= 12 ? "PM" : "AM"; // Determine AM/PM
  return `${formattedHours}:${minutes} ${period}`;
}

export function formatDateTime(dateString) {
  const date = new Date(dateString);

  const padZero = (num) => (num < 10 ? "0" + num : num);

  const day = padZero(date.getDate());
  const month = padZero(date.getMonth() + 1); // Months are zero-indexed
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = padZero(date.getMinutes());
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  const formattedTime = `${padZero(hours)}:${minutes} ${ampm}`;
  const formattedDate = `${day}/${month}/${year}`;

  return `${formattedDate} ${formattedTime}`;
}

export const formatAttendanceTime = (date) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = days[date.getDay()];
  const dateNum = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const time = `${hours}:${minutes}:${seconds}`;

  const fullDate = `${dateNum}/${month}/${year}`;

  return {
    time,
    day,
    date: fullDate,
    month: months[month - 1],
    year,
  };
};

export const formatTimeToAmPm = (date) => {
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const amPm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12; // Convert 0 to 12 for midnight
  const formattedHours = hours.toString().padStart(2, "0");

  return `${formattedHours}:${minutes}:${seconds} ${amPm}`;
};

// DD/MM/YYYY to js date
export function parseDateString(dateString) {
  // Split the input string by the '/' delimiter
  const [day, month, year] = dateString.split("/").map(Number);

  // Create a new Date object
  // Note: In JavaScript, months are 0-indexed (January is 0, February is 1, etc.)
  const dateObject = new Date(year, month - 1, day);

  return dateObject;
}



export function parseTime(timeString) {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes, seconds] = time.split(':');
  
  hours = parseInt(hours, 10);
  
  if (modifier === 'PM' && hours !== 12) {
    hours += 12;
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }
  
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  date.setMilliseconds(0); // Ensure no milliseconds are affecting the calculation
  
  return date;
}

function calculateRemainingTime(totalhr, done) {
  // Convert the 'done' string to a floating-point number
  const doneNumeric = parseFloat(done);

  // Parse the 'done' hours and minutes from the decimal format
  const doneHours = Math.floor(doneNumeric); // Extract the integer part for hours
  const doneMinutes = Math.round((doneNumeric % 1) * 60); // Extract the decimal part and convert to minutes

  // Calculate total minutes already done
  const doneTotalMinutes = doneHours * 60 + doneMinutes;

  // Calculate total minutes from totalhr
  const totalMinutes = totalhr * 60;

  // Calculate remaining minutes
  const remainingTotalMinutes = totalMinutes - doneTotalMinutes;

  // Convert remaining total minutes back to hours and minutes format
  const remainingHours = Math.floor(remainingTotalMinutes / 60);
  const remainingMinutes = remainingTotalMinutes % 60;

  // Return the result in HH:MM format
  return `${remainingHours}:${
    remainingMinutes < 10 ? "0" : ""
  }${remainingMinutes}`;
}




export function convertTo24HourFormat(timeStr) {
  const [time, modifier] = timeStr.split(' ');
  let [hours, minutes, seconds] = time.split(':');
  
  if (hours === '12') {
    hours = '00';
  }
  
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  
  return `${hours}:${minutes}:${seconds}`;
}










export const getAttendanceStatus = (row) => {
  if (row.day === "Saturday" || row.day === "Sunday") {
    return "Holiday";
  } else if (!row.data.checkin && !row.data.checkout) {
    return "Absent";
  } else if (!row.data.checkout && row.data.checkin) {
    return "Regularize";
  } else if (!row.data.checkin && row.data.checkout) {
    return "Regularize";
  } else if (
    row.data.checkin &&
    row.data.checkout &&
    +row.data.totalhours >= 9
  ) {
    return "Completed";
  } else if (
    row.data.checkin &&
    row.data.checkout &&
    +row.data.totalhours < 9
  ) {
    let temp = calculateRemainingTime(9, row.data.totalhours);
    return temp;
  } else {
    return "N/A";
  }
};

export const getOnboardStatus = (status) => {
  switch (status) {
    case "Offerletter sent":
      return "Processed";
    case "Verify":
      return "Verified";
    case "Reject":
      return "Rejected";
    default:
      return status;
  }
};

export const getColorForStatus = (status) => {
  switch (status) {
    case "Scheduled":
      return "teal";
    case "Passed":
      return "green";
    case "Approved":
      return "green";
    case "Offer Letter Generated":
      return "green";
    case "Joined":
      return "green";
    case "Document submitted":
      return "sky";
    case "Verify":
      return "skyblue";
    case "Failed":
      return "red";
    case "Rejected":
      return "red";
    case "Reject":
      return "red";
    case "Paused":
      return "orange";
    case "Pending":
      return "orange";
    case "On Hold":
      return "orange";
    default:
      return "teal"; // Default color
  }
};

export const getOriginalID = (id, rmstr) => {
  return id.replace(rmstr, "");
};

export const getBoxColor = (value) => {
  switch (value) {
    case "Leave":
      case "Total Employee":
        case "Employees" :
      return "#D6BBfB";
    case "Reimbursement":
      case "Total Manager":
        case "Payroll" :
      return "#FEDF89";
    case "Employee Exit":
      case "Tasks" :
      case "New Joiners":
      return "#B2DDFF";
    case "Attendance":
      case "Total Leaved":
      return "#8098F9";
    case "Ticket":
      case "Performance" :
      return "#A6F4C5";
    default:
      return "teal";
  }
};

export const getAttendanceStatusColor = (value) => {
  switch (value) {
    case "Completed":
      return "green";
    case "Approved":
      return "green";
    case "Absent":
      return "red";
    case "Rejected":
      return "red";
    default:
      return "orange";
  }
};

export const performanceTextColor = (value) => {
  switch (value) {
    case "Pending":
    case "Create":
    case "Medium":
      return "#93370D";

    case "Completed":
    case "Generated":
    case "Low":
      return "#05603A";

    case "In Progress":
      return "#05603A";

    case "High":
      return "#C0473E";

    default:
      return "#93370D";
  }
};

export const performanceBgColor = (value) => {
  switch (value) {
    case "Pending":
    case "Create":
    case "Medium":
      return "#FEC84B";

    case "Completed":
    case "Generated":
    case "Low":
      return "#6CE9A6";

    case "In Progress":
      return "skyblue";

    case "High":
      return "#FDA29B";

    default:
      return "#FEC84B";
  }
};
