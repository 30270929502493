import {
  Box,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  Search as SearchIcon,
  CalendarToday as CalendarIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { DateRangePicker } from "react-date-range";
import { addDays, differenceInCalendarDays, format, subDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getTimeAttendance,
  getTimeAttendanceByID,
} from "../../../../redux/actions/actions.hrms/timeAttendance.actions";
import Cookies from "js-cookie";
import Loader from "../../../../Constants/Loader";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { FiDownload } from "react-icons/fi";
import {
  AttendanceExcelBody,
  AttendanceExcelHeader,
  dummyExceldata,
  excelHeaders,
} from "../../../../Constants/constants";
import axios from "axios";
import AlertPopUp from "../../../../Constants/AlertPopUp";

//import "./TimeAttendance.css"

const TimeAttendance = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const {
    timeAttendanceData,
    timeAttendance_error,
    timeAttendance_loading,
    timeAttendanceByIDData,
  } = useSelector((state) => state.timeAttendanceReducer);

   //for notify alert
   const [open, setOpen] = useState(false);
   const [msg, setMsg] = useState("");
   const [alertType, setAlertType] = useState("success");
   const handleClose = (event, reason) => {
     if (reason === "clickaway") {
       return;
     }
     setOpen(false);
   };

  const [value, setValue] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [emp, setEmp] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // State to manage dialog visibility
  const [data, setData] = useState({
    employeeid: "",
    name: "",
    date: "",
    day: "",
    month: "",
    year: "",
    checkin: "",
    checkout: "",
    totalhours: "",
    requeststatus: "",
    approvestatus: "",
    reason: "",
    requesttype: "",
    reportingto: "",
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  const handleSearch = (event) => {
    // Handle search logic here
  };

  const handleSelect = (ranges) => {
    const { selection } = ranges;
    const daysDifference = differenceInCalendarDays(
      selection.endDate,
      selection.startDate
    );

    if (daysDifference <= 9) {
      setValue([selection]);
    } else {
      setValue([
        {
          ...selection,
          endDate: addDays(selection.startDate, 9),
        },
      ]);
    }
  };

  // console.log(value, "jdjsdj");

  const toggleDatePicker = () => {
    setShowPicker(!showPicker);
  };

  const shiftRange = (direction) => {
    const currentRange = value[0];
    const daysDifference = differenceInCalendarDays(
      currentRange.endDate,
      currentRange.startDate
    );
    const newStartDate =
      direction === "left"
        ? subDays(currentRange.startDate, daysDifference + 1)
        : addDays(currentRange.startDate, daysDifference + 1);
    const newEndDate =
      direction === "left"
        ? subDays(currentRange.endDate, daysDifference + 1)
        : addDays(currentRange.endDate, daysDifference + 1);

    setValue([
      {
        startDate: newStartDate,
        endDate: newEndDate,
        key: "selection",
      },
    ]);
  };

  //for sample download
  const downloadSample = () => {
    // Create a new workbook and a worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet([
    AttendanceExcelHeader,
    ...AttendanceExcelBody,
  ]);

  // Define column widths
  const columnWidths = [
    { wpx: 120 }, // Employee ID
    { wpx: 150 }, // Name
    { wpx: 120 }, // Date
    { wpx: 80 }, // Day
    { wpx: 80 }, // Month
    { wpx: 80 }, // Year
    { wpx: 120 }, // Checkin
    { wpx: 120 }, // Checkout
    { wpx: 100 }, // Total Hours
  ];

  // Apply column widths to worksheet
  worksheet["!cols"] = columnWidths;

  // Add worksheet to workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write to file and trigger download
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  saveAs(
    new Blob([wbout], { type: "application/octet-stream" }),
    "sample_attendance.xlsx"
  );
  };

  const handleUploadBtnClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // console.log("Selected file:", file);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const transformedData = jsonData.map((row) => ({
          employeeid: row["Employee ID"] || "",
          name: row["Name"] || "",
          date: row["Date"] || "",
          day: row["Day"] || "",
          month: row["Month"] || "",
          year: row["Year"] || "",
          checkin: row["Checkin"] || "",
          checkout: row["Checkout"] || "",
          totalhours: row["Total Hours"] || "",
        }));

        // console.log(transformedData, "transformdata");

        try {
          let res = await axios.post("https://hrms.vliv.app/atte/uploadattendance", {
            companyid: companyInfo.companyid,
            companyemail: companyInfo.companyemail,
            jsondata: transformedData,
          },{withCredentials:true});
          // console.log(res, "res file");
          if (res.data.message === "Attendance uploaded successfully") {
            setAlertType("success");
            setOpen(true);
            setMsg("File Uploaded Successfully");
            dispatch(
              getTimeAttendance({
                companyid: companyInfo.companyid,
                companyemail: companyInfo.companyemail,
              })
            );
          }
        } catch (error) {
          console.log(error);
          setOpen(true);
          setAlertType("error");
          setMsg(error.response.data.message || "Something went wrong");
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    dispatch(
      getTimeAttendance({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (timeAttendanceData.length > 0) {
      // Step 1: Extract Unique Employees and Dates
      const uniqueEmployees = {};
      const uniqueDates = new Set();

      timeAttendanceData.forEach((record) => {
        uniqueEmployees[record.employeeid] = {
          name: record.name,
          department: record.department,
          position: record.position,
        };
        let currentDate = new Date(value[0].startDate);
        while (currentDate <= value[0].endDate) {
          if (format(currentDate, "dd/MM/yyyy") == record.date) {
            uniqueDates.add(record.date);
          } else {
            uniqueDates.add(format(currentDate, "dd/MM/yyyy"));
          }

          currentDate = addDays(currentDate, 1);
        }
        //uniqueDates.add(record.date);
      });

      const sortedUniqueDates = Array.from(uniqueDates);

      // Step 2: Initialize a Data Structure
      const attendance = {};
      Object.keys(uniqueEmployees).forEach((empId) => {
        attendance[empId] = {};
        sortedUniqueDates.forEach((date) => {
          attendance[empId][date] = { checkin: "", checkout: "" };
        });
      });

      // Step 3: Populate the Data Structure
      timeAttendanceData.forEach((record) => {
        const empId = record.employeeid;
        const date = record.date;
        attendance[empId][date] = {
          checkin: record.checkin,
          checkout: record.checkout,
        };
      });

      // Convert to rows for DataGrid
      const rows = [];
      Object.keys(attendance).forEach((empId) => {
        const row = {
          id: empId,
          empname: uniqueEmployees[empId].name,
          department: uniqueEmployees[empId].department,
          position: uniqueEmployees[empId].position,
        };
        sortedUniqueDates.forEach((date) => {
          const { checkin, checkout } = attendance[empId][date];
          if (checkin && checkout) {
            row[date] = (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {`${checkin} - ${checkout}`}{" "}
                <AccessTimeIcon style={{ color: "green" }} />
              </Box>
            );
          } else if (checkin || checkout) {
            row[date] = (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {`${checkin || checkout}`}{" "}
                <AccessTimeIcon style={{ color: "orange" }} />
              </Box>
            );
          } else {
            row[date] = (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                pt={2}
              >
                <AccessTimeIcon style={{ color: "red" }} />
              </Box>
            );
          }
        });
        rows.push(row);
      });

      // Static columns
      const staticColumns = [
        {
          field: "id",
          headerName: "ID",
          width: 150,
        },
        {
          field: "empname",
          headerName: "Name",
          width: 150,
        },
        {
          field: "department",
          headerName: "Department",
          width: 150,
        },
        {
          field: "position",
          headerName: "Position",
          width: 150,
        },
      ];

      // Dynamic columns based on dates ---------API
      const dateColumns = sortedUniqueDates.map((date) => ({
        field: date,
        headerName: format(
          new Date(date.split("/").reverse().join("-")),
          "dd/MM/yyyy"
        ),
        width: 220,
        editable: true,
        renderCell: (params) => params.value,
      }));

      const columns = [...staticColumns, ...dateColumns];

      setColumns(columns);
      setRows(rows);
    }
  }, [timeAttendanceData, value]);

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
    setEmp(null);
    setSelectedDate(null);
  };

  useEffect(() => {
    // console.log(emp, "inside useeffect");
    if (emp) {
      setData({
        ...data,
        employeeid: emp.empid,
        tableemployeeid:emp.id,
        name: emp.empname,
      });
    }
  }, [emp]);

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // console.log(timeAttendanceData, "emp");
  // console.log(selectedDate, "selectedDate");
  // console.log(openDialog, "openDialog");
  // console.log(rows, "rows");
  // console.log(timeAttendanceData, "down data");

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <Hidden smDown>
            <Box>
              {/* Content to be shown only on screens larger than or equal to sm (600px) */}
            </Box>
          </Hidden>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <IconButton onClick={() => shiftRange("left")}>
              <ChevronLeftIcon />
            </IconButton>
            <CalendarIcon onClick={toggleDatePicker} />
            <Box sx={{ marginLeft: "8px" }} onClick={toggleDatePicker}>
              {`${format(value[0].startDate, "dd/MM/yyyy")} - ${format(
                value[0].endDate,
                "dd/MM/yyyy"
              )}`}
            </Box>
            <IconButton onClick={() => shiftRange("right")}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            {/* <TextField
              placeholder="Search…"
              variant="outlined"
              className="searchbar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            /> */}

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleFileChange}
            />
            <Button
              className="cancelBtn"
              variant="outlined"
              startIcon={<FiDownload />}
              onClick={downloadSample}
            >
              Sample
            </Button>
            <Button
              startIcon={<ExitToAppIcon />}
              className="saveBtn"
              onClick={handleUploadBtnClick}
            >
              Import File
            </Button>
          </Box>
        </Box>
        {showPicker && (
          <div ref={pickerRef}>
            {" "}
            <DateRangePicker
              ranges={value}
              onChange={handleSelect}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              direction="horizontal"
              rangeColors={["#3f51b5"]}
              staticRanges={[]} // Remove static ranges
              inputRanges={[]} // Remove input ranges
            />
          </div>
        )}
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={timeAttendance_loading ? [] : rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              pinnedColumns: {
                left: ["id", "empname", "department", "position"],
              },
            }}
            // onCellClick={handleCellClick}
            pageSizeOptions={[5, 10, 15, 20, rows.length]}
            autoHeight
            components={{
              LoadingOverlay: <Loader />,
            }}
            loading={timeAttendance_loading}
          />
        </Box>
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default TimeAttendance;
