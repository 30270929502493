import React from 'react'
import pagenotfound from "../assets/404_not_found.png"
import { Box, Grid } from '@mui/material'

const PageNotFound = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12} md={12} sm={12} >
       <Box>
    <img width="100%" height="100%" src={pagenotfound} />
       </Box>
      </Grid>
      
    </Grid>
      )
}

export default PageNotFound