import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import TimeAttendance from "./TimeAttendance";
import ApproveAttendances from "./ApproveAttendances";
import TabPanel from "@mui/lab/TabPanel";

const Attendances = () => {
  const [tabvalue, setTabValue] = React.useState("attendancelog");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      <Box>
        <TabContext value={tabvalue}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab
              label="Attendance Log"
              value="attendancelog"
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Regularize Requests"
              value="regularizerequest"
              sx={{ textTransform: "none" }}
            />
          </TabList>
          <TabPanel value="attendancelog">
            <TimeAttendance />
          </TabPanel>
          <TabPanel value="regularizerequest">
            <ApproveAttendances />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default Attendances;
