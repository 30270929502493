export const boxStyle = {
    margin: "10px",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#f9fafb",
  };
  export const typoHeading = {
    color: "black",
    fontSize: "18px",
  };
  export const typoValue = {
    color: "grey",
    fontSize: "16px",
  };
  export const empInfoBox = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  };
  export const saveBtnBox = {
    mt: "auto",
    display: "flex",
    justifyContent: "flex-end",
    gap: 2,
    padding: 1,
  };
  export function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "grey",
        width: "90px",
        height: "90px",
      },
      children: name ? `${name.split(" ")[0][0]}${name.split(" ")[0][1]}` : "",
    };
  }
  export function stringAvatar2(name) {
    return {
      sx: {
        bgcolor: "grey",
        width: "50px",
        height: "50px",
      },
      children: name ? `${name.split(" ")[0][0]}${name.split(" ")[0][1]}` : "",
    };
  }