import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../Constants/Loader";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  addEmpLeaveCategory,
  deleteEmpLeaveCategory,
  getEmpLeaveCategory,
} from "../../../../redux/actions/actions.master/empLeaveCategory.actions";

const ListLeavingReason = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { leaveReasonData, error, loading } = useSelector(
    (state) => state.empLeaveCategoryReducer
  );
  // console.log(leaveReasonData, "leave");

  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    description: "",
    code: "",
    pfcode: "",
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleAdd = () => {
    if (data.description == "") {
      setShowEmptyError(true);
      return;
    }
    dispatch(addEmpLeaveCategory(data));
    setData({
      description: "",
      code: "",
      pfcode: "",
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
  };
  const handleDelete = (id) => {
    dispatch(
      deleteEmpLeaveCategory({
        idarray: [id],
      })
    );
  };

  useEffect(() => {
    dispatch(
      getEmpLeaveCategory({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Container maxWidth="xl">
        <Box
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
          padding={"10px"}
          marginTop={"20px"}
          borderRadius={"15px"}
        >
          <Typography className="colored-heading">Leaving Reason</Typography>
          <Divider />
          <br />
          <TableContainer
           
            sx={{
              maxHeight: 400,
              overflow: "auto",
              position: "relative",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="datagrid-header">
                  <TableCell sx={{ border: "1px solid white" }}>
                    Description
                  </TableCell>
                  <TableCell sx={{ border: "1px solid white" }}>Code</TableCell>
                  <TableCell sx={{ border: "1px solid white" }}>
                    PF Code
                  </TableCell>
                  <TableCell sx={{ border: "1px solid white" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              {loading ? (
                <Loader />
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }}>
                      <TextField
                        fullWidth
                        name="description"
                        className="formInputs"
                        value={data.description}
                        placeholder="Enter a Description"
                        onChange={handleChange}
                        error={!data.description && showEmptyError}
                        helperText={
                          !data.description &&
                          showEmptyError &&
                          "Description is required"
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <TextField
                        fullWidth
                        name="code"
                        className="formInputs"
                        value={data.code}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <TextField
                        fullWidth
                        name="pfcode"
                        className="formInputs"
                        value={data.pfcode}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <IconButton color="success" onClick={handleAdd}>
                        <CheckBoxIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {leaveReasonData.length > 0 &&
                    leaveReasonData.map((ele, ind) => (
                      <TableRow key={ind}>
                        <TableCell>{ele.description}</TableCell>
                        <TableCell>{ele.code}</TableCell>
                        <TableCell>{ele.pfcode}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleDelete(ele.id)}>
                            <DeleteOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </>
  );
};

export default ListLeavingReason;
