import axios from "axios";
import {
  masterPolicyAdd,
  masterPolicyDelete,
  masterPolicyGet,
  masterPolicyUpdate,
} from "../../../Constants/api";

export const getPolicy = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_POLICY_LOADING" });
    let res = await axios.post(masterPolicyGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_POLICY_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_POLICY_FAILED", payload: error.message });
  }
};

export const addPolicy = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_POLICY_LOADING" });
    let res = await axios.post(masterPolicyAdd, obj,{withCredentials:true});
    if (res.data.message == "Added successfully") {
      dispatch({ type: "ADD_POLICY_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_POLICY_FAILED", payload: error.message });
  }
};

export const updatePolicy = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_POLICY_LOADING" });
    let res = await axios.post(masterPolicyUpdate, obj,{withCredentials:true});
    if (res.data.message == "Updated successfully") {
      dispatch({
        type: "UPDATE_POLICY_SUCCESS",
        payload: res.data.data,
      });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPDATE_POLICY_FAILED", payload: error.message });
  }
};

export const deletePolicy =
  (idArray, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_POLICY_LOADING" });
      let res = await axios.post(masterPolicyDelete, idArray,{withCredentials:true});
      if (res.data.message == "Deleted successfully") {
        dispatch({ type: "DELETE_POLICY_SUCCESS", payload: idArray });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_POLICY_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };
