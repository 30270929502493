import axios from "axios";
import {
  masterPayrollReimbursementReviewAdd,
  masterPayrollReimbursementReviewDelete,
  masterPayrollReimbursementReviewGet,
  masterPayrollReimbursementTypeAdd,
  masterPayrollReimbursementTypeDelete,
  masterPayrollReimbursementTypeGet,
} from "../../../Constants/api";

export const getReimbursementType = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_PR_RM_TYPE_LOADING" });
    let res = await axios.post(masterPayrollReimbursementTypeGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_PR_RM_TYPE_SUCCESS", payload: Array.isArray(res.data)?res.data:[] });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_PR_RM_TYPE_FAILED", payload: error.message });
  }
};

export const addReimbursementType = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_PR_RM_TYPE_LOADING" });
    let res = await axios.post(masterPayrollReimbursementTypeAdd, obj,{withCredentials:true});
    if (res.data.message == "Added successfully") {
      dispatch({ type: "ADD_PR_RM_TYPE_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_PR_RM_TYPE_FAILED", payload: error.message });
  }
};

export const deleteReimbursementType =
  ({idarray, setAlertType, setMsg, setOpen}) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_PR_RM_TYPE_LOADING" });

      let res = await axios.post(masterPayrollReimbursementTypeDelete, {idarray},{withCredentials:true});
      if (res.data.message == "Deleted successfully") {
        dispatch({ type: "DELETE_PR_RM_TYPE_SUCCESS", payload: idarray });
        // handleClose();
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
      dispatch({ type: "DELETE_PR_RM_TYPE_FAILED", payload: error.message });
    }
  };

//----------------------------------------------------------------------//

export const getReimbursementReviewer = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_PR_RM_REVIEW_LOADING" });
    let res = await axios.post(masterPayrollReimbursementReviewGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_PR_RM_REVIEW_SUCCESS", payload: Array.isArray(res.data)?res.data:[] });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_PR_RM_REVIEW_FAILED", payload: error.message });
  }
};

export const addReimbursementReviewer = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_PR_RM_REVIEW_LOADING" });
    let res = await axios.post(masterPayrollReimbursementReviewAdd, obj,{withCredentials:true});
    if (res.data.message == "Added successfully") {
      dispatch({ type: "ADD_PR_RM_REVIEW_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_PR_RM_REVIEW_FAILED", payload: error.message });
  }
};

export const deleteReimbursementReviewer =
  (idarray, handleClose, setAlertType, setMsg, setOpen) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_PR_RM_REVIEW_LOADING" });
      let res = await axios.post(
        masterPayrollReimbursementReviewDelete,
        idarray,{withCredentials:true}
      );
      if (res.data.message == "Deleted successfully") {
        dispatch({ type: "DELETE_PR_RM_REVIEW_SUCCESS", payload: idarray });
        handleClose();
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
      dispatch({ type: "DELETE_PR_RM_REVIEW_FAILED", payload: error.message });
    }
  };
