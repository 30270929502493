import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import ApplyExit from "./ApplyExit";
import { getAllExitRequestApi } from "../../../../../Constants/api";
import { getAttendanceStatusColor } from "../../../../../Constants/dateFormat";

const EmployeeExit = () => {
  const navigate = useNavigate();
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";
  let userRole = userData.userrole ? userData.userrole : "";
  let username = userData.username ? userData.username : "";
  const [empInfo, setEmpInfo] = useState({});
  const [exitRequests,setExitRequests]=useState([]);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    axios
      .post("https://hrms.vliv.app/employee/get", { companyemail, companyid },{withCredentials:true})
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let data = res.data.find((el) => el.email == email);
          if (data) {
            setEmpInfo(data);
          } else {
            setEmpInfo({});
          }
        } else {
          setEmpInfo({});
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setAlertType("error");
        setOpen(true);
        setMsg(err.message);
      });
  }, []);

  useEffect(() => {
    if (empInfo) {
      axios
        .post(getAllExitRequestApi, {
          companyemail,
          companyid,
        },{withCredentials:true})
        .then((res) => {
          if (res.data && res.data.length > 0) {
            setExitRequests(res.data);
          } else {
            setExitRequests([]);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          // setAlertType("error");
          // setOpen(true);
          // setMsg(err.message);
        });
    }
  }, [empInfo, drawerOpen]);



  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to handle drawer open
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  // Function to handle drawer close
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // console.log(exitRequests,"exit requests");

  return (
    <>
      <Box px={3} py={2}>
        <Box
          display={"flex"}
          alignContent={"center"}
          justifyContent={"space-between"}
          gap={2}
        >
          <Typography
            variant={isSmallScreen ? "h6" : "h5"}
            className="primarycolor"
          >
            Employee Exit Requests
          </Typography>
          <Button className="saveBtn" onClick={handleDrawerOpen}>
            Apply Exit
          </Button>
        </Box>
        <Box my={1}>
          <TableContainer
          
          >
            <Table>
              <TableHead>
                <TableRow sx={{backgroundColor:"#E4E7EC"}}>
                  <TableCell>Applied Date</TableCell>
                  <TableCell>Resignation Date</TableCell>
                  <TableCell>LWD Date</TableCell>
                  <TableCell>Serving Notice Period</TableCell>
                  <TableCell>Shortfall Days</TableCell>
                  <TableCell>Reporting To</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Reason</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exitRequests.filter((exit)=>exit.empid==empInfo.empid)?.map((exitreq) => (
                  <TableRow key={exitreq.id}>
                    <TableCell>{exitreq.applyingdate?new Date(exitreq.applyingdate).toLocaleDateString():"--"}</TableCell>
                    <TableCell>{exitreq.resignationdate?new Date(exitreq.resignationdate).toLocaleDateString():"--"}</TableCell>
                    <TableCell>{exitreq.lwddate?new Date(exitreq.lwddate).toLocaleDateString():"--"}</TableCell>
                    <TableCell>
                      {exitreq.servenoticeperiod?exitreq.servenoticeperiod:"--"}
                    </TableCell>
                    <TableCell>
                      {exitreq.shortfalldays?exitreq.shortfalldays:"--"}
                    </TableCell>
                    <TableCell>
                      {exitreq.reportingto?exitreq.reportingto:"--"}
                    </TableCell>
                    <TableCell  sx={{color:getAttendanceStatusColor(exitreq.status)}}>
                      {exitreq.status?exitreq.status:"--"}
                    </TableCell>
                    <TableCell>
                      {exitreq.reason?exitreq.reason:"--"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <ApplyExit drawerOpen={drawerOpen} empInfo={empInfo} handleDrawerClose={handleDrawerClose} isSmallScreen={isSmallScreen}/>
     
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default EmployeeExit;
