import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LuFolderClock } from "react-icons/lu";
import EditIcon from "@mui/icons-material/Edit";
import { IoSaveOutline } from "react-icons/io5";
import { RiTaskLine } from "react-icons/ri";
import { GrDocumentPerformance } from "react-icons/gr";
import { IoExitOutline } from "react-icons/io5";
import { PiUserCircleCheckThin } from "react-icons/pi";
import { IoWalletOutline } from "react-icons/io5";
import { BsTicketPerforatedFill } from "react-icons/bs";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { GiExitDoor } from "react-icons/gi";
import React, { useEffect, useState } from "react";
import afternoonImage from "../../../../assets/welcome_afternoon.png";
import morningImage from "../../../../assets/welcome_morning.png";
import nightImage from "../../../../assets/welcome_night.png";

import "./welcome.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useDispatch, useSelector } from "react-redux";
import { getPayrollFILLING } from "../../../../redux/actions/actions.master/payrollFilling.actions";
import Cookies from "js-cookie";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { GrAnnounce } from "react-icons/gr";
import CakeIcon from "@mui/icons-material/Cake";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  addSummaryHoliday,
  getSummaryHoliday,
  updateSummaryHoliday,
} from "../../../../redux/actions/actions.hrms/summaryHoliday.actions";
import Loader from "../../../../Constants/Loader";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { getEmpReg } from "../../../../redux/actions/actions.hrms/empRegistration.actions";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  addSummaryBirthday,
  deleteSummaryBirthday,
  getSummaryBirthday,
  updateSummaryBirthday,
} from "../../../../redux/actions/actions.hrms/summaryBirthday.actions";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ReactQuill from "react-quill";

import {
  addSummaryAnnouncement,
  getSummaryAnnouncement,
} from "../../../../redux/actions/actions.hrms/summaryAnnouncement.actions";
import { IoCreateOutline } from "react-icons/io5";
import MISWelcome from "../MIS/Welcome/MISWelcome";
import { getBoxColor } from "../../../../Constants/dateFormat";
import { useNavigate } from "react-router-dom";
import { getEvents } from "../../../../Constants/api";
import axios from "axios";

// import io from "socket.io-client";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
// const socket = io("https://hrms.vliv.app");
const Welcome = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  const boxes = [
    {
      name: "Attendance",
      icon: <LuFolderClock />,
      path: "/hrm/employeemanagement/timeandattendance",
    },
    {
      name: "Payroll",
      icon: <IoWalletOutline />,

      path: "/hrm/payroll/salary",
    },
    {
      name: "Employees",
      icon: <PiUserCircleCheckThin />,
      path: "/hrm/employeemanagement/registration",
    },
    {
      name: "Performance",
      icon: <GrDocumentPerformance />,
      path: "/hrm/performance/summary",
    },
    {
      name: "Tasks",
      icon: <RiTaskLine />,
      path: "/hrm/task&events/tasks",
    },
  ];

  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  let userrole = companyInfo.userrole ? companyInfo.userrole : "";
  let userid = companyInfo.userid ? companyInfo.userid : "";
  let companyid = companyInfo.companyid ? companyInfo.companyid : "";
  let companyemail = companyInfo.companyemail ? companyInfo.companyemail : "";

  const dispatch = useDispatch();
  const { fillingData, error, loading } = useSelector(
    (state) => state.prFILLINGReducer
  );
  const { holidayData, holiday_error, holiday_loading } = useSelector(
    (state) => state.summaryHolidayReducer
  );
  const { empData } = useSelector((state) => state.empRegistrationReducer);
  const { birthdayData, birthday_error, birthday_loading } = useSelector(
    (state) => state.summaryBirthdayReducer
  );
  const { announcementData, announcement_error, announcement_loading } =
    useSelector((state) => state.summaryAnnouncementReducer);

  const [tab, setTab] = useState("Announcement");
  const [openDialouge, setOpenDialouge] = useState(false);
  const [itemID, setItemID] = useState("");
  const [commentID, setCommentID] = useState("");
  const [reason, setReason] = useState("");
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  const [departmentOpen, setDepartmentOpen] = useState(false);
  const [createPost, setCreatePost] = useState(false);
  const [holiday, setHoliday] = useState({
    date: "",
    name: "",
    type: "",
    reason: "",
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });
  const [comments, setComments] = useState({});
  const [announce, setAnnounce] = useState({
    empid: companyInfo.userid,
    empname: companyInfo.username,
    subject: "",
    tag: [],
    description: "",
    attachment: "",
    image: "",
    day: new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }),
    position: "",
    companyemail: companyInfo.companyemail,
    companyid: companyInfo.companyid,
  });
  const [cmnt, setCmnt] = useState("");
  //for success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getBackgroundImageAndMessage = () => {
    const currentHour = new Date().getHours();
    let backgroundImage, greetingMessage;
    if (currentHour >= 12 && currentHour < 18) {
      // Afternoon image and message
      backgroundImage = afternoonImage;
      greetingMessage = "Good Afternoon";
    } else if (currentHour >= 18) {
      // Night image and message
      backgroundImage = nightImage;
      greetingMessage = "Good Evening";
    } else {
      // Morning image and message
      backgroundImage = morningImage;
      greetingMessage = "Good Morning";
    }
    return { backgroundImage, greetingMessage };
  };

  const [backgroundImage, setBackgroundImage] = useState(() => {
    const { backgroundImage } = getBackgroundImageAndMessage();
    return backgroundImage;
  });
  const [greetingMessage, setGreetingMessage] = useState(() => {
    const { greetingMessage } = getBackgroundImageAndMessage();
    return greetingMessage;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const { backgroundImage, greetingMessage } =
        getBackgroundImageAndMessage();
      setBackgroundImage(backgroundImage);
      setGreetingMessage(greetingMessage);
    }, 1000 * 60 * 60);

    return () => clearInterval(interval);
  }, []);

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const items = Array.from({ length: 6 }, (_, index) => ({
    id: index + 1,
    color: generateRandomColor(),
  }));

  const settings = {
    //dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    //autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    cssEase: "linear",
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleAddPost = () => {
    if (announce.description == "") {
      setMsg("Description is required");
      setAlertType("error");
      setOpen(true);
      return;
    }
    dispatch(
      addSummaryAnnouncement({ ...announce }, setOpen, setMsg, setAlertType)
    );
    setAnnounce({
      empid: companyInfo.userid,
      empname: companyInfo.username,
      subject: "",
      tag: [],
      description: "",
      attachment: "",
      image: "",
      day: new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      time: new Date().toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }),
      position: "",
      companyemail: companyInfo.companyemail,
      companyid: companyInfo.companyid,
    });
    setCreatePost(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHoliday({ ...holiday, [name]: value });
  };
  const handleCommentChange = (e, empId) => {
    const { value } = e.target;
    setComments({ ...comments, [empId]: value });
  };
  const handlePostChange = (e) => {
    const { name, value } = e.target;
    setAnnounce({ ...announce, [name]: value });
  };
  const handleQuillChange = (value, name) => {
    setAnnounce({ ...announce, [name]: value });
  };
  const handleSave = () => {
    // console.log("data to be save", holiday);
    dispatch(addSummaryHoliday(holiday, setOpen, setMsg, setAlertType));
    setHoliday({
      date: "",
      name: "",
      type: "",
      reason: "",
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
  };
  const handleAddComments = (id, name) => {
    dispatch(
      addSummaryBirthday(
        {
          empid: id,
          empname: name,
          message: comments[id],
          userid: companyInfo.userid,
          username: companyInfo.username,
          companyid: companyInfo.companyid,
          companyemail: companyInfo.companyemail,
        },
        setOpen,
        setMsg,
        setAlertType
      )
    );
    setComments({ ...comments, [id]: "" });
  };
  const handleOpenComment = (id) => {
    console.log(id, "comment");
    setCommentID(id);
    if (commentID === id) {
      // If the same comment is clicked again, close the editor
      setCommentID("");
    } else {
      setCommentID(id);
    }
  };
  const handleCommentEdit = () => {
    const selectedData = birthdayData.find(
      (birthday) => birthday.id == commentID
    );
    dispatch(updateSummaryBirthday({ ...selectedData, message: cmnt }));
    setCmnt("");
    setCommentID("");
  };
  const handleDeleteComment = (id) => {
    dispatch(deleteSummaryBirthday({ idarray: [id] }));
  };
  const handleDialougeOpen = (id) => {
    setOpenDialouge(!openDialouge);
    setItemID(id);
  };

  const handleUpdate = () => {
    const selectedData = holidayData.find((holiday) => holiday.id == itemID);
    if (reason == "") {
      setShowEmptyError(true);
      return;
    }
    dispatch(
      updateSummaryHoliday(
        { ...selectedData, reason: reason },
        setOpen,
        setMsg,
        setAlertType
      )
    );
    setReason("");
    setOpenDialouge(false);
  };

  const handleTag = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };
  const handleCloseTag = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };
  const handleListRole = () => {
    setRoleOpen(!roleOpen);
  };
  const handleListDepartment = () => {
    setDepartmentOpen(!departmentOpen);
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "grey",
        width: "40px",
        height: "40px",
      },
      children: name ? `${name.split(" ")[0][0]}` : "",
    };
  }

  const getEvent = async () => {
    let reqBody = { companyid, companyemail };

    try {
      setLoading1(true);
      let res = await axios.post(getEvents, reqBody,{withCredentials:true});
      res.data && res.data.length > 0 ? setEvents(res.data) : setEvents([]);
      setLoading1(false);
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error While Fetching Events");
      setLoading1(false);
    }
  };

  useEffect(() => {
    dispatch(
      getPayrollFILLING({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );

    dispatch(
      getSummaryHoliday({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
    dispatch(
      getEmpReg({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
    dispatch(
      getSummaryBirthday({
        companyid: companyid,
        companyemail: companyemail,
      })
    );
    dispatch(
      getSummaryAnnouncement({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
    getEvent();
  }, [dispatch]);

  console.log(announcementData, "announcement data");

  return (
    <>
      <Box className="common-bg-color">
        {userrole.toLowerCase() != "masteradmin" ? (
          <Grid container>
            <Grid item sm={12} xs={12} md={8} lg={8} p={0.8}>
              <Box
                className="welcome-banner"
                style={{ backgroundImage: `url(${backgroundImage})` }}
                marginBottom={1}
              >
                <Box className="welcome-text2">
                  <h1>{greetingMessage}</h1>
                  <p>Howdy, {companyInfo.username}</p>
                </Box>
              </Box>

              <Box borderRadius={3} p={1} className="bg-white">
                <p className="view-page-title fw-b">My Favourites</p>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={1}
                  flexWrap={"wrap"}
                >
                  {boxes.map((box, ind) => (
                    <Grid
                      item
                      p={1}
                      key={ind}
                      borderRadius={3}
                      sx={{ backgroundColor: getBoxColor(box.name) }}
                      flexGrow={1}
                      className="cursor-pointer"
                      onClick={() => navigate(box.path)}
                    >
                      <IconButton sx={{ marginBottom: 5 }}>
                        {box.icon}
                      </IconButton>
                      <p>{box.name}</p>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Stack direction="row" gap={1} mt={1}>
                <Box
                  className="bg-white"
                  padding={"10px"}
                  borderRadius={"15px"}
                  width={"60%"}
                >
                  <h3>Useful Links</h3>
                  <Box
                    marginTop={"10px"}
                    display={"grid"}
                    gridTemplateColumns={"repeat(2, 1fr)"}
                    rowGap={"10px"}
                    columnGap={"5px"}
                  >
                    {fillingData.length > 0 &&
                      fillingData.map((chip) => (
                        <Chip
                          component="a"
                          variant="outlined"
                          clickable
                          target="_blank"
                          href={chip.link}
                          label={chip.name}
                        />
                      ))}
                  </Box>
                </Box>
                <Box
                  className="bg-white"
                  padding={"10px"}
                  borderRadius={"15px"}
                  width={"40%"}
                >
                  <Box flexGrow={1} alignItems={"center"}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      flexDirection={"row"}
                      gap={1}
                      pl={1}
                    >
                      <p className="view-page-title">Events</p>
                    </Box>
                    <Divider />
                    <Box
                      flex={1}
                      p={0.5}
                      height={"300px"}
                      className="events"
                      sx={{ overflowY: "auto" }}
                    >
                      {events.map((event, index) => (
                        <Box
                          key={index}
                          sx={{
                            marginBottom: "10px",
                            padding: "15px",
                            borderRadius: "8px",
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Typography variant="h6" gutterBottom>
                            {event.eventname}
                          </Typography>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={1}
                          >
                            <Typography variant="body2">
                              <strong>Start:</strong> {event.startdate}{" "}
                              {event.starttime}
                            </Typography>
                            <Typography variant="body2">
                              <strong>End:</strong> {event.enddate}{" "}
                              {event.endtime}
                            </Typography>
                          </Box>
                          <Typography variant="body2" mb={1}>
                            <strong>Location:</strong> {event.location}
                          </Typography>
                          <Box mt={1}>
                            {event.participant.map((participant, idx) => (
                              <Chip
                                key={idx}
                                label={participant}
                                sx={{
                                  marginRight: "5px",
                                  marginBottom: "5px",
                                  backgroundColor: "#e0e0e0",
                                  color: "#000",
                                }}
                              />
                            ))}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Divider />
                  <Stack>
                    {notifications.map((notification, index) => (
                      <Box
                        key={index}
                        padding={"10px"}
                        borderBottom={"1px solid #ddd"}
                      >
                        {notification}
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </Stack>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} p={0.8} flex={1}>
              <Box
                className="bg-white"
                padding={"10px"}
                marginTop={"2px"}
                borderRadius={"15px"}
                display={"flex"}
                justifyContent={"space-between"}
                gap={2}
              >
                <Button
                  startIcon={<GrAnnounce />}
                  className={
                    tab == "Announcement"
                      ? "secondnav-active-btn"
                      : "second-nav-btn"
                  }
                  onClick={() => setTab("Announcement")}
                >
                  Announcement
                </Button>
                <Button
                  startIcon={<CakeIcon />}
                  className={
                    tab == "Birthday"
                      ? "secondnav-active-btn"
                      : "second-nav-btn"
                  }
                  onClick={() => setTab("Birthday")}
                >
                  Birthday
                </Button>
                <Button
                  startIcon={<CalendarMonthIcon />}
                  className={
                    tab == "Holidays"
                      ? "secondnav-active-btn"
                      : "second-nav-btn"
                  }
                  onClick={() => setTab("Holidays")}
                >
                  Holidays
                </Button>
              </Box>
              <Box
                className="bg-white"
                padding={"10px"}
                mt={1}
                borderRadius={"15px"}
                sx={{ flex: 1, overflowY: "auto", height: "750px" }}
              >
                {tab == "Announcement" && (
                  <Box>
                    <Button
                      startIcon={<IoCreateOutline />}
                      className="saveBtn"
                      onClick={() => setCreatePost(true)}
                    >
                      Create Post
                    </Button>

                    {createPost && (
                      <Box
                        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                        padding={"10px"}
                        marginTop={"15px"}
                        borderRadius={"15px"}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          marginBottom={"5px"}
                        >
                          <Box display={"flex"} alignItems={"center"} gap={2}>
                            <Avatar
                              {...stringAvatar(
                                companyInfo && companyInfo.username
                              )}
                            />
                            <Typography>{companyInfo.username}</Typography>
                          </Box>
                          <IconButton
                            id="demo-positioned-button"
                            aria-controls={
                              menuOpen ? "demo-positioned-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={menuOpen ? "true" : undefined}
                            onClick={handleTag}
                          >
                            <GroupAddIcon />
                          </IconButton>
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleCloseTag}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem>
                              <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="Everyone"
                              />
                            </MenuItem>
                            <MenuItem>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                              >
                                <ListItemButton onClick={handleListRole}>
                                  <ListItemText primary="Role" />
                                  {roleOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse
                                  in={roleOpen}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding>
                                    <ListItemButton
                                      sx={{ pl: 4, display: "grid" }}
                                    >
                                      <FormControlLabel
                                        control={<Checkbox />}
                                        label="Frontend Developer"
                                      />
                                      <FormControlLabel
                                        control={<Checkbox />}
                                        label="Backend Developer"
                                      />
                                    </ListItemButton>
                                  </List>
                                </Collapse>
                              </List>
                            </MenuItem>
                            <MenuItem>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                              >
                                <ListItemButton onClick={handleListDepartment}>
                                  <ListItemText primary="Department" />
                                  {departmentOpen ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </ListItemButton>
                                <Collapse
                                  in={departmentOpen}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding>
                                    <ListItemButton
                                      sx={{ pl: 4, display: "grid" }}
                                    >
                                      <FormControlLabel
                                        control={<Checkbox />}
                                        label="Software Department"
                                      />
                                      <FormControlLabel
                                        control={<Checkbox />}
                                        label="Analytics Department"
                                      />
                                    </ListItemButton>
                                  </List>
                                </Collapse>
                              </List>
                            </MenuItem>
                          </Menu>
                        </Box>
                        <Divider />
                        <TextField
                          className="formInputs"
                          fullWidth
                          variant="standard"
                          placeholder="Add a subject"
                          margin="normal"
                          name="subject"
                          value={announce.subject}
                          onChange={handlePostChange}
                        />
                        <Box style={{ height: "250px" }}>
                          <ReactQuill
                            style={{
                              height: "190px",
                              marginBottom: "20px",
                              borderRadius: "5px",
                            }}
                            theme="snow"
                            value={announce.description}
                            onChange={(value) =>
                              handleQuillChange(value, "description")
                            }
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline", "strike"],
                                [{ align: [] }],
                                [{ list: "ordered" }, { list: "bullet" }],
                                [{ indent: "-1" }, { indent: "+1" }],
                                ["link", "blockquote", "code-block"],
                                ["clean"],
                              ],
                            }}
                            formats={[
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "align",
                              "list",
                              "bullet",
                              "indent",
                              "link",
                              "blockquote",
                              "code-block",
                            ]}
                          />
                        </Box>
                        <Box
                          mt={2}
                          display={"flex"}
                          justifyContent={"flex-end"}
                        >
                          <Button
                            className="cancelBtn"
                            onClick={() => setCreatePost(false)}
                          >
                            Cancel
                          </Button>
                          <Button className="saveBtn" onClick={handleAddPost}>
                            Post
                          </Button>
                        </Box>
                      </Box>
                    )}

                    {announcement_loading ? (
                      <Loader />
                    ) : announcementData?.length === 0 ? (
                      <h3 style={{ textAlign: "center", marginTop: "10px" }}>
                        No Post Found
                      </h3>
                    ) : (
                      announcementData.map((ele, i) => (
                        <Box
                          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                          padding={"10px"}
                          marginTop={"15px"}
                          borderRadius={"15px"}
                          key={i}
                        >
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            marginBottom={"5px"}
                          >
                            <Box display={"flex"} alignItems={"center"} gap={2}>
                              {ele.image ? (
                                <img
                                  src={ele.image}
                                  alt={ele.empname}
                                  loading="lazy"
                                  width={"50px"}
                                  height={"50px"}
                                  style={{
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : (
                                <Avatar {...stringAvatar(ele.empname)} />
                              )}
                              <Typography>{ele.empname}</Typography>
                            </Box>
                            <Box>
                              {i === 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Chip
                                    label="New"
                                    sx={{
                                      bgcolor: "var(--common-color)",
                                      color: "white",
                                    }}
                                    size="small"
                                  />
                                </div>
                              )}
                              <Typography fontSize={"12px"}>
                                <span>{ele.day}</span> <span>{ele.time}</span>
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Typography variant="h6" gutterBottom>
                            {ele.subject}
                          </Typography>
                          <ReactQuill
                            value={ele.description}
                            readOnly={true}
                            theme="bubble"
                          />
                        </Box>
                      ))
                    )}
                  </Box>
                )}

                {tab == "Birthday" && (
                  <Box>
                    <Stack>
                      {empData.length > 0 &&
                        empData
                          .filter((emp) => {
                            const dob = new Date(emp.dob);
                            const today = new Date();
                            return (
                              dob.getMonth() === today.getMonth() &&
                              dob.getDate() >= today.getDate()
                            );
                          })
                          .sort((a, b) => {
                            const dayA = new Date(a.dob).getDate();
                            const dayB = new Date(b.dob).getDate();
                            return dayA - dayB;
                          })
                          .map((ele, i) => {
                            const dob = new Date(ele.dob);
                            const today = new Date();
                            const isToday =
                              dob.getDate() === today.getDate() &&
                              dob.getMonth() === today.getMonth();
                            return (
                              <Box
                                boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                                padding={"10px"}
                                key={i}
                                marginBottom={"10px"}
                              >
                                <Typography
                                  variant="h5"
                                  style={{
                                    fontSize: isToday ? "24px" : "inherit",
                                  }}
                                >
                                  {isToday
                                    ? `Happy Birthday ${ele.name}`
                                    : `Upcoming Birthday of ${ele.name}`}
                                </Typography>
                                <br />
                                <p>{!isToday && ele.dob}</p>
                                {isToday && (
                                  <Box>
                                    <Typography variant="h6">
                                      Send your wishes:
                                    </Typography>
                                    <TextField
                                      className="formInputs"
                                      fullWidth
                                      variant="outlined"
                                      placeholder="Write your message"
                                      margin="normal"
                                      name="message"
                                      value={comments[ele.id] || ""}
                                      onChange={(e) =>
                                        handleCommentChange(e, ele.id)
                                      }
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              style={{
                                                color: "var(--common-color)",
                                              }}
                                              onClick={() =>
                                                handleAddComments(
                                                  ele.id,
                                                  ele.name
                                                )
                                              }
                                            >
                                              <SendIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                    <Accordion defaultExpanded>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        sx={{
                                          backgroundColor: "#b2d8d8",
                                          border: "none",
                                          borderRadius: "6px",
                                        }}
                                      >
                                        <Typography>Wishes</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        {birthday_loading ? (
                                          <Loader />
                                        ) : (
                                          birthdayData.length > 0 &&
                                          birthdayData
                                            .filter(
                                              (bth) => bth.empid === ele.id
                                            )
                                            .map((comnt) => (
                                              <Typography
                                                key={comnt.id}
                                                display={"flex"}
                                                justifyContent={"space-between"}
                                              >
                                                <Tooltip
                                                  title={comnt.role}
                                                  arrow
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {truncateText(
                                                      comnt.username,
                                                      18
                                                    )}
                                                  </span>
                                                </Tooltip>

                                                {commentID === comnt.id ? (
                                                  <TextField
                                                    fullWidth
                                                    className="formInputs"
                                                    value={cmnt}
                                                    onChange={(e) =>
                                                      setCmnt(e.target.value)
                                                    }
                                                  />
                                                ) : (
                                                  <Tooltip
                                                    title={comnt.message}
                                                    arrow
                                                  >
                                                    <span>
                                                      {truncateText(
                                                        comnt.message,
                                                        18
                                                      )}
                                                    </span>
                                                  </Tooltip>
                                                )}

                                                <span
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    gap: "5px",
                                                  }}
                                                >
                                                  {commentID === comnt.id &&
                                                  comnt.userid === userid ? (
                                                    <IconButton
                                                      onClick={
                                                        handleCommentEdit
                                                      }
                                                      color="primary"
                                                    >
                                                      <IoSaveOutline />
                                                    </IconButton>
                                                  ) : (
                                                    comnt.userid === userid && (
                                                      <IconButton
                                                        onClick={() =>
                                                          handleOpenComment(
                                                            comnt.id
                                                          )
                                                        }
                                                        color="primary"
                                                      >
                                                        <EditIcon />
                                                      </IconButton>
                                                    )
                                                  )}
                                                  {comnt.userid === userid && (
                                                    <IconButton
                                                      onClick={() =>
                                                        handleDeleteComment(
                                                          comnt.id
                                                        )
                                                      }
                                                      color="error"
                                                    >
                                                      <DeleteIcon />
                                                    </IconButton>
                                                  )}
                                                </span>
                                              </Typography>
                                            ))
                                        )}
                                      </AccordionDetails>
                                    </Accordion>
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                    </Stack>
                  </Box>
                )}
                {tab == "Holidays" && (
                  <Box>
                    {userrole.toLowerCase() == "hr" && (
                      <>
                        <Button
                          className="saveBtn"
                          variant="contained"
                          onClick={() => {
                            if (
                              !holiday.date ||
                              !holiday.name ||
                              !holiday.type
                            ) {
                              setShowEmptyError(true);
                              setMsg("Please fill all the fields");
                              setAlertType("error");
                              setOpen(true);
                              return;
                            } else {
                              handleSave();
                            }
                          }}
                        >
                          Add Holiday
                        </Button>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          marginTop="20px"
                          marginBottom="20px"
                        >
                          <Grid item md={4}>
                            <h4 style={{ marginBottom: "5px" }}>Date</h4>
                            <TextField
                              fullWidth
                              type="date"
                              className="formInputs"
                              value={holiday.date}
                              onChange={handleChange}
                              name="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                inputProps: {
                                  min: new Date().toISOString().split("T")[0], // Restrict to future dates only
                                },
                              }}
                            />
                          </Grid>
                          <Grid item md={4}>
                            <h4 style={{ marginBottom: "5px" }}>Name</h4>
                            <TextField
                              fullWidth
                              className="formInputs"
                              placeholder="Enter Holiday"
                              value={holiday.name}
                              onChange={handleChange}
                              name="name"
                            />
                          </Grid>
                          <Grid item md={4}>
                            <h4 style={{ marginBottom: "5px" }}>Type</h4>
                            <Select
                              fullWidth
                              className="formInputs"
                              inputProps={{
                                className: "selectInput",
                              }}
                              value={holiday.type}
                              onChange={handleChange}
                              name="type"
                            >
                              <MenuItem value="National">National</MenuItem>
                              <MenuItem value="Festival">Festival</MenuItem>
                              <MenuItem value="Optional">Optional</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Box>
                      <TableContainer sx={{ marginTop: "10px" }}>
                        <Table
                          sx={{ minWidth: 100 }}
                          size="small"
                          aria-label="simple table"
                        >
                          <TableHead sx={{ backgroundColor: "#F5F5F5" }}>
                            <TableRow>
                              <TableCell align="center">Date</TableCell>
                              <TableCell align="center">Name</TableCell>
                              <TableCell align="center">Type</TableCell>
                              {userrole.toLowerCase() == "hr" && (
                                <TableCell align="center">Action</TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {holiday_loading ? (
                              <Loader />
                            ) : (
                              holidayData.length > 0 &&
                              holidayData
                                .filter((holiday) => holiday.reason == "")
                                .map((holiday) => (
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                      backgroundColor:
                                        holiday.type == "National"
                                          ? "#AFE1AF"
                                          : holiday.type == "Festival"
                                          ? "lightblue"
                                          : "#D3D3D3",
                                    }}
                                    key={holiday.id}
                                  >
                                    <TableCell align="center">
                                      {holiday.date}
                                    </TableCell>
                                    <TableCell align="center">
                                      {holiday.name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {holiday.type}
                                    </TableCell>
                                    {userrole.toLowerCase() == "hr" && (
                                      <TableCell align="center">
                                        <IconButton
                                          sx={{ color: "#FF3131" }}
                                          onClick={() =>
                                            handleDialougeOpen(holiday.id)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box>
            <MISWelcome />
          </Box>
        )}
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
      <Dialog
        open={openDialouge}
        TransitionComponent={Transition}
        keepMounted
        //onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Delete Reason?</DialogTitle>
        <DialogContent>
          <h4 style={{ marginBottom: "5px" }}>Reason</h4>
          <TextField
            fullWidth
            className="formInputs"
            placeholder="Enter Proper Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            error={!reason && showEmptyError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialouge(!openDialouge)}>Cancel</Button>
          <Button onClick={handleUpdate}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Welcome;
