import React,{useState} from "react";
import Cookies from "js-cookie";


export const AuthContext =React.createContext();

function AuthContextProvider ({children}) {
    const [authContext, setAuthContext] = useState({
        token:  Cookies.get("companytoken") || null,
        userData:{}
    })

    return (<AuthContext.Provider value={{authContext,setAuthContext}}>{children}</AuthContext.Provider>)
}
export default AuthContextProvider;