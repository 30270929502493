import axios from "axios";
import {
  masterPayrollFillingAdd,
  masterPayrollFillingDelete,
  masterPayrollFillingGet,
  masterPayrollFillingTypeMasterAdd,
  masterPayrollFillingTypeMasterDelete,
  masterPayrollFillingTypeMasterGet,
  masterPayrollFillingUpdate,
} from "../../../Constants/api";

export const getPayrollFILLING = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_PR_FILLING_LOADING" });
    let res = await axios.post(masterPayrollFillingGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_PR_FILLING_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_PR_FILLING_FAILED", payload: error.message });
  }
};

export const addPayrollFILLING = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_PR_FILLING_LOADING" });
    let res = await axios.post(masterPayrollFillingAdd, obj,{withCredentials:true});
    if (res.data.message == "Added successfully") {
      dispatch({ type: "ADD_PR_FILLING_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_PR_FILLING_FAILED", payload: error.message });
  }
};

export const updatePayrollFILLING = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_PR_FILLING_LOADING" });
    let res = await axios.post(masterPayrollFillingUpdate, obj,{withCredentials:true});
    if (res.data.message == "Updated successfully") {
      dispatch({
        type: "UPDATE_PR_FILLING_SUCCESS",
        payload: res.data.data,
      });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPDATE_PR_FILLING_FAILED", payload: error.message });
  }
};

export const deletePayrollFILLING =
  (idArray, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_PR_FILLING_LOADING" });
      let res = await axios.post(masterPayrollFillingDelete, idArray,{withCredentials:true});
      if (res.data.message == "Deleted successfully") {
        dispatch({ type: "DELETE_PR_FILLING_SUCCESS", payload: idArray });
        handleClose();
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_PR_FILLING_FAILED", payload: error.message });
    }
  };

//Type Master
export const getType = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_TYPE_LOADING" });
    let res = await axios.post(masterPayrollFillingTypeMasterGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_TYPE_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_TYPE_FAILED", payload: error.message });
  }
};

export const addType = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_TYPE_LOADING" });
    let res = await axios.post(masterPayrollFillingTypeMasterAdd, obj,{withCredentials:true});
    if (res.data.message == "Added successfully") {
      dispatch({ type: "ADD_TYPE_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_TYPE_FAILED", payload: error.message });
  }
};
export const deleteType = (idArray, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_TYPE_LOADING" });
    let res = await axios.post(masterPayrollFillingTypeMasterDelete, idArray,{withCredentials:true});
    if (res.data.message == "Deleted successfully") {
      dispatch({ type: "DELETE_TYPE_SUCCESS", payload: idArray });
      handleClose();
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "DELETE_TYPE_FAILED", payload: error.message });
  }
};
