import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  FormControl,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  List,
  Menu,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";

import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  setMonth,
  setYear,
  subMonths,
  addMonths,
} from "date-fns";

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import AddEvents from "./AddEvents";
import { deleteEventsApi, getEvents } from "../../../../Constants/api";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import Loader from "../../../../Constants/Loader";
import EditEvents from "./EditEvents";

const Events = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";
  let userRole = userData.userrole ? userData.userrole : "";
  let username = userData.username ? userData.username : "";
  let department = userData.department ? userData.department : "";

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [eventData, setEventData] = useState({
    eventname: "",
    startdate: "",
    enddate: "",
    starttime: "",
    endtime: "",
    location: "",
    participant: [],
    companyemail,
    companyid,
  });

  const [editEventData, setEditEventData] = useState({
    eventname: "",
    startdate: "",
    enddate: "",
    starttime: "",
    endtime: "",
    location: "",
    participant: [],
    companyemail,
    companyid,
  });

  const [previousdata, setPreviousdata] = useState({});

  //for alert messages
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const dateFormat = "MMMM yyyy";
  const daysOfWeekFormat = "E";

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart);
  const endDate = endOfWeek(monthEnd);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setEventData({
      eventname: "",
      startdate: "",
      enddate: "",
      starttime: "",
      endtime: "",
      location: "",
      participant: [],
      companyemail,
      companyid,
    });
    getData();
  };

  const editHandleDialogClose = () => {
    setEditDialogOpen(false);
    setEditEventData({
      eventname: "",
      startdate: "",
      enddate: "",
      starttime: "",
      endtime: "",
      location: "",
      participant: [],
      companyemail,
      companyid,
    });
    setPreviousdata({});
    getData();
  };

  const handleMonthChange = (e) => {
    setCurrentDate(setMonth(currentDate, e.target.value));
  };

  const handleYearChange = (e) => {
    setCurrentDate(setYear(currentDate, e.target.value));
  };

  const handleDateClick = (date) => {
    if (userRole.toLowerCase() == "user") {
      setAlertType("error");
      setOpen(true);
      setMsg("Un Authorised Access");
      return;
    }

    setEventData({
      ...eventData,
      startdate: format(date, "yyyy-MM-dd"),
    });
    setDialogOpen(true);
  };

  const handlePrevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const handleAddEventClick = () => {
    if (userRole.toLowerCase() == "user") {
      setAlertType("error");
      setOpen(true);
      setMsg("Un Authorised Access");
      return;
    }
    setDialogOpen(true);
  };

  //for events
  const isEventDate = (date) => {
    return data.some((event) => {
      const startDate = new Date(event.startdate);
      const endDate = new Date(event.enddate);
      return (
        date.getFullYear() === startDate.getFullYear() &&
        date.getMonth() === startDate.getMonth() &&
        date.getDate() >= startDate.getDate() &&
        date.getDate() <= endDate.getDate()
      );
    });
  };

  const getEventDetails = (date) => {
    const event = data.find((event) => {
      const startDate = new Date(event.startdate);
      const endDate = new Date(event.enddate);
      return (
        date.getFullYear() === startDate.getFullYear() &&
        date.getMonth() === startDate.getMonth() &&
        date.getDate() >= startDate.getDate() &&
        date.getDate() <= endDate.getDate()
      );
    });
    return event
      ? `${event.eventname} (${format(
          new Date(event.startdate),
          "MMM d"
        )} - ${format(new Date(event.enddate), "MMM d")})`
      : "";
  };

  const renderDays = () => {
    const days = [];
    let startDate = startOfWeek(currentDate);

    for (let i = 0; i < 7; i++) {
      days.push(
        <Grid item xs key={i} my={1}>
          <Typography align="center">
            {format(addDays(startDate, i), daysOfWeekFormat)}
          </Typography>
        </Grid>
      );
    }
    return <Grid container>{days}</Grid>;
  };

  const renderCells = () => {
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, "d");
        const cloneDay = day;
        const eventDetails = getEventDetails(cloneDay);
        const isEvent = isEventDate(cloneDay);

        const isToday =
          format(day, "dd-MM-yyyy") === format(currentDate, "dd-MM-yyyy");
        const isCurrentMonth =
          format(day, "MM-yyyy") === format(currentDate, "MM-yyyy");

        days.push(
          <Grid item xs key={day} sx={{ textAlign: "center", marginBottom: 1 }}>
            {isEvent ? (
              <Tooltip title={eventDetails} arrow>
                <Box
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="center"
                  width={80}
                  height={75}
                  borderRadius="5px"
                  bgcolor="#e0f7fa"
                  color="#000"
                  cursor="default"
                  fontSize="1.2rem"
                >
                  {formattedDate}
                </Box>
              </Tooltip>
            ) : (
              <Box
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                width={80}
                height={75}
                borderRadius="5px"
                bgcolor={isToday ? "#1976d2" : isCurrentMonth ? "#fff" : "none"}
                color={isToday ? "#fff" : isCurrentMonth ? "#000" : "#9e9e9e"}
                cursor="pointer"
                fontSize="1.2rem"
                border={isToday ? "2px solid #1976d2" : "none"}
                sx={{
                  ...(format(day, "E") === "Sun" && {
                    color: "red",
                    opacity: 0.6,
                  }),
                }}
                onClick={() => handleDateClick(cloneDay)}
              >
                {formattedDate}
              </Box>
            )}
          </Grid>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <Grid
          container
          justifyContent="center"
          key={day}
          sx={{ marginBottom: 1 }}
        >
          {days}
        </Grid>
      );
      days = [];
    }
    return <>{rows}</>;
  };

  const getData = async () => {
    let reqBody;
    if (userRole == "User") {
      reqBody = { companyid, companyemail, department };
    } else {
      reqBody = { companyid, companyemail };
    }
    try {
      setLoading(true);
      let res = await axios.post(getEvents, reqBody,{withCredentials:true});
      res.data && res.data.length > 0 ? setData(res.data) : setData([]);
      setLoading(false);
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error While Fetching Events");
      setLoading(false);
    }
  };

  const handleDelete = async (event) => {
    // console.log(event, "event");
    try {
      let res = await axios.post(deleteEventsApi, {
        companyid: event.companyid,
        companyemail: event.companyemail,
        participant: event.participant,
        eventname: event.eventname,
      },{withCredentials:true});
      if (res.data.message == "Event deleted successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("Event deleted");
        getData();
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg("Error While Deleting Event");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // console.log(data,"data events")

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        gap={2.5}
        flexWrap={"wrap-reverse"}
      >
        <Box  flexGrow={1.3}>
          <Box my={1}>
            <Typography variant={"h6"} textAlign="center" py={1}>
              Events
            </Typography>
            <Divider />
          </Box>
          <Box
            my={1}
            className="events-list"
            height={"80vh"}
            sx={{ overflowY: "auto", overflowX: "hidden" }}
          >
            {loading ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Loader />
              </Box>
            ) : (
              <List>
                {data?.map((event, index) => (
                  <ListItem
                    key={index}
                    sx={{ mb: 1, ml: 1, borderRadius: 1 }}
                    className="listShadow"
                  >
                    <ListItemText
                      primary={
                        <Typography style={{ fontSize: "1.25rem" }}>
                          {event.eventname}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography
                            component="span"
                            style={{ color: "#2F9CCC" }}
                          >
                            {`${format(
                              new Date(event.startdate),
                              "MMM d"
                            )} - ${format(new Date(event.enddate), "MMM d")}`}
                          </Typography>
                          <Typography>{` ${event.participant.join(
                            ", "
                          )}`}</Typography>
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        color="primary"
                        onClick={() => {
                          if (userRole.toLowerCase() == "user") {
                            setAlertType("error");
                            setOpen(true);
                            setMsg("Un Authorised Access");
                            return;
                          }
                          setEditEventData((prev) => ({ ...prev, ...event }));
                          setPreviousdata({ ...event });
                          setEditDialogOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        color="error"
                        onClick={() => {
                          if (userRole.toLowerCase() == "user") {
                            setAlertType("error");
                            setOpen(true);
                            setMsg("Un Authorised Access");
                            return;
                          }

                          handleDelete(event);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>
        <Box  flexGrow={2}>
          <Box display={"flex"} flexDirection={"column"}>
            <Box
              flexGrow={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              px={6}
              py={1}
              my={1}
              flexWrap={"wrap"}
            >
              <IconButton onClick={handlePrevMonth}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6">
                {format(currentDate, dateFormat)}
              </Typography>
              <IconButton onClick={handleNextMonth}>
                <ArrowForwardIcon />
              </IconButton>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                flexWrap={"wrap-reverse"}
              >
                <Box>
                  <FormControl variant="outlined" size="small">
                    <Select
                      value={currentDate.getMonth()}
                      onChange={handleMonthChange}
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <MenuItem key={i} value={i}>
                          {format(setMonth(currentDate, i), "MMMM")}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ marginLeft: 2 }}
                  >
                    <Select
                      value={currentDate.getFullYear()}
                      onChange={handleYearChange}
                    >
                      {Array.from({ length: 21 }, (_, i) => (
                        <MenuItem
                          key={i}
                          value={currentDate.getFullYear() - 10 + i}
                        >
                          {currentDate.getFullYear() - 10 + i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Button
                  className="saveBtn"
                  startIcon={<AddIcon />}
                  onClick={handleAddEventClick}
                >
                  Add Event
                </Button>
              </Box>
            </Box>
            <Box flexGrow={2} className="commonShadow">
              {renderDays()}
              {renderCells()}
            </Box>
          </Box>
        </Box>
      </Box>
      <AddEvents
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
        eventData={eventData}
        setEventData={setEventData}
      />
      <EditEvents
        editDialogOpen={editDialogOpen}
        editHandleDialogClose={editHandleDialogClose}
        editEventData={editEventData}
        setEditEventData={setEditEventData}
        previousdata={previousdata}
      />
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default Events;
