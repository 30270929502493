import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { addAttendanceShift } from "../../../../redux/actions/actions.master/attendanceShift.actions";

const AddShift = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);

  const [data, setData] = useState({
    shiftname: "",
    shiftcode: "",
    color: "",
    timezone: companyInfo.timezone,
    intime: "",
    outtime: "",
    halfday: "",
    fullday: "",
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSave = () => {
    // console.log("data to be save", data);
    dispatch(addAttendanceShift(data));
    setData({
      shiftname: "",
      shiftcode: "",
      color: "",
      timezone: companyInfo.timezone,
      intime: "",
      outtime: "",
      halfday: "",
      fullday: "",
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
    setOpenDrawer(false)
  };
  return (
    <>
      <Button
        className="saveBtn"
        variant="contained"
        onClick={() => setOpenDrawer(true)}
      >
        Add
      </Button>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box width={700} display="flex" flexDirection="column" height="100%">
          <Box p={2} flexGrow={1} overflow="auto">
            <Typography
              variant="h6"
              color={"#062C41"}
              display={"flex"}
              fontWeight={600}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={1}
            >
              Add Shift
              <span>
                <IconButton
                  onClick={() => {
                    setData({
                      shiftname: "",
                      shiftcode: "",
                      color: "",
                      timezone: companyInfo.timezone,
                      intime: "",
                      outtime: "",
                      halfday: "",
                      fullday: "",
                      companyid: companyInfo.companyid,
                      companyemail: companyInfo.companyemail,
                    });
                    setOpenDrawer(false);
                  }}
                >
                  <CancelIcon sx={{ color: "black" }} />
                </IconButton>
              </span>
            </Typography>
            <Divider />
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Shift Name</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="Enter Shit Name"
                  value={data.shiftname}
                  onChange={handleChange}
                  name="shiftname"
                  error={!data.shiftname && showEmptyError}
                  helperText={
                    !data.shiftname &&
                    showEmptyError &&
                    "Shift Name is required"
                  }
                />
              </Grid>
              <Grid item md={2.9}>
                <h4 style={{ marginBottom: "5px" }}>Shift Code</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.shiftcode}
                  onChange={handleChange}
                  name="shiftcode"
                />
              </Grid>
              <Grid item md={2.9}>
                <h4 style={{ marginBottom: "5px" }}>Select Color</h4>
                <Select
                  fullWidth
                  className="formInputs"
                  inputProps={{
                    className: "selectInput",
                  }}
                  value={data.color}
                  onChange={handleChange}
                  name="color"
                >
                  <MenuItem value="#ff0000">Red</MenuItem>
                  <MenuItem value="#00ff00">Green</MenuItem>
                  <MenuItem value="#0000ff">Blue</MenuItem>
                  <MenuItem value="#ffff00">Yellow</MenuItem>
                  <MenuItem value="#ff00ff">Magenta</MenuItem>
                  <MenuItem value="#00ffff">Cyan</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Time Zone</h4>
                <TextField
                  disabled
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.timezone}
                  onChange={handleChange}
                  name="timezone"
                />
              </Grid>
              <Grid item md={2.9}>
                <h4 style={{ marginBottom: "5px" }}>In Time</h4>
                <TextField
                  type="time"
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.intime}
                  onChange={handleChange}
                  name="intime"
                  error={!data.intime && showEmptyError}
                  helperText={
                    !data.intime &&
                    showEmptyError &&
                    "Starting Time is required"
                  }
                />
              </Grid>
              <Grid item md={2.9}>
                <h4 style={{ marginBottom: "5px" }}>Out Time</h4>
                <TextField
                  type="time"
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.outtime}
                  onChange={handleChange}
                  name="outtime"
                  error={!data.outtime && showEmptyError}
                  helperText={
                    !data.outtime && showEmptyError && "Ending Time is required"
                  }
                />
              </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            <h4 style={{ marginBottom: "5px" }}>
              Minimum working hours to mark present.
            </h4>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid
                item
                md={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <h4>For Half Day</h4>
                <TextField
                  sx={{ width: "40%" }}
                  type="time"
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.halfday}
                  onChange={handleChange}
                  name="halfday"
                  error={!data.halfday && showEmptyError}
                  helperText={
                    !data.halfday &&
                    showEmptyError &&
                    "Total Work Hour is required"
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid
                item
                md={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <h4>For Full Day</h4>
                <TextField
                  sx={{ width: "40%" }}
                  type="time"
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.fullday}
                  onChange={handleChange}
                  name="fullday"
                  error={!data.fullday && showEmptyError}
                  helperText={
                    !data.fullday &&
                    showEmptyError &&
                    "Total Work Hour is required"
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            mt="auto"
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            bgcolor={"background.paper"}
            p={2}
            display={"flex"}
            flexDirection={"row-reverse"}
          >
            <Button
              className="saveBtn"
              variant="contained"
              onClick={() => {
                if (
                  !data.shiftname ||
                  !data.intime ||
                  !data.outtime ||
                  !data.halfday ||
                  !data.fullday
                ) {
                  setShowEmptyError(true);
                  return;
                } else {
                  handleSave();
                }
              }}
            >
              Save
            </Button>
            <Button
              className="cancelBtn"
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setData({
                  shiftname: "",
                  shiftcode: "",
                  color: "",
                  timezone: companyInfo.timezone,
                  intime: "",
                  outtime: "",
                  halfday: "",
                  fullday: "",
                  companyid: companyInfo.companyid,
                  companyemail: companyInfo.companyemail,
                });
                setOpenDrawer(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AddShift;
