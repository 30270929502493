import {
  Box,
  Checkbox,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import Loader from "../../../../../Constants/Loader";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getEmpExit } from "../../../../../redux/actions/actions.hrms/employeeExit.actions";
import { formatDate } from "../../../../../Constants/dateFormat";

const NoticePeriod = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emp_exit_loading, emp_exit_error, empExitData } = useSelector(
    (state) => state.empExitReducer
  );
  const [searchValue, setSearchValue] = useState("")
  
  const handleSearch = (event) => {
    // Handle search logic here
    setSearchValue(event.target.value)
  };
  const filterData = () => {
    return empExitData&& empExitData.length > 0 && empExitData?.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();
  const rows = filteredData;


  const handleRowClick = (params) => {
    navigate(`/hrm/employeemanagement/employeeexit/noticeperiod/update/${params.row.id}`);
  };

  const columns = [
    
    {
      field: "empid",
      headerName: "ID",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
    },
    {
      field: "reportmanager",
      headerName: "Reporting To",
      flex: 1,
    },
    {
      field: "applyingdate",
      headerName: "Applying Date",
      flex: 1,
      renderCell:(params)=><>
      {formatDate(params.row.applyingdate)}
      </>
    },
    {
      field: "resignationdate",
      headerName: "Resignation Date",
      flex: 1,
      renderCell:(params)=><>
      {params.row.resignationdate?formatDate(params.row.resignationdate):"-"}
      </>
    },
    {
      field: "lwddate",
      headerName: "LWD Date",
      flex: 1,
      renderCell:(params)=><>
      {params.row.lwddate?formatDate(params.row.lwddate):"-"}
      </>
    },
    {
      field: "expectlwddate",
      headerName: "Expected LWD Date",
      flex: 1,
      renderCell:(params)=><>
      {params.row.expectlwddate?formatDate(params.row.expectlwddate):"-"}
      </>
    },
    {
      field: "shortfalldays",
      headerName: "Shortfall Days",
      flex: 1,
    },
    {
      field: "leavingreason",
      headerName: "Leaving Reason",
      flex: 1,
    },
  ];

  useEffect(() => {
    dispatch(
      getEmpExit({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);
  return (
    <>
      <Box>
        <Box className="searchbar-container">
          <Hidden smDown>
            <Box>
              {/* Content to be shown only on screens larger than or equal to sm (600px) */}
            </Box>
          </Hidden>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <TextField
              placeholder="Search…"
              variant="outlined"
              className="searchbar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </Box>
        </Box>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={emp_exit_loading ? [] : rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            onRowClick={handleRowClick}
            pageSizeOptions={[
             5,10,15,20
            ]}
            autoHeight
            components={{
              LoadingOverlay: <Loader />,
            }}
            loading={emp_exit_loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default NoticePeriod;
