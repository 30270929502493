const initialState = {
  loading: false,
  error: "",
  fillingData: [],
};
export const prFILLINGReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_PR_FILLING_LOADING":
    case "ADD_PR_FILLING_LOADING":
    case "UPDATE_PR_FILLING_LOADING":
    case "DELETE_PR_FILLING_LOADING":
      return { ...state, loading: true };

    case "GET_PR_FILLING_SUCCESS":
      return { ...state, loading: false, fillingData: payload };
    case "ADD_PR_FILLING_SUCCESS":
      return {
        ...state,
        loading: false,
        fillingData: [...state.fillingData, payload],
      };
    case "UPDATE_PR_FILLING_SUCCESS":
      return {
        ...state,
        loading: false,
        fillingData: state.fillingData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };
    case "DELETE_PR_FILLING_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        fillingData: state.fillingData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_PR_FILLING_FAILED":
    case "UPDATE_PR_FILLING_FAILED":
    case "GET_PR_FILLING_FAILED":
    case "DELETE_PR_FILLING_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

const typeState = {
  loading: false,
  error: "",
  typeMaster: [],
};

export const typeReducer = (state = typeState, { type, payload }) => {
  switch (type) {
    case "GET_TYPE_LOADING":
    case "ADD_TYPE_LOADING":
    case "DELETE_TYPE_LOADING":
      return { ...state, loading: true };

    case "GET_TYPE_SUCCESS":
      return { ...state, loading: false, typeMaster: payload };

    case "ADD_TYPE_SUCCESS":
      return {
        ...state,
        loading: false,
        typeMaster: [...state.typeMaster, payload],
      };

    case "DELETE_TYPE_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        typeMaster: state.typeMaster.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_TYPE_FAILED":
    case "GET_TYPE_FAILED":
    case "DELETE_TYPE_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
