const initialState = {
  loading: false,
  error: "",
  leaveTypeData: [],
};

const typeState = {
  loading: false,
  error: "",
  typeMaster: [],
};

export const leaveTypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_LEAVE_TYPE_LOADING":
    case "ADD_LEAVE_TYPE_LOADING":
    case "UPDATE_LEAVE_TYPE_LOADING":
    case "DELETE_LEAVE_TYPE_LOADING":
      return { ...state, loading: true };

    case "GET_LEAVE_TYPE_SUCCESS":
      return { ...state, loading: false, leaveTypeData: payload };

    case "ADD_LEAVE_TYPE_SUCCESS":
      return {
        ...state,
        loading: false,
        leaveTypeData: [...state.leaveTypeData, payload],
      };

    case "UPDATE_LEAVE_TYPE_SUCCESS":
      return {
        ...state,
        loading: false,
        leaveTypeData: state.leaveTypeData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case "DELETE_LEAVE_TYPE_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        leaveTypeData: state.leaveTypeData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_LEAVE_TYPE_FAILED":
    case "UPDATE_LEAVE_TYPE_FAILED":
    case "GET_LEAVE_TYPE_FAILED":
    case "DELETE_LEAVE_TYPE_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

export const typeMasterReducer = (state = typeState, { type, payload }) => {
  switch (type) {
    case "GET_TYPE_LOADING":
    case "ADD_TYPE_LOADING":
    case "DELETE_TYPE_LOADING":
      return { ...state, loading: true };

    case "GET_TYPE_SUCCESS":
      return { ...state, loading: false, typeMaster: payload };

    case "ADD_TYPE_SUCCESS":
      return {
        ...state,
        loading: false,
        typeMaster: [...state.typeMaster, payload],
      };

    case "DELETE_TYPE_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        typeMaster: state.typeMaster.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_TYPE_FAILED":
    case "GET_TYPE_FAILED":
    case "DELETE_TYPE_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
