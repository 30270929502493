

import dayjs from "dayjs";

export const allowanceObjects = {
  basic: "Basic Allowance",
  houserent: "House Rent Allowance",
  internet: "Telephone & Internet Allowances",
  travel: "Travel Allowance",
  vehicle: "Vehicle & Driver,fuel Allowance",
  conveyance: "Conveyance Allowance",
  otherallowance: "Other Allowances",
  otherdeduction: "Other Deductions",
  pfemployee: "PF Employee",
  pfemployer: "PF Employer",
  esicemployee: "ESIC Employee",
  esicemployer: "ESIC Employer",
  ptax: "Professional Tax",
  tds: "TDS",
  special: "Special Allowance",
  education: "Education Allowance",
  medical: "Medical Allowance",
  insurance: "Insurances",
};

// Transform data to graph for payroll  function goes here
export const transformData = (data) => {
  const graphArray = [];

  // Group data by year and month
  const groupedData = data.reduce((acc, curr) => {
    const { year, month, totalgross, deduction, earning } = curr;

    if (!acc[year]) {
      acc[year] = {};
    }
    if (!acc[year][month]) {
      acc[year][month] = { totalgross: 0, totaldeduction: 0, totalEarning: 0 };
    }
    acc[year][month].totalgross += parseFloat(totalgross || 0);
    acc[year][month].totaldeduction += parseFloat(deduction || 0);
    acc[year][month].totalEarning += parseFloat(earning || 0);

    return acc;
  }, {});

  // Create the graphArray in the required format
  for (const year in groupedData) {
    const monthSpent = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].map((month) => ({
      month,
      totalgross: groupedData[year][month]?.totalgross || 0,
      totaldeduction: groupedData[year][month]?.totaldeduction || 0,
      totalEarning: groupedData[year][month]?.totalEarning || 0,
    }));

    graphArray.push({ year, monthSpent });
  }

  // console.log(graphArray,"outside graph show")

  return graphArray;
};

export const excelHeaders = [
  "Employee ID",
  "Position",
  "Role",
  "Department",
  "Sub Department",
  "Reporting Manager",
  "Level",
  "Gender",
  "Email",
  "Name",
  "Status",
  "Month",
  "Year",
  "CTC",
  "Basic Allowances",
  "House Rent Allowance",
  "Special Allowance",
  "Educational Allowances",
  "Medical Allowances",
  "Insurances",
  "Telephone & Internet Allowances",
  "Vehicle & Driver Fuel Allowances",
  "Travel Allowances",
  "Other Allowances",
  "Other Deductions",
  "EPF",
  "PF Employee",
  "PF Employer",
  "ESIC Employee Pay",
  "ESIC Employer",
  "Conveyance Pay",
  "Professional Tax",
  "TDS",
  "Hold Status",
  "Gross Pay",
  "EPF Wages",
  "EWS Wages",
  "EDLI Wages",
  "EE",
  "EPS",
  "ER",
  "Bank",
  "Accountno.",
  "Account Holder",
  "IFSC",
  "Incentives",
  "Earning",
  "Total Gross",
  "LOP",
  "Deduction",
];

export const dummyExceldata = [
  [
    "EMP001",
    "Manager",
    "Sales Manager",
    "Sales",
    "Regional",
    "John Doe",
    "5",
    "Male",
    "john.doe@example.com",
    "John Doe",
    "Active",
    "January",
    "2024",
    "100000",
    "50000",
    "20000",
    "5000",
    "3000",
    "1204",
    "2000",
    "1500",
    "1000",
    "500",
    "1000",
    "200",
    "2000",
    "1000",
    "500",
    "1000",
    "200",
    "1000",
    "200",
    "100",
    "Active",
    "300",

    "90000",
    "80000",
    "70000",
    "500",
    "300",
    "200",
    "Bank of America",
    "1234567890",
    "John Doe",
    "IFSC123",
    "5000",
    "90000",
    "85000",
    "2000",
    "7000",
  ],
  [
    "EMP002",
    "Assistant",
    "HR Assistant",
    "HR",
    "Recruitment",
    "Jane Smith",
    "3",
    "Female",
    "jane.smith@example.com",
    "Jane Smith",
    "Active",
    "February",
    "2024",
    "80000",
    "40000",
    "16000",
    "4000",
    "2400",
    "1600",
    "1200",
    "800",
    "400",
    "800",
    "160",
    "1420",
    "1600",
    "800",
    "400",
    "800",
    "160",
    "800",
    "160",
    "80",
    "Inactive",
    "240",

    "72000",
    "64000",
    "56000",
    "400",
    "240",
    "160",
    "Wells Fargo",
    "0987654321",
    "Jane Smith",
    "IFSC456",
    "4000",
    "72000",
    "68000",
    "1600",
    "5600",
  ],
  // Additional rows can be added similarly
];

export function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + "...";
}

export function getFileNameFromUrl(url) {
  if (!url) {
    return "";
  }
  const parts = url.split("/");
  return parts[parts.length - 1];
}

export const generateAvatarColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 75%, 60%)`;
  return color;
};

export const getInitials = (name) => {
  const names = name.split(" ");
  const initials = names.map((n) => n[0]).join("");
  return initials.toUpperCase();
};

export const AttendanceExcelHeader = [
  "Employee ID",
  "Name",
  "Date",
  "Day",
  "Month",
  "Year",
  "Checkin",
  "Checkout",
  "Total Hours",
];

export const AttendanceExcelBody = [
  [
    "EMP001",
    "John Doe",
    "25/06/2024",
    "Tuesday",
    "June",
    "2024",
    "09:00:00 AM",
    "05:00:00 PM",
    "8.00",
  ],
  [
    "EMP002",
    "Jane Smith",
    "25/06/2024",
    "Tuesday",
    "June",
    "2024",
    "09:30:00 AM",
    "05:15:00 PM",
    "7.75",
  ],
  [
    "EMP003",
    "Alice Johnson",
    "26/06/2024",
    "Wednesday",
    "2024",
    "June",
    "08:45:00 AM",
    "04:30:00 PM",
    "7.75",
  ],
  [
    "EMP004",
    "Bob Brown",
    "26/06/2024",
    "Wednesday",
    "June",
    "2024",
    "10:00:00 AM",
    "06:00:00 PM",
    "8.00",
  ],
  [
    "EMP005",
    "Carol White",
    "27/06/2024",
    "Thursday",
    "June",
    "2024",
    "09:15:00 AM",
    "05:00:00 PM",
    "7.75",
  ],
];


// export const numberToWords = (num) => {
//   if (num === 0) return "Zero";

//   const belowTwenty = [
//     "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen",
//     "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
//   ];
//   const tens = [
//     "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
//   ];
//   const thousands = ["", "Thousand", "Million", "Billion", "Trillion"];

//   const convert = (n) => {
//     if (n < 20) return belowTwenty[n];
//     if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 ? "-" + belowTwenty[n % 10] : "");
//     if (n < 1000) return belowTwenty[Math.floor(n / 100)] + " Hundred" + (n % 100 ? " " + convert(n % 100) : "");
//     return "";
//   };

//   let word = "";
//   let i = 0;

//   while (num > 0) {
//     if (num % 1000 !== 0) {
//       word = convert(num % 1000) + (thousands[i] ? " " + thousands[i] : "") + " " + word;
//     }
//     num = Math.floor(num / 1000);
//     i++;
//   }

//   return word.trim() + " Only";
// };


export function convertToIndianCurrencyWords(amount) {
  const t = (n, s) => n < 1 ? '' : `${t(Math.floor(n / 100), ' Hundred ')}${n % 100 < 20 ? ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'][n % 100] : ['Zero', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'][Math.floor(n / 10)] + (n % 10 ? `-${['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'][n % 10]}` : '')}${n % 10 === 0 ? '' : n > 0 ? s : ''}`;
  const u = [{ v: 1e7, s: ' Crore ' }, { v: 1e5, s: ' Lakh ' }, { v: 1e3, s: ' Thousand ' }, { v: 1e2, s: ' Hundred ' }];
  let r = '';
  u.forEach(({ v, s }) => {
    const q = Math.floor(amount / v);
    r += t(q, s);
    amount -= q * v;
  });
  const rupeesWords = `${r.toUpperCase()}RUPEES`;
  const paise = Math.round((amount - Math.floor(amount)) * 100);
  const paiseWords = t(paise, '');
  return `${rupeesWords}${paise > 0 ? ` AND ${paiseWords.toUpperCase()} PAISE` : ''} ONLY`;
}



export const extractYear = (value) => {
  if (!value) {
    return "";
  }

  // If value is a date string in the format "dd-mm-yyyy" or similar
  const dateMatch = value.match(/\d{2}[-/]\d{2}[-/](\d{4})/);
  if (dateMatch) {
    return dateMatch[1]; // Return the year part from the matched date
  }

  // If value is a year only
  const yearMatch = value.match(/^\d{4}$/);
  if (yearMatch) {
    return yearMatch[0]; // Return the year part
  }

  // If value is a valid Date object or string that can be converted to Date
  const date = new Date(value);
  if (!isNaN(date.getTime())) {
    return date.getFullYear(); // Return the year part
  }

  // Default case
  return value;
};



export function isLeaveTypeExists(leaveTypes, newLeave) {
  // Convert newLeave to lowercase and remove "leave"
  const lowerCaseNewLeave = newLeave.toLowerCase().replace("leave", "").trim();

  // Iterate through the leaveTypes array
  for (let i = 0; i < leaveTypes.length; i++) {
    // Convert the leavetype to lowercase, remove "leave" for comparison
    const strippedLeaveType = leaveTypes[i].leavetype.toLowerCase().replace("leave", "").trim();
    
    // Compare stripped values
    if (strippedLeaveType === lowerCaseNewLeave) {
      // Return true if a match is found
      return true;
    }
  }
  
  // Return false if no match is found
  return false;
}


export function calculateShortfallArray(
  resignationdate,
  expectedlastworkingdate,
  servenoticeperiod,
  lwdasperpolicy,
  shortfalldays
) {
  if (servenoticeperiod === "Yes") {
    return [];
  }

  const resignationDate = dayjs(resignationdate);
  const expectedLastWorkingDate = dayjs(expectedlastworkingdate);

  // Calculate remaining days in the resignation month
  const lastWorkingDayOfMonth = expectedLastWorkingDate.endOf("month").date();
  const remainingDaysInMonth = lastWorkingDayOfMonth - expectedLastWorkingDate.date();

  let currentMonth = expectedLastWorkingDate.startOf("month");
  let remainingShortfall = shortfalldays - remainingDaysInMonth - 1; // Handle extra days in the resignation month

  const shortfallArray = [];

  // Handle the first month's shortfall (remaining days of resignation month)
  shortfallArray.push({
    month: currentMonth.format("MMMM"),
    year: currentMonth.format("YYYY"),
    shortfallinnotice: remainingDaysInMonth + 1, // Add the remaining days
  });

  // Move to the next month
  currentMonth = currentMonth.add(1, "month");

  // Handle the rest of the months
  while (remainingShortfall > 0) {
    const daysInMonth = currentMonth.daysInMonth(); // Get actual days in the current month
    const shortfallInMonth = Math.min(remainingShortfall, daysInMonth); // Allocate shortfall based on actual days in the month

    shortfallArray.push({
      month: currentMonth.format("MMMM"),
      year: currentMonth.format("YYYY"),
      shortfallinnotice: shortfallInMonth,
    });

    remainingShortfall -= shortfallInMonth; // Subtract allocated shortfall
    currentMonth = currentMonth.add(1, "month"); // Move to the next month
  }

  return shortfallArray;
}

export const processResumeScore = (data) => {
  if(!data && !data.length){
    return []
  }else{
    return data.map(item => {
      // Extract match percentage, keywords missing, and final thoughts from the score string
      const scoreMatch = item.Score.match(/\*\*Match Percentage\*\* \| (\d+%) \|/);
      const keywordsMatch = item.Score.match(/\*\*Keywords Missing\*\* \| (.*?) \|/);
      const finalThoughtsMatch = item.Score.match(/\*\*Final Thoughts\*\* \| (.*?) \|/);
  
      return {
        ...item,
        Score: {
          matchpercentage: scoreMatch ? scoreMatch[1] : "N/A",
          keywordmissing: keywordsMatch ? keywordsMatch[1].trim().replace(/\n/g, ', ') : "None",
          finalthoughts: finalThoughtsMatch ? finalThoughtsMatch[1].trim() : "No final thoughts provided"
        }
      };
    });
  }
};