import {
  Box,
  Button,
  IconButton,
  Tooltip,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  Typography,
  Divider,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import { RiSendPlaneLine } from "react-icons/ri";
import { LuEye } from "react-icons/lu";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { BsEye } from "react-icons/bs";
import { BsSendArrowUp } from "react-icons/bs";
import { DataGrid } from "@mui/x-data-grid";

import {
  formatDate,
  getColorForStatus,
  getOnboardStatus,
} from "../../../../Constants/dateFormat";
import { getShortlistedApplications } from "../../../../redux/InterView/actions";
import { getRecruitment } from "../../../../redux/recruitmentprocess/actions";
import Loader from "../../../../Constants/Loader";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import {
  getInterviewScheduledTemplate,
  getOfferLetterTemplate,
  getOnboardingEmailTemplate,
} from "../../../../Constants/MailTemplates";
import axios from "axios";
import ViewDetails from "./ViewDetails";
import { formpage } from "../../../../Constants/api";

const OnBoarding = () => {
  // COOKIES PART
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let companyname = userData.companyname ? userData.companyname : "";
  let userRole = userData.userrole ? userData.userrole : "";
  // COOKIES PART

  const navigate = useNavigate();

  // REDUX PART
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state) => state.interViewReducer
  );
  const { data: recruitmentMaster } = useSelector(
    (state) => state.recruitmentReducer
  );

  // for drawer
  const [selectedRowId, setSelectedRowId] = useState(null);

  // for view modal
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (paramsRow) => {
    setSelectedRow(paramsRow.id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRow(null);
  };

  // for alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Dialog and form states
  const [dialogOpen, setDialogOpen] = useState(false);
  const [statusDropdownValue, setStatusDropdownValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [currentRow, setCurrentRow] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);

  const handleStatusClick = (row) => {
    if (row.status === "Reject" || row.status == "Joined") return;

    setCurrentRow(row);
    setStatusDropdownValue("");
    setInputValue("");

    let dropdownOptions = [];
    if (row.status === "Offerletter sent") {
      dropdownOptions = ["Reject"];
    } else if (row.status === "submitted document") {
      dropdownOptions = ["Verify", "Reject"];
    } else if (row.status === "Verify") {
      dropdownOptions = ["Reject"];
    }

    setStatusOptions(dropdownOptions);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentRow(null);
  };

  const handleStatusUpdate = async () => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/jobapplication/onboardstatus",
        {
          id: currentRow.id,
          obj: {
            date: new Date(),
            status: statusDropdownValue,
            remarks: inputValue,
          },
        },{withCredentials:true}
      );
      if (res.data.message == "Status updated successfully") {
        setOpen(true);
        setAlertType("success");
        setMsg("Status Updated..");
      }
    } catch (error) {
      console.log(error, "error");
      setOpen(true);
      setAlertType("error");
      setMsg(error.message);
    }

    setDialogOpen(false);
  };

  const interviewColumn = [
    { field: "candidateid", headerName: "CID" },
    { field: "createdAt", headerName: "Applied Date" },
    { field: "position", headerName: "Position", flex: 1 },
    { field: "fullname", headerName: "Name", flex: 1 },
    { field: "budget", headerName: "Budget", flex: 1 },
    { field: "expectedctc", headerName: "ECTC in LPA" },
    { field: "finalctc", headerName: "Final CTC in LPA" },
    { field: "joiningdate", headerName: "DOJ" },
    {
      field: "cv",
      headerName: "Resume",
      renderCell: (params) =>
        params.value ? (
          <IconButton onClick={() => handleJDClick(params.row.cv)}>
            <AttachFileIcon />
          </IconButton>
        ) : null,
      cellClassName: "MuiDataGrid-cell--withRenderer",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      cellClassName: "MuiDataGrid-cell--withRenderer",
      renderCell: (params) => (
        <Button
          sx={{
            color: getColorForStatus(params.row.status),
          }}
          onClick={() => handleStatusClick(params.row)}
        >
          {getOnboardStatus(params.row.status)}
        </Button>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Copy Form Link" placement="top-end">
            <IconButton>
              <ContentCopyIcon
                onClick={() => handleCopyClick(params.row.data)}
              />
            </IconButton>
          </Tooltip>
          {params.row.status == "Offerletter sent" && (
            <Tooltip title="Send Onboarding Mail" placement="top-end">
              <IconButton onClick={() => sendOnboardMail(params.row.data)}>
                <RiSendPlaneLine />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="View Details" placement="top-end">
            <IconButton onClick={() => handleRowClick(params.row)}>
              <LuEye />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      cellClassName: "MuiDataGrid-cell--withRenderer",
    },
  ];

  const interviewRow =
    data.length > 0
      ? data
          .filter(
            (appl) =>
              appl.onboardstatushistory && appl.onboardstatushistory.length > 0
          )
          .map((application) => {
            const recruitMent = recruitmentMaster.find(
              (recruit) => recruit.id === application.recruitmentid
            );

            return {
              id: application.id,
              candidateid: application.candidateid,
              createdAt: formatDate(application.createdAt),
              position: application.position,
              fullname: application.fullname,
              budget: recruitMent
                ? `${recruitMent.frombudget} - ${recruitMent.tobudget}`
                : "-",
              expectedctc: application.expectedctc,
              finalctc: application.finalctc,
              joiningdate: application.joiningdate
                ? formatDate(application.joiningdate)
                : "N/A",
              cv: application.cv,
              status: application.onboardstatushistory
                ? application.onboardstatushistory[
                    application.onboardstatushistory.length - 1
                  ]?.status
                : "N/A",
              data: application,
            };
          })
      : [];

  const handleJDClick = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop() || "File";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sendOnboardMail = (data) => {
    // console.log(data, "data");
    const formLink = `${formpage}/onboarddocuments-upload?key=${data.companyid}&jaid=${data.id}&recid=${data.recruitmentid}&cid=${data.candidateid}`;

    const emailContent = getOnboardingEmailTemplate(
      data,
      companyname,
      formLink
    );

    const mailtoLink = `mailto:${
      data.email
    }?subject=${companyname} Onboarding &body=${encodeURIComponent(
      emailContent
    )}`;
    window.location.href = mailtoLink;
  };

  const handleCopyClick = (data) => {
    const linkToCopy = `${formpage}/onboarddocuments-upload?key=${data.companyid}&jaid=${data.id}&recid=${data.recruitmentid}&cid=${data.candidateid}`;

    if (copy(linkToCopy)) {
      setAlertType("success");
      setOpen(true);
      setMsg("Link copied successfully");
    } else {
      setAlertType("error");
      setOpen(true);
      setMsg("Failed to copy");
    }
  };

  useEffect(() => {
    dispatch(
      getShortlistedApplications(
        { companyid, companyemail },
        setOpen,
        setMsg,
        setAlertType
      )
    );
  }, [dispatch, dialogOpen]);

  useEffect(() => {
    dispatch(getRecruitment({ companyid, companyemail }));
  }, [dispatch]);

  const onboardStatusObj = {
    "Document submitted": ["Verify", "Reject"],
    "Offerletter sent": ["Reject"],
    Verify: ["Reject", "Joined"],
  };

  // console.log(currentRow, "list interview data");

  return (
    <>
      <Box my={2}></Box>

      <Box sx={{ height: "auto", width: "100%" }}>
        <DataGrid
          rows={loading ? [] : interviewRow}
          columns={interviewColumn}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20, interviewRow.length]}
          autoHeight
          components={{
            LoadingOverlay: Loader,
          }}
          loading={loading}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
      <ViewDetails
        open={modalOpen}
        handleClose={handleCloseModal}
        rowId={selectedRow}
      />
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
      {dialogOpen && (
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
            <Typography
              color={"#000A14"}
              fontSize={"1.3rem"}
              fontWeight={"500"}
              textAlign={"center"}
            >
              Update Status
            </Typography>

            <IconButton onClick={handleDialogClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Divider />
          <DialogContent
            px={2}
            sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2, gap: 2 }}
          >
            <Box display="flex" flexDirection="column" mb={1.5}>
              <Select
                value={statusDropdownValue}
                onChange={(e) => setStatusDropdownValue(e.target.value)}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select Status
                </MenuItem>
                {onboardStatusObj[currentRow.status].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                label="Remarks"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                sx={{ mt: 2 }}
                fullWidth
              />
            </Box>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              gap={1}
            >
              <Button
                onClick={handleDialogClose}
                className="cancelBtn"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button className="saveBtn" onClick={handleStatusUpdate}>
                Update
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default OnBoarding;
