import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import SavingsIcon from "@mui/icons-material/Savings";
import SpeedIcon from "@mui/icons-material/Speed";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import PaidIcon from "@mui/icons-material/Paid";
import PolicyIcon from "@mui/icons-material/Policy";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineEditCalendar } from "react-icons/md";
import { RiTaskLine } from "react-icons/ri";
import { TbReportSearch } from "react-icons/tb";
import HomeIcon from "@mui/icons-material/Home";


import Cookies from "js-cookie";
let userInfo = null;
let userrole = null;
let planname = null;

const userInfoCookie = Cookies.get("userinfo");

if (userInfoCookie) {
  try {
    userInfo = JSON.parse(userInfoCookie);
    userrole = userInfo?.userrole;
    planname = userInfo?.planname;

    // console.log(userrole, "userrole");
  } catch (error) {
    console.error("Error parsing userinfo cookie:", error);
  }
}

export const routes1 = [
  {
    path: "/hrm",
    name: "Hrm",
    iconComponent: <GroupsIcon />,
    subRoutes: [
      {
        path: "/hrm/summary",
        name: "Home",
        iconComponent: <HomeIcon />,
        subRoutes: [
          {
            path: "/hrm/summary/welcome",
            name: "Welcome",
          },
          // {
          //   path: "/hrm/summary/dashboard",
          //   name: "Dashboard",
          // },
          // {
          //   path: "/hrm/summary/MIS",
          //   name: "MIS",
          // },
        ],
      },
      {
        path: "/hrm/recruitmanagement",
        name: "Recruitments",
        iconComponent: <PersonAddAlt1Icon />,
        subRoutes: [
          {
            path: "/hrm/recruitmanagement/recruit",
            name: "Recruitment",
          },
          {
            path: "/hrm/recruitmanagement/recruitprocess",
            name: "Recruitment Process",
          },
          {
            path: "/hrm/recruitmanagement/interviewprocess",
            name: "Interview Process",
          },
          {
            path: "/hrm/recruitmanagement/onboarding",
            name: "Onboarding Process",
          },
        ],
      },
      {
        path: "/hrm/employeemanagement",
        name: "Employee Management",
        iconComponent: <Diversity1Icon />,
        subRoutes: [
          {
            path: "/hrm/employeemanagement/registration",
            name: "Registration",
          },
          {
            path: "/hrm/employeemanagement/orgchart",
            name: "Organization Chart",
          },
          {
            path: "/hrm/employeemanagement/timeandattendance",
            name: "Time & Attendance",
          },
          {
            path: "/hrm/employeemanagement/reimbursement",
            name: "Reimbursement",
          },
          {
            path: "/hrm/employeemanagement/leavemanagement",
            name: "Leave Management",
          },
          {
            path: "/hrm/employeemanagement/documentgenerate",
            name: "Document Generation",
          },
          {
            path: "/hrm/employeemanagement/employeeexit",
            name: "Employee Exit",
          },
        ],
      },
      {
        path: "/hrm/employeeselfservice",
        name: "Employee Self-Service",
        iconComponent: <FollowTheSignsIcon />,
        subRoutes: [
          {
            path: "/hrm/employeeselfservice/summary",
            name: "Summary",
          },
          {
            path: "/hrm/employeeselfservice/timendattendance",
            name: "Time & Attendance",
          },
          {
            path: "/hrm/employeeselfservice/reimbursement",
            name: "Reimbursement",
          },
          {
            path: "/hrm/employeeselfservice/leave",
            name: "Leave Management",
          },
          {
            path: "/hrm/employeeselfservice/employee-exit",
            name: "Employee Exit",
          },
          {
            path: "/hrm/employeeselfservice/empdocs",
            name: "Employee Documents",
          },
        ],
      },
      {
        path: "/hrm/task&events",
        name: "Task & Events",
        iconComponent: <RiTaskLine />,
        subRoutes: [
          {
            path: "/hrm/task&events/tasks",
            name: "Task",
          },
          {
            path: "/hrm/task&events/events",
            name: "Events",
          },
        ],
      },
      {
        path: "/hrm/payroll",
        name: "Payroll",
        iconComponent: <SavingsIcon />,
        subRoutes: [
          {
            path: "/hrm/payroll/payrollinputs",
            name: "Payroll Inputs",
          },
          {
            path: "/hrm/payroll/salary",
            name: "Salary",
          },
          // {
          //   path: "/hrm/payroll/loan",
          //   name: "Loan",
          // },
          {
            path: "/hrm/payroll/reports",
            name: "Reports",
          },
        ],
      },

      {
        path: "/hrm/performance",
        name: "Performance",
        iconComponent: <SpeedIcon />,
        subRoutes: [
          {
            path: "/hrm/performance/summary",
            name: "Summary",
          },
          {
            path: "/hrm/performance/orggoalkpi",
            name: "Organization Goal KPI",
          },
          {
            path: "/hrm/performance/empdevkpi",
            name: "Employee Developement KPI",
          },
          {
            path: "/hrm/performance/teambuildkpi",
            name: "Team Building KPI",
          },
        ],
      },
      {
        path: "/hrm/compliance",
        name: "Compliance",
        iconComponent: <AssignmentTurnedInIcon />,
        subRoutes: [
          {
            path: "/hrm/compliance",
            name: "Compliance",
          },
        ],
      },
      {
        path: "/hrm/report",
        name: "Report",
        iconComponent: <MdOutlineDashboard />,
        subRoutes: [
          {
            path: "/hrm/report/analytics",
            name: "Analytics",
          },
          {
            path: "/hrm/report/stats",
            name: "Stats",
          },
        ],
      },
    ],
  },
  (userrole == "HR" || userrole == "masteradmin")? {
    path: "/settings",
    name: "Settings",
    iconComponent: <SettingsIcon />,
    subRoutes: [
      {
        path: "/settings/general",
        name: "General",
        iconComponent: <SettingsIcon />,
        subRoutes: [
          {
            path: "/settings/general/invoicepaysliplogo",
            name: "Invoice/Payslip Logo",
          },
          {
            path: "/settings/general/values",
            name: "Values",
          },
        ],
      },
      {
        path: "/settings/employees",
        name: "Employees",
        iconComponent: <GroupsIcon />,
        subRoutes: [
          {
            path: "/settings/employees/employeeseries",
            name: "Employee Series",
          },
          {
            path: "/settings/employees/employeemasters",
            name: "Employee Masters",
          },
          {
            path: "/settings/employees/leavingreason",
            name: "Leaving Reason",
          },
          {
            path: "/settings/employees/assetgroupsetting",
            name: "Asset Group Setting",
          },
          {
            path: "/settings/employees/configureemployeedirectory",
            name: "Configure Employee Directory",
          },
        ],
      },
      {
        path: "/settings/payroll",
        name: "Payroll",
        iconComponent: <PaidIcon />,
        subRoutes: [
          // {
          //   path: "/settings/payroll/lwfpolicies",
          //   name: "LWF Policies",
          // },
          // {
          //   path: "/settings/payroll/pfptesilwffiling",
          //   name: "PF,PT,ESI & LWF Filing",
          // },
          {
            path: "/settings/payroll/structures",
            name: "Structure",
          },
          {
            path: "/settings/payroll/reimbursementreviewer",
            name: "Reimbursement Reviewer",
          },
        ],
      },
      // {
      //   path: "/settings/policies",
      //   name: "Policies",
      //   iconComponent: <PolicyIcon />,
      //   subRoutes: [
      //     {
      //       path: "/settings/policies/policies",
      //       name: "Policies",
      //     },
      //   ],
      // },
      {
        path: "/settings/leave",
        name: "Leave",
        iconComponent: <ExitToAppIcon />,
        subRoutes: [
          {
            path: "/settings/leave/leavetype",
            name: "Leave Type",
          },
          {
            path: "/settings/leave/regularize",
            name: "Regularize",
          },
          // {
          //   path: "/settings/leave/weekendpolicy",
          //   name: "Weekend Policy",
          // },
          // {
          //   path: "/settings/leave/leavescheme",
          //   name: "Leave Scheme",
          // },
        ],
      },
     
      {
        path: "/settings/attendance",
        name: "Attendance",
        iconComponent: <EventAvailableIcon />,
        subRoutes: [
          {
            path: "/settings/attendance/shift",
            name: "Shift",
          },
        ],
      },
      {
        path: "/settings/performance",
        name: "Performance",
        iconComponent: <SpeedIcon />,
        subRoutes: [
          {
            path: "/settings/performance/organizationKpi",
            name: "Organization Goal KPI",
          },
          {
            path: "/settings/performance/employeeDevelopementKpi",
            name: "Employee Developement KPI",
          },
          {
            path: "/settings/performance/teambuildingKpi",
            name: "Team Building KPI",
          },
          // {
          //   path: "/settings/performance/structure",
          //   name: "Structure",
          // },
        ],
      },
    ],
  } : 
  (userrole == "Manager") ?
  {
    path: "/settings",
    name: "Settings",
    iconComponent: <SettingsIcon />,
    subRoutes: [
      {
        path: "/settings/performance",
        name: "Performance",
        iconComponent: <SpeedIcon />,
        subRoutes: [
          {
            path: "/settings/performance/organizationKpi",
            name: "Organization Goal KPI",
          },
          {
            path: "/settings/performance/employeeDevelopementKpi",
            name: "Employee Developement KPI",
          },
          {
            path: "/settings/performance/teambuildingKpi",
            name: "Team Building KPI",
          },
          // {
          //   path: "/settings/performance/structure",
          //   name: "Structure",
          // },
        ],
      },
    ],
  }:
  {
   
  }
];

export const otherroutes = [];

export const userroute = [
  {
    path: "/hrm",
    name: "Hrm",
    iconComponent: <GroupsIcon />,
    subRoutes: [
      // {
      //   path: "/hrm/summary",
      //   name: "Home",
      //   iconComponent: <MdOutlineDashboard />,
      //   subRoutes: [
      //     {
      //       path: "/hrm/summary/welcome",
      //       name: "Welcome",
      //     },
      //     {
      //       path: "/hrm/summary/dashboard",
      //       name: "Dashboard",
      //     },
      //     {
      //       path: "/hrm/summary/MIS",
      //       name: "MIS",
      //     },
      //   ],
      // },
      // {
      //   path: "/hrm/recruitmanagement",
      //   name: "Recruitments",
      //   iconComponent: <PersonAddAlt1Icon />,
      //   subRoutes: [
      //     {
      //       path: "/hrm/recruitmanagement/recruit",
      //       name: "Recruitment",
      //     },
      //     {
      //       path: "/hrm/recruitmanagement/recruitprocess",
      //       name: "Recruitment Process",
      //     },
      //     {
      //       path: "/hrm/recruitmanagement/interviewprocess",
      //       name: "Interview Process",
      //     },
      //     {
      //       path: "/hrm/recruitmanagement/onboarding",
      //       name: "Onboarding Process",
      //     },
      //   ],
      // },
      // {
      //   path: "/hrm/employeemanagement",
      //   name: "Employee Management",
      //   iconComponent: <Diversity1Icon />,
      //   subRoutes: [
      //     {
      //       path: "/hrm/employeemanagement/registration",
      //       name: "Registration",
      //     },
      //     {
      //       path: "/hrm/employeemanagement/orgchart",
      //       name: "Organization Chart",
      //     },
      //     {
      //       path: "/hrm/employeemanagement/timeandattendance",
      //       name: "Time & Attendance",
      //     },
      //     {
      //       path: "/hrm/employeemanagement/leavemanagement",
      //       name: "Leave Management",
      //     },
      //     {
      //       path: "/hrm/employeemanagement/documentgenerate",
      //       name: "Document Generation",
      //     },
      //     {
      //       path: "/hrm/employeemanagement/employeeexit",
      //       name: "Employee Exit",
      //     },
      //   ],
      // },
      {
        path: "/hrm/employeeselfservice",
        name: "Employee Self-Service",
        iconComponent: <FollowTheSignsIcon />,
        subRoutes: [
          {
            path: "/hrm/employeeselfservice/summary",
            name: "Summary",
          },
          {
            path: "/hrm/employeeselfservice/timendattendance",
            name: "Time & Attendance",
          },
          {
            path: "/hrm/employeeselfservice/reimbursement",
            name: "Reimbursement",
          },
          {
            path: "/hrm/employeeselfservice/leave",
            name: "Leave Management",
          },
          {
            path: "/hrm/employeeselfservice/employee-exit",
            name: "Employee Exit",
          },
          {
            path: "/hrm/employeeselfservice/empdocs",
            name: "Employee Documents",
          },
        ],
      },
      {
        path: "/hrm/task&events",
        name: "Task & Events",
        iconComponent: <RiTaskLine />,
        subRoutes: [
          {
            path: "/hrm/task&events/tasks",
            name: "Task",
          },
          {
            path: "/hrm/task&events/events",
            name: "Events",
          },
        ],
      },
      // {
      //   path: "/hrm/payroll",
      //   name: "Payroll",
      //   iconComponent: <SavingsIcon />,
      //   subRoutes: [
      //     {
      //       path: "/hrm/payroll/payrollinputs",
      //       name: "Payroll Inputs",
      //     },
      //     {
      //       path: "/hrm/payroll/salary",
      //       name: "Salary",
      //     },
      //     // {
      //     //   path: "/hrm/payroll/loan",
      //     //   name: "Loan",
      //     // },
      //     {
      //       path: "/hrm/payroll/reports",
      //       name: "Reports",
      //     },
      //   ],
      // },

      // {
      //   path: "/hrm/performance",
      //   name: "Performance",
      //   iconComponent: <SpeedIcon />,
      //   subRoutes: [
      //     {
      //       path: "/hrm/performance/summary",
      //       name: "Summary",
      //     },
      //     {
      //       path: "/hrm/performance/orggoalkpi",
      //       name: "Organization Goal KPI",
      //     },
      //     {
      //       path: "/hrm/performance/empdevkpi",
      //       name: "Employee Developement KPI",
      //     },
      //     {
      //       path: "/hrm/performance/teambuildkpi",
      //       name: "Team Building KPI",
      //     },
      //   ],
      // },
      {
        path: "/hrm/compliance",
        name: "Compliance",
        iconComponent: <AssignmentTurnedInIcon />,
        subRoutes: [
          {
            path: "/hrm/compliance",
            name: "Compliance",
          },
        ],
      },
    ],
  },
];
