import React, { useState } from "react";
import { Button, Snackbar, Alert } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AlertPopUp from "../../../../Constants/AlertPopUp";

const theme = createTheme({
  palette: {
    primary: {
      main: "#388e3c", // Green color for the "Shortlisted" button
    },
    secondary: {
      main: "#757575", // Grey color for the "Cancel" button
    },
  },
});

function MultiStatusChangePopUp({ selectedRows, multiHandleClose,multiStatusChange }) {


  return (
    <>
      <ThemeProvider theme={theme}>
        <div>
          <Snackbar
            open={selectedRows.length > 0}
            //   autoHideDuration={6000}
            onClose={multiHandleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={multiHandleClose}
              severity="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Change the status Of selected Rows!
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={()=>multiStatusChange(selectedRows,"Paid")}
                  sx={{ marginLeft: 1 }}
                >
                  Paid
                </Button>
                <Button
                  color="info"
                  variant="contained"
                  size="small"
                  onClick={()=>multiStatusChange(selectedRows,"Process")}
                  sx={{ marginLeft: 1 }}
                >
                  Process
                </Button>
              </div>
            </Alert>
          </Snackbar>
        </div>
      </ThemeProvider>
    </>
  );
}

export default MultiStatusChangePopUp;
