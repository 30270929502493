const remTypeState = {
  tloading: false,
  terror: "",
  remTypeData: [],
};
export const prRemTypeReducer = (state = remTypeState, { type, payload }) => {
  switch (type) {
    case "GET_PR_RM_TYPE_LOADING":
    case "ADD_PR_RM_TYPE_LOADING":
    case "DELETE_PR_RM_TYPE_LOADING":
      return { ...state, tloading: true };

    case "GET_PR_RM_TYPE_SUCCESS":
      return { ...state, tloading: false, remTypeData: payload };
    case "ADD_PR_RM_TYPE_SUCCESS":
      return {
        ...state,
        tloading: false,
        remTypeData: [...state.remTypeData, payload],
      };
    case "DELETE_PR_RM_TYPE_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        tloading: false,
        remTypeData: state.remTypeData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_PR_RM_TYPE_FAILED":
    case "GET_PR_RM_TYPE_FAILED":
    case "DELETE_PR_RM_TYPE_FAILED":
      return { ...state, tloading: false, error: payload };

    default:
      return state;
  }
};

const remReviewState = {
  rloading: false,
  rerror: "",
  remReviewData: [],
};
//-------------------------------------------------------------------------//
export const prRemReviewReducer = (
  state = remReviewState,
  { type, payload }
) => {
  switch (type) {
    case "GET_PR_RM_REVIEW_LOADING":
    case "ADD_PR_RM_REVIEW_LOADING":
    case "DELETE_PR_RM_REVIEW_LOADING":
      return { ...state, rloading: true };

    case "GET_PR_RM_REVIEW_SUCCESS":
      return { ...state, rloading: false, remReviewData: payload };
    case "ADD_PR_RM_REVIEW_SUCCESS":
      return {
        ...state,
        rloading: false,
        remReviewData: [...state.remReviewData, payload],
      };
    case "DELETE_PR_RM_REVIEW_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        rloading: false,
        remReviewData: state.remReviewData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_PR_RM_REVIEW_FAILED":
    case "GET_PR_RM_REVIEW_FAILED":
    case "DELETE_PR_RM_REVIEW_FAILED":
      return { ...state, rloading: false, error: payload };

    default:
      return state;
  }
};
