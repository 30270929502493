const initialState = {
  loading: false,
  error: "",
  positionData: [],
};

const positionState = {
  loading: false,
  error: "",
  positionMaster: [],
};

export const empPositionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_EMP_POSITION_LOADING":
    case "ADD_EMP_POSITION_LOADING":
    case "UPDATE_EMP_POSITION_LOADING":
    case "DELETE_EMP_POSITION_LOADING":
      return { ...state, loading: true };

    case "GET_EMP_POSITION_SUCCESS":
      return { ...state, loading: false, positionData: payload };

    case "ADD_EMP_POSITION_SUCCESS":
      return {
        ...state,
        loading: false,
        positionData: [...state.positionData, payload],
      };

    case "UPDATE_EMP_POSITION_SUCCESS":
      return {
        ...state,
        loading: false,
        positionData: state.positionData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case "DELETE_EMP_POSITION_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        positionData: state.positionData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_EMP_POSITION_FAILED":
    case "UPDATE_EMP_POSITION_FAILED":
    case "GET_EMP_POSITION_FAILED":
    case "DELETE_EMP_POSITION_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

export const positionReducer = (state = positionState, { type, payload }) => {
  switch (type) {
    case "GET_POSITION_LOADING":
    case "ADD_POSITION_LOADING":
    case "DELETE_POSITION_LOADING":
      return { ...state, loading: true };

    case "GET_POSITION_SUCCESS":
      return { ...state, loading: false, positionMaster: payload };

    case "ADD_POSITION_SUCCESS":
      return {
        ...state,
        loading: false,
        positionMaster: [...state.positionMaster, payload],
      };

    case "DELETE_POSITION_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        positionMaster: state.positionMaster.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_POSITION_FAILED":
    case "GET_POSITION_FAILED":
    case "DELETE_POSITION_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
