import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { Editor as WysiwygEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Cookies from "js-cookie";
import axios from "axios";
import { FaCloudUploadAlt } from "react-icons/fa";
import { CiCloud } from "react-icons/ci";
import { useDropzone } from "react-dropzone";
import AddAssessment from "../AddCompliance/AddAssessment";
import WestIcon from "@mui/icons-material/West";
import JoditEditor from "jodit-react";

function UpdateCompliance() {
  const editor = useRef(null);

  const { id } = useParams();
  const navigate = useNavigate();
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const userrole = userInfo.userrole;
  const department = userInfo.department;
  const companyemail = userInfo.companyemail;

  const [data, setData] = useState({
    id,
    compliancename: "",
    description: "",
    tags: [],
    thumbnail: "",
    assessmentid: "",
    file: "",
    companyid,
    email,
    username,
    userid,
    userrole,
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  //For add assessment
  const [openAddAssessment, setOpenAddAssessment] = useState(false);
  const [assessmentId, setAssessmentId] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    axios
      .post(`https://hrms.vliv.app/compliance/getbyid`, { id },{withCredentials:true})
      .then((res) => {
        // console.log(res.data, "compliandce editor res")
        const fetchedData = res.data;
        // const contentState = convertFromRaw(fetchedData.description);
        setData(fetchedData);
        setAssessmentId(fetchedData.assessmentid);
        // setEditorState(EditorState.createWithContent(contentState));
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    setData((prevData) => ({
      ...prevData,
      description: rawContent,
    }));
  };
  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleDescriptionChange = (newContent) => {
    setData((prevValues) => ({
      ...prevValues,
      description: newContent,
    }));
  };

  const handleTagInputKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const newTag = tagInput.trim();
      if (newTag && !data.tags.includes(newTag)) {
        // setTags([...tags, newTag]);
        setData((prevData) => ({
          ...prevData,
          tags: [...data.tags, newTag],
        }));
      }
      setTagInput("");
    }
  };
  const handleDeleteTag = (tagToDelete) => () => {
    // setTags(tags.filter((tag) => tag !== tagToDelete));
    setData((prevData) => ({
      ...prevData,
      tags: data.tags.filter((tag) => tag !== tagToDelete),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //   For thumbnail
  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB

    if (file.size > 500 * 1024 * 1024) {
      // 500MB in bytes
      alert(
        "File size exceeds 500MB. Please upload a file smaller than 500MB."
      );
      return;
    }

    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    axios
      .post("https://hrms.vliv.app/compliance/upload", formData,{withCredentials:true}, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          thumbnail: res.data.file,
        }));

        if (res.data.file) {
          axios
            .post(`https://hrms.vliv.app/storage/addsize`, {
              size: fileSizeInGB,
              companyid,
              companyemail,
            },{withCredentials:true})
            .then((res) => {
              // console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };
  //   For file upload
  const handleFileChange = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    axios
      .post("https://hrms.vliv.app/compliance/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },{withCredentials:true})
      .then((res) => {
        setData((prev) => ({
          ...prev,
          file: res.data.file,
        }));
      })
      .catch((err) => console.log(err));
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB

    if (file.size > 500 * 1024 * 1024) {
      // 500MB in bytes
      alert(
        "File size exceeds 500MB. Please upload a file smaller than 500MB."
      );
      return;
    }

    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    axios
      .post("https://hrms.vliv.app/compliance/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },{withCredentials:true})
      .then((res) => {
        setData((prev) => ({
          ...prev,
          file: res.data.file,
        }));

        if (res.data.file) {
          axios
            .post(`https://hrms.vliv.app/storage/addsize`, {
              size: fileSizeInGB,
              companyid,
              companyemail,
            },{withCredentials:true})
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleBack = () => {
    navigate("/hrm/compliance");
  };

  const handleUpdate = () => {
    if (
      data.compliancename == "" ||
      data.description == "" ||
      data.thumbnail == ""
    ) {
      return alert("Kindly add required fields");
    }
    axios
      .post(`https://hrms.vliv.app/compliance/update`, {
        ...data,
        assessmentid: assessmentId,
      },{withCredentials:true})
      .then((res) => {
        // console.log(res.data, "Update respons")
        alert(res.data.message);
        navigate("/hrm/compliance");
      })
      .catch((err) => console.log(err));
  };
  //   console.log(data, "data");
  return (
    <Box sx={{ background: "#f0f9ff" }}>
      {/* <Box sx={{ display: "flex", padding: 1 }}>
          <Typography
            sx={{
              width: "5vw",
              color: "grey",
              // border: "solid 1px grey",
              padding: "1px",
              borderRadius: "5px",
            }}
            onClick={handleBack}
          >
            <IoMdArrowRoundBack
            style={{ width: "30px", height: "30px", color: "black" }}
          />
          </Typography>
        </Box> */}

      <Button
        sx={{ marginLeft: 1 }}
        onClick={handleBack}
        startIcon={<WestIcon />}
        className="cancelBtn"
        // variant="outlined"
      >
        Back
      </Button>

      <Container sx={{ padding: 1, background: "#f0f9ff" }}>
        <Box
          sx={{
            display: "flex",
            mb: 1,
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Update Compliance</Typography>
        </Box>
        <Box
          sx={{
            marginBottom: 1,
            width: "100%",
            background: "#ffff",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: 1,
            borderRadius: 2,
          }}
        >
          <label>Compliance Name</label>

          <TextField
            label="Compliance Name"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: "100%", mt: 1, background: "#ffff" }}
            name="compliancename"
            value={data.compliancename}
            onChange={handleInputChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: 1,
            width: "100%",
            background: "#ffff",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: 1,
            borderRadius: 2,
          }}
        >
          <label>Description</label>
          {/* <WysiwygEditor
            editorState={editorState}
            onEditorStateChange={handleEditorStateChange}
            placeholder="Enter text here..."
          /> */}

           <JoditEditor
            ref={editor}
            value={data.description}
            tabIndex={1}
            onBlur={handleDescriptionChange}
            onChange={() => {}}
          />
        </Box>
        <Box
          sx={{
            marginBottom: 1,
            width: "100%",
            background: "#ffff",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: 1,
            borderRadius: 2,
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="tag"
            label="Tag"
            name="tag"
            value={tagInput}
            onChange={handleTagInputChange}
            onKeyPress={handleTagInputKeyPress}
            helperText="Press 'Enter' or ',' to add a tag"
          />
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            sx={{ marginBottom: "5px" }}
          >
            {data.tags.length > 0 &&
              data.tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={handleDeleteTag(tag)}
                  sx={{
                    margin: 0.5,
                    background: "#f0efed",
                    fontWeight: "bold",
                    border: "solid 1px grey",
                  }}
                />
              ))}
          </Stack>
        </Box>
        <Box
          sx={{
            marginBottom: 1,
            width: "100%",
            display: "flex",
            gap: 3,
            background: "#ffff",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: 1,
            borderRadius: 2,
          }}
        >
          <Box>
            <FormControl sx={{ margin: "13px 0px" }} required>
              {/* <InputLabel htmlFor="compliance-file">Choose Video</InputLabel> */}
              <label style={{ marginBottom: "10px" }}>Add Thumbnail</label>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="video-file"
                type="file"
                onChange={handleThumbnailChange}
              />

              <label htmlFor="video-file">
                <Button
                  variant="contained"
                  component="span"
                  sx={{ background: "#37668F", color: "white" }}
                >
                  <CiCloud
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  />
                  Upload
                </Button>
              </label>
            </FormControl>
          </Box>
          <Box
            sx={{
              width: "250",
              height: "110px",
              marginTop: 4,
            }}
          >
            <img
              style={{ borderRadius: "8px" }}
              width="200"
              height="100"
              src={
                data.thumbnail ||
                "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ="
              }
            />
          </Box>
        </Box>

        {/* <Box
            sx={{
              marginBottom: 1,
              width: "100%",
              background: "#ffff",
              padding: 2,
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              borderRadius: "5px",
            }}
          >
            <Typography>Add File</Typography>
            <Box
              sx={{
                border: "dotted 2px",
                width: "100%",
                height: "30vh",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
  
                alignItems: "center",
              }}
            >
              <Box sx={{}}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Drag & Drop or Browse the file
                </Typography>
                <input
                  style={{ display: "none" }}
                  id="video-file"
                  type="file"
                  onChange={handleFileChange}
                />
                <Typography sx={{ display: "flex", justifyContent: "center" }}>
                  {" "}
              
                  <FaCloudUploadAlt style={{ width: "50px", height: "50px" }} />
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{ fontSize: "11px", fontWeight: "bold", color: "grey" }}
            >
              Supported file type 'All'
            </Typography>
          </Box> */}

        <Box
          sx={{
            marginBottom: 1,
            width: "100%",
            background: "#ffff",
            padding: 2,
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: "5px",
          }}
        >
          <Typography>Add File</Typography>
          <Box
            {...getRootProps()}
            sx={{
              border: "dotted 2px",
              width: "100%",
              height: "30vh",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: isDragActive ? "#f0f0f0" : "#ffffff",
            }}
          >
            <input {...getInputProps()} />
            <Box sx={{}}>
              <Typography sx={{ fontWeight: "bold" }}>
                Drag & Drop or Browse the file
              </Typography>
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                <FaCloudUploadAlt style={{ width: "50px", height: "50px" }} />
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{ fontSize: "11px", fontWeight: "bold", color: "grey" }}
          >
            Supported file type 'All' {data.file}
          </Typography>
        </Box>

        {/* For the button  */}

        <Box
          sx={{
            display: "flex",
            gap: "5px",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Button
            sx={{
              border: "solid 1px grey",
              backgroundColor: "#FFFF",
              color: "#252729",
              width: "100px",
              borderRadius: "5px",
            }}
            onClick={handleBack}
          >
            <b>Cancel</b>
          </Button>
          <Button
            sx={{
              backgroundColor: "#37668F",
              "&:hover": {
                backgroundColor: "#37668F", // Prevent background color change on hover
              },
              color: "white",
              width: "100px",
              border: "solid 1px grey",
              borderRadius: "5px",
            }}
            onClick={handleUpdate}
          >
            <b>Save</b>
          </Button>
        </Box>
      </Container>

 
    </Box>
  );
}

export default UpdateCompliance;
