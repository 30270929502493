import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import Loader from "../../../../Constants/Loader";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  addPolicy,
  deletePolicy,
  getPolicy,
  updatePolicy,
} from "../../../../redux/actions/actions.master/policies.actions";
import { masterPolicyFileUpload } from "../../../../Constants/api";
import { FiEdit } from "react-icons/fi";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { TbFileDownload } from "react-icons/tb";

const Policies = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { policyData, error, loading } = useSelector(
    (state) => state.policyReducer
  );

  const [showEmptyError, setShowEmptyError] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [data, setData] = useState({
    title: "",
    description: "",
    file: "",
    addedby: companyInfo.username,
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  const columns = [
    {
      field: "title",
      headerName: "Title",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerClassName: "datagrid-header",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
    },
    {
      field: "addedby",
      headerName: "Added By",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "action",
      headerName: "",
      headerClassName: "datagrid-header",
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)}>
            <FiEdit />
          </IconButton>
          <IconButton onClick={() => handleDownload(params.row.file)}>
            <TbFileDownload />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteOutlinedIcon />
          </IconButton>
        </>
      ),
    },
  ];
  const handleEdit = (row) => {
    // console.log(row);
    setData(row);
    setIsEditMode(true);
  };
  const handleEditRow = () => {
    if (!data.title || !data.description || !data.file) {
      setShowEmptyError(true);
      return;
    }
    dispatch(updatePolicy(data));
    setData({
      title: "",
      description: "",
      file: "",
      addedby: companyInfo.username,
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
    setIsEditMode(false);
  };
  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };
  const handleFileChange = useCallback(async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      // Process the files here
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const res = await axios.post(
        masterPolicyFileUpload,

        formData,{withCredentials:true},
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      // console.log("res", res);
      if (res.data.file) {
        const fileurl = res.data.file;
        setData((prevData) => ({
          ...prevData,
          file: fileurl,
        }));
      }
    }
  }, []);
  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileUrl.split("/").pop();
    link.click();
  };
  const handleDelete = (id) => {
    dispatch(
      deletePolicy({
        idarray: [id],
      })
    );
  };
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  }, []);
  const handleSave = () => {
    if (!data.title || !data.description || !data.file) {
      setShowEmptyError(true);
      return;
    }
    dispatch(addPolicy(data));
    setData({
      title: "",
      description: "",
      file: "",
      addedby: companyInfo.username,
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
  };

  useEffect(() => {
    dispatch(
      getPolicy({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);
  return (
    <>
      <Container maxWidth="xl">
        <Stack direction="row" gap={5}>
          <Box
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            padding={"10px"}
            marginTop={"20px"}
            borderRadius={"15px"}
            width={"30%"}
            //maxHeight={"310px"}
          >
            <Typography className="colored-heading">
              {isEditMode ? "Edit Policy" : "Add Policy"}
            </Typography>
            <Divider />
            <br />
            <Grid
              container
              alignItems={"center"}
              direction={"column"}
              spacing={2}
              marginBottom="20px"
            >
              <Grid item md={5.9} width={"90%"}>
                <h4 style={{ marginBottom: "5px" }}>
                  Title <span style={{ color: "red" }}>*</span>
                </h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.title}
                  onChange={handleChange}
                  name="title"
                  error={!data.title && showEmptyError}
                  helperText={
                    !data.title && showEmptyError && "Title is required"
                  }
                />
              </Grid>
              <Grid item md={5.9} width={"90%"}>
                <h4 style={{ marginBottom: "5px" }}>
                  Description <span style={{ color: "red" }}>*</span>
                </h4>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  className="formInputs"
                  placeholder="-"
                  value={data.description}
                  onChange={handleChange}
                  name="description"
                  error={!data.description && showEmptyError}
                  helperText={
                    !data.description &&
                    showEmptyError &&
                    "Description is required"
                  }
                />
              </Grid>
              <Grid item md={5.9} width={"90%"}>
                <h4 style={{ marginBottom: "5px" }}>
                  Upload File <span style={{ color: "red" }}>*</span>
                </h4>
                <TextField
                  disabled
                  fullWidth
                  name="file"
                  className="formInputs"
                  placeholder="Choose File"
                  value={data.file}
                  error={!data.file && showEmptyError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button variant="outlined" onClick={handleBrowseClick}>
                          Upload
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  accept=".gif,.png,.jpg,.jpeg"
                  onChange={handleFileChange}
                />
              </Grid>
              <br />
              <p style={{ textAlign: "left" }}>
                Upload files only : jpeg, pdf, png file
              </p>
            </Grid>
            <Box display={"flex"} justifyContent={"flex-end"} gap={"10px"}>
              {" "}
              <Button
                className="saveBtn"
                variant="contained"
                onClick={isEditMode ? handleEditRow : handleSave}
              >
                {isEditMode ? "Update" : "Save"}
              </Button>
            </Box>
          </Box>
          <Box
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            padding={"10px"}
            marginTop={"20px"}
            borderRadius={"15px"}
            width={"70%"}
          >
            <Typography className="colored-heading">
              List of all Policies
            </Typography>
            <Divider />
            <br />
            <Box
              sx={{
                maxHeight: 350,
                overflow: "auto",
                position: "relative",
                p: 1,
              }}
            >
              <DataGrid
                rows={loading ? [] : policyData}
                autoHeight
                components={{
                  LoadingOverlay: <Loader />,
                }}
                loading={loading}
                columns={columns}
                hideFooterPagination={true}
              />
            </Box>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default Policies;
