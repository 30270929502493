import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";

import {
  addRecruitment,
  editRecruitment,
} from "../../../../redux/recruitmentprocess/actions";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import { recruitmentGetByIdApi } from "../../../../Constants/api";
import { getFileNameFromUrl } from "../../../../Constants/constants";

const EditRecruitmentDrawer = ({ drawerOpen, handleCloseDrawer, rowId }) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.recruitmentReducer);

  const navigate = useNavigate();
  const [jdLoading, setJdLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const [dropDowns, setDropDowns] = useState([]);

  const [data, setData] = useState({
    branch: "",
    department: "",
    positions: "",
    noofpositions: "",
    employeetype: "",
    frombudget: "",
    tobudget: "",
    level: "",
    experience: "",
    description: "",
    companyemail: companyemail,
    companyid: companyid,
    country: "",
    status: "",
    approver: "",
    jd: "",
    jdtext: "",
    raisedby: "",
    id: rowId,
  });

  const fileInputRef = React.useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "experience" && value < 0) {
      return;
    }

    setData({ ...data, [name]: value });
  };

  const handleQuillChange = (value, name) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleSave = () => {
    dispatch(
      editRecruitment(data, setOpen, setMsg, setAlertType, handleCloseDrawer)
    );
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    let url = "https://hrms.vliv.app/recruitment/jdupload";
    if (files.length > 0) {
      handleUploadFile(files, url, e.target.name);
    } else {
      setOpen(true);
      setAlertType("error");
      setMsg("Choose a file to upload..");
    }
  };

  const handleUploadFile = async (files, url, name) => {
    try {
      setJdLoading(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const response = await axios.post(url, formData,{withCredentials:true}, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data.file) {
        setData((prevData) => ({ ...prevData, [name]: response.data.file }));
        setAlertType("success");
        setOpen(true);
        setMsg("JD Uploaded..");
      } else {
        setData((prevData) => ({ ...prevData, [name]: "" }));
        setAlertType("error");
        setOpen(true);
        setMsg("Error in uploading file..");
      }

      setJdLoading(false);
    } catch (error) {
      setJdLoading(false);
      setAlertType("error");
      setOpen(true);
      setMsg("Error in uploading file..");
      console.error("Error uploading file:", error.message);
    }
  };

  const getDropdowns = async () => {
    try {
      let res = await axios.post(
        `https://hrms.vliv.app/employee/position/get`,
        {
          companyid,
          companyemail,
        },{withCredentials:true}
      );
      res.data && res.data.length > 0
        ? setDropDowns(
            res.data.filter((ele) => ele.status.toLowerCase() == "active")
          )
        : setDropDowns([]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  useEffect(() => {
    if (rowId) {
      axios
        .post(recruitmentGetByIdApi, { id: rowId },{withCredentials:true})
        .then((res) => {
          const responseData = res.data?.data?.[0];
          if (responseData) {
            setData((prevData) => ({ ...prevData, ...responseData }));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [rowId]);

  const areFieldsFilled = () => {
    return Object.entries(data).every(([key, value]) => {
      if (
        key == "companyemail" ||
        key == "companyid" ||
        key == "country" ||
        key == "status" ||
        key == "approver" ||
        key == "raisedby"
      ) {
        return true; // Skip checking these fields
      }
      return value !== "";
    });
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={handleCloseDrawer}
        sx={{
          width: "50vw",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "50vw",
            boxSizing: "border-box",
            "@media (max-width: 600px)": {
              width: "90vw", // adjust width for smaller screens
            },
          },
        }}
      >
        <p className="drawer-heading primarycolor">RID-{rowId}</p>
        <Divider />
        <Box className="drawer-form-container">
          <Grid container my={1.5}>
            <Grid item md={5.8} sm={12} xs={12}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Branch <span className="star">*</span>
              </Typography>
              <FormControl fullWidth className="formInputs">
                <Select
                  value={data.branch}
                  inputProps={{ className: "selectInput" }}
                  onChange={handleChange}
                  name="branch"
                >
                  {dropDowns
                    ?.filter((dropdown) => dropdown.position == "branch")
                    .map((ele) => (
                      <MenuItem value={ele.name} key={ele.id}>
                        {ele.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={0.4}></Grid>
            <Grid item md={5.8} sm={12} xs={12}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Department <span className="star">*</span>
              </Typography>
              <FormControl fullWidth className="formInputs">
                <Select
                  value={data.department}
                  inputProps={{ className: "selectInput" }}
                  onChange={handleChange}
                  name="department"
                >
                  {dropDowns
                    ?.filter((dropdown) => dropdown.position == "department")
                    .map((ele) => (
                      <MenuItem value={ele.name} key={ele.id}>
                        {ele.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container my={1.5}>
            <Grid item md={5.8} sm={12} xs={12}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Position <span className="star">*</span>
              </Typography>
              <FormControl fullWidth className="formInputs">
                <Select
                  value={data.positions}
                  inputProps={{ className: "selectInput" }}
                  onChange={handleChange}
                  name="positions"
                >
                  {dropDowns
                    ?.filter((dropdown) => dropdown.position == "position")
                    .map((ele) => (
                      <MenuItem value={ele.name} key={ele.id}>
                        {ele.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={0.4}></Grid>
            <Grid item md={5.8} sm={12} xs={12}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Number of position <span className="star">*</span>
              </Typography>
              <TextField
                fullWidth
                type="number"
                className="formInputs"
                value={data.noofpositions}
                onChange={handleChange}
                name="noofpositions"
                // placeholder="Enter number of Position"
              />
            </Grid>
          </Grid>
          <Grid container my={1.5}>
            <Grid item md={5.8} sm={12} xs={12}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Type of employee <span className="star">*</span>
              </Typography>
              <FormControl fullWidth className="formInputs">
                <Select
                  value={data.employeetype}
                  inputProps={{ className: "selectInput" }}
                  onChange={handleChange}
                  name="employeetype"
                >
                  {dropDowns
                    ?.filter((dropdown) => dropdown.position == "employeetype")
                    .map((ele) => (
                      <MenuItem value={ele.name} key={ele.id}>
                        {ele.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={0.4}></Grid>
            <Grid item md={5.8} sm={12} xs={12}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Level <span className="star">*</span>
              </Typography>
              <FormControl fullWidth className="formInputs">
                <Select
                  value={data.level}
                  inputProps={{ className: "selectInput" }}
                  onChange={handleChange}
                  name="level"
                >
                  {dropDowns
                    ?.filter((dropdown) => dropdown.position == "level")
                    .map((ele) => (
                      <MenuItem value={ele.name} key={ele.id}>
                        {ele.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container my={1.5}>
            <Grid item md={5.8} sm={12} xs={12}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Years of experience <span className="star">*</span>
              </Typography>
              <TextField
                fullWidth
                type="number"
                className="formInputs"
                value={data.experience}
                onChange={handleChange}
                name="experience"
              />
            </Grid>
            <Grid item md={0.4}></Grid>
            <Grid item md={2.8}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Budget From <span className="star">*</span>
              </Typography>
              <TextField
                fullWidth
                type="number" // Change the type to text to remove the up and down arrow
                className="formInputs"
                value={data.frombudget}
                onChange={handleChange}
                // placeholder="FROM"
                name="frombudget"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="adornment">
                      <div
                        style={{
                          backgroundColor: "#98A2B3",
                          borderRadius: "4px",
                          padding: "9px",
                          marginRight: "-15px",
                        }}
                      >
                        LPA
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={0.2}></Grid>
            <Grid item md={2.8}>
              <Typography component="h6" style={{ marginBottom: "3px" }}>
                Budget To <span className="star">*</span>
              </Typography>
              <TextField
                fullWidth
                type="number" // Change the type to text to remove the up and down arrow
                className="formInputs"
                value={data.tobudget}
                onChange={handleChange}
                // placeholder="TO"
                name="tobudget"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="adornment">
                      <div
                        style={{
                          backgroundColor: "#98A2B3",
                          borderRadius: "4px",
                          padding: "9px",
                          marginRight: "-15px",
                        }}
                      >
                        LPA
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container my={1.5}>
            <Typography component="h6" style={{ marginBottom: "3px" }}>
              Business Justification <span className="star">*</span>
            </Typography>
            <textarea
              rows={3}
              style={{
                width: "100%",
                borderRadius: "5px",
                padding: "5px ",
                fontSize: "large",
              }}
              placeholder="Enter Justification.."
              value={data.description}
              name="description"
              onChange={handleChange}
            />
          </Grid>
          <Grid container>
            <h4 style={{ marginBottom: "5px" }}>
              Job Description <span className="star">*</span>
            </h4>
            <div style={{ width: "100%" }}>
            <ReactQuill
                theme="snow"
                value={data.jdtext}
                onChange={(value) => handleQuillChange(value, "jdtext")}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["bold", "italic", "underline"],
                    ["link"],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }], // Add alignment options here
                  ],
                }}
                formats={[
                  "header",
                  "font",
                  "list",
                  "bullet",
                  "bold",
                  "italic",
                  "underline",
                  "link",
                  "color",
                  "background",
                  "align", // Add 'align' to formats
                ]}
                style={{
                  height: "150px",
                  marginBottom: "20px",
                  borderRadius: "5px",
                  fontSize: "large",
                  width: "100%",
                }}
              />
            </div>
          </Grid>
          <Box
            my={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={handleClick}
              endIcon={<AttachFileIcon />}
              sx={{ textTransform: "none", color: "#00b0ff" }}
            >
              Update JD
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              name="jd"
            />

            {data.jd && (
              <span style={{ color: "#00b0ff", marginLeft: "10px" }}>
                {getFileNameFromUrl(data.jd)}
              </span>
            )}

            {loading ? (
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                className="saveBtn"
              >
                Updating
              </LoadingButton>
            ) : (
              <Button className="saveBtn" onClick={handleSave}>
                Update
              </Button>
            )}
          </Box>
        </Box>
      </Drawer>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default EditRecruitmentDrawer;
