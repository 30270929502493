const initialState = {
  attLeaveReport_loading: false,
  attLeaveReport_error: "",
  attLeaveReportData: [],
  attLeaveReportByIDData: {},
};
export const attLeaveReportReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_ATT_LEAVE_RPT_LOADING":
    case "GET_ATT_LEAVE_RPT_BY_ID_LOADING":
      return { ...state, attLeaveReport_loading: true };

    case "GET_ATT_LEAVE_RPT_SUCCESS": {
      //  console.log(payload,"pl");
      return {
        ...state,
        attLeaveReport_loading: false,
        attLeaveReportData: payload,
      };
    }
    case "GET_ATT_LEAVE_RPT_BY_ID_SUCCESS": {
      //  console.log(payload,"pl");
      return {
        ...state,
        attLeaveReport_loading: false,
        attLeaveReportByIDData: payload,
      };
    }

    case "GET_ATT_LEAVE_RPT_FAILED":
    case "GET_ATT_LEAVE_RPT_BY_ID_FAILED":
      return {
        ...state,
        attLeaveReport_loading: false,
        attLeaveReport_error: payload,
      };

    default:
      return state;
  }
};
