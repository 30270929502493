import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { styled } from "@mui/system";
import AppraisalLetter from "./AppraisalLetter/AppraisalLetter";
import ExperienceLetter from "./ExperienceLetter/ExperienceLetter";
import FNADocument from "./FNADocument/FNADocument";
import NOCDocument from "./NOCDocument/NOCDocument";
import RecomendationLetter from "./RecommendationLetter/RecomendationLetter";
import PromotionLetter from "./PromotionLetter/PromotionLetter";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: "1rem",
  color: "#494949",
  "&.Mui-selected": {
    color: "#026AA2",
    backgroundColor: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    boxShadow: "0 2px 4px rgba(0,0,0.3,0.3)",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "white",
  },
}));

const DocumentGeneration = () => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab) {
      setValue(parseInt(savedTab, 10));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("selectedTab", newValue);
  };

  return (
    <Box sx={{ width: "100%" }} pt={1}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="document generation tabs"
      >
        <CustomTab label="Appraisal Letter" {...a11yProps(0)} />
        <CustomTab label="Promotion Letter" {...a11yProps(1)} />
        <CustomTab label="Experience Letter" {...a11yProps(2)} />
        <CustomTab label="F&F Document" {...a11yProps(3)} />
        <CustomTab label="NOC Document" {...a11yProps(4)} />
        <CustomTab label="Recommendation Letter" {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={value} index={0} >
        <AppraisalLetter />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PromotionLetter/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ExperienceLetter />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <FNADocument />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <NOCDocument />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <RecomendationLetter />
      </TabPanel>
    </Box>
  );
};

export default DocumentGeneration;
