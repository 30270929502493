import axios from "axios";
import { hrmsTimeAttendanceGet, hrmsTimeAttendanceGetbyID } from "../../../Constants/api";

export const getTimeAttendance = (obj) => async (dispatch) => {
    try {
      dispatch({ type: "GET_TIME_ATT_LOADING" });
      let res = await axios.post(hrmsTimeAttendanceGet, obj,{withCredentials:true});
  
      if (res.data) {
        dispatch({ type: "GET_TIME_ATT_SUCCESS", payload: res.data });
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "GET_TIME_ATT_FAILED", payload: error.message });
    }
  };
  export const getTimeAttendanceByID = (obj) => async (dispatch) => {
    try {
      dispatch({ type: "GET_TIME_ATT_BY_ID_LOADING" });
      let res = await axios.post(hrmsTimeAttendanceGetbyID, obj,{withCredentials:true});
  
      if (res.data) {
        dispatch({ type: "GET_TIME_ATT_BY_ID_SUCCESS", payload: res.data });
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_TIME_ATT_BY_ID_FAILED",
        payload: error.message,
      });
    }
  };