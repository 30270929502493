import axios from "axios";
import {
  masterEmployeeLeaveCategoryAdd,
  masterEmployeeLeaveCategoryDelete,
  masterEmployeeLeaveCategoryGet,
  masterEmployeeLeaveCategoryUpdate,
} from "../../../Constants/api";

export const getEmpLeaveCategory = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_EMP_LEAVE_CATEGORY_LOADING" });
    let res = await axios.post(masterEmployeeLeaveCategoryGet, obj,{withCredentials:true});
    // console.log(res,"res")
    if (res.data) {
      dispatch({ type: "GET_EMP_LEAVE_CATEGORY_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_EMP_LEAVE_CATEGORY_FAILED", payload: error.message });
  }
};

export const addEmpLeaveCategory = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_EMP_LEAVE_CATEGORY_LOADING" });
    let res = await axios.post(masterEmployeeLeaveCategoryAdd, obj,{withCredentials:true});
    // console.log("add res of position", res);
    if (res.data.message == "Leave reason added successfully") {
      dispatch({
        type: "ADD_EMP_LEAVE_CATEGORY_SUCCESS",
        payload: res.data.data,
      });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_EMP_LEAVE_CATEGORY_FAILED", payload: error.message });
  }
};

export const updateEmpLeaveCategory = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_EMP_LEAVE_CATEGORY_LOADING" });
    let res = await axios.post(masterEmployeeLeaveCategoryUpdate, obj,{withCredentials:true});
    if (res.data.message == "Leave reason updated successfully") {
      dispatch({
        type: "UPDATE_EMP_LEAVE_CATEGORY_SUCCESS",
        payload: res.data.data,
      });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: "UPDATE_EMP_LEAVE_CATEGORY_FAILED",
      payload: error.message,
    });
  }
};

export const deleteEmpLeaveCategory =
  (idArray, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_EMP_LEAVE_CATEGORY_LOADING" });
      let res = await axios.post(masterEmployeeLeaveCategoryDelete, idArray,{withCredentials:true});
      if (res.data.message == "Leave reason deleted successfully") {
        dispatch({
          type: "DELETE_EMP_LEAVE_CATEGORY_SUCCESS",
          payload: idArray,
        });
        handleClose();
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "DELETE_EMP_LEAVE_CATEGORY_FAILED",
        payload: error.message,
      });
    }
  };
