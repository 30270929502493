const initialState = {
  timeAttendance_loading: false,
  timeAttendance_error: "",
  timeAttendanceData: [],
  timeAttendanceByIDData: {},
};
export const timeAttendanceReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_TIME_ATT_LOADING":
    case "GET_TIME_ATT_BY_ID_LOADING":
      return { ...state, timeAttendance_loading: true };

    case "GET_TIME_ATT_SUCCESS": {
      //  console.log(payload,"pl");
      return {
        ...state,
        timeAttendance_loading: false,
        timeAttendanceData: payload,
      };
    }
    case "GET_TIME_ATT_BY_ID_SUCCESS": {
      //  console.log(payload,"pl");
      return {
        ...state,
        timeAttendance_loading: false,
        timeAttendanceByIDData: payload,
      };
    }

    case "GET_TIME_ATT_FAILED":
    case "GET_TIME_ATT_BY_ID_FAILED":
      return {
        ...state,
        timeAttendance_loading: false,
        timeAttendance_error: payload,
      };

    default:
      return state;
  }
};
