import {
  Box,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  addReimbursementReviewer,
  addReimbursementType,
  deleteReimbursementReviewer,
  deleteReimbursementType,
  getReimbursementReviewer,
  getReimbursementType,
} from "../../../../redux/actions/actions.master/payrollReimbursement.actions";
import Loader from "../../../../Constants/Loader";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AlertPopUp from "../../../../Constants/AlertPopUp";

const Reimbursement = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { remTypeData, terror, tloading } = useSelector(
    (state) => state.prRemTypeReducer
  );
  const { remReviewData, rerror, rloading } = useSelector(
    (state) => state.prRemReviewReducer
  );

  //for alert msg
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [category, setCategory] = useState("");
  const [reviewer, setReviewer] = useState("");
  const [showEmptyError, setShowEmptyError] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    let varOcg = false;
    for (let i = 0; i < remReviewData.length; i++) {
      if (remReviewData[i].reviewer == value) {
        varOcg = true;
        break;
      }
    }

    setReviewer(value);
    if (value && !varOcg) {
      dispatch(
        addReimbursementReviewer({
          reviewer: value,
          companyid: companyInfo.companyid,
          companyemail: companyInfo.companyemail,
        })
      );
      setReviewer("");
    }
  };

  const handleAddCategory = () => {
    if (!category) {
      setShowEmptyError(true);
      return;
    }
    dispatch(
      addReimbursementType({
        category: category,
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
    setCategory("");
  };

  const handleDeleteType = (id) => {
    dispatch(
      deleteReimbursementType({
        idarray: [id],
        setAlertType,
        setMsg,
        setOpen,
      })
    );
  };

  const handleDeleteReview = (id) => {
    dispatch(
      deleteReimbursementReviewer({
        idarray: [id],
        setAlertType,
        setMsg,
        setOpen,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getReimbursementType({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getReimbursementReviewer({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  // console.log(remReviewData, "reviewer");
  return (
    <>
      <Container maxWidth="xl">
        <Box
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
          padding={"10px"}
          marginTop={"20px"}
          borderRadius={"15px"}
        >
          <Typography className="colored-heading">
            Reimbursement Reviewer
          </Typography>
          <Divider />
          <br />
          <Stack direction="row" gap={5} justifyContent={"space-between"}>
            <Box padding={"10px"} marginTop={"20px"} width={"40%"}>
              <h4 style={{ marginBottom: "5px" }}>Reimbursement Category</h4>
              <TextField
                sx={{ width: "70%" }}
                fullWidth
                className="formInputs"
                placeholder="Create Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleAddCategory}>
                        <AddCircleIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                border={"1px solid black"}
                padding={"10px"}
                marginTop={"20px"}
              >
                {tloading ? (
                  <Loader />
                ) : (
                  remTypeData.length > 0 &&
                  remTypeData.map((elem, index) => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                      padding={"10px"}
                    >
                      {elem.category}
                      <IconButton
                        sx={{
                          color: "red",
                          height: "2px",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();

                          handleDeleteType(elem.id);
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Box>
                  ))
                )}
              </Box>
            </Box>
            <Box padding={"10px"} marginTop={"20px"} width={"40%"}>
              <h4 style={{ marginBottom: "5px" }}>Reimbursement Reviewer</h4>
              <Select
                sx={{ width: "70%" }}
                fullWidth
                className="formInputs"
                inputProps={{
                  className: "selectInput",
                }}
                value={reviewer}
                onChange={handleChange}
              >
                <MenuItem value="HR">HR</MenuItem>
                <MenuItem value="Manager">Manager</MenuItem>
              </Select>
              <Box
                border={"1px solid black"}
                padding={"10px"}
                marginTop={"20px"}
              >
                {rloading ? (
                  <Loader />
                ) : (
                  remReviewData.length > 0 &&
                  remReviewData.map((elem, index) => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                      padding={"10px"}
                    >
                      {elem.reviewer}
                      <IconButton
                        sx={{
                          color: "red",
                          height: "2px",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteReview(elem.id);
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Box>
                  ))
                )}
              </Box>
            </Box>
          </Stack>
        </Box>
      </Container>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleCloseAlert}
        type={alertType}
      />
    </>
  );
};

export default Reimbursement;
