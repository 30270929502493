const initialState = {
  loading: false,
  error: "",
  directoryData: [],
};

export const empDirectoryReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_EMP_DIRECTORY_LOADING":
    case "ADD_EMP_DIRECTORY_LOADING":
    case "UPDATE_EMP_DIRECTORY_LOADING":
      return { ...state, loading: true };

    case "GET_EMP_DIRECTORY_SUCCESS":
      return { ...state, loading: false, directoryData: payload };

    case "ADD_EMP_DIRECTORY_SUCCESS":
      return {
        ...state,
        loading: false,
        directoryData: [...state.directoryData, payload],
      };

    case "UPDATE_EMP_DIRECTORY_SUCCESS":
      return {
        ...state,
        loading: false,
        directoryData: state.directoryData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case "ADD_EMP_DIRECTORY_FAILED":
    case "UPDATE_EMP_DIRECTORY_FAILED":
    case "GET_EMP_DIRECTORY_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
