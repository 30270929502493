const initialState = {
  loading: false,
  error: "",
  assetData: [],
};
export const employeeAssetReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_EMP_ASSET_LOADING":
    case "ADD_EMP_ASSET_LOADING":
    case "UPDATE_EMP_ASSET_LOADING":
    case "DELETE_EMP_ASSET_LOADING":
      return { ...state, loading: true };

    case "GET_EMP_ASSET_SUCCESS":
      return { ...state, loading: false, assetData: payload };
    case "ADD_EMP_ASSET_SUCCESS":
      return {
        ...state,
        loading: false,
        assetData: [...state.assetData, payload],
      };
    case "UPDATE_EMP_ASSET_SUCCESS":
      return {
        ...state,
        loading: false,
        assetData: state.assetData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };
    case "DELETE_EMP_ASSET_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        assetData: state.assetData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_EMP_ASSET_FAILED":
    case "UPDATE_EMP_ASSET_FAILED":
    case "GET_EMP_ASSET_FAILED":
    case "DELETE_EMP_ASSET_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
