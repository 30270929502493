import axios from "axios";
import {
  masterEmployeePositionAdd,
  masterEmployeePositionDelete,
  masterEmployeePositionGet,
  masterEmployeePositionMasterAdd,
  masterEmployeePositionMasterDelete,
  masterEmployeePositionMasterGet,
  masterEmployeePositionUpdate,
} from "../../../Constants/api";

export const getEmpPosition = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_EMP_POSITION_LOADING" });
    let res = await axios.post(masterEmployeePositionGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_EMP_POSITION_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_EMP_POSITION_FAILED", payload: error.message });
  }
};

export const addEmpPosition = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_EMP_POSITION_LOADING" });
    let res = await axios.post(masterEmployeePositionAdd, obj,{withCredentials:true});
    // console.log("add res of position", res);
    if (res.data.message == "Employee position added successfully") {
      dispatch({ type: "ADD_EMP_POSITION_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_EMP_POSITION_FAILED", payload: error.message });
  }
};

export const updateEmpPosition = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_EMP_POSITION_LOADING" });
    let res = await axios.post(masterEmployeePositionUpdate, obj,{withCredentials:true});
    if (res.data.message == "Employee position updated successfully") {
      dispatch({ type: "UPDATE_EMP_POSITION_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPDATE_EMP_POSITION_FAILED", payload: error.message });
  }
};

export const deleteEmpPosition = (idArray, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_EMP_POSITION_LOADING" });
    let res = await axios.post(masterEmployeePositionDelete, idArray,{withCredentials:true});
    if (res.data.message == "Employee position deleted successfully") {
      dispatch({ type: "DELETE_EMP_POSITION_SUCCESS", payload: idArray });
      handleClose();
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "DELETE_EMP_POSITION_FAILED", payload: error.message });
  }
};

//Position Master
export const getPosition = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_POSITION_LOADING" });
    let res = await axios.post(masterEmployeePositionMasterGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_POSITION_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_POSITION_FAILED", payload: error.message });
  }
};

export const addPosition = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_POSITION_LOADING" });
    let res = await axios.post(masterEmployeePositionMasterAdd, obj,{withCredentials:true});
    if (res.data.message == "Added successfully") {
      dispatch({ type: "ADD_POSITION_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_POSITION_FAILED", payload: error.message });
  }
};
export const deletePosition = (idArray, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_POSITION_LOADING" });
    let res = await axios.post(masterEmployeePositionMasterDelete, idArray,{withCredentials:true});
    if (res.data.message == "Deleted successfully") {
      dispatch({ type: "DELETE_POSITION_SUCCESS", payload: idArray });
      handleClose();
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "DELETE_POSITION_FAILED", payload: error.message });
  }
};
