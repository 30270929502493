import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import AlertPopUp from "../../../../Constants/AlertPopUp";

const RegulariseMaster = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";

  const [templastDate, setTempLastDate] = useState("");
  const [data, setData] = useState({
    companyid,
    lastdate: "", // Initialize lastdate here
  });

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getRegulariseMasterDate = async () => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/attedance/lastdate/get",
        {
          companyemail,
          companyid,
        },{withCredentials:true}
      );
      if (res.data && res.data.length > 0) {
        setData((prev) => ({ ...prev, ...res.data[0] }));
        setTempLastDate(res.data[0].lastdate);
      } else {
        setTempLastDate("");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleSave = async () => {
    try {
      if (templastDate) {
        let res = await axios.post(
          "https://hrms.vliv.app/attedance/lastdate/update",
          { lastdate: `${data.lastdate}`, companyemail, companyid, id: data.id },{withCredentials:true}
        );
        if (res.data.message == "Last date updated successfully") {
          setAlertType("success");
          setOpen(true);
          setMsg("Update Marked");
        }
      } else {
        let res = await axios.post(
          "https://hrms.vliv.app/attedance/lastdate/add",
          { lastdate: `${data.lastdate || "31"}`, companyemail, companyid },{withCredentials:true}
        );
        if (res.data.message == "Last date added successfully") {
          setAlertType("success");
          setOpen(true);
          setMsg("Date is marked");
        }
      }
      // console.log(res, "res added");
      getRegulariseMasterDate();
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getRegulariseMasterDate();
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
        py={"10px"}
        marginTop={"20px"}
        mx={1}
        borderRadius={"15px"}
        height={"400px"}
      >
        <Typography
          variant={isSmallScreen ? "h6" : "h5"}
          className="primarycolor"
          px={2}
        >
          Regularization
        </Typography>
        <Divider />
        <Grid
          container
          px={2}
          my={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          flexWrap={"wrap"}
          gap={2}
        >
          <Grid item md={4} sm={12} xs={12}>
            <Typography className="view-page-value">
              Last Date of Regularization for Every Month
            </Typography>
            <TextField
              fullWidth
              type="number"
              className="formInputs"
              name="lastdate"
              inputProps={{
                min: 1,
                max: 31,
              }}
              value={data.lastdate} // Set the controlled value here
              onChange={(e) => {
                let value = parseInt(e.target.value, 10);

                // Ensure value is within valid range
                if (value < 1) {
                  value = 1;
                } else if (value > 31) {
                  value = 31;
                }

                setData((prev) => ({ ...prev, lastdate: value }));
              }}
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "-" || e.key === "+") {
                  e.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Button className="saveBtn" onClick={handleSave}>
              Submit
            </Button>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Typography className="view-page-value" marginTop={2}>
              ( Set the last date for regularization, raised by the employees.
              Regularization raised after the specified day of every month won't
              be considered. )
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default RegulariseMaster;
