import React from "react";
import ListAsset from "./ListAsset";

const AssetGroup = () => {
  return (
    <>
      <ListAsset />
    </>
  );
};

export default AssetGroup;
