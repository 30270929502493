import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Divider,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import AddTask from "./AddTask";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import Loader from "../../../../Constants/Loader";
import { DataGrid } from "@mui/x-data-grid";
import { getTasksApi, updateTaskStatus } from "../../../../Constants/api";
import {
  formatDate,
  performanceBgColor,
  performanceTextColor,
} from "../../../../Constants/dateFormat";

const Tasks = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";
  let userRole = userData.userrole ? userData.userrole : "";
  let username = userData.username ? userData.username : "";

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // State for dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  //for notify alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getData = async () => {
    let body = {};
    if (userRole != "User") {
      body = {
        companyemail,
        companyid,
        useremail: email,
      };
    } else {
      body = {
        companyemail,
        companyid,
        empemail: email,
      };
    }
    try {
      setLoading(true);
      let res = await axios.post(getTasksApi, body,{withCredentials:true});
      // console.log(res, "res");
      if (res.data && res.data.length > 0) {
        setData(res.data);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg("Error while fetching tasks..");
      setLoading(false);
    }
  };

  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);
  // Function to handle drawer open
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  // Function to handle drawer close
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    getData();
  };

  useEffect(() => {
    getData();
  }, [dialogOpen]);

  const handleStatusClick = (row) => {
    setSelectedRowId(row.id);
    setSelectedStatus(row.status);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedRowId(null);
    setSelectedStatus("");
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleStatusSave = async () => {
    try {
      let res = await axios.post(updateTaskStatus, {
        id: selectedRowId,
        status: selectedStatus,
      },{withCredentials:true});
      if (res.data.message) {
        setAlertType("success");
        setOpen(true);
        setMsg("Task status updated successfully");
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg("Error while updating tasks..");
    }
    setDialogOpen(false);
  };

  const allColumns = [
    { field: "createdAt", headerName: "Date", flex: 1 },
    { field: "taskname", headerName: "Task Name", flex: 1 },
    { field: "assignee", headerName: "Name", flex: 1 },
    { field: "assigneedby", headerName: "Assigned By", flex: 1 },
    { field: "assigneeid", headerName: "Employee Id", flex: 1 },
    { field: "department", headerName: "Department", flex: 1 },
    { field: "position", headerName: "Position", flex: 1 },
    { field: "tag", headerName: "Tags", flex: 1 },
    { field: "duedate", headerName: "Due Date", flex: 1 },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      renderCell: (params) => (
        <Button
          sx={{
            color: performanceTextColor(params.row.priority),
            backgroundColor: performanceBgColor(params.row.priority),
            textTransform: "capitalize",
            borderRadius: 2,
          }}
        >
          {params.row.priority}
        </Button>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Button
          sx={{
            color: performanceTextColor(params.row.status),
            backgroundColor: performanceBgColor(params.row.status),
            textTransform: "capitalize",
            borderRadius: 2,
          }}
          onClick={() => handleStatusClick(params.row)}
        >
          {params.row.status}
        </Button>
      ),
    },
    { field: "description", headerName: "Description", flex: 1 },
  ];

  const filteredColumns = allColumns.filter((column) => {
    if (userRole === "User") {
      return (
        column.field !== "assignee" &&
        column.field !== "assigneeid" &&
        column.field !== "department" &&
        column.field !== "position"
      );
    }
    return column.field !== "assigneedby";
  });

  const rows =
    data.length > 0
      ? data.map((task) => ({
          id: task.id,
          createdAt: formatDate(task.createdAt),
          taskname: task.taskname,
          assignee: task.assignee,
          assigneeid: task.assigneeid,
          assigneedby: task.assigneedby,
          department: task.department,
          position: task.position,
          tag: task.tag,
          duedate: task.duedate,
          priority: task.priority,
          description: task.description,
          status: task.status,
          data: task,
        }))
      : [];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box px={1} py={2}>
        <Box
          display={"flex"}
          alignContent={"center"}
          justifyContent={"space-between"}
          gap={2}
          px={2}
        >
          <Typography
            variant={isSmallScreen ? "h6" : "h5"}
            className="primarycolor"
          >
            Tasks
          </Typography>
          {userRole !== "User" && (
            <Button className="saveBtn" onClick={handleDrawerOpen}>
              Add Task / Activities
            </Button>
          )}
        </Box>
        <Box my={2}>
          <Paper style={{ height: "auto", width: "100%" }}>
            <DataGrid
              rows={loading ? [] : rows}
              columns={filteredColumns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 8 },
                },
              }}
              pageSizeOptions={[8, 16, 24, 32, rows.length]}
              autoHeight
              components={{
                LoadingOverlay: Loader,
              }}
              loading={loading}
            />
          </Paper>
        </Box>
      </Box>
      <AddTask
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        isSmallScreen={isSmallScreen}
      />
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
          <Typography
            color={"#000A14"}
            fontSize={"1.3rem"}
            fontWeight={"500"}
            textAlign={"center"}
          >
            Change Task Status
          </Typography>

          <IconButton onClick={handleDialogClose}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Divider />
        <DialogContent
          px={2}
          sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2, gap: 2 }}
        >
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={selectedStatus}
              onChange={handleStatusChange}
              label="Status"
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
            </Select>
          </FormControl>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
            mt={2}
          >
            <Button
              onClick={handleDialogClose}
              className="cancelBtn"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={handleStatusSave} className="saveBtn">
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Tasks;
