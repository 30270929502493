const initialState = {
    loading: false,
    error: "",
    seriesData: [],
  };
  export const empSeriesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case "GET_EMP_SERIES_LOADING":
      case "ADD_EMP_SERIES_LOADING":
      case "UPDATE_EMP_SERIES_LOADING":
      case "DELETE_EMP_SERIES_LOADING":
        return { ...state, loading: true };
  
      case "GET_EMP_SERIES_SUCCESS":
        return { ...state, loading: false, seriesData: payload };
      case "ADD_EMP_SERIES_SUCCESS":
        return {
          ...state,
          loading: false,
          seriesData: [...state.seriesData, payload],
        };
      case "UPDATE_EMP_SERIES_SUCCESS":
        return {
          ...state,
          loading: false,
          seriesData: state.seriesData.map((item) =>
            item.id === payload.id ? payload : item
          ),
        };
      case "DELETE_EMP_SERIES_SUCCESS": {
        const idsToDelete = payload.idarray;
        return {
          ...state,
          loading: false,
          seriesData: state.seriesData.filter(
            (item) => !idsToDelete.includes(item.id)
          ),
        };
      }
  
      case "ADD_EMP_SERIES_FAILED":
      case "UPDATE_EMP_SERIES_FAILED":
      case "GET_EMP_SERIES_FAILED":
      case "DELETE_EMP_SERIES_FAILED":
        return { ...state, loading: false, error: payload };
  
      default:
        return state;
    }
  };
  