import { formatDateTime } from "./dateFormat";

export const getOfferLetterTemplate = (data, companyname) => {
  return `Dear ${data.fullname},
    
    ${companyname} is delighted to offer you the [full-time, part-time, etc.] position of [job title] with an anticipated start date of ${data.joiningdate}, contingent upon [background check, drug screening, etc.].
    
    As the ${data.position}, you will be responsible for [brief mention of job responsibilities and expectations].
    You will report directly to [manager/supervisor name and title] at [workplace location]. Working hours are from [hours of day, days of week].
    
    The starting salary for this position is [dollar amount] per [hour, year, etc.]. Payment is on a [weekly, biweekly, monthly, etc.] basis by [direct deposit, check, etc.], starting on [date of first pay period]. In addition, you will be eligible to receive [discuss additional compensation potential].
    ${companyname} offers a comprehensive benefits program, which includes [medical insurance, 401(k), paid time off, etc.].
    
    Your employment with ${companyname} will be on an at-will basis, which means you and the company are free to terminate employment at any time, with or without cause or advance notice. This letter is not a contract indicating employment terms or duration.
    
    Please confirm your acceptance of this offer by signing and returning this letter by [offer expiration date].
    Sincerely,
    
    [Your Signature]
    [Your Printed Name]
    [Your Job Title]`;
};

export const getInterviewScheduledTemplate = (data) => {
  return `Dear ${data.candidateName},

${data.companyName} is pleased to invite you to the ${data.interviewRound} round of interviews for the ${data.position} position. 

Please find the details of your interview below:

- **Date**: ${data.interviewDate}
- **Time**: ${data.interviewTime}
- **Meeting Link**: [Join the Meeting](${data.meetingLink})

You will be meeting with ${data.interviewerName}, ${data.interviewerTitle}. Please ensure that you are available and ready at least 10 minutes before the scheduled time.

If you have any questions or need to reschedule, please feel free to contact us at ${data.companyNameEmail}.

Looking forward to meeting you!

Best regards,

${data.senderName}
${data.senderTitle}
${data.companyName}
`;
};


export const getUpdatedInterviewTemplate = (data) => {
    return `Dear ${data.candidateName},
  
  We hope this email finds you well. We would like to inform you that there has been a change in the schedule for your upcoming interview for the ${data.position} position at ${data.companyName}.
  
  Please find the updated details of your interview below:
  
  - **New Date**: ${data.interviewDate}
  - **New Time**: ${data.interviewTime}
  - **Meeting Link**: [Join the Meeting](${data.meetingLink})
  
  You will be meeting with ${data.interviewerName}, ${data.interviewerTitle}. Please ensure that you are available and ready at least 10 minutes before the scheduled time.
  
  We apologize for any inconvenience this may cause. If you have any questions or need further assistance, please feel free to contact us at ${data.companyNameEmail}.
  
  Looking forward to meeting you!
  
  Best regards,
  
  ${data.senderName}
  ${data.senderTitle}
  ${data.companyName}
  `;
  };
  


  export const getOnboardingEmailTemplate = (data, companyName, formLink) => {
    return `Dear ${data.fullname},
  Congratulations and welcome to ${companyName}! We are thrilled to have you on board as our new ${data.position}. Your skills and experience will be a great addition to our team, and we are excited to see the contributions you will make.
  **Welcome to the Team!**
  At ${companyName}, we pride ourselves on fostering a collaborative and innovative environment. We are confident that you will find your role both challenging and rewarding. To help you get started, we have outlined the next steps in your onboarding process.
  **Joining Date**
  Your official joining date is **${data.joiningdate}**. We look forward to welcoming you to the office on this day.
  **Onboarding Process**
  ### 1. Fill Out Your Details
  - Click the link below to complete your personal information and upload the required documents.
  - [Complete Onboarding Form] (${formLink})
  - Please complete this form by next week.

  Best regards,
  ${companyName}
  ${data.companyemail}
  `;
  };
  
  
  