import axios from "axios";
import { masterAttendanceShiftAdd, masterAttendanceShiftDelete, masterAttendanceShiftGet, masterAttendanceShiftUpdate } from "../../../Constants/api";

export const getAttendanceShift = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_ATT_SHIFT_LOADING" });
    let res = await axios.post(masterAttendanceShiftGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_ATT_SHIFT_SUCCESS", payload: Array.isArray(res.data)?res.data:[] });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_ATT_SHIFT_FAILED", payload: error.message });
  }
};

export const addAttendanceShift = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_ATT_SHIFT_LOADING" });
    let res = await axios.post(masterAttendanceShiftAdd, obj,{withCredentials:true});
    if (res.data.message == "Shift added successfully") {
      dispatch({ type: "ADD_ATT_SHIFT_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_ATT_SHIFT_FAILED", payload: error.message });
  }
};

export const updateAttendanceShift = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_ATT_SHIFT_LOADING" });
    let res = await axios.post(masterAttendanceShiftUpdate, obj,{withCredentials:true});
    if (res.data.message == "Shift updated successfully") {
      dispatch({
        type: "UPDATE_ATT_SHIFT_SUCCESS",
        payload: res.data.data,
      });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPDATE_ATT_SHIFT_FAILED", payload: error.message });
  }
};

export const deleteAttendanceShift =
  (idArray, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_ATT_SHIFT_LOADING" });
      let res = await axios.post(masterAttendanceShiftDelete, idArray,{withCredentials:true});
      if (res.data.message == "Shift deleted successfully") {
        dispatch({ type: "DELETE_ATT_SHIFT_SUCCESS", payload: idArray });
        handleClose();
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_ATT_SHIFT_FAILED", payload: error.message });
    }
  };
