import axios from "axios";
import {
  masterEmployeeSeriesAdd,
  masterEmployeeSeriesDelete,
  masterEmployeeSeriesGet,
  masterEmployeeSeriesUpdate,
} from "../../../Constants/api";

export const getEmpSeries = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_EMP_SERIES_LOADING" });
    let res = await axios.post(masterEmployeeSeriesGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_EMP_SERIES_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_EMP_SERIES_FAILED", payload: error.message });
  }
};

export const addEmpSeries = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_EMP_SERIES_LOADING" });
    let res = await axios.post(masterEmployeeSeriesAdd, obj,{withCredentials:true});
    if (res.data.message == "Series added successfully") {
      dispatch({ type: "ADD_EMP_SERIES_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_EMP_SERIES_FAILED", payload: error.message });
  }
};

export const updateEmpSeries = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_EMP_SERIES_LOADING" });
    let res = await axios.post(masterEmployeeSeriesUpdate, obj,{withCredentials:true});
    if (res.data.message == "Series updated successfully") {
      dispatch({ type: "UPDATE_EMP_SERIES_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPDATE_EMP_SERIES_FAILED", payload: error.message });
  }
};

export const deleteEmpSeries = (idArray,handleClose) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_EMP_SERIES_LOADING" });
    let res = await axios.post(masterEmployeeSeriesDelete, idArray,{withCredentials:true});
    if (res.data.message == "Series deleted successfully") {
      dispatch({ type: "DELETE_EMP_SERIES_SUCCESS", payload: idArray });
      handleClose()
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "DELETE_EMP_SERIES_FAILED", payload: error.message });
  }
};
