import { Grid } from "@mui/material";
import React from "react";
import BankReport from "./BankReport";
import EPFReport from "./EPFReport";
import ESICReport from "./ESICReport";
import ManagementReport from "./ManagementReport";

const PayrollReport = () => {
  return (
    <>
      <Grid container justifyContent={"space-between"} gap={0.1}>
        <Grid item md={5.9} sm={12} xs={12}>
          <BankReport />
        </Grid>
        <Grid item md={5.9} sm={12} xs={12}>
          <EPFReport />
        </Grid>
        <Grid item md={5.9} sm={12} xs={12}>
          <ESICReport />
        </Grid>
        <Grid item md={5.9} sm={12} xs={12}>
          <ManagementReport />
        </Grid>
      </Grid>
    </>
  );
};

export default PayrollReport;
