import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { IoIosExit } from "react-icons/io";
import { IoExit } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  convertTo24HourFormat,
  formatAttendanceTime,
  formatDate,
  formatTimeToAmPm,
  parseTime,
  performanceBgColor,
  performanceTextColor,
} from "../../../../../../Constants/dateFormat";
import AlertPopUp from "../../../../../../Constants/AlertPopUp";
import axios from "axios";
import {
  addAttendanceCheckin,
  addAttendanceCheckout,
  getTasksApi,
} from "../../../../../../Constants/api";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TimeAndAttendance from "../../TimendAttendance/TimeAndAttendance";
import { getChipColor } from "../../../../../../Constants/Colors";
import { FaRegFilePowerpoint } from "react-icons/fa6";

const Dashboard = () => {
  const [tabvalue, setTabValue] = React.useState("attendance");

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";
  let userRole = userData.userrole ? userData.userrole : "";
  let username = userData.username ? userData.username : "";

  const [empInfo, setEmpInfo] = useState({});
  const [tasks, setTasks] = useState([]);
  const [remainingLeaves, setRemainingLeaves] = useState([]);
  const [payslips, setPayslips] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");
  const [totalHr, setTotalHr] = useState("");

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const handleCheckIn = async () => {
    if (checkInTime && checkOutTime) return;

    try {
      const now = new Date();
      const temp = formatAttendanceTime(now);
      let res = await axios.post(addAttendanceCheckin, {
        employeeid: empInfo.empid ? empInfo.empid : "",
        tableemployeeid:empInfo.id || "",
        name: empInfo.name ? empInfo.name : "",
        date: temp.date,
        day: temp.day,
        month: temp.month,
        year: temp.year,
        checkin: formatTimeToAmPm(now),
        checkout: "",
        totalhours: "",
        status: "",
        companyemail,
        companyid,
      },{withCredentials:true});
      if (res.data.message == "Attendance added successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("Checkin Successfull");
        setCheckInTime(formatTimeToAmPm(now));
      }
    } catch (error) {
      console.log(error, "err");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
    }
  };

  const handleCheckOut = async () => {
    if (!checkInTime) {
      alert("Please check in first.");
      return;
    }

    if (checkOutTime) return;

    try {
      const now = new Date();
      const temp = formatAttendanceTime(now);
      const checkInDate = new Date(`1970-01-01 ${checkInTime}`);
      const checkOutDate = new Date(`1970-01-01 ${formatTimeToAmPm(now)}`);
    
      // Calculate the difference in milliseconds
      const diffInMilliseconds = checkOutDate - checkInDate;
    
      // Convert milliseconds to hours
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    
      // Return the result formatted to 2 decimal places
      let avgHr= diffInHours.toFixed(2);
      // Make API call
      let res = await axios.post(addAttendanceCheckout, {
        date: temp.date,
        employeeid: empInfo.empid ? empInfo.empid : "",
        checkout: formatTimeToAmPm(now),
        companyid,
        totalhours: avgHr,
      },{withCredentials:true});
      // console.log(res, "checkout res");
      if (res.data.message === "Attendance updated successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("Checkout Successful");
        setCheckOutTime(formatTimeToAmPm(now));
        setTotalHr(avgHr);
        // localStorage.setItem("checkOutTime", now);
      }
    } catch (error) {
      // console.log(error, "err");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
    }
  };

  //for success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getTasks = async () => {
    let body = {};
    if (userRole !== "User") {
      body = {
        companyemail,
        companyid,
        useremail: email,
      };
    } else {
      body = {
        companyemail,
        companyid,
        empemail: email,
      };
    }
    try {
      // setLoading(true);
      let res = await axios.post(getTasksApi, body,{withCredentials:true});
      if (res.data && res.data.length > 0) {
        setTasks(res.data);
      } else {
        setTasks([]);
      }
      // setLoading(false);
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error while fetching tasks.");
      // setLoading(false);
    }
  };

  const getPayslipForEmployee = async (employeeid) => {
    // console.log(employeeid,"emp id")
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/payroll/generatedslipbyemployee",
        { companyemail, companyid, employeeid },{withCredentials:true}
      );
      Array.isArray(res.data) ? setPayslips(res.data) : setPayslips([]);
    } catch (error) {
      console.log(error, "payslip error");
    }
  };

  const getTodaysAttendance = async (employeeid) => {
    try {
      let res = await axios.post("https://hrms.vliv.app/atte/getbyemployee", {
        companyid,
        companyemail,
        employeeid,
        date: formatDate(new Date()),
      },{withCredentials:true});

      if (res.data && res.data.data && res.data.data.length) {
        setCheckInTime(res.data.data[0].checkin);
        setCheckOutTime(res.data.data[0].checkout);
        setTotalHr(res.data.data[0].totalhours);
      } else {
        setCheckInTime("");
        setCheckOutTime("");
        setTotalHr("");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  //for getting all employees and setting emp information
  useEffect(() => {
    axios
      .post("https://hrms.vliv.app/employee/get", { companyemail, companyid },{withCredentials:true})
      .then((res) => {
        // console.log(res, "res");
        if (res.data && res.data.length > 0) {
          let data = res.data.find((el) => el.email == email);
          if (data) {
            setEmpInfo(data);
          } else {
            setEmpInfo({});
          }
        } else {
          setEmpInfo({});
        }
        // console.log(res,"res")
      })
      .catch((err) => {
        console.log(err, "err");
        setAlertType("error");
        setOpen(true);
        setMsg(err.message);
      });

    getTasks();
  }, []);

  useEffect(() => {
    if (empInfo.empid) {
      // console.log(empInfo,"empinfo dashboard")
      axios
        .post("https://hrms.vliv.app/attedance/leave/remainingleaves", {
          companyemail,
          companyid,
          employeeid: empInfo.empid,
          tableemployeeid:empInfo.id,
        },{withCredentials:true})
        .then((res) => {
          // console.log(res, "res 2")
          if (res.data && res.data.remainingLeaves.length > 0) {
            setRemainingLeaves(res.data.remainingLeaves);
          } else {
            setRemainingLeaves([]);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          // setAlertType("error");
          // setOpen(true);
          // setMsg(err.message);
        });
    }
    if (empInfo.empid) {
      getPayslipForEmployee(empInfo.empid);
      getTodaysAttendance(empInfo.empid);
    }
  }, [empInfo]);

  return (
    <>
      <TabContext value={tabvalue}>
        <TabPanel value="attendance">
          <Box p={2}>
            {/* Use Grid container for responsive layout */}
            <Grid
              container
              spacing={2}
              style={{ display: "flex", alignItems: "stretch" }}
            >
              {/* Each Box is a Grid item with responsive properties */}
              <Grid
                item
                xs={12}
                md={4}
                style={{ display: "flex", position: "relative" }}
              >
                <Box className="commonShadow" borderRadius={3} flexGrow={1}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    {" "}
                    <Typography
                      variant="h6"
                      className="view-page-title"
                      p={1.5}
                    >
                      Mark Attendance
                    </Typography>
                    <Typography
                      variant="h6"
                      className="view-page-title"
                      p={1.5}
                    >
                      {formatAttendanceTime(currentTime).time}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    p={4}
                    flexWrap={"wrap"}
                    gap={1}
                  >
                    <Button
                      endIcon={<IoIosExit />}
                      className="saveBtn"
                      sx={{ backgroundColor: "#12B76A !important" }}
                      onClick={handleCheckIn}
                      disabled={checkInTime || checkOutTime}
                    >
                      Check In
                    </Button>
                    <Button
                      endIcon={<IoExit />}
                      className="saveBtn"
                      sx={{ backgroundColor: "#E31B54 !important" }}
                      onClick={handleCheckOut}
                      disabled={!checkInTime || checkOutTime}
                    >
                      Check Out
                    </Button>
                  </Box>
                  <Divider />
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    p={4}
                    flexWrap={"wrap"}
                    gap={1}
                  >
                    <Box className="d-flex-align-center-justify-center-direction-column">
                      <Typography className="view-page-title">
                        In Time
                      </Typography>
                      <h2>{checkInTime ? checkInTime : "--:--"}</h2>
                    </Box>
                    <Box className="d-flex-align-center-justify-center-direction-column">
                      <Typography className="view-page-title">
                        Out Time
                      </Typography>
                      <h2>{checkOutTime ? checkOutTime : "--:--"}</h2>
                    </Box>
                    <Box className="d-flex-align-center-justify-center-direction-column">
                      <Typography className="view-page-title">
                        Avg Hours/Day
                      </Typography>
                      <h2>{totalHr || "0.00"} hrs</h2>
                    </Box>
                  </Box>
                </Box>
                <Typography
                  onClick={() => handleTabChange("attendanceview")}
                  color={"primary"}
                  className="cursor-pointer"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    padding: "10px",
                    textAlign: "right",
                  }}
                >
                  View Report
                </Typography>
              </Grid>

              {/* Responsive Box for Leave Balances */}
              <Grid
                item
                xs={12}
                md={4}
                style={{ display: "flex", position: "relative" }}
              >
                <Box className="commonShadow" borderRadius={3} flexGrow={1}>
                  <Typography variant="h6" className="view-page-title" p={1.5}>
                    Leave Balances
                  </Typography>
                  <Divider />
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    p={4}
                    flexWrap={"wrap"}
                    gap={1}
                  >
                    {remainingLeaves.map((leave, ind) => (
                      <Box
                        key={ind}
                        className="d-flex-align-center-justify-center-direction-column"
                      >
                        <Typography className="view-page-title">
                          {leave.leavetype}
                        </Typography>
                        <h2>{leave.remaining}</h2>
                      </Box>
                    ))}
                  </Box>
                  <Box p={1}>
                    <Typography
                      color={"primary"}
                      className="cursor-pointer"
                      textAlign={"right"}
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        padding: "10px",
                        textAlign: "right",
                      }}
                      onClick={() => navigate("/hrm/employeeselfservice/leave")}
                    >
                      Apply Leave
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Responsive Box for To Do Task */}
              <Grid
                item
                xs={12}
                md={4}
                style={{ display: "flex", position: "relative" }}
              >
                <Box className="commonShadow" borderRadius={3} flexGrow={1}>
                  <Typography variant="h6" className="view-page-title" p={1.5}>
                    To Do Task
                  </Typography>
                  <Divider />

                  <Box
                    flex={1}
                    height={"250px"}
                    className="tasks"
                    sx={{
                      overflowY: "auto",
                      padding: "10px",
                    }}
                  >
                    {tasks.slice(0, 3).map((task, index) => (
                      <Box
                        key={index}
                        sx={{
                          marginBottom: "10px",
                          padding: "15px",
                          borderRadius: "8px",
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                        >
                          <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                            {task.taskname}
                          </p>
                          <Button
                            sx={{ color: performanceTextColor(task.status) }}
                          >
                            {task.status}
                          </Button>
                        </Box>
                        <Box mb={1}>
                          <p>
                            <strong>Description:</strong> {task.description}
                          </p>
                          <p>
                            <strong>Due Date:</strong> {formatDate(task.duedate)}
                          </p>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                        >
                          <p>
                            <strong>Assigned by:</strong> {task.assigneedby}
                          </p>
                          <Button
                            sx={{
                              color: performanceTextColor(task.priority),
                              backgroundColor: performanceBgColor(
                                task.priority
                              ),
                            }}
                          >
                            {task.priority}
                          </Button>
                        </Box>
                        <Box mt={1}>
                          {task.tag.map((tag, idx) => (
                            <Chip
                              key={idx}
                              label={tag}
                              style={{
                                backgroundColor: getChipColor(tag),
                                color: "#fff",
                                margin: "0px 2px",
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    ))}
                    {tasks.length > 3 && (
                      <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button
                          color="primary"
                          onClick={() => navigate("/hrm/task&events/tasks")}
                        >
                          View More
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box p={1}>
            <Grid
              container
              p={2}
              spacing={2}
              style={{ display: "flex", alignItems: "stretch" }}
            >
              <Grid
                item
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className="commonShadow"
                borderRadius={3}
              >
                <Box pl={1}>
                  <p className="view-page-title">Organisation</p>
                </Box>
                <Divider />
                <Box flex={1} height={"150px"}></Box>
              </Grid>
              <Grid item lg={0.1} md={0.1} sm={12} xs={12}></Grid>
              <Grid
                item
                lg={7.8}
                md={7.8}
                sm={12}
                xs={12}
                className="commonShadow"
                borderRadius={3}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"row"}
                  gap={1}
                  pl={1}
                  flexGrow={1}
                >
                  <p className="view-page-title">Pay Slip</p>
                </Box>
                <Divider />
                <Box
                  flex={1}
                  height={"200px"}
                  pr={1}
                  py={1}
                  sx={{ overflowY: "auto" }}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#E4E7EC" }}>
                          <TableCell>Date</TableCell>
                          <TableCell>Total Earnings</TableCell>
                          <TableCell>Total Deductions</TableCell>
                          <TableCell>LOP</TableCell>
                          <TableCell>Net Total</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payslips.map((payslip, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {payslip.month}-{payslip.year}
                            </TableCell>
                            <TableCell>{payslip.earning}</TableCell>
                            <TableCell>{payslip.deduction}</TableCell>
                            <TableCell>{payslip.lop || "-"}</TableCell>
                            <TableCell>{payslip.netTotal || "-"}</TableCell>
                            <TableCell>
                              <IconButton
                                component="a"
                                href={`https://${payslip.payslip}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FaRegFilePowerpoint />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <AlertPopUp
            open={open}
            msg={msg}
            handleClose={handleClose}
            type={alertType}
          />
        </TabPanel>
        <TabPanel value="attendanceview">
          <TimeAndAttendance />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default Dashboard;
