import { jobApplicationsTypes } from "./actions";

const initialState = {
  loading: false,
  error: "",
  data: [],
  message: "",
};

export const jobApplicationReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case jobApplicationsTypes.GET_JOBAPPLICATION_LOADING:
    case jobApplicationsTypes.ADD_JOBAPPLICATION_LOADING:
    case jobApplicationsTypes.STATUSCHANGE_JOBAPPLICATION_LOADING:
      return { ...state, loading: true };

    case jobApplicationsTypes.GET_JOBAPPLICATION_SUCCESS:
      return { ...state, loading: false, data: payload };

    case jobApplicationsTypes.ADD_JOBAPPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, payload.data],
        message: payload.message,
      };

    case jobApplicationsTypes.STATUSCHANGE_JOBAPPLICATION_SUCCESS: {
      let updatedData = state.data.map((application) => {
        if (payload.idarray.includes(application.id)) {
          return { ...application, status: payload.status };
        }
        return application;
      });

      return { ...state, loading: false, data: updatedData };
    }

    case jobApplicationsTypes.GET_JOBAPPLICATION_ERROR:
    case jobApplicationsTypes.ADD_JOBAPPLICATION_ERROR:
    case jobApplicationsTypes.STATUSCHANGE_JOBAPPLICATION_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
