import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Paper,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../../../../Constants/Loader";
import {
  documentGenerationGetApi,
} from "../../../../../Constants/api";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  performanceBgColor,
  performanceTextColor,
} from "../../../../../Constants/dateFormat";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { noccontext } from "../../../../../Constants/editorContext";
import EditIcon from "@mui/icons-material/Edit";
import NOCDocumentDialog from "./NOCDocumentDialog";
import ViewNOCDocument from "./ViewNOCDocument";
import EditNOCDocument from "./EditNOCDocument";

// Custom modules and formats for ReactQuill
const Font = ReactQuill.Quill.import("formats/font"); // grab the font format
Font.whitelist = ["sans-serif", "serif", "monospace", "cursive"]; // allow all fonts
ReactQuill.Quill.register(Font, true);

const NOCDocument = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";
  let username = userData.username ? userData.username : "";
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // State to manage the selected row
  const [openDialog, setOpenDialog] = useState(false); // State to manage create dialog open/close
  const [openViewDialog, setOpenViewDialog] = useState(false); // State to manage view dialog open/close
  const [editorContent, setEditorContent] = useState(noccontext); // State for editor content
  const [openEditDialog, setOpenEditDialog] = useState(false); // State to manage edit dialog open/close

  //for alert msg
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      let res = await axios.post(documentGenerationGetApi, {
        companyemail,
        companyid,
      },{withCredentials:true});
      if (res.data && res.data.length > 0) {
        setData(res.data.filter((obj) => obj.role != "masteradmin"));
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleOpenDialog = (row) => {
    if (row.nocstatus == "Generated") {
      return;
    }
    setSelectedRow(row);
    setOpenDialog(true);
  };

  const handleOpenViewDialog = (row) => {
    if (row.nocstatus != "Generated") {
      return;
    }
    setSelectedRow(row);
    setOpenViewDialog(true);
  };

  const handleOpenEditDialog = (row) => {
    if (row.nocstatus != "Generated") {
      return;
    }
    setSelectedRow(row);
    setOpenEditDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRow(null);
    // setEditorContent("");
    getData();
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    setSelectedRow(null);
    // setEditorContent("");
    // getData();
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);
    // setEditorContent("");
    getData();
  };

  const columns = [
    { field: "empid", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "department", headerName: "Department", flex: 1 },
    { field: "position", headerName: "Position", flex: 1 },
    { field: "role", headerName: "Role", flex: 1 },
    { field: "email", headerName: "Mail", flex: 1 },
    { field: "reportmanager", headerName: "Reporting Manager", flex: 1 },
    {
      field: "nocstatus",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Button
          sx={{
            color: performanceTextColor(params.row.nocstatus),
            backgroundColor: performanceBgColor(params.row.nocstatus),
            textTransform: "capitalize",
            borderRadius: 2,
          }}
          onClick={() => handleOpenDialog(params.row)}
        >
          {params.row.nocstatus}
        </Button>
      ),
    },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleOpenViewDialog(params.row)} disabled={params.row.nocstatus != "Generated"}>
            <RemoveRedEyeIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenEditDialog(params.row)} disabled={params.row.nocstatus != "Generated"}>
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rows =
    data.length > 0
      ? data.map((doc) => ({
          id: doc.id,
          empid: doc.empid,
          name: doc.name,
          department: doc.department,
          position: doc.position,
          role: doc.role,
          email: doc.email,
          reportmanager: doc.reportmanager,
          nocstatus: doc.nocstatus ? doc.nocstatus : "Create",
          data: doc,
        }))
      : [];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box my={2}>
        <Paper style={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={loading ? [] : rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 8 },
              },
            }}
            pageSizeOptions={[8, 16, 24, 32, rows.length]}
            getRowId={(row) => row.id}
            autoHeight
            components={{
              LoadingOverlay: Loader,
            }}
            loading={loading}
          />
        </Paper>
      </Box>
      <NOCDocumentDialog
        editorContent={editorContent}
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialog}
        selectedRow={selectedRow}
        setEditorContent={setEditorContent}
      />
      <ViewNOCDocument
        openViewDialog={openViewDialog}
        selectedRow={selectedRow}
        handleCloseViewDialog={handleCloseViewDialog}
      />
      <EditNOCDocument
        handleCloseEditDialog={handleCloseEditDialog}
        openEditDialog={openEditDialog}
        selectedRow={selectedRow}
      />

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleCloseAlert}
        type={alertType}
      />
    </>
  );
};

export default NOCDocument;
