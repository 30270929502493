import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
  Switch,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Divider,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/system";
import Cookies from "js-cookie";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../../../../Constants/Loader";
import AlertPopUp from "../../../../Constants/AlertPopUp";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: "1rem",
  color: "#494949",
  "&.Mui-selected": {
    color: "#026AA2",
    backgroundColor: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    boxShadow: "0 2px 4px rgba(0,0,0.3,0.3)",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "white",
  },
}));

const EmployeeMasters = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  let companyid = companyInfo.companyid ? companyInfo.companyid : "";
  let companyemail = companyInfo.companyemail ? companyInfo.companyemail : "";

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //for alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [value, setValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [newStatus, setNewStatus] = useState("Active");

  // For edit modal
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editRowData, setEditRowData] = useState(null);

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab) {
      setValue(parseInt(savedTab, 10));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("selectedTab", newValue);
  };

  const tempTabs = [
    { tab: "Department", name: "department" },
    { tab: "Position", name: "position" },
    { tab: "Profile", name: "profile" },
    { tab: "Employee Type", name: "employeetype" },
    { tab: "Level", name: "level" },
    { tab: "Location", name: "location" },
    { tab: "Branch", name: "branch" },
    { tab: "Attendance Scheme", name: "attendancescheme" },
    { tab: "Sub Department", name: "subdepartment" },
  ];

  const getAllMasters = async () => {
    try {
      setLoading(true);
      let res = await axios.post(
        "https://hrms.vliv.app/employee/position/get",
        { companyid, companyemail },{withCredentials:true}
      );
      res.data && res.data.length > 0 ? setData(res.data) : setData([]);
      setLoading(false);
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error While Fetching Data");
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllMasters();
  }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setNewValue("");
    setNewStatus("Active");
    getAllMasters();
  };

  const handleNewValueChange = (e) => {
    setNewValue(e.target.value);
  };

  const handleNewStatusChange = (e) => {
    setNewStatus(e.target.value);
  };

  const handleAddNewValue = async () => {
    // Add functionality to save the new value
    // console.log("New Value:", newValue);
    // console.log("New Status:", newStatus);

    try {
      let res = await axios.post(
        "https://hrms.vliv.app/employee/position/add",
        {
          title: tempTabs[value].name,
          name: newValue,
          status: newStatus,
          companyemail,
          companyid,
        },{withCredentials:true}
      );

      if (res.data.message == "Employee master added successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg(`New ${tempTabs[value].tab} Added..`);
        setTimeout(() => {
          handleModalClose();
        }, 1000);
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
    }
  };

  const handleEditClick = (row) => {
    setEditRowData(row);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setEditRowData(null);
    getAllMasters();
  };

  const handleEditValueChange = (e) => {
    setEditRowData({ ...editRowData, name: e.target.value });
  };

  const handleEditStatusChange = (e) => {
    setEditRowData({ ...editRowData, status: e.target.value });
  };

  const handleSwitch = async (e, row) => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/employee/position/update",
        {
          title: tempTabs[value].name,
          name: row.name,
          status: row.status.toLowerCase() == "active" ? "In Active" : "Active",
          companyemail,
          companyid,
          id: row.id,
        },{withCredentials:true}
      );
      if (res.data.message == "Employee position updated successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg(`Status Updated..`);
        getAllMasters();
      }
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg(`error.response.data.message`);
    }
  };

  const handleEditSave = async () => {
    // Handle saving the edited value
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/employee/position/update",
        {
          title: tempTabs[value].name,
          name: editRowData.name,
          status: editRowData.status,
          companyemail,
          companyid,
          id: editRowData.id,
        },{withCredentials:true}
      );
      if (res.data.message == "Employee position updated successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg(`${tempTabs[value].tab} Updated..`);
        setTimeout(() => {
          handleEditModalClose();
        }, 1000);
      }
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg(`Error while updating ${tempTabs[value].tab} `);
    }
  };

  // console.log(data, "data");

  return (
    <>
      <Box sx={{ width: "100%" }} pt={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="document generation tabs"
        >
          {tempTabs.map((tab, i) => (
            <CustomTab label={tab.tab} {...a11yProps(0)} key={i} />
          ))}
        </Tabs>
        {tempTabs.map((tab, i) => (
          <TabPanel value={value} index={i} key={i}>
            <Box p={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography className="view-page-value fw-b">{tab.tab}</Typography>
                <Button className="saveBtn" onClick={handleModalOpen}>
                  Add {tab.tab}
                </Button>
              </Box>
              <Box my={1}>
                <Paper style={{ height: "auto", width: "100%" }}>
                  <DataGrid
                    rows={
                      loading
                        ? []
                        : data.filter((ele) => ele.position === tab.name)
                    }
                    columns={[
                      { field: "name", headerName: tab.tab, flex: 2 },
                      {
                        field: "status",
                        headerName: "Status",
                        flex: 1,
                        renderCell: (params) => (
                          <Switch
                            checked={params.value == "Active"}
                            onChange={(e) => handleSwitch(e, params.row)}
                          />
                        ),
                      },
                      {
                        field: "actions",
                        headerName: "Actions",
                        flex: 1,
                        renderCell: (params) => (
                          <IconButton
                            onClick={() => handleEditClick(params.row)}
                          >
                            <EditIcon />
                          </IconButton>
                        ),
                      },
                    ]}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 8 },
                      },
                    }}
                    pageSizeOptions={[
                      8,
                      16,
                      24,
                      32,
                      data.filter((ele) => ele.position === tab.name).length,
                    ]}
                    autoHeight
                    components={{
                      LoadingOverlay: Loader,
                    }}
                    loading={loading}
                  />
                </Paper>
              </Box>
            </Box>
          </TabPanel>
        ))}
        <Dialog open={modalOpen} onClose={handleModalClose}>
          <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
            <Typography
              color={"#000A14"}
              fontSize={"1.3rem"}
              fontWeight={"500"}
              textAlign={"center"}
            >
              Add New {tempTabs[value].tab}
            </Typography>

            <IconButton onClick={handleModalClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Divider />
          <DialogContent
            px={2}
            sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2, gap: 2 }}
          >
            <TextField
              label={tempTabs[value].tab}
              value={newValue}
              onChange={handleNewValueChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                value={newStatus}
                onChange={handleNewStatusChange}
                label="Status"
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="In Active">In Active</MenuItem>
              </Select>
            </FormControl>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              gap={1}
              mt={2}
            >
              <Button
                onClick={handleModalClose}
                className="cancelBtn"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button onClick={handleAddNewValue} className="saveBtn">
                Add
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        {editRowData && (
          <Dialog open={editModalOpen} onClose={handleEditModalClose}>
            <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
              <Typography
                color={"#000A14"}
                fontSize={"1.3rem"}
                fontWeight={"500"}
                textAlign={"center"}
              >
                Edit {tempTabs[value].tab}
              </Typography>

              <IconButton onClick={handleEditModalClose}>
                <CancelIcon />
              </IconButton>
            </Box>
            <Divider />
            <DialogContent
              px={2}
              sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2, gap: 2 }}
            >
              <TextField
                label={tempTabs[value].tab}
                value={editRowData.name}
                onChange={handleEditValueChange}
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  value={editRowData.status}
                  onChange={handleEditStatusChange}
                  label="Status"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="In Active">In Active</MenuItem>
                </Select>
              </FormControl>
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={1}
                mt={2}
              >
                <Button
                  onClick={handleEditModalClose}
                  className="cancelBtn"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button onClick={handleEditSave} className="saveBtn">
                  Save
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default EmployeeMasters;
