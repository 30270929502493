import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  LinearProgress,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { HiUserGroup } from "react-icons/hi2";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import afternoonImage from "../../../../../assets/assets/hrmswelcomeimageAfternoon.png";
import morningImage from "../../../../../assets/assets/hrmswelcomeimageMorning.png";
import nightImage from "../../../../../assets/assets/hrmswelcomeimageNight.png";
import { Line, LineChart, ResponsiveContainer, XAxis } from "recharts";
import { transformData } from "../../../../../Constants/constants";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";
import { getPayrollInputsApi } from "../../../../../Constants/api";
import dayjs from "dayjs";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2"; // Importing Doughnut and Bar chart

// Register the required components
ChartJS.register(
  ArcElement,
  ChartTooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const MISDashboard = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let username = userData.username ? userData.username : "";

  // For For bar chart employees start
  const [barChartEmployeesData, setBarChartEmployeesData] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [selectedCategory, setSelectedCategory] = useState("employees");
  const [availableYears, setAvailableYears] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [loading, setLoading] = useState(false);

  // Fetch data when category or year changes
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const url =
        selectedCategory === "employees"
          ? "https://hrms.vliv.app/employee/monthlyemployeereport"
          : "https://hrms.vliv.app/employee/monthlymanagerreport";

      try {
        const response = await axios.post(url, {
          companyemail,
          companyid,
        },{withCredentials:true});

        const responseData = response.data;
        if (responseData && typeof responseData === "object") {
          const yearData = responseData[currentYear];
          const monthlyData = new Array(12).fill(0);
          if (yearData) {
            Object.keys(yearData).forEach((month) => {
              monthlyData[month - 1] = parseInt(yearData[month], 10);
            });
          }
          setBarChartEmployeesData((prevState) => ({
            ...prevState,
            datasets: [
              {
                ...prevState.datasets[0],
                data: monthlyData,
              },
            ],
          }));

          setAvailableYears(
            Object.keys(responseData).map((year) => parseInt(year, 10))
          );
        } else {
          setBarChartEmployeesData((prevState) => ({
            ...prevState,
            datasets: [
              {
                ...prevState.datasets[0],
                data: [],
              },
            ],
          }));
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (companyid && companyemail) {
      fetchData();
    }
  }, [selectedCategory, currentYear, companyid, companyemail]);

  // Handlers for category change and year navigation
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handlePrevYear1 = () => {
    setCurrentYear((prevYear) => {
      const currentIndex = availableYears.indexOf(prevYear);
      if (currentIndex > 0) {
        return availableYears[currentIndex - 1];
      }
      return prevYear;
    });
  };

  const handleNextYear = () => {
    setCurrentYear((prevYear) => {
      const currentIndex = availableYears.indexOf(prevYear);
      if (currentIndex < availableYears.length - 1) {
        return availableYears[currentIndex + 1];
      }
      return prevYear;
    });
  };
  // For bar chart employees end

  // For Graph start

  const [data, setData] = useState([]);

  // Initialize the current month and year
  const [currentDate, setCurrentDate] = useState(dayjs());

  const getData = async () => {
    try {
      setLoading(true);
      let res = await axios.post(getPayrollInputsApi, {
        companyemail,
        companyid,
      },{withCredentials:true});
      res.data && res.data.length > 0 ? setData(res.data) : setData([]);
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // for graph
  const [graphData, setGraphData] = useState([]);
  const [currentYearIndex, setCurrentYearIndex] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);

  useEffect(() => {
    const transformedData = transformData(data);
    setGraphData(transformedData);
  }, [data]);

  useEffect(() => {
    if (graphData.length) {
      const { monthSpent } = graphData[currentYearIndex];
      const total = monthSpent.reduce(
        (sum, month) => sum + month.totalEarning,
        0
      );
      setTotalEarnings(total);
    }
  }, [graphData, currentYearIndex]);

  const handleNextYear2 = () => {
    setCurrentYearIndex((prev) => (prev + 1) % graphData.length);
  };

  const handlePrevYear = () => {
    setCurrentYearIndex((prev) =>
      prev === 0 ? graphData.length - 1 : prev - 1
    );
  };

  // For Graph end

  // For doughnut chart start
  const [attendanceData, setAttendanceData] = useState({
    timedEmployeeCount: 0,
    lateEmployeeCount: 0,
    AbsentEmployeeCount: 0,
    totalEmployees: 0,
  });

  // Fetch attendance data
  useEffect(() => {
    axios
      .post("https://hrms.vliv.app/employee/attendancegraph", {
        companyid,
        companyemail,
      },{withCredentials:true})
      .then((res) => {
        // console.log(res.data, 'attendance data');
        setAttendanceData(res.data);
      })
      .catch((err) => console.log(err));
  }, [companyid, companyemail]);

  // Set doughnut data
  const doughnutData = useMemo(
    () => ({
      datasets: [
        {
          data: [
            attendanceData.timedEmployeeCount,
            attendanceData.lateEmployeeCount,
            attendanceData.AbsentEmployeeCount,
          ],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    }),
    [attendanceData]
  );

  const doughnutOptions = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
    }),
    []
  );

  const renderDoughnutLabels = useMemo(() => {
    const colorCard = ["#FF6384", "#36A2EB", "#FFCE56"];
    return ["On Time", "Late Arrival", "Absent"].map((label, index) => (
      <Typography
        key={index}
        variant="body2"
        sx={{ display: "flex", alignItems: "center", gap: 0.7 }}
      >
        <Card
          sx={{ background: colorCard[index], width: "10px", height: "10px" }}
        ></Card>
        <Typography>
          {label}: {doughnutData.datasets[0].data[index]}
        </Typography>
      </Typography>
    ));
  }, [doughnutData]);
  // For doughnut chart end

  // For working format progress charts start
  const workingFormatData = useMemo(
    () => ({
      office: 70, // Example percentage
      remote: 30, // Example percentage
    }),
    []
  );

  // For working format progress charts end

  // For employee status bar chart start
  const [employeeStatus, setEmployeeStatus] = useState({
    permanent: 0,
    contract: 0,
    parttime: 0,
  });

  useEffect(() => {
    axios
      .post(`https://hrms.vliv.app/employee/emptypegraphdata`, {
        companyemail,
        companyid,
      },{withCredentials:true})
      .then((res) => {
        setEmployeeStatus({
          permanent: res.data.filter((elem) => {
            return elem.emptype == "FullTime";
          })[0].count,
          contract: res.data.filter((elem) => {
            return elem.emptype == "Contractual";
          })[0].count,
          parttime: res.data.filter((elem) => {
            return elem.emptype == "Part Time";
          })[0].count,
        });
        // console.log(res.data, "employee status data")
      })
      .catch((err) => console.log(err));
  }, [companyemail, companyid]);

  const barChartData = useMemo(
    () => ({
      labels: ["Permanent", "Contract", "Part Time"],
      datasets: [
        {
          label: "",
          data: [
            employeeStatus.permanent,
            employeeStatus.contract,
            employeeStatus.parttime,
          ],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(255, 159, 64, 0.2)",
            "rgba(255, 205, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(201, 203, 207, 0.2)",
          ],
          borderColor: [
            "rgb(255, 99, 132)",
            "rgb(255, 159, 64)",
            "rgb(255, 205, 86)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(153, 102, 255)",
            "rgb(201, 203, 207)",
          ],
          borderWidth: 1,
        },
      ],
    }),
    [employeeStatus]
  );

  const barChartOptions = useMemo(
    () => ({
      responsive: true,
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    }),
    []
  );
  // For employee status bar chart end

  // if (!graphData.length) return null;

  const { year, monthSpent } = graphData[currentYearIndex] || {};

  return (
    <>
      <Box sx={{ background: "#F0F9FF", padding: "12px" }}>
        <Grid
          container
          sx={{ mt: 1, padding: 0 }}
          justifyContent={"space-between"}
        >
          <Grid
            item
            lg={5.95}
            md={5.95}
            sm={12}
            xs={12}
            p={2}
            className="commonShadow"
            sx={{ backgroundColor: "white" }}
            borderRadius={2}
          >
             <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" >
                  Employees
                </Typography>
                <Box
                  display="flex"
                  // justifyContent="flex-end"
                  alignItems="center"
                  
                >
                  <IconButton
                    onClick={handlePrevYear1}
                    disabled={availableYears.length === 0}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                  <Typography variant="body1" mx={2}>
                    {currentYear}
                  </Typography>
                  <IconButton
                    onClick={handleNextYear}
                    disabled={availableYears.length === 0}
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </Box>
              </Box>
            <Box>
              <RadioGroup
                row
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <FormControlLabel
                  value="employees"
                  control={<Radio />}
                  label="Employees"
                />
                <FormControlLabel
                  value="managers"
                  control={<Radio />}
                  label="Managers"
                />
                <FormControlLabel
                  value="newjoined"
                  control={<Radio />}
                  label="New Joined"
                />
                <FormControlLabel
                  value="leftemployee"
                  control={<Radio />}
                  label="Left Employee"
                />
              </RadioGroup>
              {loading ? (
                <CircularProgress />
              ) : (
                <Box
                  className="welcome-banner"
                  style={{ background: "" }}
                  marginBottom={1}
                >
                  <Bar
                    data={barChartEmployeesData}
                    options={{ responsive: true }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            md={5.95}
            lg={5.95}
            sm={12}
            xs={12}
            bgcolor={"white"}
            p={2}
            borderRadius={2}
            className="commonShadow"
          >
            <Typography variant="h6" mb={2}>
              Salary
            </Typography>
            <Typography variant="h4" color="primary" mb={3}>
              ₹ {totalEarnings.toLocaleString()}
            </Typography>
            <Box position="relative" mb={3}>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={monthSpent}>
                  <XAxis dataKey="month" padding={{ left: 30, right: 30 }} />
                  <Tooltip />
                  <Line type="monotone" dataKey="totalgross" stroke="#007bff" />
                  <Line
                    type="monotone"
                    dataKey="totaldeduction"
                    stroke="#ff7300"
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <IconButton onClick={handlePrevYear}>
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant="body1" mx={2}>
                {year}
              </Typography>
              <IconButton onClick={handleNextYear2}>
                <NavigateNextIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        {/* For below other 3 charts charts */}

        <Grid
          container
          sx={{ mt: 1, padding: 1 }}
          justifyContent={"space-between"}
        >
          <Grid
            item
            md={3.95}
            lg={3.95}
            sm={12}
            xs={12}
            bgcolor={"white"}
            p={2}
            borderRadius={2}
            className="commonShadow"
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" mb={2}>
                Attendance
              </Typography>
              <Typography sx={{ color: "grey" }}>Today</Typography>
            </Box>

            <Box position="relative" mb={3}>
              <Doughnut data={doughnutData} options={doughnutOptions} />
            </Box>
            <Box mt={2}>{renderDoughnutLabels}</Box>
          </Grid>

          <Grid
            item
            md={3.95}
            lg={3.95}
            sm={12}
            xs={12}
            bgcolor={"white"}
            p={2}
            borderRadius={2}
            className="commonShadow"
          >
            <Typography variant="h6" mb={2}>
              Working Format
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <Typography>Office</Typography>
                <CircularProgress
                  variant="determinate"
                  value={workingFormatData.office}
                  size={110}
                  thickness={5}
                  sx={{ color: "pink" }}
                />
                <Typography>{workingFormatData.office}%</Typography>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Typography>Remote</Typography>
                <CircularProgress
                  variant="determinate"
                  value={workingFormatData.remote}
                  size={110}
                  thickness={5}
                />
                <Typography>{workingFormatData.remote}%</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            md={3.95}
            lg={3.95}
            sm={12}
            xs={12}
            bgcolor={"white"}
            p={2}
            borderRadius={2}
            className="commonShadow"
          >
            <Typography variant="h6" mb={2}>
              Employee Status
            </Typography>

            <Box position="relative" mb={3}>
              <Bar data={barChartData} options={barChartOptions} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MISDashboard;
