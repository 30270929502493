import axios from "axios";
import { masterEmployeeConfigDirectoryAdd, masterEmployeeConfigDirectoryGet, masterEmployeeConfigDirectoryUpdate } from "../../../Constants/api";

export const getEmpDirectory = (obj) => async (dispatch) => {
    try {
      dispatch({ type: "GET_EMP_DIRECTORY_LOADING" });
      let res = await axios.post(masterEmployeeConfigDirectoryGet, obj,{withCredentials:true});
      // console.log(res,"res")
      if (res.data) {
        dispatch({ type: "GET_EMP_DIRECTORY_SUCCESS", payload: res.data });
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "GET_EMP_DIRECTORY_FAILED", payload: error.message });
    }
  };
  
  export const addEmpDirectory = (obj) => async (dispatch) => {
    try {
      dispatch({ type: "ADD_EMP_DIRECTORY_LOADING" });
      let res = await axios.post(masterEmployeeConfigDirectoryAdd, obj,{withCredentials:true});
      // console.log("add res of DIRECTORY", res);
      if (res.data.message == "Employee config added successfully") {
        dispatch({ type: "ADD_EMP_DIRECTORY_SUCCESS", payload: res.data.data });
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "ADD_EMP_DIRECTORY_FAILED", payload: error.message });
    }
  };
  
  export const updateEmpDirectory = (obj) => async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_EMP_DIRECTORY_LOADING" });
      let res = await axios.post(masterEmployeeConfigDirectoryUpdate, obj,{withCredentials:true});
      // console.log(res,"res")
      if (res.data.message == "Employee status updated successfully") {
        dispatch({ type: "UPDATE_EMP_DIRECTORY_SUCCESS", payload: res.data.data });
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "UPDATE_EMP_DIRECTORY_FAILED", payload: error.message });
    }
  };
