const initialState = {
  loading: false,
  error: "",
  policyData: [],
};
export const policyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_POLICY_LOADING":
    case "ADD_POLICY_LOADING":
    case "UPDATE_POLICY_LOADING":
    case "DELETE_POLICY_LOADING":
      return { ...state, loading: true };

    case "GET_POLICY_SUCCESS":
      return { ...state, loading: false, policyData: payload };
    case "ADD_POLICY_SUCCESS":
      return {
        ...state,
        loading: false,
        policyData: [...state.policyData, payload],
      };
    case "UPDATE_POLICY_SUCCESS":
      return {
        ...state,
        loading: false,
        policyData: state.policyData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };
    case "DELETE_POLICY_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        policyData: state.policyData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_POLICY_FAILED":
    case "UPDATE_POLICY_FAILED":
    case "GET_POLICY_FAILED":
    case "DELETE_POLICY_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
