const initialState = {
    holiday_loading: false,
    holiday_error: "",
    holidayData: [],
  };
export const summaryHolidayReducer = (
    state = initialState,
    { type, payload }
  ) => {
    switch (type) {
      case "GET_SUMM_HOL_LOADING":
      case "ADD_SUMM_HOL_LOADING":
      case "UPDATE_SUMM_HOL_LOADING":
      case "DELETE_SUMM_HOL_LOADING":
        return { ...state, loading: true };
  
      case "GET_SUMM_HOL_SUCCESS": {
        //  console.log(payload,"pl");
        return { ...state, loading: false, holidayData: payload };
      }
  
      case "ADD_SUMM_HOL_SUCCESS":
        return {
          ...state,
          loading: false,
          holidayData: [...state.holidayData, payload],
        };
  
      case "UPDATE_SUMM_HOL_SUCCESS":
        return {
          ...state,
          loading: false,
          holidayData: state.holidayData.map((item) =>
            item.id === payload.id ? payload : item
          ),
        };
  
      case "DELETE_SUMM_HOL_SUCCESS": {
        const idsToDelete = payload.idarray;
        return {
          ...state,
          loading: false,
          holidayData: state.holidayData.filter((item) => !idsToDelete.includes(item.id)),
        };
      }
  
      case "ADD_SUMM_HOL_FAILED":
      case "UPDATE_SUMM_HOL_FAILED":
      case "GET_SUMM_HOL_FAILED":
      case "DELETE_SUMM_HOL_FAILED":
        return { ...state, loading: false, error: payload };
  
      default:
        return state;
    }
  };