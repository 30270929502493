import { Box, Container, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddAsset from "./AddAsset";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../../../Constants/Loader";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  deleteEmployeeAsset,
  getEmployeeAsset,
} from "../../../../redux/actions/actions.master/empAssetGroup.actions";

const ListAsset = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { assetData, error, loading } = useSelector(
    (state) => state.employeeAssetReducer
  );

  const handleDelete = (id) => {
    dispatch(
      deleteEmployeeAsset({
        idarray: [id],
      })
    );
  };

  const columns = [
    {
      field: "groupname",
      headerName: "Group Name",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "users",
      headerName: "Users",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "asset",
      headerName: "Asset Type",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "datagrid-header",
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row.id)}>
          <DeleteOutlinedIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getEmployeeAsset({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Container maxWidth="xl">
        <Box
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
          padding={"10px"}
          marginTop={"20px"}
          borderRadius={"15px"}
        >
          <Typography className="colored-heading">Asset Group</Typography>
          <Divider />
          <br />
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <AddAsset />
          </Box>
          <br />
          <Box
            sx={{
              maxHeight: 350,
              overflow: "auto",
              position: "relative",
              p: 1,
            }}
          >
            <DataGrid
              rows={loading ? [] : assetData}
              autoHeight
              components={{
                LoadingOverlay: <Loader />,
              }}
              loading={loading}
              columns={columns}
              hideFooterPagination={true}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ListAsset;
