import React from "react";
import ListShift from "./ListShift";

const Shift = () => {
  return (
    <>
      <ListShift />
    </>
  );
};

export default Shift;
