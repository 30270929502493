export const appraisalContext = `<p>Dear [Name],</p>

<p>Thank you for meeting with me today to discuss your recent performance review. We appreciate the efforts and dedication you bring to your role.</p>

<h2>Performance Summary</h2>

<h3>Achievements and Strengths:</h3>
<ul>
  <li>[Highlight major achievements and contributions]</li>
  <li>[Specific projects completed successfully]</li>
  <li>[Skills and strengths demonstrated]</li>
</ul>

<h3>Areas for Improvement:</h3>
<ul>
  <li>[Discuss any areas where performance could be improved]</li>
  <li>[Set specific, measurable goals for improvement]</li>
  <li>[Offer support or resources for development]</li>
</ul>

<h2>Feedback and Development</h2>

<p>Your work over the past [time period] has been valuable, and we recognize the effort you have put in. [Provide specific examples of positive performance]. However, there are areas where we see room for further growth and development. [Provide constructive feedback].</p>

<h2>Salary Review Outcome</h2>

<p>After careful consideration of various factors, including [mention relevant factors such as company financial status, budget constraints, or market comparisons], we have decided that there will not be a salary increase at this time.</p>

<h2>Looking Forward</h2>

<p>We want to assure you that this decision is not a reflection of your hard work or potential. Our goal is to support your continued growth and success within the company. To help you progress, we have outlined the following action plan:</p>

<h3>Development Plan:</h3>
<ul>
  <li>[List specific actions and resources]</li>
  <li>[Provide any training or mentoring opportunities]</li>
  <li>[Set a timeline for review]</li>
</ul>

<h2>Closing Remarks</h2>

<p>We appreciate your understanding and hope you will continue to bring your dedication and commitment to your role. We are confident in your abilities and look forward to seeing your continued contributions.</p>

<p>Please let us know if you have any questions or if there are ways we can better support your professional development.</p>

<p>Thank you.</p>
`;

export const experienceContext = `<p>This Certifies that <strong>[Name]</strong> as an <strong>[Position]</strong> from <strong>[Date]</strong> has Completed To <strong>[Date]</strong> Outstanding Service at <strong>[Company Name]</strong>.</p>
<p>Your Dedication and Contributions are Greatly Valued. Your Unwavering Efforts, Professionalism, and Enthusiasm have Significantly Contributed to Our Team's Success and Growth. We Sincerely Appreciate Your Hard Work, Positive Attitude, and The Valuable Contributions You Have Made to Our Organization.</p>
<p>Congratulations on Reaching this Milestone, and We Look Forward to Continuing Our Journey Together.</p>
<p><strong>DATE:</strong> <strong>[Date]</strong></p>
<p><strong>PLACE:</strong> <strong>[Place]</strong></p>
`;

export const fandacontext = `
<h2 style="text-align: center;">[LOGO]</h2>
<p style="text-align: center;"><strong>Position:</strong> Jr Software Developer</p>
<p style="text-align: center;"><strong>Role:</strong> Head of the Department</p>
<p style="text-align: center;"><strong>Mail ID:</strong> saravana.s@gmail.com</p>

<hr />

<p><strong>[Recipient Name]</strong></p>
<p><strong>[Recipient Job Title]</strong></p>
<p><strong>[Recipient Department]</strong></p>
<p><strong>[Company Name]</strong></p>
<p><strong>[Company Address]</strong></p>
<p><strong>[City, State, ZIP Code]</strong></p>

<hr />

<p>Dear [Recipient Name],</p>

<p>I hope this message finds you well.</p>

<h2 style="color: #2e6c80;">Introduction</h2>
<p>I am writing to present the financial report for the period of <strong>[Start Date]</strong> to <strong>[End Date]</strong> and to propose the budget for the upcoming period.</p>

<h2 style="color: #2e6c80;">Financial Report/Statement</h2>
<ul>
  <li><strong>Period Covered:</strong> [Start Date] to [End Date]</li>
  <li><strong>Revenue:</strong> [Revenue Amount]</li>
  <li><strong>Expenses:</strong> [Expenses Amount]</li>
  <li><strong>Net Income:</strong> [Net Income Amount]</li>
  <li><strong>Significant Variances/Trends:</strong> [Description of variances or trends]</li>
  <li><strong>Notes:</strong> [Any necessary explanations or additional notes on the figures]</li>
</ul>

<h2 style="color: #2e6c80;">Budget Proposal/Request</h2>
<p>The purpose of this proposal is to request approval for the budget for the upcoming period. Below is a detailed breakdown of the proposed budget:</p>
<ul>
  <li><strong>Category 1:</strong> [Amount]</li>
  <li><strong>Category 2:</strong> [Amount]</li>
  <li><strong>Category 3:</strong> [Amount]</li>
  <li><strong>Total Proposed Budget:</strong> [Total Amount]</li>
</ul>
<p><strong>Justification:</strong> The proposed budget is essential for [explain the need for the budget and the expected benefits].</p>
<p><strong>Implementation Timeline:</strong> The budget will be implemented from [Start Date] to [End Date].</p>
<p><strong>Actions Required:</strong> [List any actions required from the recipient]</p>
<p><strong>Follow-Up Plan:</strong> [Suggest a follow-up plan or next steps]</p>

<h2 style="color: #2e6c80;">Conclusion</h2>
<p>To summarize, the financial report shows [summary of financial report] and the proposed budget is [summary of budget proposal]. We would appreciate your approval of the proposed budget. Please feel free to contact me if you have any questions or need further information.</p>

<p>Thank you for your attention to this matter.</p>

<p>Best regards,</p>
<p><strong>Head of the Department</strong></p>
<p><strong>saravana.s@gmail.com</strong></p>

<hr />

<p style="text-align: center;"><strong>[Name]</strong></p>
<p style="text-align: center;"><strong>Chief Executive Officer</strong></p>
<p style="text-align: center;"><strong>[Company Name]</strong></p>
<p style="text-align: center;"><strong>[Company Address]</strong></p>
<p style="text-align: center;"><strong>[Phone Number]</strong></p>
<p style="text-align: center;"><strong>[Mail ID]</strong></p>
`;

export const noccontext = `<h2 style="text-align: center; color: #4CAF50;">No Objection Certificate</h2>

<p>Dear [Name],</p>

<p><strong>Position:</strong> Jr Software Developer</p>
<p><strong>Role:</strong> Head of the Department</p>
<p><strong>Department:</strong> IT</p>

<p><strong>Recipient Details:</strong></p>
<p>[Recipient Name]</p>
<p>[Recipient Job Title]</p>
<p>[Recipient Department]</p>
<p>[Company Name]</p>
<p>[Company Address]</p>
<p>[City, State, ZIP Code]</p>

<hr />

<p>Dear [Recipient Name],</p>

<p>This is to certify that <strong>[Your Company/Institution Name]</strong>, located at <strong>[Company/Institution Address]</strong>, has no objection to <strong>[Employee/Student Name]</strong>, who is currently employed/studying with us as a <strong>[Job Title/Position or Course]</strong>, for <strong>[reason for NOC, e.g., applying for a visa, pursuing higher studies, attending a conference, etc.]</strong>.</p>

<h2 style="color: #2e6c80;">Details:</h2>
<ul>
  <li><strong>Employee/Student Name:</strong> [Name]</li>
  <li><strong>Employee/Student ID/Registration Number:</strong> [ID Number]</li>
  <li><strong>Designation/Course:</strong> [Designation/Course]</li>
  <li><strong>Department:</strong> [Department Name]</li>
  <li><strong>Duration of Employment/Study:</strong> [Start Date] to [End Date or Present]</li>
</ul>

<p><strong>[Employee/Student Name]</strong> has been with us since <strong>[Start Date]</strong> and has shown excellent performance and dedication in their role/course. We confirm that <strong>[he/she/they]</strong> will be on <strong>[leave/sabbatical/official duty]</strong> from <strong>[Start Date]</strong> to <strong>[End Date]</strong> for the purpose of <strong>[specific purpose, e.g., attending a conference, further studies, etc.]</strong>, and we have no objection to <strong>[his/her/their]</strong> undertaking this activity.</p>

<p>We have no objections to <strong>[Employee/Student Name]</strong> utilizing this NOC for <strong>[specific purpose, e.g., visa application, admission to another institution, participation in an event, etc.]</strong>.</p>

<p>Please feel free to contact us if you require any further information.</p>

<p>Best regards,</p>
<p><strong>Jr Software Developer</strong></p>
<p><strong>Head of the Department</strong></p>
<p><strong>IT</strong></p>

<hr />

<p style="text-align: center;"><strong>[Name]</strong></p>
<p style="text-align: center;"><strong>Chief Executive Officer</strong></p>
<p style="text-align: center;"><strong>[Company Name]</strong></p>
<p style="text-align: center;"><strong>[Company Address]</strong></p>
<p style="text-align: center;"><strong>[Phone Number]</strong></p>
<p style="text-align: center;"><strong>[Mail ID]</strong></p>
`;

export const promotioncontext = `<h2 style="text-align: center; color: #4CAF50;">Promotion Announcement</h2>

<p><strong>From:</strong> Jr Software Developer, Head of the Department</p>
<p><strong>Email:</strong> saravana.s@gmail.com</p>
<p><strong>Department:</strong> IT</p>

<hr />

<p><strong>[Recipient Name]</strong></p>
<p><strong>[Recipient Job Title]</strong></p>
<p><strong>[Recipient Department]</strong></p>
<p><strong>[Company Name]</strong></p>
<p><strong>[Company Address]</strong></p>
<p><strong>[City, State, ZIP Code]</strong></p>

<hr />

<p>Dear [Recipient Name],</p>

<p>We are delighted to announce your promotion to <strong>[New Job Title]</strong> effective <strong>[Effective Date]</strong>. This recognition comes in light of your outstanding performance and significant contributions to the company.</p>

<p>In your new role, you will take on greater responsibilities including <strong>[Briefly outline new responsibilities]</strong>. Your new compensation package reflects your enhanced role and will be discussed further in our upcoming meeting on <strong>[Meeting Date]</strong>.</p>

<p>Your dedication, hard work, and leadership have been instrumental to our success, and we are confident that you will continue to excel and drive positive outcomes in your new position.</p>

<p>Congratulations on this well-deserved promotion!</p>

<p>Best regards,</p>
<p><strong>[Your Name]</strong></p>
<p><strong>Jr Software Developer</strong></p>
<p><strong>Head of the Department</strong></p>
<p><strong>Email:</strong> saravana.s@gmail.com</p>
<p><strong>IT Department</strong></p>

<hr />

<p style="text-align: center;"><strong>Company Name</strong></p>
<p style="text-align: center;"><strong>Company Address</strong></p>
<p style="text-align: center;"><strong>Phone Number</strong></p>
<p style="text-align: center;"><strong>Email</strong></p>
`;

export const recommendcontext = `            <h2 style={{ textAlign: 'center', color: '#4CAF50' }}>Recommendation Letter</h2>

            <p><strong>From:</strong> Jr Software Developer, Head of the Department</p>
            <p><strong>Email:</strong> saravana.s@gmail.com</p>
            <p><strong>Department:</strong> IT</p>

            <hr />

            <p><strong>[Recipient Name]</strong></p>
            <p><strong>[Recipient Job Title]</strong></p>
            <p><strong>[Recipient Department]</strong></p>
            <p><strong>[Company Name]</strong></p>
            <p><strong>[Company Address]</strong></p>
            <p><strong>[City, State, ZIP Code]</strong></p>

            <hr />

            <p>Dear [Recipient Name],</p>

            <p>I am writing to wholeheartedly recommend [Candidate's Name] for [admission to a program, a job position, a scholarship, etc.]. I have had the pleasure of knowing [Candidate's Name] for [duration] as [his/her/their] [relationship to candidate, e.g., supervisor, professor, colleague] at [Your Company/Institution].</p>

            <p>In my capacity as [Your Job Title] at [Your Company/Institution], I have worked closely with [Candidate's Name] on various projects and observed [his/her/their] exceptional abilities and dedication firsthand. [He/She/They] consistently demonstrated a high level of [specific qualities, e.g., competence, creativity, leadership, etc.], making a significant impact on our team/department.</p>

            <p>[Candidate's Name] has shown remarkable [skills/qualities, e.g., analytical skills, problem-solving abilities, leadership, etc.] through [specific examples or anecdotes]. [He/She/They] have always approached [tasks/projects] with a positive attitude and a strong work ethic.</p>

            <p>For instance, [provide a specific example of a project or task the candidate excelled in, detailing their role, contributions, and the outcome]. This example is a testament to [Candidate's Name]'s [skills/qualities] and [his/her/their] ability to [relevant competency, e.g., work under pressure, lead a team, innovate, etc.].</p>

            <p>Based on my experience working with [Candidate's Name], I am confident that [he/she/they] will bring the same level of dedication, skill, and enthusiasm to [the new opportunity, program, job position, etc.]. [His/Her/Their] ability to [specific strength] and [specific quality] make [him/her/them] an outstanding candidate for [the opportunity].</p>

            <p>I strongly recommend [Candidate's Name] without reservation. Please feel free to contact me if you require any further information or would like to discuss [Candidate's Name]'s qualifications in more detail.</p>

            <p>Best regards,</p>
            <p><strong>[Your Name]</strong></p>
            <p><strong>Jr Software Developer</strong></p>
            <p><strong>Head of the Department</strong></p>
            <p><strong>Email:</strong> saravana.s@gmail.com</p>
            <p><strong>IT Department</strong></p>

            <hr />

            <p style={{ textAlign: 'center' }}><strong>Company Name</strong></p>
            <p style={{ textAlign: 'center' }}><strong>Company Address</strong></p>
            <p style={{ textAlign: 'center' }}><strong>Phone Number</strong></p>
            <p style={{ textAlign: 'center' }}><strong>Email</strong></p>
`;





