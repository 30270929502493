import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../../Constants/Loader";
import { DataGrid } from "@mui/x-data-grid";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  addPayrollFILLING,
  addType,
  deletePayrollFILLING,
  deleteType,
  getPayrollFILLING,
  getType,
  updatePayrollFILLING,
} from "../../../../redux/actions/actions.master/payrollFilling.actions";
import CheckIcon from "@mui/icons-material/Check";
import { CustomSelect } from "../../../../Constants/CustomSelect";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const Filing = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { fillingData, error, loading } = useSelector(
    (state) => state.prFILLINGReducer
  );

  const {
    typeMaster,
    error: typeError,
    loading: typeLoading,
  } = useSelector((state) => state.typeReducer);

  const [isAddingType, setIsAddingType] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    name: "",
    type: "",
    link: "",
    star: false,
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "link",
      headerName: "Link",
      headerClassName: "datagrid-header",
      flex: 1,
      renderCell: (params) => {
        const link =
          params.value.startsWith("http://") ||
          params.value.startsWith("https://")
            ? params.value
            : `http://${params.value}`;
        return (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      headerClassName: "datagrid-header",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            sx={{ color: "#ffdf00" }}
            onClick={() => handleStar(params.row.id, params.row.star)}
          >
            {params.row.star ? <StarIcon /> : <StarBorderIcon />}
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteOutlinedIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleDelete = (id) => {
    dispatch(
      deletePayrollFILLING({
        idarray: [id],
      })
    );
  };
  const handleStar = (id, currentStatus) => {
    const newStatus = !currentStatus;
    dispatch(updatePayrollFILLING({ id, star: newStatus }));
  };
  const handleAddingType = async () => {
    // console.log(data.type, "type");
    setIsAddingType(!isAddingType);
    if (!data.type) {
      return;
    }
    dispatch(
      addType({
        type: data.type,
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  };
  const handleDeleteType = (id) => {
    dispatch(
      deleteType({
        idarray: [id],
      })
    );
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSave = () => {
    if(!data.name || !data.type || !data.link){
      setShowEmptyError(true);
      return;
    }
    dispatch(addPayrollFILLING(data));
    setData({
      name: "",
      type: "",
      link: "",
      star: false,
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
  };

  useEffect(() => {
    dispatch(
      getPayrollFILLING({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getType({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);
  return (
    <>
      <Container maxWidth="xl">
        <Stack direction="row" gap={5}>
          <Box
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            padding={"10px"}
            marginTop={"20px"}
            borderRadius={"15px"}
            width={"30%"}
          >
            <Typography className="colored-heading">
              Add filling Location
            </Typography>
            <Divider />
            <br />
            <Grid
              container
              alignItems={"center"}
              direction={"column"}
              spacing={2}
              marginBottom="20px"
            >
              <Grid item md={5.9} width={"90%"}>
                <h4 style={{ marginBottom: "5px" }}>Name</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.name}
                  onChange={handleChange}
                  name="name"
                  error={!data.name && showEmptyError}
                  helperText={
                    !data.name && showEmptyError && "Name is required"
                  }
                />
              </Grid>
              <Grid item md={5.9} width={"90%"}>
                <h4 style={{ marginBottom: "5px" }}>Type</h4>
                {isAddingType ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Create Type"
                    value={data.type}
                    onChange={handleChange}
                    name="type"
                    error={!data.type && showEmptyError}
                    helperText={
                      !data.type && showEmptyError && "Type is required"
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleAddingType}>
                            <CheckIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <CustomSelect
                    fullWidth
                    className="formInputs"
                    inputProps={{
                      className: "selectInput",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setIsAddingType(!isAddingType)}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    value={data.type}
                    onChange={handleChange}
                    name="type"
                    error={!data.type && showEmptyError && "Type is required"}
                  >
                    {typeLoading ? (
                      <Loader />
                    ) : (
                      typeMaster.length > 0 &&
                      typeMaster.map((elem, index) => (
                        <MenuItem key={index} value={elem.type}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                          >
                            {elem.type}
                            <IconButton
                              sx={{
                                color: "red",
                                height: "2px",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteType(elem.id);
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Box>
                        </MenuItem>
                      ))
                    )}
                  </CustomSelect>
                )}
              </Grid>
              <Grid item md={5.9} width={"90%"}>
                <h4 style={{ marginBottom: "5px" }}>Link</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.link}
                  onChange={handleChange}
                  name="link"
                  error={!data.link && showEmptyError}
                  helperText={
                    !data.link && showEmptyError && "Link is required"
                  }
                />
              </Grid>
            </Grid>
            <Box display={"flex"} justifyContent={"flex-end"} gap={"10px"}>
              {" "}
              <Button
                className="saveBtn"
                variant="contained"
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Box
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            padding={"10px"}
            marginTop={"20px"}
            borderRadius={"15px"}
            width={"70%"}
          >
            <Typography className="colored-heading">
              PF, PT, ESIC & LWF Filling Address
            </Typography>
            <Divider />
            <br />
            <Box
              sx={{
                maxHeight: 350,
                overflow: "auto",
                position: "relative",
                p: 1,
              }}
            >
              <DataGrid
                rows={loading ? [] : fillingData}
                autoHeight
                components={{
                  LoadingOverlay: <Loader />,
                }}
                loading={loading}
                columns={columns}
                hideFooterPagination={true}
              />
            </Box>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default Filing;
