const initialState = {
  emp_leaveApply_loading: false,
  emp_leaveApply_error: "",
  empLeaveApplyData: [],
  empLeaveApplyByIDData: {},
  empLeaveApplyByEMpIDData: {},
};

export const attLeaveReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_ATT_LEAVE_LOADING":
    case "GET_ATT_LEAVE_BY_ID_LOADING":
    case "GET_ATT_LEAVE_BY_EMPID_LOADING":
    case "ADD_ATT_LEAVE_LOADING":
    case "UPDATE_ATT_LEAVE_LOADING":
    case "DELETE_ATT_LEAVE_LOADING":
      return { ...state, emp_leaveApply_loading: true };

    case "GET_ATT_LEAVE_SUCCESS": {
      //  console.log(payload,"pl");
      return {
        ...state,
        emp_leaveApply_loading: false,
        empLeaveApplyData: payload,
      };
    }
    case "GET_ATT_LEAVE_BY_ID_SUCCESS": {
      //  console.log(payload,"pl");
      return {
        ...state,
        emp_leaveApply_loading: false,
        empLeaveApplyByIDData: payload,
      };
    }
    case "GET_ATT_LEAVE_BY_EMPID_SUCCESS": {
      //  console.log(payload,"pl");
      return {
        ...state,
        emp_leaveApply_loading: false,
        empLeaveApplyByEMpIDData: payload,
      };
    }

    case "ADD_ATT_LEAVE_SUCCESS":
      return {
        ...state,
        emp_leaveApply_loading: false,
        empLeaveApplyData: [...state.empLeaveApplyData, payload],
      };

    case "UPDATE_ATT_LEAVE_SUCCESS":
      return {
        ...state,
        emp_leaveApply_loading: false,
        empLeaveApplyData: state.empLeaveApplyData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case "DELETE_ATT_LEAVE_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        emp_leaveApply_loading: false,
        empLeaveApplyData: state.empLeaveApplyData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_ATT_LEAVE_FAILED":
    case "UPDATE_ATT_LEAVE_FAILED":
    case "GET_ATT_LEAVE_FAILED":
    case "GET_ATT_LEAVE_BY_ID_FAILED":
    case "GET_ATT_LEAVE_BY_EMPID_FAILED":
    case "DELETE_ATT_LEAVE_FAILED":
      return {
        ...state,
        emp_leaveApply_loading: false,
        emp_leaveApply_error: payload,
      };

    default:
      return state;
  }
};
