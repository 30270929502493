import React from 'react';
import { Box, Typography, Card, CardContent, Avatar, Button } from '@mui/material';
import { green, orange } from '@mui/material/colors';
import { CheckCircle, HourglassEmpty } from '@mui/icons-material';

const SalaryCards = ({currentDate,CurrNetPayrolls,CurrHoldPayrolls,PrevPaidPayrolls}) => {

    const currentMonthFirstDate = currentDate.startOf('month');
    // Get the first date of the previous month
    const prevMonthFirstDate = currentMonthFirstDate.subtract(1, 'month').startOf('month')

  const PayrollCard = ({ title, date, amount, status, statusColor, icon }) => (
    <Card sx={{ marginBottom: 2 }}>
      <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="body2" color="textSecondary">{title}</Typography>
          <Typography variant="h6">{amount}</Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="body2" color="textSecondary">{date}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', color: statusColor }}>
            {icon}
            <Typography variant="body2" sx={{ marginLeft: 0.5 }}>{status}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ padding: 3, maxWidth: "100%" }}>
      <PayrollCard
        title="Previous Payroll"
        date={prevMonthFirstDate.format('MMMM D, YYYY')}
        amount={`₹ ${PrevPaidPayrolls}`}
        // status="PAID"
        statusColor={green[500]}
        // icon={<CheckCircle />}
      />
      <PayrollCard
        title="Current Payroll"
        date={currentMonthFirstDate.format('MMMM D, YYYY')}
        amount={`₹ ${CurrNetPayrolls}`}
        // status="Approved"
        statusColor={green[500]}
        // icon={<CheckCircle />}
      />
      <PayrollCard
        title="Hold salary"
        date={currentMonthFirstDate.format('MMMM D, YYYY')}
        amount={`₹ ${CurrHoldPayrolls}`}
        // status="PENDING"
        statusColor={orange[500]}
        // icon={<HourglassEmpty />}
      />
    </Box>
  );
}

export default SalaryCards;
