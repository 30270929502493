import {
  Box,
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Divider,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import dayjs from "dayjs"; // Import dayjs for date manipulation
import axios from "axios";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import {
  employeeExitRequestApi,
  employeeReimbursementAddapi,
} from "../../../../../Constants/api";

const ApplyReimburse = ({
  drawerOpen,
  handleDrawerClose,
  isSmallScreen,
  empInfo,
}) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  const [emptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    empid: "",
    tableemployeeid:"",
    type: "",
    billno: "",
    fromdate: "",
    tilldate: "",
    amount: "",
    attach: "",
    description: "",
    status: "Pending",
    companyid: companyid,
    companyemail: companyemail,
  });
  const [reimbursementData, setReimbursementData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  //for file upload
  const handleFileChange = (e) => {
    const files = e.target.files;
    let url = "https://hrms.vliv.app/employee/upload/reimbursement";

    if (files.length > 0) {
      handleUploadFile(files, url, e.target.name);
    } else {
      // console.log("choose file");
      setOpen(true);
      setAlertType("error");
      setMsg("Choose a file to upload..");
    }
  };

  const handleUploadFile = async (files, url, name) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const response = await axios.post(url, formData,{withCredentials:true}, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data.file) {
        setData((prev) => ({ ...prev, [name]: response.data.file }));
        setAlertType("success");
        setOpen(true);
        setMsg("Attachment Uploaded..");
      } else {
        setData((prev) => ({ ...prev, [name]: "" }));
        setAlertType("error");
        setOpen(true);
        setMsg("Error in uploading file..");
      }
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error in uploading file..");
      console.error("Error uploading file:", error.message);
    }
  };

  const handleApply = async () => {
    // console.log(data, "exit data");
    try {
      let res = await axios.post(employeeReimbursementAddapi, data,{withCredentials:true});
      // console.log(res, "response");
      if (res.data.message == "Reimbursement added successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("Reimbursement Request Raised");
        setTimeout(() => {
          handleDrawerClose();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
      setTimeout(() => {
        handleDrawerClose();
      }, 1000);
    }
  };

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      empid: empInfo.empid,
      tableemployeeid:empInfo.id,
      reportingto: empInfo.reportmanager,
    }));
  }, [empInfo]);

  // console.log(data, "data kjid");



  // For reimbusement type dropdown
  useEffect(() => {
    axios.post(`https://hrms.vliv.app/payroll/category/get`, {
      companyid,
      companyemail
    },{withCredentials:true})
      .then((res) => {
        // console.log(res.data, "reimbusement type");
        setReimbursementData(res.data)
      })
      .catch((err) => console.log(err))
  }, [])
  return (
    <>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box
          width={isSmallScreen ? "90vw" : 550}
          display={"flex"}
          flexDirection={"column"}
          height={"100vh"}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={2} py={1}
          >
            <Typography variant="h6">Create Request</Typography>
            <IconButton onClick={handleDrawerClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box
            px={2}
            py={1}
            className="drawer-form-container"
            flex={1}
            display="flex"
            flexDirection="column"
            overflow="auto"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  {!data.type && <InputLabel>Reimbursement Type</InputLabel>}
                  <Select name="type" value={data.type} onChange={handleChange}>
              
                    {reimbursementData.length > 0 && reimbursementData.map((item, index) => (
                      <MenuItem value={item.category}>
                        {item.category}
                      </MenuItem>
                    ))

                    }

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Bill No"
                  name="billno"
                  value={data.billno}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="From Date"
                  name="fromdate"
                  type="date"
                  value={data.fromdate}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // inputProps={{ min: new Date().toISOString().split('T')[0] }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Till Date"
                  name="tilldate"
                  type="date"
                  value={data.tilldate}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // inputProps={{ min: new Date().toISOString().split('T')[0] }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Amount"
                  name="amount"
                  value={data.amount}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  multiline
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Attach File"
                  fullWidth
                  type="file"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="attach"
                  onChange={handleFileChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  name="description"
                  value={data.description}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              gap={2}
              flexWrap={"wrap"}
              mt={3}
            >
              <Button
                onClick={handleDrawerClose}
                sx={{ ml: 2 }}
                className="cancelBtn"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button className="saveBtn" onClick={handleApply}>
                Submit
              </Button>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default ApplyReimburse;
