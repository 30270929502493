import { interviewTypes } from "./actions";

const initialState = {
  loading: false,
  error: "",
  data: [],
  message: "",
};

export const interViewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //for loading
    case interviewTypes.GET_SHORTLISTED_JOBAPPLICATION_LOADING:
      return { ...state, loading: true };

    // for success
    case interviewTypes.GET_SHORTLISTED_JOBAPPLICATION_SUCCESS:
      return { ...state, loading: false, data: payload };

    //for error
    case interviewTypes.GET_SHORTLISTED_JOBAPPLICATION_ERROR:
      return { ...state, loading: false, error: payload };

      

    default:
      return state;
  }
};
