import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { styled } from "@mui/system";
import MISWelcome from "./Welcome/MISWelcome";
import MISDashboard from "./Dashboard/MISDashboard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: "1rem",
  color: "#494949",
  "&.Mui-selected": {
    color: "#026AA2",
    backgroundColor: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    boxShadow: "0 2px 4px rgba(0,0,0.3,0.3)",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "white",
  },
}));

const MIS = () => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab1");
    if (savedTab) {
      setValue(parseInt(savedTab, 10));
    } else {
      setValue(0); // Ensure the default tab is the first one
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("selectedTab1", newValue);
  };

  return (
    <Box sx={{ width: "100%" }} pt={1}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="MIS tabs"
      >
        <CustomTab label=" Welcome" {...a11yProps(0)} />
        <CustomTab label=" Dashboard" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <MISWelcome />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MISDashboard />
      </TabPanel>
    </Box>
  );
};

export default MIS;
