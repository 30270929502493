import { Select, styled } from "@mui/material";

export const CustomSelect = styled(Select)(({ theme }) => ({
    ".MuiSelect-icon": {
      display: "none",
    },
    "& .MuiOutlinedInput-root": {
      paddingRight: 0, // Adjust padding to fit the icon properly
    },
    "& .MuiInputAdornment-root": {
      marginRight: 0, // Remove default margin
    },
  }));