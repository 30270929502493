const initialState = {
  loading: false,
  error: "",
  ewfData: [],
};
export const prEWFReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_PR_EWF_LOADING":
    case "ADD_PR_EWF_LOADING":
    case "UPDATE_PR_EWF_LOADING":
    case "DELETE_PR_EWF_LOADING":
      return { ...state, loading: true };

    case "GET_PR_EWF_SUCCESS":
      return { ...state, loading: false, ewfData: payload };
    case "ADD_PR_EWF_SUCCESS":
      return {
        ...state,
        loading: false,
        ewfData: [...state.ewfData, payload],
      };
    case "UPDATE_PR_EWF_SUCCESS":
      return {
        ...state,
        loading: false,
        ewfData: state.ewfData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };
    case "DELETE_PR_EWF_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        ewfData: state.ewfData.filter((item) => !idsToDelete.includes(item.id)),
      };
    }

    case "ADD_PR_EWF_FAILED":
    case "UPDATE_PR_EWF_FAILED":
    case "GET_PR_EWF_FAILED":
    case "DELETE_PR_EWF_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
