import {
    Box,
    Button,
    Drawer,
    Grid,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Divider,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Cookies from "js-cookie";
  import dayjs from "dayjs"; // Import dayjs for date manipulation
  import axios from "axios";
  import { addEmployeeDevelopementKpiMasterApi, addOrganisationgoalKpiMasterApi } from "../../../../Constants/api";
  import AlertPopUp from "../../../../Constants/AlertPopUp";
  
  const AddEmployeeDevelopementKpi = ({
    drawerOpen,
    handleDrawerClose,
    isSmallScreen,
    empInfo,
  }) => {
    const cookieData = Cookies.get("userinfo");
    let userData = cookieData ? JSON.parse(cookieData) : {};
    let companyid = userData.companyid ? userData.companyid : "";
    let companyemail = userData.companyemail ? userData.companyemail : "";
    const [emptyError, setShowEmptyError] = useState(false);
  
    const [data, setData] = useState({
      kpiname: "",
      description: "",
      companyemail,
      companyid,
      email: "",
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    };
  
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const [alertType, setAlertType] = useState("success");
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };
  
    const handleAdd = async () => {
      // console.log(data, "data");
      try {
        let res = await axios.post(addEmployeeDevelopementKpiMasterApi, data,{withCredentials:true});
        if (res.data.message == "Kpi added successfully") {
          setAlertType("success");
          setOpen(true);
          setMsg("Employee Developement kpi added");
          setTimeout(() => {
            handleDrawerClose();
          }, 1000);
        }
      } catch (error) {
        console.log(error, "error");
        setAlertType("error");
        setOpen(true);
        setMsg(error.response.data.message || "Something went wrong");
        setTimeout(() => {
          handleDrawerClose();
        }, 1000);
      }
    };
  
    useEffect(() => {
      setData((prev) => ({
        ...prev,
        email: empInfo.email,
      }));
    }, [empInfo]);
  
    return (
      <>
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
          <Box width={isSmallScreen ? "90vw" : 550} my={0.8}>
            <Typography variant="h6" className="primarycolor" mb={2} px={2}>
              Add KPI
            </Typography>
          </Box>
          <Divider />
          <form>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <TextField
                  label="KPI Name"
                  name="kpiname"
                  onChange={handleChange}
                  value={data.kpiname}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descriptions"
                  name="description"
                  value={data.description}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                gap={2}
                flexWrap={"wrap"}
              >
                <Button
                  onClick={handleDrawerClose}
                  sx={{ ml: 2 }}
                  className="cancelBtn"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button className="saveBtn" onClick={handleAdd}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Drawer>
        <AlertPopUp
          open={open}
          msg={msg}
          handleClose={handleClose}
          type={alertType}
        />
      </>
    );
  };
  
  export default AddEmployeeDevelopementKpi;
  