import axios from "axios";
import {
  hrmsSummaryHolidayAdd,
  hrmsSummaryHolidayDelete,
  hrmsSummaryHolidayGet,
  hrmsSummaryHolidayUpdate,
} from "../../../Constants/api";

export const getSummaryHoliday = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_SUMM_HOL_LOADING" });
    let res = await axios.post(hrmsSummaryHolidayGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_SUMM_HOL_SUCCESS", payload: Array.isArray(res.data)?res.data : [] });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_SUMM_HOL_FAILED", payload: error.message });
  }
};

export const addSummaryHoliday =
  (obj, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: "ADD_SUMM_HOL_LOADING" });
      let res = await axios.post(hrmsSummaryHolidayAdd, obj,{withCredentials:true});
      // console.log("add res of emp", res);
      if (res.data.message == "Holiday added successfully") {
        dispatch({ type: "ADD_SUMM_HOL_SUCCESS", payload: res.data.data });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "ADD_SUMM_HOL_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

export const updateSummaryHoliday =
  (obj, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_SUMM_HOL_LOADING" });
      let res = await axios.post(hrmsSummaryHolidayUpdate, obj,{withCredentials:true});
      if (res.data.message == "Holiday updated successfully") {
        dispatch({ type: "UPDATE_SUMM_HOL_SUCCESS", payload: res.data.data });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "UPDATE_SUMM_HOL_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

export const deleteSummaryHoliday =
  (obj, handleClose, setOpen, setMsg, setAlertType) =>
  async (dispatch) => {
    try {
      dispatch({ type: "DELETE_SUMM_HOL_LOADING" });
      let res = await axios.post(hrmsSummaryHolidayDelete, obj,{withCredentials:true});
      if (res.data.message == "Holiday deleted successfully") {
        dispatch({
          type: "DELETE_SUMM_HOL_SUCCESS",
          payload: obj,
        });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
        handleClose();
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_SUMM_HOL_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };
