import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { routes1, userroute } from "../../Constants/routes";
import IconButton from "@mui/material/IconButton";
import { HiOutlineLightBulb } from "react-icons/hi";
import Cookies from "js-cookie";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { Button, Tooltip, Typography } from "@mui/material";
import { MdGroups } from "react-icons/md";
import { TbBulb } from "react-icons/tb";
import SettingsIcon from "@mui/icons-material/Settings";
import { IoBookSharp } from "react-icons/io5";
import { PiShareNetwork } from "react-icons/pi";
import { PiFileDocLight } from "react-icons/pi";

const Sidebar = ({ setSelectedRoute, showButtons, setShowButtons }) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let userRole = userData.userrole ? userData.userrole : "";

  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const [selectedMainRoute, setSelectedMainRoute] = React.useState("");

  const isActive = (path) => {
    return path ? currentPath.includes(path.toLowerCase()) : false;
  };

  const renderRoute = userRole === "User" ? userroute : routes1;

  const handleMainRouteClick = (path) => {
    setSelectedMainRoute(path);
    navigate(path);
  };
  return (
    <Box
      sx={{
        width: showButtons ? "75px" : "40px",
        position: "fixed",
        left: 0,
        top: "60px", // Offset for fixed navbar
        bottom: 0,
        bgcolor: "#F4FAFF",
        transition: "width 0.3s ease-in-out",
        zIndex: 999,
        boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
        // border: "solid 1px #dbdbd9",
      }}
    >
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          // ml: "-10px",
          padding: 0.5,
          mt: 2.2,
          alignItems: "center",
        }}
      >
        {showButtons ? (
          <AiOutlineMenuFold
            style={{ width: "24px", height: "24px" }}
            onClick={() => setShowButtons(false)}
          />
        ) : (
          <AiOutlineMenuUnfold
            style={{ width: "24px", height: "24px" }}
            onClick={() => setShowButtons(true)}
          />
        )}
      </Typography>

      {showButtons && (
        <Box
          className={showButtons ? "fade-in" : "fade-out"}
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 1.4,
            gap: 1,
            padding: 0.4,
          }}
        >
          <Button
            sx={{
              // boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
              color: "black",
              display: "flex",
              flexDirection: "column",
            }}
            href="https://klms.vliv.app/kms/dashboard"
          >
            <Typography sx={{ padding: 0.5, borderRadius: 2 }}>
              <TbBulb
                style={{
                  width: "24px",
                  height: "24px",
                  color: "grey",
                }}
              />
            </Typography>
            <Typography
              style={{
                color: "grey",
                mt: "4px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              KMS
            </Typography>
          </Button>

          <Button
            sx={{
              // boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
              color: "black",
              display: "flex",
              flexDirection: "column",
            }}
            href="https://klms.vliv.app/lms/dashboard"
          >
            <Typography sx={{ padding: 0.5, borderRadius: 2 }}>
              <IoBookSharp
                style={{
                  width: "24px",
                  height: "24px",
                  color: "grey",
                }}
              />
            </Typography>
            <Typography
              style={{
                color: "grey",
                mt: "4px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              LMS
            </Typography>
          </Button>

          <Button
            sx={{
              // boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
              color: "black",
              display: "flex",
              flexDirection: "column",
            }}
            href="https://pms.vliv.app/pms/projects"
          >
            <Typography sx={{ padding: 0.5, borderRadius: 2 }}>
              <PiShareNetwork
                style={{
                  width: "24px",
                  height: "24px",
                  color: "grey",
                }}
              />
            </Typography>
            <Typography
              style={{
                color: "grey",
                mt: "4px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              PMS
            </Typography>
          </Button>
          <Button
            sx={{
              // boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
              color: "black",
              display: "flex",
              flexDirection: "column",
            }}
            href="https://dms.vliv.app/dms/document"
          >
            <Typography sx={{ padding: 0.5, borderRadius: 2 }}>
              <PiFileDocLight
                style={{
                  width: "24px",
                  height: "24px",
                  color: "grey",
                }}
              />
            </Typography>
            <Typography
              style={{
                color: "grey",
                mt: "4px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              DMS
            </Typography>
          </Button>
          <Box
            sx={{
              // border: "solid 1px black",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: "30px",
              width:"100%"
            }}
          >
            {renderRoute?.map((route, ind) => (
              <Tooltip
                key={ind}
                title={route.iconComponent?.props?.title || ""}
              >
                <span>
                  <Button
                    sx={{
                      // boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
                      color: "black",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      // mb: 0.6,
                    }}
                    onClick={() => {
                      if (!route.iconComponent?.props?.title) {
                        handleMainRouteClick(route.path);
                      }
                    }}
                    disabled={!!route.iconComponent?.props?.title}
                  >
                    <Typography>
                      <Typography
                        sx={{
                          background:
                            currentPath.includes(route.path) ||
                            selectedMainRoute === route.path
                              ? "#37668F"
                              : "#FBFBFB",
                          padding: 1,
                          borderRadius: 2,
                          width: "40px",
                          height: "40px",
                          mb: "4px",
                          boxShadow:
                            currentPath.includes(route.path) ||
                            selectedMainRoute === route.path
                              ? "rgba(0, 0, 0, 0.18) 0px 2px 4px"
                              : "",
                        }}
                      >
                        {route.path == "/hrm" ? (
                          <MdGroups
                            style={{
                              width: "24px",
                              height: "24px",
                              color:
                                currentPath.includes(route.path) ||
                                selectedMainRoute === route.path
                                  ? "#FFFF"
                                  : "grey",
                              // border:"solid 1px black"
                            }}
                          />
                        ) : route.path == "/settings" ? (
                          <SettingsIcon
                            style={{
                              width: "24px",
                              height: "24px",
                              color:
                                currentPath.includes(route.path) ||
                                selectedMainRoute === route.path
                                  ? "#FFFF"
                                  : "grey",
                            }}
                          />
                        ) : null}
                      </Typography>

                      <Typography
                        style={{
                          mt: "4px",
                          fontSize: "16px",
                          fontWeight: "500",
                          color:
                            currentPath.includes(route.path) ||
                            selectedMainRoute === route.path
                              ? "#37668F"
                              : "grey",
                        }}
                      >
                        {route.path == "/settings" ? null : route.name}
                      </Typography>
                    </Typography>
                  </Button>
                </span>
              </Tooltip>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Sidebar;
