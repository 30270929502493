import { Box, Button, Checkbox, Modal, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { Editor as WysiwygEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import Cookies from "js-cookie";
import { AiOutlineDelete } from "react-icons/ai";
import { MdModeEdit, MdOutlineQuiz } from "react-icons/md";
import { CiLink } from "react-icons/ci";
import WestIcon from "@mui/icons-material/West";
import "../../../../styles/JoditEditorStyles.css";
import { RiDeleteBin6Line } from "react-icons/ri";

function ViewCompliance() {
  const { id } = useParams();
  const navigate = useNavigate();

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const userrole = userInfo.userrole;
  const department = userInfo.department;

  const [data, setData] = useState([]);
  const [agreedUser, setAgreedUser] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  //View assessment
  const [openViewAssessment, setOpenViewAssessment] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [complianceid, setComplianceid] = useState(id);
  const [checkAuthor, setCheckAuthor] = useState(false);

  useEffect(() => {
    axios
      .post(`https://hrms.vliv.app/compliance/getbyid`, { id },{withCredentials:true})
      .then((res) => {
        // console.log(res.data, "get by id");
        setData(res.data);
        // setEditorState(
        //   EditorState.createWithContent(convertFromRaw(res.data.description)) // now this editor is not in use
        // );
      })
      .catch((err) => console.log(err));
  }, [id, refresh]);

  const handleBack = () => {
    navigate("/hrm/compliance");
  };

  const handleOpenAssessment = () => {
    // setIsChecked(event.target.checked);
    setOpenViewAssessment(true);
  };

  const handleAgree = () => {
    axios
      .post(`https://hrms.vliv.app/compliance/agreed`, { id, email })
      .then((res) => {
        alert(res.data.message);
        setRefresh(!refresh);
      },{withCredentials:true})
      .catch((err) => console.log(err));
  };

  const handleUpdate = () => {
    navigate(`/hrm/updatecompliance/${id}`);
  };

  const handleDelete = (id) => {
    axios
      .post(`https://hrms.vliv.app/compliance/delete`, { id })
      .then((res) => {
        alert(res.data.message);
        navigate('/hrm/compliance')
       
      },{withCredentials:true})
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Button
        sx={{ marginLeft: 1 }}
        onClick={handleBack}
        startIcon={<WestIcon />}
        className="cancelBtn"
        // variant="outlined"
      >
        Back
      </Button>

      <Box sx={{ background: "#F4FEFE", padding:2 }}>
        <Box sx={{ padding: 1, position: "relative", display: "flex", gap: 4 }}>
          <Box sx={{ width: "50%" }}>
            <img
              src={data.thumbnail}
              width="100%"
              height="200"
              style={{ borderRadius: "10px" }}
            />
          </Box>
          <Box
            sx={
              {
                // width: "50%",
              }
            }
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: "bold", fontSize: "28px" }}>
                  {data.compliancename}
                </Typography>
              </Box>
            </Box>
          </Box>
          {(userrole == "masteradmin" || data.userid == userid) && (
            <Box
              sx={{
                position: "absolute",
                top: "80%",
                bottom: 1,
                left: "85%",
                // right: "10%",
                display:"flex",
                justifyContent:"space-between",
                gap:1.5,
                // border:"solid 1px black"
              }}
            >
              <Button
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  padding: 1,

                  borderRadius: "10px",
                  backgroundColor: "#FFFF",
                  color: "#000",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                }}
                // variant="primary"
                variant="secondary"
                onClick={handleUpdate}
              >
                <MdModeEdit
                  style={{
                    width: "18px",
                    height: "18px",
                    mr: 1,
                  }}
                />
                <p style={{ marginLeft: "8px", marginRight: "8px" }}>Edit</p>
              </Button>

              <Button
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  padding: 1,

                  borderRadius: "10px",
                  backgroundColor: "#FFFF",
                  color: "#000",
                  border:"solid 0.5px black"
                }}
                variant="secondary"
                onClick={() => handleDelete(data.id)}
              >
                <RiDeleteBin6Line
                  style={{
                    width: "18px",
                    height: "18px",
                    mr: 1,
                  }}
                />
                <p style={{ marginLeft: "8px", marginRight: "8px" }}>Delete</p>
              </Button>
            </Box>
          )}
        </Box>

        {/* For the view of description */}
        {/* <Box>
        <WysiwygEditor editorState={editorState} readOnly toolbarHidden />
      </Box> */}
        <Box
          sx={{ padding: 2, bgcolor: "#FFFF", borderRadius: 5 }}
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
        {data.file && (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "",
              justifyContent: "flex-start",
              padding: 1,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Attachment:</Typography>
            <a href={data.file} target="_blank">
              <CiLink style={{ color: "blue" }} />
            </a>
          </Box>
        )}



          </Box>
     
    {/* Checkbox and Buttons */}
    {data && data.agreed && data.agreed.includes(email) ? (
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Typography sx={{ fontWeight: "bold", color: "green" }}>
            ✔ Agreed with this policy
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{ display: "flex", justifyContent: "space-between", padding: 1 }}
        >
          {data && data.assessmentid ? (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                {
                  <Typography sx={{ fontWeight: "bold" }}>
                    <MdOutlineQuiz onClick={handleOpenAssessment} /> Kindly
                    complet an Assessment.
                  </Typography>
                }
                {(data && data.fail && data.fail.includes(email) && data.pass && !data.pass.includes(email)) ? (
                  <Typography sx={{ color: "red" }}>
                    You have failed the assessment
                  </Typography>
                ) : (data && data.fail && data.fail.includes(email) && data.pass && data.pass.includes(email)) ? (
                  <Typography sx={{ color: "Green" }}>
                    Congrats to clear the assessment
                  </Typography>
                ) : (
                  <Typography></Typography>
                )}
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}
          {data && data.pass && data.pass.includes(email) && (
            <Box>
              <Button
                onClick={handleAgree}
                sx={{
                  marginLeft: 1,
                  backgroundColor: "#37668F",
                  color: "white",
                }}
              >
                I Agree
              </Button>
            </Box>
          )}

          {/* Show I agree if there are no assessment inside the compliance */}
          {data && data.assessmentid == false && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleAgree}
                sx={{
                  marginLeft: 1,
                  backgroundColor: "#37668F",
                  color: "white",
                }}
              >
                I Agree
              </Button>
            </Box>
          )}
        </Box>
      )}

    </>
  );
}

export default ViewCompliance;
