import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { recruitmentGetByIdApi } from "../../../../Constants/api";
import CancelIcon from "@mui/icons-material/Cancel";
import Cookies from "js-cookie";
import { formatDate } from "../../../../Constants/dateFormat";
import { getFileNameFromUrl } from "../../../../Constants/constants";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  overflow: "hidden",
  "&::-webkit-scrollbar": {
    width: "3px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "teal",
  },
};

const boxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 8,
  my: 1.5,
};

const childStyle = {
  flex: 1,
};

const RowDetailsModal = ({ open, handleClose, rowId }) => {
  //cokies information
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let username = userData.username ? userData.username : "";

  const [data, setData] = useState({
    branch: "",
    department: "",
    positions: "",
    noofpositions: "",
    employeetype: "",
    frombudget: "",
    tobudget: "",
    level: "",
    experience: "",
    description: "",
    companyemail: companyemail,
    companyid: companyid,
    country: "",
    status: "",
    approver: "",
    jd: "",
    raisedby: "",
    id: rowId,
  });

  useEffect(() => {
    axios
      .post(recruitmentGetByIdApi, { id: rowId },{withCredentials:true})

      .then((res) => {
        // console.log(res, "res");
        res.data?.data.length
          ? setData((prev) => ({
              ...prev,
              ...res.data.data[0],
            }))
          : setData((prev) => prev);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [rowId]);

  // console.log(data, "data");

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
          <Typography
            color={"#000A14"}
            fontSize={"1.3rem"}
            fontWeight={"500"}
            textAlign={"center"}
          >
            {data.positions}
          </Typography>

          <Typography
            color={"#000A14"}
            fontSize={"1.3rem"}
            fontWeight={"500"}
            textAlign={"center"}
          >
            RID-{rowId}
          </Typography>

          <IconButton onClick={handleClose}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Divider />
        <Grid container px={2} sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2,gap:2  }}>
          <Grid item md={5.5} sm={12} xs={12} className="view-left">
            <Box sx={boxStyle}>
              <p className="view-page-title" style={childStyle}>
                Branch{" "}
              </p>
              <p className="view-page-value" style={childStyle}>
                {data.branch}
              </p>
            </Box>
            <Box sx={boxStyle}>
              <p className="view-page-title" style={childStyle}>
                Position{" "}
              </p>
              <p className="view-page-value" style={childStyle}>
                {data.positions}
              </p>
            </Box>
            <Box sx={boxStyle}>
              <p className="view-page-title" style={childStyle}>
                Employee Type{" "}
              </p>
              <p className="view-page-value" style={childStyle}>
                {data.employeetype}
              </p>
            </Box>
            <Box sx={boxStyle}>
              <p className="view-page-title" style={childStyle}>
                Experience{" "}
              </p>
              <p className="view-page-value" style={childStyle}>
                {data.experience}
              </p>
            </Box>
            <Box sx={boxStyle}>
              <p className="view-page-title" style={childStyle}>
                Budget From
              </p>
              <p className="view-page-value" style={childStyle}>
                {data.frombudget}
              </p>
            </Box>
            <Box sx={boxStyle}>
              <p className="view-page-title" style={childStyle}>
                Application Date
              </p>
              <p className="view-page-value" style={childStyle}>
                {data.createdAt ? formatDate(data.createdAt) : "-"}
              </p>
            </Box>
          </Grid>
          <Grid item md={5.5} sm={12} xs={12} className="view-right">
            <Box sx={boxStyle}>
              <p className="view-page-title" style={childStyle}>
                Department :
              </p>
              <p className="view-page-value" style={childStyle}>
                {data.department}
              </p>
            </Box>
            <Box sx={boxStyle}>
              <p className="view-page-title" style={childStyle}>
                No Of Positions :
              </p>
              <p className="view-page-value" style={childStyle}>
                {data.noofpositions}
              </p>
            </Box>
            <Box sx={boxStyle}>
              <p className="view-page-title" style={childStyle}>
                Level :
              </p>
              <p className="view-page-value" style={childStyle}>
                {data.level}
              </p>
            </Box>
            <Box sx={boxStyle}>
            <p className="view-page-title" style={childStyle}>Raised By :</p>
            <p className="view-page-value" style={childStyle}>{data.raisedby}</p>
            </Box>
            <Box sx={boxStyle}>
            <p className="view-page-title" style={childStyle}>Budget To :</p>
            <p className="view-page-value" style={childStyle}>{data.tobudget}</p>
            </Box>
            <Box sx={boxStyle}>
            <p className="view-page-title" style={childStyle}>JD :</p>
              <p style={childStyle}>
                {data.jd ? (
                  <a href={data.jd}  style={{textDecoration:"none"}} className="primarycolor">
                    {getFileNameFromUrl(data.jd)}
                  </a>
                ) : (
                  "-"
                )}
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default RowDetailsModal;
