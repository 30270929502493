import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { LuVideo } from "react-icons/lu";
import { PiHandsClapping } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { CiTrash } from "react-icons/ci";

import { AiOutlineDelete } from "react-icons/ai";
// import ViewAssessment from "../Assessment/ViewAssessment/ViewAssessment";

function ComplianceDashboard() {
  const navigate = useNavigate();
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const department = userInfo.department;
 const userrole = userInfo.userrole;
 
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);



  useEffect(() => {
    axios
      .post(`https://hrms.vliv.app/compliance/get`, { companyid },{withCredentials:true})
      .then((res) => {
        // console.log(res.data, "get all data");
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleAddCompliance = () => {
    navigate("/hrm/addcompliance");
  };

  const handleViewCompliance = (id) => {
    navigate(`/hrm/viewcompliance/${id}`);
  };
  //To Search
  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();

  //For delete

  const handleOpenDialog = (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setDeleteId(null);
    setOpenDialog(false);
  };

  const handleDelete = () => {
    axios
      .post(`https://hrms.vliv.app/compliance/delete`, { id: deleteId },{withCredentials:true})
      .then((res) => {
        alert(res.data.message);
        setData(data.filter((item) => item.id !== deleteId));
        handleCloseDialog();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Box sx={{ padding: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={{ mb: 2, fontWeight: "bold" }}>
            <BsFileEarmarkCheck />
            <span> Compliance</span>
          </Typography>{" "}
          <Typography
            sx={{
              mt: 2,
              mb: 2,
              // fontWeight: "bold",
              color: "#026AA2",
              cursor: "pointer",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              sx={{ marginBottom: 2, width: 400, marginRight: 2 }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Typography>{" "}
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        >
         {(userrole == "masteradmin" || userrole == "HR") &&  <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <Box
              sx={{
                background: "#FFFF",
                width: "100%",
                height: "100%",
                textAlign: "center",
                borderRadius: "15px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                border: "dotted 2px #37668F",
                cursor: "pointer",
              }}
              onClick={handleAddCompliance}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "#37668F" }}>
                  <FaPlus />
                  <br />
                  Create
                </Typography>
              </Box>
            </Box>
          </Grid>}
          {filteredData.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              key={index}
              sx={{ position: "relative" }}
            >
         {   (userrole =="masteradmin" || userrole == "admin") &&   <Box sx={{  position: "absolute",
                          top: 25,
                          right: 10,
                          width: 30,
                          height: 30,
                          color: "red",
                          backgroundColor: "white",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",}}>
                <CiTrash
                  style={{
                    width: "22px",
                    height: "22px",
                    cursor: "pointer",

                    color: "#2D3747",
                    borderRadius: 5
                  }}
                  onClick={() => handleOpenDialog(item.id)}
                />
              </Box>}
              <Box
                sx={{
                  background: "#FFFF",
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "15px",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
                onClick={() => handleViewCompliance(item.id)}
              >
                <img
                  src={item.thumbnail}
                  alt="Thumbnail"
                  style={{
                    width: "100%",
                    height: "24vh",
                    borderRadius: "10px",
                  }}
                />
                <Box>
                  <Box
                    sx={{
                      width: "16vw",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 0px 5px 5px",
                      mt: 1,
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                        {item.compliancename.substring(0,21)+"..."}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "grey",
                      }}
                    >
                      created at - {new Date(item.createdAt).toDateString()}
                    </Typography>
                    <Typography>
                      <PiHandsClapping
                        style={{
                          color: "green",
                          width: "25px",
                          height: "25px",
                          borderRadius: "8px",
                          padding: 1,
                          marginBottom: -5,
                        }}
                      />
                      <span> {item.agreed && +item.agreed.length || 0}</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this compliance?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>



     
    </>
  );
}

export default ComplianceDashboard;
