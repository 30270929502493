import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Country, State } from "country-state-city";
import { addPayrollEWF } from "../../../../redux/actions/actions.master/payrollEWF.actions";

const allCountries = Country.getAllCountries();

const AddLWF = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  let country = companyInfo.country || "";
  const dispatch = useDispatch();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [states, setStates] = useState([]);
  const [data, setData] = useState({
    state: "",
    effectdate: "",
    frequency: "",
    startmonth: "",
    minlimit: "",
    maxlimit: "",
    employeecontri: "",
    employercontri: "",
    isApplicable: "",
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (name == "isApplicable") {
      setData({ ...data, [name]: e.target.checked });
    }
  };

  const handleSave = () => {
    // console.log("data to be save", data);
    dispatch(addPayrollEWF(data));
    setData({
      state: "",
      effectdate: "",
      frequency: "",
      startmonth: "",
      minlimit: "",
      maxlimit: "",
      employeecontri: "",
      employercontri: "",
      isApplicable: false,
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
    setOpenDrawer(false);
  };

  useEffect(() => {
    
    const countryISO = allCountries.find(
      (c) => c.name.toLowerCase() === country.toLowerCase()
    ) || "";
    setStates(State.getStatesOfCountry(countryISO.isoCode) || []);
  }, []);

  return (
    <>
      <Button
        className="saveBtn"
        variant="contained"
        onClick={() => setOpenDrawer(true)}
      >
        Add
      </Button>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box width={700} display="flex" flexDirection="column" height="100%">
          <Box p={2} flexGrow={1} overflow="auto">
            <Typography
              variant="h6"
              color={"#062C41"}
              display={"flex"}
              fontWeight={600}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={1}
            >
              Add LWF Policies
              <span>
                <IconButton
                  onClick={() => {
                    setData({
                      state: "",
                      effectdate: "",
                      frequency: "",
                      startmonth: "",
                      minlimit: "",
                      maxlimit: "",
                      employeecontri: "",
                      employercontri: "",
                      isApplicable: false,
                      companyid: companyInfo.companyid,
                      companyemail: companyInfo.companyemail,
                    });
                    setOpenDrawer(false);
                  }}
                >
                  <CancelIcon sx={{ color: "black" }} />
                </IconButton>
              </span>
            </Typography>
            <Divider />
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>State</h4>
                <Select
                  fullWidth
                  inputProps={{
                    className: "selectInput",
                  }}
                  className="formInputs"
                  placeholder="Enter State Name"
                  value={data.state}
                  onChange={handleChange}
                  name="state"
                  error={!data.state && showEmptyError}
                  helperText={
                    !data.state && showEmptyError && "State is required"
                  }
                >
                  {states?.map((state, ind) => (
                    <MenuItem
                      value={state.name}
                      key={ind}
                      sx={{ textAlign: "left" }}
                    >
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Effective Date</h4>
                <TextField
                  fullWidth
                  type="date"
                  className="formInputs"
                  value={data.effectdate}
                  onChange={handleChange}
                  name="effectdate"
                  error={!data.effectdate && showEmptyError}
                  helperText={
                    !data.effectdate && showEmptyError && "Date is required"
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Contribution Frequency</h4>
                <Select
                  inputProps={{
                    className: "selectInput",
                  }}
                  fullWidth
                  className="formInputs"
                  value={data.frequency}
                  onChange={handleChange}
                  name="frequency"
                  error={!data.frequency && showEmptyError}
                  helperText={
                    !data.frequency && showEmptyError && "Frequency is required"
                  }
                >
                  <MenuItem value="Half Yearly">Half Yearly</MenuItem>
                  <MenuItem value="Annually">Annually</MenuItem>
                </Select>
              </Grid>
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>
                  Contribution Start Month
                </h4>
                <Select
                  fullWidth
                  inputProps={{
                    className: "selectInput",
                  }}
                  className="formInputs"
                  value={data.startmonth}
                  onChange={handleChange}
                  name="startmonth"
                  error={!data.startmonth && showEmptyError}
                  helperText={
                    !data.startmonth &&
                    showEmptyError &&
                    "Starting Month is required"
                  }
                >
                  <MenuItem value="January">January</MenuItem>
                  <MenuItem value="February">February</MenuItem>
                  <MenuItem value="March">March</MenuItem>
                  <MenuItem value="April">April</MenuItem>
                  <MenuItem value="May">May</MenuItem>
                  <MenuItem value="June">June</MenuItem>
                  <MenuItem value="July">July</MenuItem>
                  <MenuItem value="August">August</MenuItem>
                  <MenuItem value="September">September</MenuItem>
                  <MenuItem value="October">October</MenuItem>
                  <MenuItem value="November">November</MenuItem>
                  <MenuItem value="December">December</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Min Limit</h4>
                <TextField
                  fullWidth
                  type="number"
                  className="formInputs"
                  placeholder="-"
                  value={data.minlimit}
                  onChange={handleChange}
                  name="minlimit"
                  error={!data.minlimit && showEmptyError}
                  helperText={
                    !data.minlimit &&
                    showEmptyError &&
                    "Minimum Limit is required"
                  }
                />
              </Grid>
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Max Limit</h4>
                <TextField
                  fullWidth
                  type="number"
                  className="formInputs"
                  placeholder="-"
                  value={data.maxlimit}
                  onChange={handleChange}
                  name="maxlimit"
                  error={!data.maxlimit && showEmptyError}
                  helperText={
                    !data.maxlimit &&
                    showEmptyError &&
                    "Maximum Limit is required"
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Employee Contribution</h4>
                <TextField
                  fullWidth
                  type="number"
                  className="formInputs"
                  placeholder="-"
                  value={data.employeecontri}
                  onChange={handleChange}
                  name="employeecontri"
                  error={!data.employeecontri && showEmptyError}
                  helperText={!data.employeecontri && showEmptyError}
                />
              </Grid>
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Employer Contribution</h4>
                <TextField
                  fullWidth
                  type="number"
                  className="formInputs"
                  placeholder="-"
                  value={data.employercontri}
                  onChange={handleChange}
                  name="employercontri"
                  error={!data.employercontri && showEmptyError}
                  helperText={!data.employercontri && showEmptyError}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isApplicable"
                      checked={data.isApplicable}
                      onChange={handleChange}
                    />
                  }
                  label="Is applicable in your State"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            mt="auto"
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            bgcolor={"background.paper"}
            p={2}
            display={"flex"}
            justifyContent={"flex-end"}
            gap={2}
          >
            <Button
              className="cancelBtn"
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setData({
                  state: "",
                  effectdate: "",
                  frequency: "",
                  startmonth: "",
                  minlimit: "",
                  maxlimit: "",
                  employeecontri: "",
                  employercontri: "",
                  isApplicable: false,
                  companyid: companyInfo.companyid,
                  companyemail: companyInfo.companyemail,
                });
                setOpenDrawer(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="saveBtn"
              variant="contained"
              onClick={() => {
                if (
                  !data.state ||
                  !data.startmonth ||
                  !data.effectdate ||
                  !data.frequency ||
                  !data.minlimit ||
                  !data.maxlimit ||
                  !data.employercontri ||
                  !data.employercontri
                ) {
                  setShowEmptyError(true);
                  return;
                } else {
                  handleSave();
                }
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AddLWF;
