import React from "react";
import ListLWF from "./ListLWF";

const LWF = () => {
  return (
    <>
      <ListLWF />
    </>
  );
};

export default LWF;
