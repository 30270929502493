import React from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../Constants/PageNotFound";
import RecruitmentProcess from "../Components/HRM/RecruitManagement/RecruitmentProcess/RecruitmentProcess";
import Recruitment from "../Components/HRM/RecruitManagement/Recruitment/Recruitment";
import PayslipLogo from "../Components/Masters/General/PayslipLogo/PayslipLogo";
import Values from "../Components/Masters/General/Values/Values";
import Series from "../Components/Masters/Employees/Series/Series";
import Position from "../Components/Masters/Employees/Position/Position";
import LeavingReason from "../Components/Masters/Employees/LeavingReason/LeavingReason";
import AssetGroup from "../Components/Masters/Employees/AssetGroup/AssetGroup";
import EmployeeDirectory from "../Components/Masters/Employees/EmployeeDirectory/EmployeeDirectory";
import FBPolicy from "../Components/Masters/Payroll/FBPolicy/FBPolicy";
import Filing from "../Components/Masters/Payroll/Filing/Filing";
import LWF from "../Components/Masters/Payroll/LWF/LWF";
import Reimbursement from "../Components/Masters/Payroll/Reimbursement/Reimbursement";
import Policies from "../Components/Masters/Policies/Policies/Policies";
import LeaveScheme from "../Components/Masters/Leave/LeaveScheme/LeaveScheme";
import LeaveType from "../Components/Masters/Leave/LeaveType/LeaveType";
import RegulariseMaster from "../Components/Masters/Leave/RegulariseMaster/RegulariseMaster";
import WeekendPolicy from "../Components/Masters/Leave/WeekendPolicy/WeekendPolicy";
import Shift from "../Components/Masters/Attendance/Shift/Shift";
import JobApplications from "../Components/HRM/RecruitManagement/JobApplications/JobApplications";
import InterViewProcess from "../Components/HRM/RecruitManagement/InterviewProcess/InterViewProcess";
import Registration from "../Components/HRM/EmployeeManagement/Registration/Registration";
import OnBoarding from "../Components/HRM/RecruitManagement/OnBoardingprocess/OnBoarding";
import OnBoardDocumentUpload from "../Components/HRM/RecruitManagement/OnBoardingprocess/OnBoardDocumentUpload";
import ViewEditEmployee from "../Components/HRM/EmployeeManagement/Registration/ViewEditEmployee";
import ORGChart from "../Components/HRM/EmployeeManagement/ORGChart/ORGChart";
import Dashboard from "../Components/HRM/Summary/Dashboard/Dashboard";
import Welcome from "../Components/HRM/Summary/Welcome/Welcome";
import Summary from "../Components/HRM/EmployeeManagement/EmpSelfService/Summary/Summary";
import TimeAndAttendance from "../Components/HRM/EmployeeManagement/EmpSelfService/TimendAttendance/TimeAndAttendance";
import Leave from "../Components/HRM/EmployeeManagement/LeaveManagement/Leave";
import LeaveManagement from "../Components/HRM/EmployeeManagement/EmpSelfService/LeaveManagement/LeaveManagement";
import EmployeeExit from "../Components/HRM/EmployeeManagement/EmpSelfService/EmployeeExit/EmployeeExit";
import TimeAttendance from "../Components/HRM/EmployeeManagement/TimeAttendance/TimeAttendance";
import Reimburse from "../Components/HRM/EmployeeManagement/EmpSelfService/Reimburse/Reimburse";
import HREmployeeExit from "../Components/HRM/EmployeeManagement/EmployeeExit/HREmployeeExit";
import AddSeparation from "../Components/HRM/EmployeeManagement/EmployeeExit/Separation/AddSeparation";
import EditSeparation from "../Components/HRM/EmployeeManagement/EmployeeExit/Separation/EditSeparation";
import EditNoticePeriod from "../Components/HRM/EmployeeManagement/EmployeeExit/NoticePeriod/EditNoticePeriod";
import SignIn from "../Components/Auth/SignIn";
import ProtectRoute from "./ProtectRoute";
import EditFF from "../Components/HRM/EmployeeManagement/EmployeeExit/FF/EditFF";
import EmployeeDocuments from "../Components/HRM/EmployeeManagement/EmpSelfService/EmployeeDocuments/EmployeeDocuments";
import DashboardAttendance from "../Components/HRM/EmployeeManagement/EmpSelfService/Summary/Dashboard/Dashboard";

import OrganizationGoalKpi from "../Components/Masters/Performance/OrganisationKpi/OrganizationGoalKpi";
import EmployeeDevelopementKpi from "../Components/Masters/Performance/EmployeeDevelopementKpi/EmployeeDevelopementKpi";
import TeamBuildingKpi from "../Components/Masters/Performance/TeamBuildingKpi/TeamBuildingKpi";
import SummaryKPI from "../Components/HRM/Performance/Summary/SummaryKPI";
import OrganizationalGoalKPI from "../Components/HRM/Performance/OrganizationalGoalKPI/OrganizationalGoalKPI";
import EmployeeDevelopementKPI from "../Components/HRM/Performance/EmployeeDevelopementKPI/EmployeeDevelopementKPI";
import TeamBuildingKPI from "../Components/HRM/Performance/TeamBuildingKPI/TeamBuildingKPI";
import DocumentGeneration from "../Components/HRM/EmployeeManagement/DocumentGeneration/DocumentGeneration";
import Tasks from "../Components/HRM/TaskAndEvents/Task/Tasks";
import Events from "../Components/HRM/TaskAndEvents/Events/Events";
import MIS from "../Components/HRM/Summary/MIS/MIS";
import AllowanceStructure from "../Components/Masters/Payroll/Structure/AllowanceStructure";
import PayrollInputs from "../Components/HRM/Payroll/PayrollInputs/PayrollInputs";
import Salary from "../Components/HRM/Payroll/Salary/Salary";
import Loan from "../Components/HRM/Payroll/Loan/Loan";
import PayrollReport from "../Components/HRM/Payroll/Report/PayrollReport";
import Cookies from "js-cookie";

import Compliance from "../Components/HRM/Compliance/Compliance";
import AddCompliance from "../Components/HRM/Compliance/AddCompliance/AddCompliance";
import UpdateCompliance from "../Components/HRM/Compliance/UpdateCompliance/UpdateCompliance";
import ViewCompliance from "../Components/HRM/Compliance/ViewCompliance/ViewCompliance";
import EmployeeMasters from "../Components/Masters/Employees/Position/EmployeeMasters";
import MISDashboard from "../Components/HRM/Summary/MIS/Dashboard/MISDashboard";
import ReimbursementApprove from "../Components/HRM/EmployeeManagement/ReimburseMent/ReimbursementApprove";
import Attendances from "../Components/HRM/EmployeeManagement/TimeAttendance/Attendances";

const AllRoutes = () => {
  let userInfo = null;
  let userrole = null;

  const userInfoCookie = Cookies.get("userinfo");

  if (userInfoCookie) {
    try {
      userInfo = JSON.parse(userInfoCookie);
      userrole = userInfo?.userrole;
    } catch (error) {
      console.error("Error parsing userinfo cookie:", error);
    }
  }
  return (
    <Routes>
      {/* these routes need not to be protected  */}

      <Route exact path="/signin" element={<SignIn />}></Route>
      <Route exact path="*" element={<PageNotFound />}></Route>

      <Route
        exact
        path="/jobapplications"
        element={<JobApplications />}
      ></Route>
      <Route
        exact
        path="/onboarddocuments-upload"
        element={<OnBoardDocumentUpload />}
      ></Route>
      {/* these routes need not to be protected  */}

      
      <Route element={<ProtectRoute />}>
        <Route exact path="/" element={<h1>Home</h1>}></Route>
        <Route exact path="/hrm/summary" element={<Welcome />}></Route>
        <Route exact path="/hrm/summary/welcome" element={<Welcome />}></Route>
        <Route
          exact
          path="/hrm/report/analytics"
          element={<Dashboard />}
        ></Route>
        <Route exact path="/hrm/summary/MIS" element={<MIS />}></Route>
        <Route
          exact
          path="/hrm/report/stats"
          element={<MISDashboard />}
        ></Route>
        <Route
          exact
          path="/hrm/recruitmanagement/recruit"
          element={<Recruitment />}
        ></Route>
        <Route
          exact
          path="/hrm/recruitmanagement/recruitprocess"
          element={<RecruitmentProcess />}
        ></Route>
        <Route
          exact
          path="/hrm/recruitmanagement/interviewprocess"
          element={<InterViewProcess />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/registration"
          element={<Registration />}
        ></Route>
        <Route
          exact
          path="hrm/recruitmanagement/onboarding"
          element={<OnBoarding />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/registration/update/:id"
          element={<ViewEditEmployee />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/orgchart"
          element={<ORGChart />}
        ></Route>
        <Route
          exact
          path="/hrm/employeeselfservice/summary"
          element={<Summary />}
        ></Route>
        <Route
          exact
          path="/hrm/employeeselfservice/timendattendance"
          element={<DashboardAttendance />}
        ></Route>
        <Route
          exact
          path="/hrm/employeeselfservice/timendattendanceview"
          element={<TimeAndAttendance />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/reimbursement"
          element={<ReimbursementApprove />}
        ></Route>
        <Route
          exact
          path="/hrm/employeeselfservice/leave"
          element={<LeaveManagement />}
        ></Route>
        <Route
          exact
          path="/hrm/employeeselfservice/employee-exit"
          element={<EmployeeExit />}
        ></Route>
        <Route
          exact
          path="/hrm/employeeselfservice/reimbursement"
          element={<Reimburse />}
        ></Route>
        <Route
          exact
          path="/hrm/employeeselfservice/empdocs"
          element={<EmployeeDocuments />}
        ></Route>
        <Route
          exact
          path="/hrm/performance/summary"
          element={<SummaryKPI />}
        ></Route>
        <Route
          exact
          path="/hrm/performance/orggoalkpi"
          element={<OrganizationalGoalKPI />}
        ></Route>
        <Route
          exact
          path="/hrm/performance/empdevkpi"
          element={<EmployeeDevelopementKPI />}
        ></Route>
        <Route
          exact
          path="/hrm/performance/teambuildkpi"
          element={<TeamBuildingKPI />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/leavemanagement"
          element={<Leave />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/documentgenerate"
          element={<DocumentGeneration />}
        ></Route>
        <Route
          exact
          path="/hrm/payroll/payrollinputs"
          element={<PayrollInputs />}
        ></Route>
        <Route exact path="/hrm/payroll/salary" element={<Salary />}></Route>
        <Route exact path="/hrm/payroll/loan" element={<Loan />}></Route>
        <Route
          exact
          path="/hrm/payroll/reports"
          element={<PayrollReport />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/timeandattendance"
          element={<Attendances />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/employeeexit"
          element={<HREmployeeExit />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/employeeexit/separation/add"
          element={<AddSeparation />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/employeeexit/separation/update"
          element={<EditSeparation />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/employeeexit/noticeperiod/update/:id"
          element={<EditNoticePeriod />}
        ></Route>
        <Route
          exact
          path="/hrm/employeemanagement/employeeexit/ff/update/:id"
          element={<EditFF />}
        ></Route>

        {/* For compliacnce */}
        <Route exact path="/hrm/compliance" element={<Compliance />} />
        {(userrole == "masteradmin" || userrole == "HR" ) && (
          <Route exact path="/hrm/addcompliance" element={<AddCompliance />} />
        )}
        {(userrole == "masteradmin" || userrole == "HR" ) && (
          <Route
            exact
            path="/hrm/updatecompliance/:id"
            element={<UpdateCompliance />}
          />
        )}

        <Route
          exact
          path="/hrm/viewcompliance/:id"
          element={<ViewCompliance />}
        />

        <Route exact path="/hrm/task&events/tasks" element={<Tasks />}></Route>
        <Route
          exact
          path="/hrm/task&events/events"
          element={<Events />}
        ></Route>

        {/**
         ******** HRMS Master Routes---- General
         */}
        {(userrole == "HR" || userrole == "masteradmin") && (
          <Route
            exact
            path="/settings/general/invoicepaysliplogo"
            element={<PayslipLogo />}
          ></Route>
        )}
        {(userrole == "HR" || userrole == "masteradmin") && (
          <Route
            exact
            path="/settings/general/values"
            element={<Values />}
          ></Route>
        )}
        {/**
         ******** HRMS Master Routes---- Employees
         */}
        {(userrole == "HR" || userrole == "masteradmin") && (
          <Route
            exact
            path="/settings/employees/employeeseries"
            element={<Series />}
          ></Route>
        )}
        {(userrole == "HR" || userrole == "masteradmin") && (
          <Route
            exact
            path="/settings/employees/employeemasters"
            element={<EmployeeMasters />}
          ></Route>
        )}
        {(userrole == "HR" || userrole == "masteradmin") && (
          <Route
            exact
            path="/settings/employees/leavingreason"
            element={<LeavingReason />}
          ></Route>
        )}
 {   (userrole == "HR" || userrole == "masteradmin") &&     <Route
          exact
          path="/settings/employees/assetgroupsetting"
          element={<AssetGroup />}
        ></Route>}
       {(userrole == "HR" || userrole == "masteradmin") &&  <Route
          exact
          path="/settings/employees/configureemployeedirectory"
          element={<EmployeeDirectory />}
        ></Route>}
        {/**
         ******** HRMS Master Routes---- Payroll
         */}
        <Route
          exact
          path="/settings/payroll/lwfpolicies"
          element={<LWF />}
        ></Route>
        <Route
          exact
          path="/settings/payroll/pfptesilwffiling"
          element={<Filing />}
        ></Route>
        { (userrole == "HR" || userrole == "masteradmin") && <Route
          exact
          path="/settings/payroll/reimbursementreviewer"
          element={<Reimbursement />}
        ></Route>}
 {   (userrole == "HR" || userrole == "masteradmin") &&     <Route
          exact
          path="/settings/payroll/structures"
          element={<AllowanceStructure />}
        ></Route>}
        <Route
          exact
          path="/settings/payroll/fbppolicy"
          element={<FBPolicy />}
        ></Route>
        {/**
         ******** HRMS Master Routes---- Policies
         */}
        <Route
          exact
          path="/settings/policies/policies"
          element={<Policies />}
        ></Route>
        {/**
         ******** HRMS Master Routes---- Leave
         */}
       {(userrole == "HR" || userrole == "masteradmin") &&  <Route
          exact
          path="/settings/leave/leavetype"
          element={<LeaveType />}
        ></Route>}
        {(userrole == "HR" || userrole == "masteradmin") &&  <Route
          exact
          path="/settings/leave/regularize"
          element={<RegulariseMaster />}
        ></Route>}
        <Route
          exact
          path="/settings/leave/weekendpolicy"
          element={<WeekendPolicy />}
        ></Route>
        <Route
          exact
          path="/settings/leave/leavescheme"
          element={<LeaveScheme />}
        ></Route>
        {/**
         ******** HRMS Master Routes---- Attendance
         */}
  {    (userrole == "HR" || userrole == "masteradmin") &&  <Route
          exact
          path="/settings/attendance/shift"
          element={<Shift />}
        ></Route>}
        {/**
         ******** HRMS Master Routes---- performance
         */}
      { (userrole == "HR" || userrole == "masteradmin" || userrole == "Manager") && <Route
          exact
          path="/settings/performance/organizationKpi"
          element={<OrganizationGoalKpi />}
        ></Route>}
      { (userrole == "HR" || userrole == "masteradmin" || userrole == "Manager") &&  <Route
          exact
          path="/settings/performance/employeeDevelopementKpi"
          element={<EmployeeDevelopementKpi />}
        ></Route>}
    {  (userrole == "HR" || userrole == "masteradmin" || userrole == "Manager") &&   <Route
          exact
          path="/settings/performance/teambuildingKpi"
          element={<TeamBuildingKpi />}
        ></Route>}
      </Route>
    </Routes>
  );
};

export default AllRoutes;
