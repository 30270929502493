const initialState = {
  emp_exit_loading: false,
  emp_exit_error: "",
  empExitData: [],
};
export const empExitReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_EMP_EXIT_LOADING":
    case "ADD_EMP_EXIT_LOADING":
    case "UPDATE_EMP_EXIT_LOADING":
    case "DELETE_EMP_EXIT_LOADING":
      return { ...state, emp_exit_loading: true };

    case "GET_EMP_EXIT_SUCCESS": {
      //  console.log(payload,"pl");
      return { ...state, emp_exit_loading: false, empExitData: payload };
    }

    case "ADD_EMP_EXIT_SUCCESS":
      return {
        ...state,
        emp_exit_loading: false,
        empExitData: [...state.empExitData, payload],
      };

    case "UPDATE_EMP_EXIT_SUCCESS":
      return {
        ...state,
        emp_exit_loading: false,
        empExitData: state.empExitData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case "DELETE_EMP_EXIT_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        emp_exit_loading: false,
        empExitData: state.empExitData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_EMP_EXIT_FAILED":
    case "UPDATE_EMP_EXIT_FAILED":
    case "GET_EMP_EXIT_FAILED":
    case "DELETE_EMP_EXIT_FAILED":
      return { ...state, emp_exit_loading: false, emp_exit_error: payload };

    default:
      return state;
  }
};
