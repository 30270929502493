import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import { CustomSelect } from "../../../../Constants/CustomSelect";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Loader from "../../../../Constants/Loader";
import {
  addGeneralValue,
  addValueCategory,
  addValueSubCategory,
  deleteValueCategory,
  deleteValueSubCategory,
  getValueCategory,
  getValueSubCategory,
} from "../../../../redux/actions/actions.master/value.actions";

const AddValues = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { catData, cerror, cloading } = useSelector(
    (state) => state.valueCatReducer
  );
  const { subCatData, serror, sloading } = useSelector(
    (state) => state.valueSubCatReducer
  );

  const [openDrawer, setOpenDrawer] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isAddingSubCategory, setIsAddingSubCategory] = useState(false);
  const [data, setData] = useState({
    category: "",
    subcategory: "",
    value: "",
    code: "",
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSave = () => {
    // console.log("data to be save", data);
    dispatch(addGeneralValue(data));
    setData({
      category: data.category,
      subcategory: data.subcategory,
      value: "",
      code: "",
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
  };
  const handleDeleteCategory = (id) => {
    dispatch(
      deleteValueCategory({
        idarray: [id],
      })
    );
  };

  const handleAddingCategory = async () => {
    setIsAddingCategory(!isAddingCategory);
    if (!data.category) {
      return;
    }
    dispatch(
      addValueCategory({
        category: data.category,
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  };
  const handleDeleteSubCategory = (id) => {
    dispatch(
      deleteValueSubCategory({
        idarray: [id],
      })
    );
  };

  const handleAddingSubCategory = async () => {
    setIsAddingSubCategory(!isAddingSubCategory);
    if (!data.subcategory) {
      return;
    }
    dispatch(
      addValueSubCategory({
        subcategory: data.subcategory,
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getValueCategory({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getValueSubCategory({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Button
        className="saveBtn"
        variant="contained"
        onClick={() => setOpenDrawer(true)}
      >
        Add
      </Button>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box width={700} display="flex" flexDirection="column" height="100%">
          <Box p={2} flexGrow={1} overflow="auto">
            <Typography
              variant="h6"
              color={"#062C41"}
              display={"flex"}
              fontWeight={600}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={1}
            >
              Add Values
              <span>
                <IconButton
                  onClick={() => {
                    setData({
                      category: "",
                      subcategory: "",
                      value: "",
                      code: "",
                      companyid: companyInfo.companyid,
                      companyemail: companyInfo.companyemail,
                    });
                    setOpenDrawer(false);
                  }}
                >
                  <CancelIcon sx={{ color: "black" }} />
                </IconButton>
              </span>
            </Typography>
            <Divider />
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>
                  Category <span style={{ color: "red" }}>*</span>
                </h4>
                {isAddingCategory ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Create Category"
                    value={data.category}
                    onChange={handleChange}
                    name="category"
                    error={!data.category && showEmptyError}
                    helperText={
                      !data.category && showEmptyError && "Category is required"
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleAddingCategory}>
                            <CheckIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <CustomSelect
                    fullWidth
                    className="formInputs"
                    inputProps={{
                      className: "selectInput",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setIsAddingCategory(!isAddingCategory)}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    value={data.category}
                    onChange={handleChange}
                    name="category"
                    error={!data.category && showEmptyError}
                  >
                    {cloading ? (
                      <Loader />
                    ) : (
                      catData.length > 0 &&
                      catData.map((elem, index) => (
                        <MenuItem key={index} value={elem.category}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                          >
                            {elem.category}
                            <IconButton
                              sx={{
                                color: "red",
                                height: "2px",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteCategory(elem.id);
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Box>
                        </MenuItem>
                      ))
                    )}
                  </CustomSelect>
                )}
              </Grid>
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>
                  Sub-Category <span style={{ color: "red" }}>*</span>
                </h4>
                {isAddingSubCategory ? (
                  <TextField
                    fullWidth
                    className="formInputs"
                    placeholder="Create Sub-Category"
                    value={data.subcategory}
                    onChange={handleChange}
                    name="subcategory"
                    error={!data.subcategory && showEmptyError}
                    helperText={
                      !data.subcategory &&
                      showEmptyError &&
                      "Sub-Category is required"
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleAddingSubCategory}>
                            <CheckIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <CustomSelect
                    fullWidth
                    className="formInputs"
                    inputProps={{
                      className: "selectInput",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setIsAddingSubCategory(!isAddingSubCategory)
                          }
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    value={data.subcategory}
                    onChange={handleChange}
                    name="subcategory"
                    error={!data.subcategory && showEmptyError}
                  >
                    {sloading ? (
                      <Loader />
                    ) : (
                      subCatData.length > 0 &&
                      subCatData.map((elem, index) => (
                        <MenuItem key={index} value={elem.subcategory}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                          >
                            {elem.subcategory}
                            <IconButton
                              sx={{
                                color: "red",
                                height: "2px",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteSubCategory(elem.id);
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Box>
                        </MenuItem>
                      ))
                    )}
                  </CustomSelect>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>
                  Value <span style={{ color: "red" }}>*</span>
                </h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.value}
                  onChange={handleChange}
                  name="value"
                  error={!data.value && showEmptyError}
                  helperText={
                    !data.value && showEmptyError && "Value name is required"
                  }
                />
              </Grid>
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Code</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.code}
                  onChange={handleChange}
                  name="code"
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            mt="auto"
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            bgcolor={"background.paper"}
            p={2}
            display={"flex"}
            flexDirection={"row-reverse"}
          >
            <Button
              className="saveBtn"
              variant="contained"
              onClick={() => {
                if (!data.category || !data.subcategory || !data.value) {
                  setShowEmptyError(true);
                  return;
                } else {
                  handleSave();
                }
              }}
            >
              Save
            </Button>
            <Button
              className="cancelBtn"
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setData({
                  category: "",
                  subcategory: "",
                  value: "",
                  code: "",
                  companyid: companyInfo.companyid,
                  companyemail: companyInfo.companyemail,
                });
                setOpenDrawer(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AddValues;
