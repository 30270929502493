import {
  Box,
  Button,
  Drawer,
  TextField,
  useMediaQuery,
  useTheme,
  Paper,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import ApplyReimburse from "./ApplyReimburse";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  formatDate,
  getColorForStatus,
} from "../../../../../Constants/dateFormat";
import Loader from "../../../../../Constants/Loader";

const EmployeeExit = () => {
  const navigate = useNavigate();
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";
  let userRole = userData.userrole ? userData.userrole : "";
  let username = userData.username ? userData.username : "";
  const [loading, setLoading] = useState(false);
  const [empInfo, setEmpInfo] = useState({});
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    axios
      .post("https://hrms.vliv.app/employee/get", { companyemail, companyid },{withCredentials:true})
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let data = res.data.find((el) => el.email == email);
          if (data) {
            setEmpInfo(data);
          } else {
            setEmpInfo({});
          }
        } else {
          setEmpInfo({});
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setAlertType("error");
        setOpen(true);
        setMsg(err.message);
      });
  }, []);

  useEffect(() => {
    if (empInfo) {
      setLoading(true);
      axios
        .post("https://hrms.vliv.app/employee/reimbursement/get", {
          companyemail,
          companyid,
          empid: empInfo.empid,
          tableemployeeid:empInfo.id,
        },{withCredentials:true})
        .then((res) => {
          if (res.data && res.data.length > 0) {
            setData(res.data);
          } else {
            setData([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "err");
          setLoading(false);
        });
    }
  }, [empInfo, drawerOpen]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // console.log(data, "reimburse data");

  const totalRaisedAmt = data.reduce((acc, curr) => {
    return Number(acc) + Number(curr.amount);
  }, 0);

  const totalPendingAmt = data
    .filter((ele) => ele.status?.toLowerCase() == "pending")
    .reduce((acc, curr) => {
      return Number(acc) + Number(curr.amount);
    }, 0);

  const totalApprovedAmt = data
    .filter((ele) => ele.status?.toLowerCase() == "approve")
    .reduce((acc, curr) => {
      return Number(acc) + Number(curr.amount);
    }, 0);

  const totalRejectedAmt = data
    .filter((ele) => ele.status?.toLowerCase() == "reject")
    .reduce((acc, curr) => {
      return Number(acc) + Number(curr.amount);
    }, 0);
  // Columns definition for DataGrid
  const columns = [
    {
      field: "fromdate",
      headerName: "From Date",
      width: 130,
      renderCell: (params) => <>{formatDate(params.row.fromdate)}</>,
    },
    {
      field: "tilldate",
      headerName: "Till Date",
      width: 130,
      renderCell: (params) => <>{formatDate(params.row.tilldate)}</>,
    },
    { field: "type", headerName: "Reimbursement Type", width: 180 },
    { field: "amount", headerName: "Amount", width: 130 },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      renderCell: (params) => (
        <Button
          sx={{
            color: getColorForStatus(params.row.status),
            textTransform: "capitalize",
          }}
        >
          {params.row.status}
        </Button>
      ),
    },
    { field: "billno", headerName: "Bill No", width: 130 },
    {
      field: "attach",
      headerName: "Attachment",
      width: 130,
      renderCell: (params) =>
        params.value ? (
          <IconButton onClick={() => handleJDClick(params.row)}>
            <AttachFileIcon />
          </IconButton>
        ) : null,
      cellClassName: "MuiDataGrid-cell--withRenderer",
    },
    { field: "description", headerName: "Description", width: 200 },
    { field: "remark", headerName: "Remarks", width: 200 },
  ];

  const rows =
    data.length > 0
      ? data.map((reimburse) => ({
          id: reimburse.id,
          fromdate: reimburse.fromdate,
          tilldate: reimburse.tilldate,
          type: reimburse.type,
          amount: reimburse.amount,
          status: reimburse.status,
          billno: reimburse.billno,
          attach: reimburse.attach,
          description: reimburse.description,
          remark: reimburse.remark,
          data: reimburse,
        }))
      : [];

  const handleRowClick = (params, event) => {
    if (event.target.closest(".MuiDataGrid-cell--withRenderer")) {
      return;
    }
  };

  const handleJDClick = (row) => {
    const link = document.createElement("a");
    link.href = row.attach;
    link.download = row.attach.split("/").pop() || "Attachments";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box px={3} py={2}>
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={2}
          py={2}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
            flexWrap={"wrap"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              flexWrap={"wrap"}
            >
              <p className="view-page-title">Total Raised:</p>
              <TextField
                size="small"
                value={totalRaisedAmt}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              flexWrap={"wrap"}
            >
              <p className="view-page-title">Pending Amount:</p>
              <TextField
                size="small"
                value={totalPendingAmt}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              flexWrap={"wrap"}
            >
              <p className="view-page-title">Approved Claimed:</p>
              <TextField
                size="small"
                value={totalApprovedAmt}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              flexWrap={"wrap"}
            >
              <p className="view-page-title">Rejected Claimed:</p>
              <TextField
                size="small"
                value={totalRejectedAmt}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box textAlign={"right"}>
          <Button className="saveBtn" onClick={handleDrawerOpen}>
            Create
          </Button>
        </Box>
        <Box my={1}>
          <Paper style={{ height: "auto", width: "100%" }}>
            <DataGrid
              rows={loading ? [] : rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, rows.length]}
              checkboxSelection
              getRowId={(row) => row.id}
              autoHeight
              components={{
                LoadingOverlay: <Loader />,
              }}
              loading={loading}
              onRowClick={handleRowClick}
            />
          </Paper>
        </Box>
      </Box>
      <ApplyReimburse
        drawerOpen={drawerOpen}
        empInfo={empInfo}
        handleDrawerClose={handleDrawerClose}
        isSmallScreen={isSmallScreen}
      />

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default EmployeeExit;
