import axios from "axios";
import {
  masterPayrollLWFAdd,
  masterPayrollLWFDelete,
  masterPayrollLWFGet,
  masterPayrollLWFUpdate,
} from "../../../Constants/api";

export const getPayrollEWF = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_PR_EWF_LOADING" });
    let res = await axios.post(masterPayrollLWFGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_PR_EWF_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_PR_EWF_FAILED", payload: error.message });
  }
};

export const addPayrollEWF = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_PR_EWF_LOADING" });
    let res = await axios.post(masterPayrollLWFAdd, obj,{withCredentials:true});
    if (res.data.message == "Added successfully") {
      dispatch({ type: "ADD_PR_EWF_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_PR_EWF_FAILED", payload: error.message });
  }
};

export const updatePayrollEWF = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_PR_EWF_LOADING" });
    let res = await axios.post(masterPayrollLWFUpdate, obj,{withCredentials:true});
    if (res.data.message == "Updated successfully") {
      dispatch({
        type: "UPDATE_PR_EWF_SUCCESS",
        payload: res.data.data,
      });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPDATE_PR_EWF_FAILED", payload: error.message });
  }
};

export const deletePayrollEWF = (idArray, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_PR_EWF_LOADING" });
    let res = await axios.post(masterPayrollLWFDelete, idArray,{withCredentials:true});
    if (res.data.message == "Deleted successfully") {
      dispatch({ type: "DELETE_PR_EWF_SUCCESS", payload: idArray });
      handleClose();
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "DELETE_PR_EWF_FAILED", payload: error.message });
  }
};
