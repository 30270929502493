const initialState = {
  loading: false,
  error: "",
  shiftData: [],
};
export const attendanceShiftReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_ATT_SHIFT_LOADING":
    case "ADD_ATT_SHIFT_LOADING":
    case "UPDATE_ATT_SHIFT_LOADING":
    case "DELETE_ATT_SHIFT_LOADING":
      return { ...state, loading: true };

    case "GET_ATT_SHIFT_SUCCESS":
      return { ...state, loading: false, shiftData: payload };
    case "ADD_ATT_SHIFT_SUCCESS":
      return {
        ...state,
        loading: false,
        shiftData: [...state.shiftData, payload],
      };
    case "UPDATE_ATT_SHIFT_SUCCESS":
      return {
        ...state,
        loading: false,
        shiftData: state.shiftData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };
    case "DELETE_ATT_SHIFT_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        shiftData: state.shiftData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_ATT_SHIFT_FAILED":
    case "UPDATE_ATT_SHIFT_FAILED":
    case "GET_ATT_SHIFT_FAILED":
    case "DELETE_ATT_SHIFT_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
