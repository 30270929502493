import {
  Box,
  Button,
  Checkbox,
  Hidden,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Search as SearchIcon, Try } from "@mui/icons-material";
import Loader from "../../../../../Constants/Loader";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getEmpExit } from "../../../../../redux/actions/actions.hrms/employeeExit.actions";
import { formatDate } from "../../../../../Constants/dateFormat";
import axios from "axios";
import AlertPopUp from "../../../../../Constants/AlertPopUp";

const FF = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emp_exit_loading, emp_exit_error, empExitData } = useSelector(
    (state) => state.empExitReducer
  );

  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (event) => {
    // Handle search logic here
    setSearchValue(event.target.value);
  };

  //for success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const filterData = () => {
    return (
      empExitData &&
      empExitData.length > 0 &&
      empExitData?.filter((item) =>
        searchValue
          ? Object.values(item).some((value) =>
              value
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchValue.toLowerCase().trim())
            )
          : true
      )
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  const handleRowClick = (params) => {
    navigate(`/hrm/employeemanagement/employeeexit/ff/update/${params.row.id}`);
  };

  const StatusCellRenderer = (params) => {
    const [status, setStatus] = useState(
      params.row.fandfstatus ? params.row.fandfstatus : "Pending"
    );

    const handleStatusChange = async (event) => {

      if(params.row.fandfstatus=="Settled"){
        setAlertType("error");
        setOpen(true);
        setMsg("Can not modify settled F&F");
        return;
      }
      
      const newStatus = event.target.value;
      // setStatus(newStatus);
      // console.log(newStatus, "new status");

      try {
        let res = await axios.post("https://hrms.vliv.app/exit/updatestatus", {
          id: params.row.id,
          fandfstatus: newStatus,
        },{withCredentials:true});
        if (res.data.message == "Status updated successfully") {
          dispatch(
            getEmpExit({
              companyid: companyInfo.companyid,
              companyemail: companyInfo.companyemail,
            })
          );
          setAlertType("success");
          setOpen(true);
          setMsg("Status Changed..");
        } else {
          setAlertType("error");
          setOpen(true);
          setMsg("Something went wrong..");
        }
      } catch (error) {
        console.log(error, "error");
        setAlertType("error");
        setOpen(true);
        setMsg("Something went wrong..");
      }
    };

    return (
      <Select
        value={status}
        onChange={handleStatusChange}
        displayEmpty
        fullWidth
        sx={{
          border: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <MenuItem
          value="Pending"
          // sx={{ color: getPayrollStatusColor("Applied") }}
        >
          Pending
        </MenuItem>
        <MenuItem
          value="Approved"
          // sx={{ color: getPayrollStatusColor("Shortlist") }}
        >
          Approved
        </MenuItem>
        <MenuItem
          value="Settled"
          // sx={{ color: getPayrollStatusColor("Reject") }}
        >
          Settled
        </MenuItem>
      </Select>
    );
  };

  const columns = [
    {
      field: "empid",
      headerName: "ID",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
    },
    {
      field: "resignationdate",
      headerName: "Resign Date",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.resignationdate
            ? formatDate(params.row.resignationdate)
            : "-"}
        </>
      ),
    },
    {
      field: "lwddate",
      headerName: "LWD Date",
      flex: 1,
      renderCell: (params) => (
        <>{params.row.lwddate ? formatDate(params.row.lwddate) : "-"}</>
      ),
    },
    {
      field: "expectlwddate",
      headerName: "Expected LWD Date",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.expectlwddate
            ? formatDate(params.row.expectlwddate)
            : "-"}
        </>
      ),
    },
    {
      field: "totalleaveencashment",
      headerName: "Leave Encashment",
      flex: 1,
    },
    {
      field: "totalshortfallpayout",
      headerName: "Total Shortfall Payout",
      flex: 1,
    },
    // {
    //   field: "assetstatus",
    //   headerName: "Asset Status",
    //   flex: 1,
    // },
    {
      field: "totalsettlementamount",
      headerName: "Settlement Amount",
      flex: 1,
    },
    {
      field: "settlementdate",
      headerName: "Settlement Date",
      flex: 1,
      renderCell: (params) => <>{formatDate(params.row.settlementdate)}</>,
    },
    {
      field: "fandfstatus",
      headerName: "F&F Status",
      flex: 1,
      renderCell: StatusCellRenderer,
    },
  ];

  useEffect(() => {
    dispatch(
      getEmpExit({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Box>
        <Box className="searchbar-container">
          <Hidden smDown>
            <Box>
              {/* Content to be shown only on screens larger than or equal to sm (600px) */}
            </Box>
          </Hidden>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <TextField
              placeholder="Search…"
              variant="outlined"
              className="searchbar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </Box>
        </Box>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={emp_exit_loading ? [] : rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            onRowClick={handleRowClick}
            pageSizeOptions={[5, 10, 15, 20]}
            autoHeight
            components={{
              LoadingOverlay: <Loader />,
            }}
            loading={emp_exit_loading}
          />
        </Box>
      </Box>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default FF;
