import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import Dashboard from "../Summary/Dashboard/Dashboard";
import Welcome from "./Welcome/Welcome";



const Summary = () => {
  const [tabvalue, setTabValue] = useState("welcome");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      <Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabvalue}>
            {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Welcome"
                  value="welcome"
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="Dashboard"
                  value="dashboard"
                  sx={{ textTransform: "none" }}
                />
              </TabList>
            </Box> */}
            <TabPanel value="welcome" sx={{padding:1}} >
              <Box sx={{ height: "auto", width: "100%" }}>
               <Welcome/>
              </Box>
              </TabPanel>
              {/* <TabPanel value="dashboard" sx={{padding:1}}>
              <Box sx={{ height: "auto", width: "100%" }}>
                 <Dashboard/>
              </Box>
              </TabPanel> */}
          </TabContext>
        </Box>
      </Box>
    </>
  );
};

export default Summary;
