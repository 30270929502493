import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const ConfirmRejectModal = ({
  selectedRow,
  open,
  handleClose,
  handleConfirm,
}) => {
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const boxStyle = {
    margin: "10px",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#f9fafb",
  };
  const typoHeading = {
    color: "black",
    fontSize: "18px",
  };
  const typoValue = {
    color: "grey",
    fontSize: "16px",
  };
  const empInfoBox = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  };
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "grey",
        width: "90px",
        height: "90px",
      },
      children: name ? `${name.split(" ")[0][0]}` : "",
    };
  }
  function stringAvatar2(name) {
    return {
      sx: {
        bgcolor: "grey",
        width: "50px",
        height: "50px",
      },
      children: name ? `${name.split(" ")[0][0]}` : "",
    };
  }

  // console.log(selectedRow, "selectedRow");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogContent>
        <Box sx={boxStyle}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            gap={2}
            mb={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap={2} alignItems="center">
              <Avatar {...stringAvatar(selectedRow && selectedRow.name)} />
              <Box>
                <Typography sx={typoHeading}>
                  {selectedRow && selectedRow.empname}
                </Typography>
                <Typography sx={typoValue}>
                  {selectedRow && selectedRow.position}
                </Typography>
              </Box>
            </Box>
            <Box sx={empInfoBox}>
              <Typography sx={typoValue}>Employee ID</Typography>
              <Typography sx={typoHeading}>
                {selectedRow && selectedRow.empid}
              </Typography>
            </Box>

            <Box sx={empInfoBox}>
              <Typography sx={typoValue}>Department</Typography>
              <Typography sx={typoHeading}>
                {selectedRow && selectedRow.department}
              </Typography>
            </Box>

            <Box display="flex" gap={2} alignItems="center">
              <Avatar
                {...stringAvatar2(selectedRow && selectedRow.appliedto)}
              />
              <Box>
                <Typography sx={typoValue}>Reporting Manager</Typography>
                <Typography sx={typoHeading}>
                  {selectedRow && selectedRow.appliedto}
                </Typography>
              </Box>
            </Box>
          </Stack>
          <Divider />
        </Box>

        <Box sx={boxStyle}>
          <Typography sx={typoHeading}>Leave Details</Typography>
          <br />
          <Stack direction="row" justifyContent={"space-between"}>
            <Box>
              <Typography sx={typoValue}>Leave Applied Date</Typography>
              <Typography sx={typoHeading}>
                {selectedRow && selectedRow.date ? selectedRow.date : "--"}
              </Typography>
              <br />
              <Typography sx={typoValue}>Attachment</Typography>
              <Typography sx={typoHeading}>
                {selectedRow && selectedRow.attachfile
                  ? selectedRow.attachfile
                  : "--"}
              </Typography>
            </Box>
            <Box>
              <Typography sx={typoValue}>Leave Type</Typography>
              <Typography sx={typoHeading}>
                {selectedRow && selectedRow.leavetype
                  ? selectedRow.leavetype
                  : "--"}
              </Typography>
              <br />
              <Typography sx={typoValue}>Reason</Typography>
              <Typography sx={typoHeading}>
                {selectedRow && selectedRow.reason ? selectedRow.reason : "--"}
              </Typography>
            </Box>
            <Box>
              <Typography sx={typoValue}>Code</Typography>
              <Typography sx={typoHeading}>
                {selectedRow && selectedRow.code ? selectedRow.code : "--"}
              </Typography>
            </Box>
            <Box>
              <Typography sx={typoValue}>Leave From</Typography>
              <Typography sx={typoHeading}>
                {selectedRow && selectedRow.fromdate
                  ? selectedRow.fromdate
                  : "--"}
              </Typography>
            </Box>
            <Box>
              <Typography sx={typoValue}>Leave To</Typography>
              <Typography sx={typoHeading}>
                {selectedRow && selectedRow.todate ? selectedRow.todate : "--"}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typoHeading}>Rejection Details</Typography>
          <br />
          <Box>
            <Typography sx={typoValue}>Reason</Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={reason}
              onChange={handleReasonChange}
            />
          </Box>
        </Box>
        <Box mt={2}></Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="cancelBtn">
          Cancel
        </Button>
        <Button
          onClick={() => handleConfirm(reason)}
          className="saveBtn"
          autoFocus
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRejectModal;
