import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import { LuDownload } from "react-icons/lu";
import "react-quill/dist/quill.snow.css";
import { formatDateTime } from "../../../../../Constants/dateFormat";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const ViewFNADocument = ({ handleCloseViewDialog, selectedRow, openViewDialog }) => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDownload = async (index) => {
    const input = document.getElementById(`pdfShadow-${index}`);
           // Apply styles to the container to add padding and ensure it fits within an A4 page
           input.style.padding = '20px'; // Add padding around the content
           input.style.width = '210mm'; // A4 width in mm
           input.style.height = '297mm'; // A4 height in mm
           input.style.boxSizing = 'border-box'; // Ensure padding is included in the dimensions
    const canvas = await html2canvas(input, {
      scale: 2,
      useCORS: true,
      logging: true,
    });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: [canvas.width, canvas.height],
    });

    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    pdf.save(`f&a${index}.pdf`);
  };

  return (
    <Dialog
      open={openViewDialog}
      onClose={handleCloseViewDialog}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        {selectedRow &&
          selectedRow.data &&
          selectedRow.data.fandaarray &&
          selectedRow.data.fandaarray.length > 0 &&
          selectedRow.data.fandaarray.map((fanda, ind) => (
            <Box key={ind} id={`fanda-${ind}`} my={1.5}>
              <Box id={`pdfShadow-${ind}`} className="pdfShadow" p={2} style={{ background: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <ReactQuill value={fanda.format} readOnly={true} theme="bubble" />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" >
                <Box>
                  <Typography component="h6">
                    {formatDateTime(fanda.date)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    variant="contained"
                    color="success"
                    onClick={() => handleDownload(ind)}
                  >
                    <LuDownload />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseViewDialog}
          variant="outlined"
          className="cancelBtn"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewFNADocument;
