import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { updatedocumentgenerationapi } from "../../../../../Constants/api";
import AlertPopUp from "../../../../../Constants/AlertPopUp";

const NOCDocumentDialog = ({
  openDialog,
  handleCloseDialog,
  selectedRow,
  editorContent,
  setEditorContent,
}) => {
      //for alert msg
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      let res = await axios.post(updatedocumentgenerationapi, {
        ...selectedRow.data,
        noctitle: "NOC Document",
        nocstatus: "Generated",
        nocformat:editorContent,
        nocarray: [
          {
            format: editorContent,
            date: new Date(),
          },
        ],
      },{withCredentials:true});
     if(res.data.message=="Document updated successfully"){
        setAlertType("success");
        setOpen(true);
        setMsg("NOC Document Generated");
        setTimeout(() => {
          handleCloseDialog();
        }, 1000);
     }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
      setTimeout(() => {
        handleCloseDialog();
      }, 1000);
    }
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>NOC Document</DialogTitle>
        <DialogContent>
          {/* {selectedRow && (
            <Box mb={2}>
              <Typography variant="h6">Employee Details</Typography>
              <Typography>ID: {selectedRow.empid}</Typography>
              <Typography>Name: {selectedRow.name}</Typography>
              <Typography>Department: {selectedRow.department}</Typography>
              <Typography>Position: {selectedRow.position}</Typography>
            </Box>
          )} */}
          <ReactQuill
            value={editorContent}
            onChange={setEditorContent}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ size: [] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                [{ color: [] }, { background: [] }], // Text color and background color
                ["link", "image", "video"],
                ["clean"],
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "video",
              "color",
              "background",
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            className="cancelBtn"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} className="saveBtn">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleCloseAlert}
        type={alertType}
      />
    </>
  );
};

export default NOCDocumentDialog;
