import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

function Report() {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;

  const [data, setData] = useState([]);
  const [allCompliance, setAllCompliance] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCompliances, setSelectedCompliances] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [complianceRes, usersRes] = await Promise.all([
          axios.post(`https://hrms.vliv.app/compliance/get`, { companyid },{withCredentials:true}),
          axios.post(`https://hrms.vliv.app/compliance/allusers`, {
            companyid: companyid.toString(),
          },{withCredentials:true}),
        ]);
        setAllCompliance(complianceRes.data);
        setAllUsers(usersRes.data);

        const userData = usersRes.data.map(user => {
          const agreedPolicies = complianceRes.data.filter(comp =>
            comp.agreed && comp.agreed.includes(user.email)
          );
          return {
            id: user.id,
            userid: user.userid,
            name: user.username,
            department: user.department,
            agreed: `${agreedPolicies.length}/${complianceRes.data.length}`,
            compliances: agreedPolicies
          };
        });

        setData(userData);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [companyid]);

  const handleViewComplianceTrigger = (id) => {
    const selectedUser = data.find(user => user.id === id);
    if (selectedUser) {
      setSelectedCompliances(selectedUser.compliances);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const columns = [
    { field: "userid", headerName: "User id", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "department", headerName: "Department", flex: 1 },
    { field: "agreed", headerName: "Policies", flex: 1 },
    {
      field: "action", headerName: "Action", flex: 1, renderCell: (params) => (
        <button
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleViewComplianceTrigger(params.row.id);
          }}
          style={{
            background: "#37668F",
            marginRight: "5px",
            color: "white",
            padding: 1,
          }}
        >
          View
        </button>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        autoHeight
        rows={data}
        columns={columns}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Compliance Details</DialogTitle>
        <DialogContent sx={{padding:2}}>
          {selectedCompliances.length > 0 ? (
            <ul style={{listStyle:"number"}}>
              {selectedCompliances.map((compliance) => (
                <li key={compliance.id}>{compliance.compliancename}</li>
              ))}
            </ul >
          ) : (
            <p>No compliances agreed by this user.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Report;
