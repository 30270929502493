import {
  Box,
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Divider,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import dayjs from "dayjs"; // Import dayjs for date manipulation
import axios from "axios";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import { employeeExitRequestApi } from "../../../../../Constants/api";

const ApplyExit = ({
  drawerOpen,
  handleDrawerClose,
  isSmallScreen,
  empInfo,
}) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  const [emptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    empid: "",
    tableemployeeid:"",
    exittype: "Voluntary",
    applyingdate: "",
    resignationdate: "",
    expectlwddate: "",
    lwddate: "",
    leavingreason: "",
    lwdperpolicy: 90,
    shortfalldays: "",
    altemail: "",
    altphone: "",
    remarks: "",
    companyid: companyid,
    companyemail: companyemail,
    reportingto: "",
    servenoticeperiod: "Yes",
    status: "Pending",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedData = { ...data, [name]: value };

    if (
      name === "servenoticeperiod" ||
      name === "resignationdate" ||
      name === "lwddate" ||
      name === "expectlwddate"
    ) {
      updatedData = calculateDates(updatedData);
    }

    setData(updatedData);
  };

  const calculateDates = (updatedData) => {
    if (
      updatedData.servenoticeperiod === "Yes" &&
      updatedData.resignationdate
    ) {
      updatedData.lwddate = dayjs(updatedData.resignationdate)
        .add(updatedData.lwdperpolicy, "day")
        .format("YYYY-MM-DD");
      updatedData.shortfalldays = 0;
      updatedData.expectlwddate = ""; // Clear expectlwddate for Yes case
    } else if (
      updatedData.servenoticeperiod === "No" &&
      updatedData.resignationdate &&
      updatedData.expectlwddate
    ) {
      updatedData.lwddate = ""; // Clear lwddate for No case
      const resignationDate = dayjs(updatedData.resignationdate);
      const expectLwdDate = dayjs(updatedData.expectlwddate);
      const servedDays = expectLwdDate.diff(resignationDate, "day");
      updatedData.shortfalldays = updatedData.lwdperpolicy - servedDays;
    }
    return updatedData;
  };

  const handleApplyExit = async () => {
    const applyingDate = dayjs().format("YYYY-MM-DD");
    const exitData = { ...data, applyingdate: applyingDate };

    // console.log(exitData, "exit data");

    try {
      let res = await axios.post(employeeExitRequestApi, exitData,{withCredentials:true});
      // console.log(res, "response");
      if (res.data.message) {
        setAlertType("success");
        setOpen(true);
        setMsg("Exit Request Raised");
        setTimeout(() => {
          handleDrawerClose();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
      setTimeout(() => {
        handleDrawerClose();
      }, 1000);
    }
  };

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      empid: empInfo.empid,
      tableemployeeid:empInfo.id,
      reportingto: empInfo.reportmanager,
    }));
  }, [empInfo]);

  return (
    <>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box
          width={isSmallScreen ? "90vw" : 600}
          display={"flex"}
          flexDirection={"column"}
          height={"100vh"}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            py={1}
          >
            <Typography variant="h6">Apply Exit</Typography>
            <IconButton onClick={handleDrawerClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box
            px={2}
            py={1}
            className="drawer-form-container"
            flex={1}
            display="flex"
            flexDirection="column"
            overflow="auto"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Exit Type"
                  name="exittype"
                  value={data.exittype}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Resignation Date"
                  name="resignationdate"
                  type="date"
                  value={data.resignationdate}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      min: new Date().toISOString().split("T")[0], // Restrict to future dates only
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Serve Notice Period</InputLabel>
                  <Select
                    name="servenoticeperiod"
                    value={data.servenoticeperiod}
                    onChange={handleChange}
                    label="Serve Notice Period"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {data.servenoticeperiod === "Yes" ? (
                <Grid item xs={12}>
                  <TextField
                    label="LWD Date"
                    name="lwddate"
                    type="date"
                    value={data.lwddate}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={true} // Disable LWD date field
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <TextField
                  label="Expected LWD Date"
                  name="expectlwddate"
                  type="date"
                  value={data.expectlwddate}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      min: new Date().toISOString().split("T")[0], // Restrict to future dates only
                    },
                  }}
                />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Leaving Reason</InputLabel>
                  <Select
                    name="leavingreason"
                    value={data.leavingreason}
                    onChange={handleChange}
                    label="Leaving Reason"
                  >
                    <MenuItem value="Transfer">Transfer</MenuItem>
                    <MenuItem value="Resignation">Resignation</MenuItem>
                    <MenuItem value="Retirement">Retirement</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Reporting To"
                  name="reportingto"
                  value={data.reportingto}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Remarks"
                  name="remarks"
                  value={data.remarks}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Shortfall Days"
                  name="shortfalldays"
                  value={data.shortfalldays}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                gap={2}
                flexWrap={"wrap"}
              >
                <Button
                  onClick={handleDrawerClose}
                  sx={{ ml: 2 }}
                  className="cancelBtn"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button className="saveBtn" onClick={handleApplyExit}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default ApplyExit;
