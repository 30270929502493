import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import Cookies from "js-cookie";
import { Button } from "@mui/material";
import { AuthContext } from "../Context/AuthContextProvider";

function ProtectRoute() {
  const {authContext,setAuthContext} = useContext(AuthContext)
  // const isLoggedIn = Cookies.get("companytoken");
  const location = useLocation();

  const handleGoBack = () => {
    window.history.back();
  };

  if (!authContext.token) {
    return <Navigate to="/signin" />;
  }

  return (
    <>
      {/* <Button onClick={handleGoBack} startIcon={<WestIcon/>} sx={{mx:2,my:1,color:"#98A2B3;",textTransform:"capitalize",fontSize:"14px",fontWeight:"300"}}>Back</Button> */}
      <Outlet />
    </>
  );
}

export default ProtectRoute;
