import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { RxCounterClockwiseClock } from "react-icons/rx";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import CountCards from "./CountCards";
import { GiExpense } from "react-icons/gi";
import { HiTicket } from "react-icons/hi2";
import { TbTicket } from "react-icons/tb";
import { TbTicketOff } from "react-icons/tb";
import { BarChart } from "@mui/x-charts/BarChart";
import { TbUser } from "react-icons/tb";
import { TbUserUp } from "react-icons/tb";
import { TbUserDown } from "react-icons/tb";
import { PieChart } from "@mui/x-charts/PieChart";
import { MdFilterList } from "react-icons/md";
import { LineChart } from "@mui/x-charts/LineChart";
import { TbClockX } from "react-icons/tb";
import { TbClockShield } from "react-icons/tb";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { getEmpReg } from "../../../../redux/actions/actions.hrms/empRegistration.actions";
import axios from "axios";
import {
  formatDate,
  performanceBgColor,
  performanceTextColor,
} from "../../../../Constants/dateFormat";
import { getTasksApi } from "../../../../Constants/api";
import { getChipColor } from "../../../../Constants/Colors";
import { stringToColor } from "../../../../Constants/StringAvatar";
const iconStyles = {
  fontSize: "40px",
  color: "var(--common-color)",
};
const tasks = [
  "Review Resumes: Evaluate and shortlist candidates for the marketing position.",
  "Onboarding: Complete onboarding process for new hires by Friday.",
  "Policy Update: Update the remote work policy and circulate it to all employees.",
  "Training Session: Schedule and organize a diversity training session for the first week of June.",
  "Employee Surveys: Conduct the annual employee satisfaction survey.",
];
const barColors = [
  "#4caf50",
  "#2196f3",
  "#ff9800",
  "#f44336",
  "#9c27b0",
  "#3f51b5",
  "#ffeb3b",
];

const Dashboard = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  let companyid = companyInfo.companyid ? companyInfo.companyid : "";
  let companyemail = companyInfo.companyemail ? companyInfo.companyemail : "";
  let userRole = companyInfo.userrole ? companyInfo.userrole : "";
  const dispatch = useDispatch();

  const [month, setMonth] = useState(dayjs(new Date()).format("MMMM"));
  const [year, setYear] = useState(dayjs(new Date()).format("YYYY"));

  const { empData } = useSelector((state) => state.empRegistrationReducer);

  const [presentEmployeeCount, setPresentEmployeeCount] = useState(0);

  const [totalExpense, setTotalExpense] = useState(0);
  const [payrollReport, setPayrollReport] = useState(0);
  const [empReport, setEmpReport] = useState({});

  const [showAllTasks, setShowAllTasks] = useState(false);
  const [tasks, setTasks] = useState([]);

  const toggleShowAllTasks = () => {
    setShowAllTasks(!showAllTasks);
  };
  const handleTaskClick = (index) => {
    alert(`${index} task clicked`);
  };

  const xLabels = ["Present", "Absent"];
  const barData = [350, 50];

  useEffect(() => {
    dispatch(
      getEmpReg({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  const getPresentData = async () => {
    try {
      let res = await axios.post("https://hrms.vliv.app/atte/presentcount", {
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
        date: formatDate(new Date()),
      },{withCredentials:true});
      // console.log(res,"res present data");
      if (res.data && res.data.data && typeof res.data.data =="number") {
        setPresentEmployeeCount(res.data.data);
      } else {
        setPresentEmployeeCount(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getExpenseData = async () => {
    try {
      let res = await axios.post("https://hrms.vliv.app/payroll/misdata", {
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
        month: month,
        year: dayjs(new Date()).format("YYYY"),
      },{withCredentials:true});
      // console.log(res, "reshbs");
      if (res.data) {
        setTotalExpense(res.data.paidsalarysum || 0);
        setPayrollReport(
          (res.data.paidsalarysum || 0) + (res.data.holdsalarysum || 0)
        );
      } else {
        setTotalExpense(0);
        setPayrollReport(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTasks = async () => {
    let body = { companyemail, companyid };

    try {
      // setLoading(true);
      let res = await axios.post(getTasksApi, body,{withCredentials:true});
      if (res.data && res.data.length > 0) {
        setTasks(res.data);
      } else {
        setTasks([]);
      }
      // setLoading(false);
    } catch (error) {
      console.log(error, "error");
      // setAlertType("error");
      // setOpen(true);
      // setMsg("Error while fetching tasks.");
      // setLoading(false);
    }
  };

  const getEmpReports = async () => {
    try {
      let res = await axios.post("https://hrms.vliv.app/employee/emproutes", {
        companyid,month,year
      },{withCredentials:true});
      // console.log(res,"res emp reports..");
      if (res.data) {
        setEmpReport({ ...res.data });
      }
    } catch (error) {
      console.log(error, "error emp reports");
    }
  };

  useEffect(() => {
    getPresentData();
    getTasks();
    getEmpReports();
  }, []);

  useEffect(() => {
    getExpenseData();
  }, [month]);

  // console.log(presentEmployeeCount, "present employee count");

  return (
    <>
      <Box className="common-bg-color" p={2}>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            p={1.5}
            className="report-card-bg"
            flex={1}
          >
            <p className="report-label">Today Staff Attendance</p>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
              my={2}
            >
              <Box
                className="br-2"
                sx={{ background: "rgba(244, 235, 255, 1)" }}
                display="flex"
                // alignItems="center"
                justifyContent="space-between"
                flexDirection="column"
                py={1.5}
                gap={2}
                flex={1}
              >
                <p
                  className="view-page-value fs-large"
                  style={{ textAlign: "center" }}
                >
                  Total Employee
                </p>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={3}
                >
                  <Box marginTop={1}>
                    <HiOutlineUserGroup
                      className="report-icon"
                      style={{ color: "rgba(234, 217, 255, 1)" }}
                    />
                  </Box>
                  <h1 className="report-value">{empData?.length}</h1>
                </Box>
              </Box>
              <Box
                className="br-2"
                sx={{ background: "rgba(209, 250, 223, 1)" }}
                display="flex"
                // alignItems="center"
                justifyContent="space-between"
                flexDirection="column"
                py={1.5}
                gap={2}
                flex={1}
              >
                <p
                  className="view-page-value fs-large"
                  style={{ textAlign: "center" }}
                >
                  Total Present
                </p>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={3}
                >
                  <Box marginTop={1}>
                    <TbClockShield
                      className="report-icon"
                      style={{ color: "rgba(115, 252, 162, 0.8)" }}
                    />
                  </Box>
                  <h1 className="report-value">{presentEmployeeCount || "0"}</h1>
                </Box>
              </Box>
              <Box
                className="br-2"
                sx={{ background: "rgba(254, 228, 226, 1)" }}
                display="flex"
                // alignItems="center"
                justifyContent="space-between"
                flexDirection="column"
                py={1.5}
                gap={2}
                flex={1}
              >
                <p
                  className="view-page-value fs-large"
                  style={{ textAlign: "center" }}
                >
                  Total Absent
                </p>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={3}
                >
                  <Box marginTop={1}>
                    <TbClockX
                      className="report-icon"
                      style={{ color: "rgba(254, 205, 202, 1)" }}
                    />
                  </Box>
                  <h1 className="report-value">
                    {empData.length - Number(presentEmployeeCount)}
                  </h1>
                </Box>
              </Box>
              <Box
                className="br-2"
                sx={{ background: "rgba(254, 240, 199, 1)" }}
                display="flex"
                // alignItems="center"
                justifyContent="space-between"
                flexDirection="column"
                py={1.5}
                gap={2}
                flex={1}
              >
                <p
                  className="view-page-value fs-large"
                  style={{ textAlign: "center" }}
                >
                  Late Arrival
                </p>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={3}
                >
                  <Box marginTop={1}>
                    <RxCounterClockwiseClock
                      className="report-icon"
                      style={{ color: "rgba(244, 221, 154, 1)" }}
                    />
                  </Box>
                  <h1 className="report-value">{"0"}</h1>
                </Box>
              </Box>
            </Box>
            <Box>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={12} md={5.95} flexGrow={1}>
                  <Box
                    elevation={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 2,
                      backgroundColor: "rgba(249, 250, 251, 1)",
                    }}
                  >
                    <Box width={"100%"}>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          pb: 1,
                        }}
                      >
                        <span style={{ color: "black", fontWeight: "bold" }}>
                          Staff Attendance
                        </span>
                        <Select
                          defaultValue="April"
                          variant="outlined"
                          size="small"
                        >
                          <MenuItem value="April">April</MenuItem>
                          <MenuItem value="May">May</MenuItem>
                          <MenuItem value="June">June</MenuItem>
                          <MenuItem value="July">July</MenuItem>
                          <MenuItem value="August">August</MenuItem>
                          <MenuItem value="September">September</MenuItem>
                          <MenuItem value="October">October</MenuItem>
                          <MenuItem value="November">November</MenuItem>
                          <MenuItem value="December">December</MenuItem>
                          <MenuItem value="January">January</MenuItem>
                          <MenuItem value="February">February</MenuItem>
                          <MenuItem value="March">March</MenuItem>
                        </Select>
                      </Typography>
                      <Box
                        height={"190px"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        gap={5}
                      >
                        <Box width={"50%"}>
                          <Typography>
                            <span style={{ marginRight: "5px" }}>Employee</span>
                            <TbUser />
                          </Typography>
                          <Typography>
                            <span style={{ marginRight: "5px" }}>Present</span>
                            <TbUserUp color="rgba(142, 252, 180, 1)" />
                          </Typography>
                          <Typography>
                            <span style={{ marginRight: "5px" }}>Absent</span>
                            <TbUserDown color="rgba(254, 205, 202, 1)" />
                          </Typography>
                        </Box>
                        <BarChart
                          series={[
                            {
                              data: barData,
                              type: "bar",
                            },
                          ]}
                          xAxis={[
                            {
                              scaleType: "band",
                              data: xLabels,
                            },
                          ]}
                          slotProps={{
                            legend: { hidden: true },
                          }}
                          colors={[
                            "rgba(142, 252, 180, 1)",
                            "rgba(254, 205, 202, 1)",
                          ]}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5.95}>
                  <Box
                    elevation={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 2,
                      backgroundColor: "rgba(249, 250, 251, 1)",
                    }}
                  >
                    <Box width={"100%"}>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          pb: 1,
                        }}
                      >
                        <span style={{ color: "black", fontWeight: "bold" }}>
                          Gender Distribution
                        </span>
                      </Typography>
                      <Box height={"200px"}>
                        <PieChart
                          series={[
                            {
                              data:
                                empReport && empReport.genderCounts
                                  ? empReport.genderCounts
                                      .filter(
                                        (ele, ind) => ele.gender && ele.count
                                      )
                                      .map((ele, ind) => ({
                                        id: ind,
                                        value: Number(ele.count),
                                        label: ele.gender,
                                        color: stringToColor(ele.gender),
                                      }))
                                  : [],
                            },
                          ]}
                          width={400}
                          height={200}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            item
            md={3.9}
            sm={12}
            xs={12}
            p={1.5}
            flex={1}
            className="report-card-bg"
          >
            <Box width={"100%"}>
              <Typography
                variant="subtitle1"
                color="primary"
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pb: 1,
                }}
              >
                <span style={{ color: "black", fontWeight: "bold" }}>
                  To Do Task
                </span>
              </Typography>
              <Divider />
              <Box
                flex={1}
                height={"415px"}
                className="tasks"
                sx={{
                  overflowY: "auto",
                  padding: "10px",
                }}
              >
                {tasks.map((task, index) => (
                  <Box
                    key={index}
                    className="report-card-bg"
                    sx={{
                      marginBottom: "10px",
                      padding: "15px",
                      borderRadius: "8px",
                      // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "rgba(249, 250, 251, 1)",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <p
                        style={{ fontSize: "18px" }}
                        className="common-bg-text-color"
                      >
                        {task.taskname}
                      </p>
                      <Button
                        sx={{
                          color: performanceTextColor(task.status),
                        }}
                      >
                        {task.status}
                      </Button>
                    </Box>
                    <Box mb={1}>
                      <p>
                        <strong>Description:</strong> {task.description}
                      </p>
                      <p>
                        <strong>Due Date:</strong> {formatDate(task.duedate)}
                      </p>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <p>
                        <strong>Assigned by:</strong> {task.assigneedby}
                      </p>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <p>
                        <strong>Assigned To:</strong> {task.assignee}
                      </p>

                      <Button
                        sx={{
                          color: performanceTextColor(task.priority),
                          backgroundColor: performanceBgColor(task.priority),
                        }}
                      >
                        {task.priority}
                      </Button>
                    </Box>
                    <Box mt={1}>
                      {task.tag.map((tag, idx) => (
                        <Chip
                          key={idx}
                          label={tag}
                          style={{
                            backgroundColor: getChipColor(tag),
                            color: "#fff",
                            margin: "0px 2px",
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* <Grid
          container
          display="flex"
          justifyContent="space-between"
          my={1.5}
          alignItems="stretch"
        >
          <Grid item md={3.95} flex={1} display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              borderRadius={1.5}
              sx={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
              mb={1.5}
            >
              <Box
                flex={1}
                p={2}
                borderRight={"0.5px solid rgba(152, 162, 179, 1)"}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <p
                    className="view-page-value fs-large fw-b"
                    style={{ color: "rgba(52, 64, 84, 1)" }}
                  >
                    Ticekts
                  </p>
                  <Box>
                    <TbTicket
                      style={{
                        fontSize: "30px",
                        color: "rgba(254, 200, 75, 1)",
                      }}
                    />
                  </Box>
                </Box>
                <h1
                  className="report-value text-right fw-500"
                  style={{ color: "black" }}
                >
                  {" "}
                  50{" "}
                </h1>
              </Box>
              <Box
                flex={1}
                p={2}
                borderRight={"0.5px solid rgba(152, 162, 179, 1)"}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <p
                    className="view-page-value fs-large fw-b"
                    style={{ color: "rgba(52, 64, 84, 1)" }}
                  >
                    Open
                  </p>
                  <Box>
                    <HiTicket
                      style={{
                        fontSize: "30px",
                        color: "rgba(253, 162, 155, 1)",
                      }}
                    />
                  </Box>
                </Box>
                <h1
                  className="report-value text-right fw-500"
                  style={{ color: "black" }}
                >
                  {" "}
                  20{" "}
                </h1>
              </Box>
              <Box flex={1} p={2}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <p
                    className="view-page-value fs-large fw-b"
                    style={{ color: "rgba(52, 64, 84, 1)" }}
                  >
                    Closed
                  </p>
                  <Box>
                    <TbTicketOff
                      style={{
                        fontSize: "30px",
                        color: "rgba(108, 233, 166, 1)",
                      }}
                    />
                  </Box>
                </Box>
                <h1
                  className="report-value text-right fw-500"
                  style={{ color: "black" }}
                >
                  {" "}
                  30{" "}
                </h1>
              </Box>
            </Box>
            <Box
              elevation={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 2,
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: 1.5,
              }}
              flex={3}
            >
              <Box width={"100%"}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pb: 1,
                  }}
                >
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    Monthly Payroll Report
                  </span>
                  <Select
                    // defaultValue="April"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setMonth(e.target.value)}
                    value={month}
                  >
                    <MenuItem value="April">April</MenuItem>
                    <MenuItem value="May">May</MenuItem>
                    <MenuItem value="June">June</MenuItem>
                    <MenuItem value="July">July</MenuItem>
                    <MenuItem value="August">August</MenuItem>
                    <MenuItem value="September">September</MenuItem>
                    <MenuItem value="October">October</MenuItem>
                    <MenuItem value="November">November</MenuItem>
                    <MenuItem value="December">December</MenuItem>
                    <MenuItem value="January">January</MenuItem>
                    <MenuItem value="February">February</MenuItem>
                    <MenuItem value="March">March</MenuItem>
                  </Select>
                </Typography>
                <Divider />
                <Box height={"158px"} p={5}>
                  <Typography variant="h4" fontWeight={"bold"}>
                    ₹ {payrollReport}
                  </Typography>
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    color={"#667085"}
                  >
                    {dayjs(new Date()).format("MMMM") == month
                      ? "This Month"
                      : month}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item md={7.95} className="report-card-bg" flex={1}>
            <Box
              elevation={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 2,
              }}
            >
              <Box width={"100%"}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pb: 1,
                  }}
                >
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    Employee Count By Department
                  </span>
                  <MdFilterList color="black" fontSize={"30px"} />
                </Typography>
                <Divider />
                <Box
                  height={"200"}
                  display={"flex"}
                  paddingLeft={"20px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <BarChart
                    dataset={
                      empReport && empReport.departmentCounts
                        ? empReport.departmentCounts.map((ele) => ({
                            ...ele,
                            count: Number(ele.count || 0),
                          }))
                        : []
                    }
                    yAxis={[{ scaleType: "band", dataKey: "department" }]}
                    series={[
                      {
                        dataKey: "count",
                        //label: "Seoul rainfall",
                        valueFormatter: departmentValueFormatter,
                      },
                    ]}
                    layout="horizontal"
                    {...chartSetting}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid> */}

        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            xs={12}
            sm={12}
            mt={1}
            md={5.95}
            className="report-card-bg"
          >
            <Box
              elevation={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 2,
              }}
            >
              <Box width={"100%"}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pb: 1,
                  }}
                >
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    Employee By Type
                  </span>
                </Typography>
                <Divider />
                <Box
                  height={"300px"}
                  display={"flex"}
                  paddingLeft={"20px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <BarChart
                    dataset={
                      empReport && empReport.emptypeCounts
                        ? empReport.emptypeCounts.map((ele) => ({
                            ...ele,
                            count: Number(ele.count),
                          }))
                        : []
                    }
                    yAxis={[{ scaleType: "band", dataKey: "emptype" }]}
                    series={[
                      {
                        dataKey: "count",
                        //label: "Seoul rainfall",
                        valueFormatter: statusValueFormatter,
                      },
                    ]}
                    layout="horizontal"
                    {...chartSetting}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            mt={1}
            sm={12}
            md={5.95}
            xs={12}
            className="report-card-bg"
          >
            <Box
              elevation={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 2,
              }}
            >
              <Box width={"100%"}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pb: 1,
                  }}
                >
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    Leave By Month
                  </span>
                </Typography>
                <Divider />
                <Box
                  height={"300px"}
                  display={"flex"}
                  paddingLeft={"20px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <BarChart
                    dataset={leaveDataset}
                    yAxis={[{ scaleType: "band", dataKey: "leave" }]}
                    series={[
                      {
                        dataKey: "count",
                        //label: "Seoul rainfall",
                        valueFormatter: leaveValueFormatter,
                      },
                    ]}
                    layout="horizontal"
                    {...chartSetting}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;

const chartSetting = {
  width: 800,
  height: 400,
};
const deptDataset = [
  {
    department: "Admin",
    employees: 4,
  },
  {
    department: "HR",
    employees: 8,
  },
  {
    department: "IT",
    employees: 11,
  },
  {
    department: "Sales",
    employees: 3,
  },
  {
    department: "Customer Support",
    employees: 23,
  },
  {
    department: "Finance",
    employees: 17,
  },
  {
    department: "Service",
    employees: 11,
  },
];

const departmentValueFormatter = (value) => `${value} Employees`;

const leaveDataset = [
  {
    leave: "Casual Leave",
    count: 15,
  },
  {
    leave: "Comm. Off",
    count: 28,
  },
  {
    leave: "Earned Leave",
    count: 50,
  },
  {
    leave: "Loss of Pay",
    count: 50,
  },
  {
    leave: "Maternity Leave",
    count: 25,
  },
  {
    leave: "Probation Leave",
    count: 50,
  },
  {
    leave: "Restricted Holidays",
    count: 50,
  },
  {
    leave: "Sick Leave",
    count: 50,
  },
  {
    leave: "Paternity Leave",
    count: 50,
  },
];
const leaveValueFormatter = (value) => `${value} Leaves`;

const statusDataset = [
  {
    status: "Contract",
    count: 15,
  },
  {
    status: "Probation",
    count: 28,
  },
  {
    status: "Confirmed",
    count: 50,
  },
];
const statusValueFormatter = (value) => `${value} Employees`;

const pData = [0, 0, 0, 6, 0, 8, 0, 0, 10, 0, 5, 0];
const uData = [0, 0, 0, 3, 0, 0, 0, 1, 0, 0, 0, 0];
const lineLabels = [
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "Jan",
  "Feb",
  "Mar",
];

const totalEmps = [1, 8, 0, 6, 1, 8, 1, 10, 1];
const yearLineLabels = [
  "< 1",
  "1-2",
  "2-3",
  "5-6",
  "6-7",
  "7-8",
  "8-9",
  "9-10",
  ">10",
];
