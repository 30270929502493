import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteModal = ({ show, handleShow, handleClose, handleDelete, name }) => {
  // console.log("show", show);
  return (
    <>
      <IconButton
        aria-label="delete"
        color="red"
        onClick={handleShow}
      >
        <DeleteIcon color="error" fontSize="inherit" />
      </IconButton>
      <Modal open={show} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxWidth: 400,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Are you sure you want to delete {name}?
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleDelete}>
              Delete
            </Button>
            <Button variant="outlined" onClick={handleClose} sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteModal;
