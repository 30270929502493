import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { employeeExitGetbyID } from "../../../../../Constants/api";
import axios from "axios";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import { updateEmpExit } from "../../../../../redux/actions/actions.hrms/employeeExit.actions";
import { formatDate } from "../../../../../Constants/dateFormat";

import dayjs from "dayjs";
import { calculateShortfallArray } from "../../../../../Constants/constants";



const EditNoticePeriod = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const [data, setData] = useState({ payrollarray: [] });
  const [shortFallArray, setShortFallArray] = useState([]);

  //for success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (id) {
      axios
        .post(employeeExitGetbyID, { id },{withCredentials:true})
        .then((res) => {
          setData((prev) => ({
            ...prev,
            ...res.data?.[0],
          }));
          const {
            resignationdate,
            expectlwddate,
            servenoticeperiod,
            lwdperpolicy,
            shortfalldays,
          } = res.data[0] || {};

          setShortFallArray(
            calculateShortfallArray(
              resignationdate,
              expectlwddate,
              servenoticeperiod,
              lwdperpolicy,
              shortfalldays
            )
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleUpdate = () => {
    // console.log(data, "saveData");
    dispatch(updateEmpExit(data, setOpen, setMsg, setAlertType, navigate));
  };

  console.log(shortFallArray, "data short fall array");

  return (
    <>
      <Box>
        <Box p={1} m={1} px={3} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Typography
            variant="h5"
            color="#0B4A6F"
            fontWeight={"bold"}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            <IconButton
              onClick={() => navigate(`/hrm/employeemanagement/employeeexit`)}
            >
              <WestIcon sx={{ color: "#0B4A6F" }} />
            </IconButton>
            <span>Notice Period</span>
          </Typography>
        </Box>
        <Box p={1} m={1} px={3} pb={3} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Typography variant="h6" gutterBottom>
            Employee Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Name</Typography>
              <Typography className="view-page-title">{data.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Employee ID</Typography>
              <Typography className="view-page-title">
                {data.empid || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Department</Typography>
              <Typography className="view-page-title">
                {data.department || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Position</Typography>
              <Typography className="view-page-title">
                {data.position || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Reporting To</Typography>
              <Typography className="view-page-title">
                {data.reportmanager || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Date of Joining
              </Typography>
              <Typography className="view-page-title">
                {data.joiningdate ? formatDate(data.joiningdate) : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box p={1} m={1} px={3} pb={3} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Typography variant="h6" gutterBottom mb={1.5}>
            Work Flow
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Name</Typography>
              <Typography className="view-page-title">{data.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Exit Type</Typography>
              <Typography className="view-page-title">
                {data.exittype || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Applied Date</Typography>
              <Typography className="view-page-title">
                {data.applieddate ? formatDate(data.applieddate) : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Resignation Date
              </Typography>
              <Typography className="view-page-title">
                {data.resignationdate ? formatDate(data.resignationdate) : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">LWD Date</Typography>
              <Typography className="view-page-title">
                {data.lwddate ? formatDate(data.lwddate) : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Expected LWD Date
              </Typography>
              <Typography className="view-page-title">
                {data.expectlwddate ? formatDate(data.expectlwddate) : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                LWD As per policy
              </Typography>
              <Typography className="view-page-title">
                {data.lwdperpolicy || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Shortfall Days
              </Typography>
              <Typography className="view-page-title">
                {data.shortfalldays || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Leaving Reason
              </Typography>
              <Typography className="view-page-title">
                {data.leavingreason || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box p={1} m={1} px={3} pb={3} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Typography variant="h6" gutterBottom>
            Notice Pay
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2.5}>
              <Box>
                <Typography className="view-page-value">
                  Notice Period
                </Typography>
                <TextField
                  fullWidth
                  className="formInputs"
                  value={data.lwdperpolicy || 0}
                  onChange={handleChange}
                  name="lwdperpolicy"
                  disabled
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={2.5}>
              <Box>
                <Typography className="view-page-value">
                  No. of Days Served
                </Typography>
                <TextField
                  fullWidth
                  className="formInputs"
                  value={(+data.lwdperpolicy || 0) - (+data.shortfalldays || 0)}
                  onChange={handleChange}
                  name="serveddays"
                  disabled
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={2.5}>
              <Box>
                <Typography className="view-page-value">
                  Shortfall in Notice
                </Typography>
                <TextField
                  fullWidth
                  className="formInputs"
                  value={data.shortfalldays}
                  onChange={handleChange}
                  name="shortfalldays"
                  disabled
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={4.5}>
              <Box overflow="auto" height={"200px"}>
                <TableContainer>
                  <Table>
                    <TableHead className="datagrid-header">
                      <TableRow>
                        <TableCell>Payroll Month</TableCell>
                        <TableCell>Shortfall in Notice</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shortFallArray.length > 0 ? (
                        shortFallArray.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {row.month} {row.year}
                            </TableCell>
                            <TableCell>{row.shortfallinnotice} Days</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2}>No Shortfall available</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        mt="auto"
        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
        bgcolor={"background.paper"}
        p={2}
        display={"flex"}
        flexDirection={"row-reverse"}
      >
        {/* <Button className="saveBtn" variant="contained" onClick={handleUpdate}>
          Save
        </Button> */}
        <Button
          className="cancelBtn"
          variant="outlined"
          style={{ marginRight: "10px" }}
          onClick={() => navigate(`/hrm/employeemanagement/employeeexit`)}
        >
          Cancel
        </Button>
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default EditNoticePeriod;
