import {
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Paper,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../../../Constants/Loader";
import { getPerformanceApi } from "../../../../Constants/api";
import {
  performanceBgColor,
  performanceTextColor,
} from "../../../../Constants/dateFormat";
import EditTeamBuildingKpi from "./EditTeamBuildingKpi";

const TeamBuildingKPI = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";
  let userRole = userData.userrole ? userData.userrole : "";
  let username = userData.username ? userData.username : "";

  const [empInfo, setEmpInfo] = useState({});
  const [allOrgKpi, setAllOrgKpi] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // State to manage the selected row
  const [openDialog, setOpenDialog] = useState(false); // State to manage dialog open/close

  //for alert msg
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      let res = await axios.post(getPerformanceApi, {
        companyemail,
        companyid,
      },{withCredentials:true});
      // console.log(res, "res");
      if (res.data && res.data.length > 0) {
        if(userRole=="HR" || userRole=="masteradmin"){
          setData(res.data);
        }
        else{
          setData(res.data.filter((obj) => obj.reportmanager === username));
        }
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
      setLoading(false);
    }
  };

  const getAllOrgGoalKpi = async () => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/performance/teamkpi/get",
        { companyemail, companyid, email: empInfo.email },{withCredentials:true}
      );
      if (res.data && res.data.length > 0){
        setAllOrgKpi(res.data)
      }else{
        setAllOrgKpi([]);
      }
      // console.log(res, "res");
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    axios
      .post("https://hrms.vliv.app/employee/get", { companyemail, companyid },{withCredentials:true})
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let data = res.data.find((el) => el.email === email);
          if (data) {
            setEmpInfo(data);
          } else {
            setEmpInfo({});
          }
        } else {
          setEmpInfo({});
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setAlertType("error");
        setOpen(true);
        setMsg(err.message);
      });
  }, []);

  useEffect(()=>{
    if(empInfo.email){
      getAllOrgGoalKpi()
    }
  },[empInfo])

  useEffect(() => {
    getData();
  }, []);

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);

  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRow(null);
    getData();
  };

  const columns = [
    { field: "empid", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "department", headerName: "Department", flex: 1 },
    { field: "position", headerName: "Position", flex: 1 },
    { field: "teamrating", headerName: "Overall Ratings", flex: 1 },
    {
      field: "teamstatus",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Button
          sx={{
            color: performanceTextColor(params.row.teamstatus),
            backgroundColor: performanceBgColor(params.row.teamstatus),
            textTransform: "capitalize",
            borderRadius: 2,
          }}
        >
          {params.row.teamstatus == "Pending" || !params.row.teamstatus
            ? "Pending"
            : params.row.teamstatus}
        </Button>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleEditClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const rows =
    data.length > 0
      ? data.map((orgkpi) => ({
          id: orgkpi.id,
          empid: orgkpi.empid,
          name: orgkpi.name,
          department: orgkpi.department,
          position: orgkpi.position,
          teamrating: orgkpi.teamrating ? orgkpi.teamrating : "-",
          teamstatus: orgkpi.teamstatus,
          data: orgkpi,
        }))
      : [];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // console.log(data, "data");

  return (
    <>
      <Box my={2}>
        <Paper style={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={loading ? [] : rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 15, 20, rows.length]}
            getRowId={(row) => row.id}
            autoHeight
            components={{
              LoadingOverlay: Loader,
            }}
            loading={loading}
          />
        </Paper>
      </Box>
      <EditTeamBuildingKpi
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialog}
        selectedRow={selectedRow}
        isSmallScreen={isSmallScreen}
        allOrgKpi={allOrgKpi}
      />
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleCloseAlert}
        type={alertType}
      />
    </>
  );
};

export default TeamBuildingKPI;
