import axios from "axios";
import {
  hrmsSummaryAnnouncementAdd,
  hrmsSummaryAnnouncementDelete,
  hrmsSummaryAnnouncementGet,
  hrmsSummaryAnnouncementUpdate,
} from "../../../Constants/api";

export const getSummaryAnnouncement = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_SUMM_ANCMNT_LOADING" });
    let res = await axios.post(hrmsSummaryAnnouncementGet, obj,{withCredentials:true});
    // console.log(res, "response");
    if (res.data) {
      dispatch({
        type: "GET_SUMM_ANCMNT_SUCCESS",
        payload: Array.isArray(res.data) ? res.data : [],
      });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_SUMM_ANCMNT_FAILED", payload: error.message });
  }
};

export const addSummaryAnnouncement =
  (obj, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: "ADD_SUMM_ANCMNT_LOADING" });
      let res = await axios.post(hrmsSummaryAnnouncementAdd, obj,{withCredentials:true});
      console.log("add res of emp", res);
      if (res.status == 200) {
        dispatch({ type: "ADD_SUMM_ANCMNT_SUCCESS", payload: res.data.data });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
      } else {
        // console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "ADD_SUMM_ANCMNT_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

export const updateSummaryAnnouncement =
  (obj, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_SUMM_ANCMNT_LOADING" });
      let res = await axios.post(hrmsSummaryAnnouncementUpdate, obj,{withCredentials:true});
      if (res.status == 200) {
        dispatch({
          type: "UPDATE_SUMM_ANCMNT_SUCCESS",
          payload: res.data.data,
        });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "UPDATE_SUMM_ANCMNT_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

export const deleteSummaryAnnouncement =
  (obj, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_SUMM_ANCMNT_LOADING" });
      let res = await axios.post(hrmsSummaryAnnouncementDelete, obj,{withCredentials:true});
      if (res.status == 200) {
        dispatch({
          type: "DELETE_SUMM_ANCMNT_SUCCESS",
          payload: obj,
        });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_SUMM_ANCMNT_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };
