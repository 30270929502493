const initialState = {
    logoData: {},
  };
  
  //
  export const logoReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case "GET": {
        return { ...state, logoData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };