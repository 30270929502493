import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs"; // Import dayjs for date
import WestIcon from "@mui/icons-material/West";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getEmpReg } from "../../../../../redux/actions/actions.hrms/empRegistration.actions";
import { getGeneralValue } from "../../../../../redux/actions/actions.master/value.actions";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import {
  addEmpExit,
  getEmpExit,
} from "../../../../../redux/actions/actions.hrms/employeeExit.actions";
import { employeeExitRequestApi } from "../../../../../Constants/api";
import axios from "axios";

const AddSeparation = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { empData, error, loading } = useSelector(
    (state) => state.empRegistrationReducer
  );

  const { valueData } = useSelector((state) => state.valueReducer);

  const { emp_exit_loading, emp_exit_error, empExitData } = useSelector(
    (state) => state.empExitReducer
  );

  //for success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [selectEmp, setSelectEmp] = useState("");
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    empid: "",
    tableemployeeid: "",
    name: "",
    department: "",
    resignationdate: "",
    position: "",
    reportmanager: "",
    reportingto: "",
    joiningdate: "",
    exittype: "Voluntary",
    applyingdate: "",
    expectlwddate: "",
    lwddate: "",
    leavingreason: "",
    lwdperpolicy: 90,
    shortfalldays: "",
    altemail: "",
    altphone: "",
    remarks: "",
    servenoticeperiod: "",
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  useEffect(() => {
    if (selectEmp) {
      let singleEmpData = empData.find((ele) => ele.empid == selectEmp);
      setData({
        ...data,
        tableemployeeid: singleEmpData.id,
        empid: singleEmpData.empid,
        name: singleEmpData.name,
        department: singleEmpData.department,
        position: singleEmpData.position,
        reportmanager: singleEmpData.reportmanager,
        joiningdate: singleEmpData.joiningdate,
      });
    }
  }, [selectEmp]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "exittype") {
      setData((prev) => ({
        ...prev,
        [name]: value,
        servenoticeperiod: "",
        resignationdate: "",
        lwddate: "",
        expectlwddate: "",
        shortfalldays: "",
      }));
    } else {
      let updatedData = { ...data, [name]: value };

      if (
        name === "servenoticeperiod" ||
        name === "resignationdate" ||
        name === "lwddate" ||
        name === "expectlwddate"
      ) {
        updatedData = calculateDates(updatedData);
      }

      setData(updatedData);
    }
  };

  const calculateDates = (updatedData) => {
    if (
      updatedData.servenoticeperiod === "Yes" &&
      updatedData.resignationdate
    ) {
      updatedData.lwddate = dayjs(updatedData.resignationdate)
        .add(updatedData.lwdperpolicy, "day")
        .format("YYYY-MM-DD");
      updatedData.shortfalldays = 0;
      updatedData.expectlwddate = ""; // Clear expectlwddate for Yes case
    } else if (
      updatedData.servenoticeperiod === "No" &&
      updatedData.resignationdate &&
      updatedData.expectlwddate
    ) {
      updatedData.lwddate = ""; // Clear lwddate for No case
      const resignationDate = dayjs(updatedData.resignationdate);
      const expectLwdDate = dayjs(updatedData.expectlwddate);
      const servedDays = expectLwdDate.diff(resignationDate, "day");
      updatedData.shortfalldays = updatedData.lwdperpolicy - servedDays;
    }
    return updatedData;
  };

  const handleSave = async () => {
    if (!data.name || !data.servenoticeperiod || !data.resignationdate) {
      setShowEmptyError(true);
      setMsg("Please fill all the fields");
      setAlertType("error");
      setOpen(true);
      return;
    }
    const applyingDate = dayjs().format("YYYY-MM-DD");
    const exitData = { ...data, applyingdate: applyingDate };

    // console.log(exitData, "exit data");
    try {
      let res = await axios.post(employeeExitRequestApi, exitData,{withCredentials:true});
      // console.log(res, "response");
      if (res.data.message) {
        setAlertType("success");
        setOpen(true);
        setMsg("Exit Request Raised");
        setTimeout(() => {
          navigate(`/hrm/employeemanagement/employeeexit`);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
     
    }
  };

  useEffect(() => {
    dispatch(
      getEmpReg({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
    dispatch(
      getGeneralValue({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );

    dispatch(
      getEmpExit({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  // console.log(empData, "emp data");
  // console.log(empExitData, "emp exit data");

  return (
    <>
      <Box>
        <Box p={2} m={1} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Typography
            variant="h5"
            color="#0B4A6F"
            fontWeight={"bold"}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            <IconButton
              onClick={() => navigate(`/hrm/employeemanagement/employeeexit`)}
            >
              <WestIcon sx={{ color: "#0B4A6F" }} />
            </IconButton>
            <span> Add Separation</span>
          </Typography>
        </Box>

        <Box p={2} m={1} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1">Select Employee</Typography>
              <Select
                size="small"
                fullWidth
                className="formInputs"
                inputProps={{
                  className: "selectInput",
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                value={selectEmp}
                onChange={(e) => setSelectEmp(e.target.value)}
                error={!data.name && showEmptyError}
              >
                {empData.length > 0 &&
                  empData
                    .filter(
                      (emp) =>
                        !empExitData.some((exit) => exit.empid === emp.empid)
                    )
                    .map((emp, index) => (
                      <MenuItem key={index} value={emp.empid}>
                        {emp.name}({emp.empid})
                      </MenuItem>
                    ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <img
                src={`akdkadkasdl`}
                alt={"image"}
                loading="lazy"
                style={{ borderRadius: "50%", width: "100px", height: "100px" }}
              />
            </Grid>
          </Grid>
        </Box>

        {selectEmp && (
          <>
            <Box p={2} m={1} bgcolor={"#F2F4F7"} borderRadius={3}>
              <Typography variant="h6" gutterBottom>
                Employee Information
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className="view-page-value">Name</Typography>
                  <Typography className="view-page-title">
                    {data.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className="view-page-value">
                    Employee ID
                  </Typography>
                  <Typography className="view-page-title">
                    {data.empid}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className="view-page-value">
                    Department
                  </Typography>
                  <Typography className="view-page-title">
                    {data.department}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className="view-page-value">Position</Typography>
                  <Typography className="view-page-title">
                    {data.position}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className="view-page-value">
                    Reporting To
                  </Typography>
                  <Typography className="view-page-title">
                    {data.reportmanager}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className="view-page-value">
                    Date of Joining
                  </Typography>
                  <Typography className="view-page-title">
                    {data.joiningdate}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box p={2} m={1} bgcolor={"#F2F4F7"} borderRadius={3}>
              <Typography variant="h6" gutterBottom>
                Exit Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className="view-page-value">
                    Type of Exit
                  </Typography>
                  <Select
                    size="small"
                    fullWidth
                    className="formInputs"
                    inputProps={{
                      className: "selectInput",
                    }}
                    name="exittype"
                    value={data.exittype}
                    onChange={handleChange}
                  >
                    <MenuItem value="Voluntary">Voluntary</MenuItem>
                    <MenuItem value="Termination">Termination</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className="view-page-value">
                    Serve Notice Period
                  </Typography>
                  <Select
                    size="small"
                    fullWidth
                    className="formInputs"
                    inputProps={{
                      className: "selectInput",
                    }}
                    name="servenoticeperiod"
                    value={data.servenoticeperiod}
                    onChange={handleChange}
                    error={!data.servenoticeperiod && showEmptyError}
                  >
                    {data.exittype == "Voluntary" && (
                      <MenuItem value="Yes">Yes</MenuItem>
                    )}
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </Grid>

                {data.exittype && (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography className="view-page-value">
                        Resignation Date
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        type="date"
                        value={data.resignationdate}
                        onChange={handleChange}
                        name="resignationdate"
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                        error={!data.resignationdate && showEmptyError}
                      />
                    </Grid>

                    <>
                      {data.servenoticeperiod === "Yes" ? (
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography className="view-page-value">
                            LWD Date
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            type="date"
                            value={data.lwddate}
                            onChange={handleChange}
                            name="lwddate"
                            disabled={true}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography className="view-page-value">
                            Expected LWD Date
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            type="date"
                            value={data.expectlwddate}
                            onChange={handleChange}
                            name="expectlwddate"
                            inputProps={{
                              min: new Date().toISOString().split("T")[0],
                            }}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6} md={4}>
                        <Typography className="view-page-value">
                          Leaving Reason
                        </Typography>
                        <Select
                          size="small"
                          fullWidth
                          className="formInputs"
                          name="leavingreason"
                          value={data.leavingreason}
                          onChange={handleChange}
                        >
                          <MenuItem value="Transfer">Transfer</MenuItem>
                          <MenuItem value="Resignation">Resignation</MenuItem>
                          <MenuItem value="Retirement">Retirement</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography className="view-page-value">
                          Shortfall Days
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          value={data.shortfalldays}
                          // onChange={handleChange}
                          name="shortfalldays"
                          disabled
                        />
                      </Grid>
                    </>
                  </>
                )}
              </Grid>
            </Box>

            {data.exittype && (
              <Box p={2} m={1} bgcolor={"#F2F4F7"} borderRadius={3}>
                <Typography variant="h6" gutterBottom>
                  Additional Information
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography className="view-page-value">
                      Alternate Email ID
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      value={data.altemail}
                      onChange={handleChange}
                      name="altemail"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography className="view-page-value">
                      Alternate Phone No
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      value={data.altphone}
                      onChange={handleChange}
                      name="altphone"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className="view-page-value">Remarks</Typography>
                    <TextField
                      size="small"
                      fullWidth
                      multiline
                      rows={3}
                      value={data.remarks}
                      onChange={handleChange}
                      name="remarks"
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        )}
      </Box>

      {data.exittype && (
        <Box
          mt="auto"
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
          bgcolor={"background.paper"}
          p={2}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Button className="saveBtn" variant="contained" onClick={handleSave}>
            Save
          </Button>
          <Button
            className="cancelBtn"
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={() => navigate(`/hrm/employeemanagement/employeeexit`)}
          >
            Cancel
          </Button>
        </Box>
      )}

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default AddSeparation;
