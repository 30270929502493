const initialState = {
    loading: false,
    error: "",
    leaveReasonData: [],
  };
export const empLeaveCategoryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case "GET_EMP_LEAVE_CATEGORY_LOADING":
        case "ADD_EMP_LEAVE_CATEGORY_LOADING":
        case "UPDATE_EMP_LEAVE_CATEGORY_LOADING":
        case "DELETE_EMP_LEAVE_CATEGORY_LOADING":
          return { ...state, loading: true };
    
        case "GET_EMP_LEAVE_CATEGORY_SUCCESS":
          return { ...state, loading: false, leaveReasonData: payload };
    
        case "ADD_EMP_LEAVE_CATEGORY_SUCCESS":
          return {
            ...state,
            loading: false,
            leaveReasonData: [...state.leaveReasonData, payload],
          };
    
        case "UPDATE_EMP_LEAVE_CATEGORY_SUCCESS":
          return {
            ...state,
            loading: false,
            leaveReasonData: state.leaveReasonData.map((item) =>
              item.id === payload.id ? payload : item
            ),
          };
    
        case "DELETE_EMP_LEAVE_CATEGORY_SUCCESS": {
          const idsToDelete = payload.idarray;
          return {
            ...state,
            loading: false,
            leaveReasonData: state.leaveReasonData.filter(
              (item) => !idsToDelete.includes(item.id)
            ),
          };
        }
    
        case "ADD_EMP_LEAVE_CATEGORY_FAILED":
        case "UPDATE_EMP_LEAVE_CATEGORY_FAILED":
        case "GET_EMP_LEAVE_CATEGORY_FAILED":
        case "DELETE_EMP_LEAVE_CATEGORY_FAILED":
          return { ...state, loading: false, error: payload };
    
        default:
          return state;
      }
}