import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Hidden,
  Paper,
  Typography,
  Grid,
  Avatar,
  Collapse,
  Divider,
  Autocomplete,
} from "@mui/material";
import {
  Search as SearchIcon,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import AddRecruitMentDrawer from "./AddRecruitMentDrawer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getRecruitment } from "../../../../redux/recruitmentprocess/actions";
import {
  formatDate,
  formatDateTime,
  getColorForStatus,
  getOnboardStatus,
} from "../../../../Constants/dateFormat";
import {
  generateAvatarColor,
  getInitials,
} from "../../../../Constants/constants";
import { getJobApplications } from "../../../../redux/JobApplication/actions";
import axios from "axios";

// Sample CSS styling for the component (adjust according to your needs)
const styles = {
  card: {
    margin: "5px 0",
    padding: "20px",
    borderRadius: "8px",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  recruitment: {
    // borderBottom: "1px solid #e0e0e0",
    boxShadow: "0 0px 6px -2px rgba(0, 0, 0, 0.3)",
    padding: "16px",
    marginBottom: "6px",
    borderRadius: "6px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "0.85rem",
    color: "#757575",
  },
};

const Recruitment = () => {
  // COOKIES PART
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let userRole = userData.userrole ? userData.userrole : "";
  // COOKIES PART

  const [selectedRecruitment, setSelectedRecruitment] = useState({});
  const [masterAdminData, setMasterAdminData] = useState({});
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const handleSelectChange = (event, value) => {
    setSelectedCandidate(value);
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState({});
  const navigate = useNavigate();

  const [allRecruitMents, setAllRecruitments] = useState([]);

  // REDUX PART
  const dispatch = useDispatch();
  //all recruitments requests
  const { data, loading, error } = useSelector(
    (state) => state.recruitmentReducer
  );
  //all job applications
  const { data: jobApplications } = useSelector(
    (state) => state.jobApplicationReducer
  );

  // REDUX PART

  const handleSearch = (event) => {
    // Handle search logic here
  };

  //for success alert
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleToggleExpand = (index) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  function groupDataByRaisedBy(data) {
    const allData = [];

    data.forEach((item) => {
      const raisedByIndex = allData.findIndex(
        (obj) => obj.raisedby === item.raisedby
      );

      const recruitment = {
        id: item.id,
        branch: item.branch,
        department: item.department,
        positions: item.positions,
        noofpositions: item.noofpositions,
        employeetype: item.employeetype,
        frombudget: item.frombudget,
        tobudget: item.tobudget,
        level: item.level,
        experience: item.experience,
        description: item.description,
        companyemail: item.companyemail,
        companyid: item.companyid,
        country: item.country,
        status: item.status,
        approver: item.approver,
        jd: item.jd,
        raisedby: item.raisedby,
        jdtext: item.jdtext,
        poststatus: item.poststatus,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
      };

      if (raisedByIndex !== -1) {
        // If raisedby already exists, push the recruitment data to the recruitments array
        allData[raisedByIndex].recruitments.push(recruitment);
      } else {
        // If raisedby does not exist, create a new object and add it to allData
        allData.push({
          raisedby: item.raisedby,
          recruitments: [recruitment],
        });
      }
    });

    setAllRecruitments(allData);
  }

  const getMasterAdmin = async () => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/employee/getmasteradmin",
        { companyemail, companyid },{withCredentials:true}
      );
      res.data ? setMasterAdminData({ ...res.data }) : setMasterAdminData({});
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getRecruitment({ companyid, companyemail }));
    dispatch(
      getJobApplications(
        { companyid, companyemail },
        setOpen,
        setMsg,
        setAlertType
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (data.length > 0) {
      groupDataByRaisedBy(data);
    }
  }, [data]);

  useEffect(() => {
    getMasterAdmin();
  }, []);

  // console.log(allRecruitMents, "allrecruitMents");
  // console.log(jobApplications,"job applications");
  // console.log(selectedRecruitment, "selected Recruitment");
  // console.log(selectedCandidate, "selected Candidate");

  return (
    <Box>
      <Box className="common-bg-color">
        <Box className="searchbar-container">
          <Hidden smDown>
            <Box>
              {/* Content to be shown only on screens larger than or equal to sm (600px) */}
            </Box>
          </Hidden>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <TextField
              placeholder="Search…"
              variant="outlined"
              className="searchbar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
            <Button className="saveBtn" onClick={() => setDrawerOpen(true)}>
              Create Recruitment
            </Button>
          </Box>
        </Box>
        <Box>
          <Grid container display="flex" justifyContent="space-between" px={1}>
            <Grid
              item
              md={
                selectedRecruitment &&
                Object.keys(selectedRecruitment).length > 0
                  ? 9
                  : 12
              }
              sm={12}
              xs={12}
              flex={1}
            >
              <Box>
                {allRecruitMents.map((group, index) => (
                  <Box key={index} style={styles.card}>
                    <Box style={styles.header}>
                      <Box display="flex" alignItems="center">
                        <Avatar
                          sx={{
                            bgcolor: generateAvatarColor(group.raisedby),
                            marginRight: "10px",
                          }}
                        >
                          {getInitials(group.raisedby)}
                        </Avatar>
                        <Box>
                          <Typography className="view-page-value">
                            {group.raisedby}
                          </Typography>
                          <Typography
                            className="view-page-title"
                            style={{ fontSize: "14px" }}
                          >
                            Application Raised
                          </Typography>
                        </Box>
                      </Box>
                      <IconButton
                        onClick={() => handleToggleExpand(index)}
                        size="large"
                      >
                        {expandedGroups[index] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    </Box>
                    <Collapse
                      in={
                        expandedGroups[index] || group.recruitments.length <= 2
                      }
                      style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
                    >
                      {group.recruitments
                        .slice(0, expandedGroups[index] ? undefined : 2)
                        .map((recruitment, idx) => (
                          <Box
                            key={idx}
                            style={styles.recruitment}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={1}
                            className={
                              recruitment.id == selectedRecruitment.id
                                ? "cursor-pointer-hover-bg"
                                : "cursor-pointer-bg"
                            }
                            onClick={() => {
                              setSelectedRecruitment(recruitment);
                              setSelectedCandidate(null);
                            }}
                          >
                            <Box
                              flex={1.5}
                              display="flex"
                              // alignItems="center"
                              justifyContent="space-between"
                              flexDirection="column"
                              gap={1}
                            >
                              <p className="view-page-title">Position</p>
                              <p className="view-page-value">
                                {recruitment.positions}
                              </p>
                            </Box>
                            <Box
                              flex={1}
                              display="flex"
                              // alignItems="center"
                              justifyContent="space-between"
                              flexDirection="column"
                              gap={1}
                            >
                              <p className="view-page-title">No of Position</p>
                              <p className="view-page-value">
                                {recruitment.noofpositions}
                              </p>
                            </Box>
                            <Box
                              flex={1.5}
                              display="flex"
                              // alignItems="center"
                              justifyContent="space-between"
                              flexDirection="column"
                              gap={1}
                            >
                              <p className="view-page-title">Department</p>
                              <p className="view-page-value">
                                {recruitment.department}
                              </p>
                            </Box>
                            <Box
                              flex={1.2}
                              display="flex"
                              // alignItems="center"
                              justifyContent="space-between"
                              flexDirection="column"
                              gap={1}
                            >
                              <p className="view-page-title">
                                Type of Employee
                              </p>
                              <p className="view-page-value">
                                {recruitment.employeetype}
                              </p>
                            </Box>
                            <Box
                              flex={1}
                              display="flex"
                              // alignItems="center"
                              justifyContent="space-between"
                              flexDirection="column"
                              gap={1}
                            >
                              <p className="view-page-title">Experience</p>
                              <p className="view-page-value">
                                {recruitment.experience}yr
                              </p>
                            </Box>
                            <Box
                              flex={1}
                              display="flex"
                              // alignItems="center"
                              justifyContent="space-between"
                              flexDirection="column"
                              gap={1}
                            >
                              <p className="view-page-title">Budget</p>
                              <p className="view-page-value">
                                {recruitment.frombudget} -{" "}
                                {recruitment.tobudget}LPA
                              </p>
                            </Box>
                            <Box
                              flex={1}
                              display="flex"
                              // alignItems="center"
                              justifyContent="space-between"
                              flexDirection="column"
                              gap={1}
                            >
                              <p className="view-page-title">Status</p>
                              <p
                                className="view-page-value"
                                style={{ textTransform: "capitalize" }}
                              >
                                {recruitment.status}
                              </p>
                            </Box>
                            <Box
                              flex={1}
                              display="flex"
                              // alignItems="center"
                              justifyContent="space-between"
                              flexDirection="column"
                              gap={1}
                            >
                              <p className="view-page-title">Request Date</p>
                              <p
                                className="view-page-value"
                                style={{ textTransform: "capitalize" }}
                              >
                                {formatDateTime(recruitment.createdAt)}
                              </p>
                            </Box>
                          </Box>
                        ))}
                    </Collapse>
                  </Box>
                ))}
              </Box>
            </Grid>

            {selectedRecruitment &&
              Object.keys(selectedRecruitment).length > 0 && (
                <Grid
                  item
                  md={2.93}
                  sm={12}
                  xs={12}
                  flex={1}
                  style={{
                    ...styles.card,
                    overflowY: "auto",
                    padding: "10px 0px",
                  }}
                  height={"auto"}
                  maxHeight={"700px"}
                  p={0}
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={3} // Reduced gap for a more compact and professional layout
                    flexWrap="wrap"
                    px={2}
                    py={2} // Added padding for better spacing inside the Box
                    sx={{
                      backgroundColor: "#f5f5f5", // Soft background color
                      borderRadius: "8px", // Rounded corners
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                    }}
                  >
                    <Autocomplete
                      size="small"
                      options={jobApplications.filter(
                        (app) => selectedRecruitment.id == app.recruitmentid
                      )}
                      getOptionLabel={(option) =>
                        `${option.candidateid}-${option.fullname}`
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Typography variant="body2">{`${option.candidateid}-${option.fullname}`}</Typography>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Candidate"
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px", // More rounded input field
                              backgroundColor: "#ffffff", // White background for contrast
                              "& fieldset": {
                                borderColor: "#d1d1d1", // Soft grey border color
                                padding: "0px 15px",
                              },
                              "&:hover fieldset": {
                                borderColor: "#a1a1a1", // Slightly darker border on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#3f51b5", // Primary color border on focus
                              },
                            },
                            "& .MuiInputBase-input": {
                              padding: "8px 14px", // Comfortable padding for input text
                            },
                          }}
                        />
                      )}
                      onChange={handleSelectChange}
                      isOptionEqualToValue={(option, value) =>
                        option.candidateid === value.candidateid
                      }
                      sx={{
                        width: "300px",
                        "& .MuiAutocomplete-paper": {
                          minWidth: "350px", // Increased dropdown width for readability
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Added shadow for dropdown
                          borderRadius: "8px", // Rounded corners for dropdown
                        },
                        "& .MuiAutocomplete-listbox": {
                          maxHeight: "200px", // Limit height for scrollability
                          overflowY: "auto", // Enable scrolling if options exceed height
                        },
                      }}
                    />

                    {selectedRecruitment.positions && (
                      <Typography
                        variant="body1"
                        sx={{
                          marginLeft: "10px", // Spacing between elements
                          color: "#333", // Darker text for better readability
                          fontWeight: "bold", // Bold text for emphasis
                        }}
                      >
                        {selectedRecruitment.positions}
                      </Typography>
                    )}
                  </Box>
                  <Divider />
                  <Box
                    display="flex"
                    flexDirection="column-reverse"
                    flexGrow={1}
                    p={1}
                  >
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Box
                        style={styles.header}
                        px={1.5}
                        py={1}
                        className="common-bg-color common-hover-box"
                        borderRadius={10}
                      >
                        {masterAdminData.username && (
                          <Box display="flex" alignItems="center">
                            <Avatar
                              sx={{
                                bgcolor: generateAvatarColor(
                                  masterAdminData.username
                                ),
                                marginRight: "8px",
                              }}
                              style={{
                                fontSize: "14px",
                                height: "36px",
                                width: "36px",
                              }}
                            >
                              {getInitials(masterAdminData.username)}
                            </Avatar>
                            <Box>
                              <Typography style={{ fontSize: "14px" }}>
                                {masterAdminData.username}
                              </Typography>
                              <Typography
                                className="view-page-title"
                                style={{ fontSize: "14px" }}
                              >
                                {masterAdminData.designation || ""}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <Typography
                            className="view-page-title"
                            style={{ fontSize: "14px" }}
                          >
                            {formatDateTime(selectedRecruitment.updatedAt)}
                          </Typography>
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "14px",
                            }}
                          >
                            {selectedRecruitment.status}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        style={styles.header}
                        px={1.5}
                        py={1}
                        className="common-bg-color common-hover-box"
                        borderRadius={10}
                      >
                        {selectedRecruitment.raisedby && (
                          <Box display="flex" alignItems="center">
                            <Avatar
                              sx={{
                                bgcolor: generateAvatarColor(
                                  selectedRecruitment.raisedby
                                ),
                                marginRight: "8px",
                              }}
                              style={{
                                fontSize: "14px",
                                height: "36px",
                                width: "36px",
                              }}
                            >
                              {getInitials(selectedRecruitment.raisedby)}
                            </Avatar>
                            <Box>
                              <Typography style={{ fontSize: "14px" }}>
                                {selectedRecruitment.raisedby}
                              </Typography>
                              <Typography
                                className="view-page-title"
                                style={{ fontSize: "14px" }}
                              >
                                Application Raised
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        <Typography
                          className="view-page-title"
                          style={{ fontSize: "14px" }}
                        >
                          {formatDateTime(selectedRecruitment.createdAt)}
                        </Typography>
                      </Box>
                    </Box>

                    {selectedCandidate &&
                      selectedCandidate.history &&
                      selectedCandidate.history.length > 0 && (
                        <Box display="flex" flexDirection="column">
                          {selectedCandidate.history
                            .reverse()
                            .map((interview, ind) => (
                              <Box
                                style={styles.header}
                                px={1.5}
                                py={1}
                                className="common-bg-color common-hover-box"
                                borderRadius={10}
                                key={ind}
                              >
                                <Box display="flex" alignItems="center">
                                  <Avatar
                                    sx={{
                                      bgcolor: generateAvatarColor(
                                        interview.interviewername
                                      ),
                                      marginRight: "8px",
                                    }}
                                    style={{
                                      fontSize: "14px",
                                      height: "36px",
                                      width: "36px",
                                    }}
                                  >
                                    {getInitials(interview.interviewername)}
                                  </Avatar>
                                  <Box>
                                    <Typography style={{ fontSize: "14px" }}>
                                      {interview.interviewername}
                                    </Typography>
                                    <Typography
                                      className="view-page-title"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {interview.interviewname}
                                    </Typography>
                                    <Typography
                                      className="view-page-title"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {interview.remarks}
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="column"
                                >
                                  <Typography
                                    className="view-page-title"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {interview.scheduleddate
                                      ? formatDateTime(interview.scheduleddate)
                                      : ""}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      textTransform: "capitalize",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {interview.offerletterstatus ? (
                                      <Button
                                        sx={{
                                          color: getColorForStatus(
                                            interview.offerletterstatus
                                          ),
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {interview.offerletterstatus}
                                      </Button>
                                    ) : (
                                      <Button
                                        sx={{
                                          color: getColorForStatus(
                                            interview.interviewstatus
                                          ),
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {interview.interviewstatus}
                                      </Button>
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                        </Box>
                      )}

                    {selectedCandidate &&
                      selectedCandidate.onboardstatushistory &&
                      selectedCandidate.onboardstatushistory.length > 0 && (
                        <Box display="flex" flexDirection="column">
                          {selectedCandidate.onboardstatushistory
                            .reverse()
                            .map((onboard, ind) => (
                              <Box
                                style={styles.header}
                                px={1.5}
                                py={1}
                                className="common-bg-color common-hover-box"
                                borderRadius={10}
                                key={ind}
                              >
                                <Box display="flex" alignItems="center">
                                  <Avatar
                                    sx={{
                                      bgcolor: generateAvatarColor(
                                        selectedCandidate.fullname
                                      ),
                                      marginRight: "8px",
                                    }}
                                    style={{
                                      fontSize: "14px",
                                      height: "36px",
                                      width: "36px",
                                    }}
                                  >
                                    {getInitials(selectedCandidate.fullname)}
                                  </Avatar>
                                  <Box>
                                    <Typography style={{ fontSize: "14px" }}>
                                      {selectedCandidate.fullname}
                                    </Typography>
                                    <Typography
                                      className="view-page-title"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {"Onboarding"}
                                    </Typography>
                                    <Typography
                                      className="view-page-title"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {onboard.remarks}
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="column"
                                >
                                  <Typography
                                    className="view-page-title"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {formatDateTime(onboard.date)}
                                  </Typography>
                                  <Button
                                    sx={{
                                      color: getColorForStatus(onboard.status),
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {getOnboardStatus(onboard.status)}
                                  </Button>
                                </Box>
                              </Box>
                            ))}
                        </Box>
                      )}
                  </Box>
                </Grid>
              )}
          </Grid>
        </Box>
      </Box>

      {/* //for drawerOpen */}
      <AddRecruitMentDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />

      {/* //for drawer */}
    </Box>
  );
};

export default Recruitment;
