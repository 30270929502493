import React, { useEffect, useState } from "react";
import Separation from "./Separation/Separation";
import NoticePeriod from "./NoticePeriod/NoticePeriod";
import FF from "./FF/FF";
import { Box, Tab } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

const EmployeeExit = () => {
  const defaultTab = "separation";
  const [tabvalue, setTabValue] = useState(localStorage.getItem("activeTab") || defaultTab);

  useEffect(() => {
    localStorage.setItem("activeTab", tabvalue);
  }, [tabvalue]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      <Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabvalue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Separation"
                  value="separation"
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="Notice Period"
                  value="noticeperiod"
                  sx={{ textTransform: "none" }}
                />
                <Tab label="F&F" value="ff" sx={{ textTransform: "none" }} />
              </TabList>
            </Box>
            <TabPanel value="separation" sx={{ padding: 1 }}>
              <Box sx={{ height: "auto", width: "100%" }}>
                <Separation />
              </Box>
            </TabPanel>
            <TabPanel value="noticeperiod" sx={{ padding: 1 }}>
              <Box sx={{ height: "auto", width: "100%" }}>
                <NoticePeriod />
              </Box>
            </TabPanel>
            <TabPanel value="ff" sx={{ padding: 1 }}>
              <Box sx={{ height: "auto", width: "100%" }}>
                <FF />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};

export default EmployeeExit;
