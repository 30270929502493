import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import { leaveType } from "../../../../../Constants/calculateTotalHrs";
import { hrmsSummaryHolidayGet } from "../../../../../Constants/api";
import HolidaysModal from "./HolidaysModal";
import OptionalHolidayModal from "./OptionalHolidayModal";
import ApplyLeaveForm from "./ApplyLeaveForm";

const LeaveManagement = () => {
  const navigate = useNavigate();
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";
  let userRole = userData.userrole ? userData.userrole : "";
  let username = userData.username ? userData.username : "";

  const [empInfo, setEmpInfo] = useState({});
  const [leaveData, setLeaveData] = useState([]);
  const [remainingLeaves, setRemainingLeaves] = useState([]);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [holidayModalOpen, setholidayModalOpen] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [optionalHolidayModalOpen, setOptionalHolidayModalOpen] =
    useState(false);

  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);

  const fetchHolidays = async () => {
    try {
      let res = await axios.post(hrmsSummaryHolidayGet, {
        companyemail,
        companyid,
      },{withCredentials:true});
      res.data && res.data.length > 0 ? setHolidays(res.data) : setHolidays([]);
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

  const openHolidayModal = () => {
    fetchHolidays();
    setholidayModalOpen(true);
  };
  const closeHolidayModal = () => {
    setholidayModalOpen(false);
  };
  const openOptionalHolidayModal = () => {
    fetchHolidays();
    setOptionalHolidayModalOpen(true);
  };

  const closeOptionalHolidayModal = () => {
    setOptionalHolidayModalOpen(false);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  useEffect(() => {
    axios
      .post("https://hrms.vliv.app/employee/get", { companyemail, companyid },{withCredentials:true})
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let data = res.data.find((el) => el.email == email);
          if (data) {
            setEmpInfo(data);
          } else {
            setEmpInfo({});
          }
        } else {
          setEmpInfo({});
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setAlertType("error");
        setOpen(true);
        setMsg(err.message);
      });
  }, []);

  useEffect(() => {
    if (empInfo.empid) {
      axios
        .post("https://hrms.vliv.app/attedance/leave/remainingleaves", {
          companyemail,
          companyid,
          employeeid: empInfo.empid,
          tableemployeeid:empInfo.id
        },{withCredentials:true})
        .then((res) => {
          // console.log(res, "res 2");
          if (res.data && res.data.remainingLeaves.length > 0) {
            setRemainingLeaves(res.data.remainingLeaves);
          } else {
            setRemainingLeaves([]);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          // setAlertType("error");
          // setOpen(true);
          // setMsg(err.message);
        });
    }
  }, [empInfo, drawerOpen]);

  useEffect(() => {
    if (empInfo.empid) {
      axios
        .post("https://hrms.vliv.app/attedance/leave/getbyemployee", {
          employeeid: empInfo.empid,
          tableemployeeid:empInfo.id,
          companyemail,
          companyid,
        },{withCredentials:true})
        .then((res) => {
          // console.log(res, "res");
          if (res.data && res.data.length > 0) {
            setLeaveData(res.data);
          } else {
            setLeaveData([]);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          // setAlertType("error");
          // setOpen(true);
          // setMsg(err.message);
        });
    }
  }, [empInfo, drawerOpen]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // console.log(leaveData, "leave data");

  return (
    <>
      <Box px={3} py={2}>
        <Box>
          <Typography
            variant={isSmallScreen ? "h6" : "h5"}
            className="primarycolor"
          >
            Leave Management
          </Typography>
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            alignItems={"center"}
            px={2}
            className="commonShadow"
            borderRadius={2}
            mt={0.5}
          >
            {remainingLeaves.map((leave, ind) => (
              <Box key={ind} flexGrow={1} p={2}>
                <p className="view-page-title">{leave.leavetype}</p>
                <Typography variant={isSmallScreen ? "h5" : "h4"} mt={0.5}>
                  {leave.remaining}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          my={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          flexWrap={"wrap"}
          gap={1}
        >
          <Button
            className="cancelBtn"
            variant="outlined"
            onClick={openHolidayModal}
          >
            List Of Holidays
          </Button>
          <Button
            className="cancelBtn"
            variant="outlined"
            onClick={openOptionalHolidayModal}
          >
            Optional Holidays
          </Button>
          <Button
            className="saveBtn"
            variant="outlined"
            onClick={toggleDrawer(true)}
            disabled={
              remainingLeaves.length === 0 ||
              remainingLeaves.every((leave) => leave.remaining == 0)
            }
            
          >
            Apply Leave
          </Button>
        </Box>
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#E4E7EC" }}>
                  <TableCell>Applied Date</TableCell>
                  <TableCell>Leave Type</TableCell>
                  <TableCell>Leave From</TableCell>
                  <TableCell>Leave To</TableCell>
                  <TableCell>Attachment</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveData?.map((leave) => (
                  <TableRow key={leave.id}>
                    <TableCell>
                      {leave.date
                        ? new Date(leave.date).toLocaleDateString()
                        : "--"}
                    </TableCell>
                    <TableCell>
                      {leave.leavetype ? leave.leavetype : "--"}
                    </TableCell>
                    <TableCell>
                      {leave.fromdate
                        ? new Date(leave.fromdate).toLocaleDateString()
                        : "--"}
                    </TableCell>
                    <TableCell>
                      {leave.todate
                        ? new Date(leave.todate).toLocaleDateString()
                        : "--"}
                    </TableCell>
                    <TableCell>
                      {leave.attachfile ? leave.attachfile : "--"}
                    </TableCell>
                    <TableCell>{leave.reason ? leave.reason : "--"}</TableCell>
                    <TableCell>
                      {leave.hrstatus ? leave.hrstatus : "--"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <HolidaysModal
        closeHolidayModal={closeHolidayModal}
        holidays={holidays}
        holidayModalOpen={holidayModalOpen}
      />
      <OptionalHolidayModal
        closeOptionalHolidayModal={closeOptionalHolidayModal}
        holidays={holidays}
        optionalHolidayModalOpen={optionalHolidayModalOpen}
      />
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
      <ApplyLeaveForm
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        isSmallScreen={isSmallScreen}
        empInfo={empInfo}
        remainingLeaves={remainingLeaves}
        setDrawerOpen={setDrawerOpen}
      />
    </>
  );
};

export default LeaveManagement;
