import { Avatar, Box, Container, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { getEmpReg } from "../../../../redux/actions/actions.hrms/empRegistration.actions";
const boxStyle = {
  width: "200px",
  backgroundColor: "#092C4C",
  padding: "10px 10px",
  margin: "auto",
  color: "white",
  borderRadius: "5px",
  textAlign: "center",
};
const treeContainerStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  //alignItems: "center",
  overflowX: "auto",
  padding: "20px 0",
  height: "500px",
};
const ORGChart = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { empData, error, loading } = useSelector(
    (state) => state.empRegistrationReducer
  );
  const containerRef = useRef(null);
  const [zoom, setZoom] = useState(1);
  const [expandedNodes, setExpandedNodes] = useState({});

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.deltaY < 0) {
        // Scroll up - zoom in
        setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));
      } else {
        // Scroll down - zoom out
        setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5));
      }
    };

    const container = containerRef.current;
    container.addEventListener("wheel", handleWheel);

    return () => {
      container.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useEffect(() => {
    dispatch(
      getEmpReg({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  const ceoData = empData.length>0 && empData.find((emp) => emp.name == emp.reportmanager);

  const toggleNode = (node) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [node]: !prev[node],
    }));
  };
  function stringAvatar2(name) {
    return {
      sx: {
        bgcolor: "grey",
        width: "50px",
        height: "50px",
      },
      children: name ? `${name.split(" ")[0][0]}` : "",
    };
  }
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  const renderTreeNode = (label, children, nodeId) => {
    const nodeData = empData.find((emp) => emp.id == nodeId);
    return (
      <TreeNode
        label={
          <Box
            display={"flex"}
            gap={2}
            sx={boxStyle}
            onClick={() => toggleNode(nodeId)}
          >
            <Avatar {...stringAvatar2(nodeData.name)} />
            <Box flex={1}>
              <Tooltip title={nodeData.name} arrow>
                <Typography fontSize={"16px"}>
                  {truncateText(nodeData.name, 11)}
                </Typography>{" "}
              </Tooltip>
              <Tooltip title={nodeData.role} arrow>
                <Typography fontSize={"12px"}>
                  {truncateText(nodeData.role, 18)}
                </Typography>{" "}
              </Tooltip>
            </Box>
          </Box>
        }
      >
        {expandedNodes[nodeId] && children}
      </TreeNode>
    );
  };

  // console.log(expandedNodes, "node");

  return (
    <Container maxWidth="xl">
      <Box
        ref={containerRef}
        sx={{
          ...treeContainerStyle,
        }}
      >
        <Box sx={{ transform: `scale(${zoom})`, transformOrigin: "center" }}>
          <Tree
            lineHeight={"30px"} // Increase height for better visibility
            lineWidth={"2px"}
            lineColor={"#36bffa"}
            lineStyle={"dashed"}
            lineBorderRadius={"10px"}
            nodePadding={"10px"}
            label={empData
              ?.filter((ele) => {
                return ele.reportmanager == ele.name;
              })
              .map((ele) => (
                <Box sx={boxStyle} display={"flex"} gap={2}>
                  <Avatar {...stringAvatar2(ele.name && ele.name)} />
                  <Box>
                    <Tooltip title={ele.name && ele.name} arrow>
                      <Typography fontSize={"16px"}>
                        {truncateText(ele.name && ele.name, 12)}
                      </Typography>{" "}
                    </Tooltip>
                    <Tooltip title={ele.name && ele.name} arrow>
                      <Typography fontSize={"12px"}>
                        {truncateText(ele.role, 12)}
                      </Typography>{" "}
                    </Tooltip>
                  </Box>
                </Box>
              ))}
          >
            {empData
              .filter(
                (ele) =>
                  ele.reportmanager == ceoData.name &&
                  ele.reportmanager != ele.name
              )
              .map((ele) =>
                renderTreeNode(
                  `${ele.name}`,
                  <>
                    {empData
                      .filter((node) => node.reportmanager == ele.name)
                      .map((node) =>
                        renderTreeNode(`${node.name}`, <></>, `${node.id}`)
                      )}
                  </>,
                  `${ele.id}`
                )
              )}
          </Tree>
        </Box>
      </Box>
    </Container>
  );
};

export default ORGChart;
