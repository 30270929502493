import axios from "axios";
import {
  masterEmployeeAssetAdd,
  masterEmployeeAssetDelete,
  masterEmployeeAssetGet,
  masterEmployeeAssetUpdate,
} from "../../../Constants/api";

export const getEmployeeAsset = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_EMP_ASSET_LOADING" });
    let res = await axios.post(masterEmployeeAssetGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_EMP_ASSET_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_EMP_ASSET_FAILED", payload: error.message });
  }
};

export const addEmployeeAsset = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_EMP_ASSET_LOADING" });
    let res = await axios.post(masterEmployeeAssetAdd, obj,{withCredentials:true});
    if (res.data.message == "Asset added successfully") {
      dispatch({ type: "ADD_EMP_ASSET_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_EMP_ASSET_FAILED", payload: error.message });
  }
};

export const updateEmployeeAsset = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_EMP_ASSET_LOADING" });
    let res = await axios.post(masterEmployeeAssetUpdate, obj,{withCredentials:true});
    if (res.data.message == "Asset updated successfully") {
      dispatch({
        type: "UPDATE_EMP_ASSET_SUCCESS",
        payload: res.data.data,
      });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPDATE_EMP_ASSET_FAILED", payload: error.message });
  }
};

export const deleteEmployeeAsset =
  (idArray, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_EMP_ASSET_LOADING" });
      let res = await axios.post(masterEmployeeAssetDelete, idArray,{withCredentials:true});
      if (res.data.message == "Asset deleted successfully") {
        dispatch({ type: "DELETE_EMP_ASSET_SUCCESS", payload: idArray });
        handleClose();
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_EMP_ASSET_FAILED", payload: error.message });
    }
  };
