const initialState = {
    loading: false,
    error: "",
    fbpData: [],
  };
  export const prFBPReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case "GET_PR_FBP_LOADING":
      case "ADD_PR_FBP_LOADING":
      case "UPDATE_PR_FBP_LOADING":
      case "DELETE_PR_FBP_LOADING":
        return { ...state, loading: true };
  
      case "GET_PR_FBP_SUCCESS":
        return { ...state, loading: false, fbpData: payload };
      case "ADD_PR_FBP_SUCCESS":
        return {
          ...state,
          loading: false,
          fbpData: [...state.fbpData, payload],
        };
      case "UPDATE_PR_FBP_SUCCESS":
        return {
          ...state,
          loading: false,
          fbpData: state.fbpData.map((item) =>
            item.id === payload.id ? payload : item
          ),
        };
      case "DELETE_PR_FBP_SUCCESS": {
        const idsToDelete = payload.idarray;
        return {
          ...state,
          loading: false,
          fbpData: state.fbpData.filter((item) => !idsToDelete.includes(item.id)),
        };
      }
  
      case "ADD_PR_FBP_FAILED":
      case "UPDATE_PR_FBP_FAILED":
      case "GET_PR_FBP_FAILED":
      case "DELETE_PR_FBP_FAILED":
        return { ...state, loading: false, error: payload };
  
      default:
        return state;
    }
  };
  