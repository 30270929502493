const initialState = {
  birthday_loading: false,
  birthday_error: "",
  birthdayData: [],
};
export const summaryBirthdayReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_SUMM_BTH_LOADING":
    case "ADD_SUMM_BTH_LOADING":
    case "UPDATE_SUMM_BTH_LOADING":
    case "DELETE_SUMM_BTH_LOADING":
      return { ...state, loading: true };

    case "GET_SUMM_BTH_SUCCESS": {
      //  console.log(payload,"pl");
      return { ...state, loading: false, birthdayData: payload };
    }

    case "ADD_SUMM_BTH_SUCCESS":
      return {
        ...state,
        loading: false,
        birthdayData: [...state.birthdayData, payload],
      };

    case "UPDATE_SUMM_BTH_SUCCESS":
      return {
        ...state,
        loading: false,
        birthdayData: state.birthdayData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case "DELETE_SUMM_BTH_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        birthdayData: state.birthdayData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_SUMM_BTH_FAILED":
    case "UPDATE_SUMM_BTH_FAILED":
    case "GET_SUMM_BTH_FAILED":
    case "DELETE_SUMM_BTH_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
