import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Tooltip,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { HiDocumentCheck } from "react-icons/hi2";
import { IoDocumentAttachOutline } from "react-icons/io5";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import Cookies from "js-cookie";

import {
  formatDate,
  formatDateTime,
  formatTime,
  getColorForStatus,
  getOnboardStatus,
} from "../../../../Constants/dateFormat";

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 2,
  width: "75%",
  height: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "hidden",
  "&::-webkit-scrollbar": {
    width: "3px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "teal",
  },
};

const contentStyle = {
  height: "100%",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  "&::-webkit-scrollbar": {
    width: "3px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "teal",
  },
};

const boxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1rem",
};

const ViewDetails = ({ open, handleClose, rowId }) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let username = userData.username ? userData.username : "";

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    id: rowId,
    fullname: "",
    position: "",
    phoneno: "",
    contactno: "",
    email: "",
    currentlocation: "",
    company: "",
    workexperience: "",
    currentctc: "",
    expectedctc: "",
    linkedin: "",
    additionalinfo: "",
    cv: "",
    jd: "",
    companyemail: "",
    candidateid: "",
    status: "",
    history: [],
    statushistory: [],
    remarkshistory: [],
    remarks: "",
    companyid: "",
    recruitmentid: "",
    createdAt: "",
    updatedAt: "",
  });

  const [docData, setDocData] = useState({});

  const fetchData = async (rowId) => {
    try {
      setLoading(true);
      const res = await axios.post(
        "https://hrms.vliv.app/jobapplication/getbyid",
        { id: rowId },{withCredentials:true}
      );
      if (res.data?.data.length) {
        setData((prev) => ({
          ...prev,
          ...res.data.data[0],
        }));
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const fetchDocdata = async () => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/jobapplication/getbycandidate",
        { companyemail, companyid, candidateid: data.candidateid },{withCredentials:true}
      );
      if (res.data && res.data.data) {
        setDocData(res.data.data);
      } else {
        setDocData({});
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (data.candidateid) {
      fetchDocdata();
    }
  }, [data.candidateid]);

  useEffect(() => {
    fetchData(rowId);
  }, [rowId]);

  const reversedHistory = data.history ? [...data.history].reverse() : [];

  // const onBoardreversedHistory = data.onboardstatushistory
  //   ? [...data.onboardstatushistory].reverse()
  //   : [];

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Box sx={contentStyle}>
          <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
            {/* <Typography variant="h6" className="primarycolor">
              {data.candidateid}
            </Typography> */}
            <Typography
              color={"#000A14"}
              fontSize={"1.3rem"}
              fontWeight={"500"}
              textAlign={"center"}
            >
              {data.position}
            </Typography>
            {/* {data.recruitmentid && (
              <Typography variant="h6" className="primarycolor">
                RID-{data.recruitmentid}
              </Typography>
            )}
            {
              data.onboardstatushistory && data.onboardstatushistory.length>0 && (
                <Tooltip title={formatDateTime(data.onboardstatushistory[0].date)} placement="top-end" sx={{fontSize:"large"}}>
                <Button sx={{color:getColorForStatus(data.onboardstatushistory[0].status)}}>
                  {data.onboardstatushistory[0].status}
                </Button>
                </Tooltip>
              )
            } */}
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Box sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2 }}>
            <Grid container display={"flex"} gap={3} flexWrap={"wrap"}>
              <Grid className="view-left" item sm={10} xs={10} md={5} lg={5}>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Candidate ID{" "}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.candidateid || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">Name</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.fullname || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">Email</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.email || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Applied For
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.position || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Phone Number
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.phoneno || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Emeregency Number
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.contactno || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Current Location
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.currentlocation || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">UAN</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {docData.uan || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">PAN</Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ ...boxStyle, justifyContent: "flex-start" }}
                  >
                    <Typography className="view-page-value">
                      {docData.panno || ""}
                    </Typography>
                    <Typography className="view-page-value">
                      {docData.pan ? (
                        <a
                          href={docData.pan}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <HiDocumentCheck
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">Aadhar</Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ ...boxStyle, justifyContent: "flex-start" }}
                  >
                    <Typography className="view-page-value">
                      {docData.aadharno || ""}
                    </Typography>
                    <Typography className="view-page-value">
                      {docData.aadhar ? (
                        <a
                          href={
                            docData.bankstatement.startsWith("http")
                              ? docData.bankstatement
                              : `https://${docData.bankstatement}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <HiDocumentCheck
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Bank Account No
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ ...boxStyle, justifyContent: "flex-start" }}
                  >
                    <Typography className="view-page-value">
                      {docData.accountno || ""}
                    </Typography>
                    <Typography className="view-page-value">
                      {docData.bankstatement ? (
                        <a
                          href={
                            docData.bankstatement.startsWith("http")
                              ? docData.bankstatement
                              : `https://${docData.bankstatement}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <HiDocumentCheck
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="view-right" item sm={10} xs={10} md={5} lg={5}>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Experience
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.workexperience || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Last Company
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.company || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Current CTC in LPA
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.currentctc || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Expected CTC in LPA
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.expectedctc || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      LinkedIn
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.linkedin ? (
                        <a
                          href={data.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedInIcon
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">Resume</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.cv ? (
                        <a
                          href={data.cv}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IoDocumentAttachOutline
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">JD</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.jd ? (
                        <a
                          href={data.jd}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <HiDocumentCheck
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">10th</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {docData.tenmarksheet ? (
                        <a
                          href={docData.tenmarksheet}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <HiDocumentCheck
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">10(+2)</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {docData.twelvemarksheet ? (
                        <a
                          href={docData.twelvemarksheet}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <HiDocumentCheck
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">UG</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {docData.degreemarksheet ? (
                        <a
                          href={docData.degreemarksheet}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <HiDocumentCheck
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">PG</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    {docData.mastermarksheet ? (
                      <a
                        href={docData.mastermarksheet}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <HiDocumentCheck
                          className="primarycolor"
                          style={{ fontSize: "20px" }}
                        />
                      </a>
                    ) : (
                      "-"
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Box>
              <Box display={"flex"} alignItems={"center"} gap={2}>
                {reversedHistory.length > 0 && (
                  <Typography
                    // className="primarycolor"
                    style={{ fontSize: "large", margin: "5px 0px" }}
                  >
                    Interview Details
                  </Typography>
                )}

                {/*  */}
              </Box>
              <Grid container gap={1}>
                {reversedHistory?.map((interview, ind) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3.9}
                    key={ind}
                    px={2}
                    className="application-status-box"
                  >
                    <Box
                      display={"flex"}
                      // alignItems={"center"}
                      // justifyContent={"space-between"}
                      flexDirection={"column"}
                      my={1}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography className="view-page-value">
                          {interview.interviewname}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {interview.offerletterstatus ? (
                            <Button
                              sx={{
                                color: getColorForStatus(
                                  interview.offerletterstatus
                                ),
                                textTransform: "capitalize",
                              }}
                            >
                              {interview.offerletterstatus}
                            </Button>
                          ) : (
                            <Button
                              sx={{
                                color: getColorForStatus(
                                  interview.interviewstatus
                                ),
                                textTransform: "capitalize",
                              }}
                            >
                              {interview.interviewstatus}
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Grid container display="flex" mb={0.5}>
                      <Grid item md={6}>
                        <Typography className="view-page-title">
                          Type
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography className="view-page-value">
                          {interview.interviewtype}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" mb={0.5}>
                      <Grid item md={6}>
                        <Typography className="view-page-title">
                          Interviewer
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography className="view-page-value">
                          {interview.interviewername || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" mb={0.5}>
                      <Grid item md={6}>
                        <Typography className="view-page-title">
                          Date & Time
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography className="view-page-value">
                          {interview.scheduleddate
                            ? formatDateTime(interview.scheduleddate)
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" mb={0.5}>
                      <Grid item md={6}>
                        <Typography className="view-page-title">
                          Remarks
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography className="view-page-value">
                          {interview.remarks || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box>
              <Box display={"flex"} alignItems={"center"} gap={2}>
                {data.onboardstatushistory.length > 0 && (
                  <Typography
                    // className="primarycolor"
                    style={{ fontSize: "large", margin: "5px 0px" }}
                  >
                    Onboarding Details
                  </Typography>
                )}

                {/*  */}
              </Box>
              <Grid container gap={1}>
                {data.onboardstatushistory?.map((onboard, ind) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3.9}
                    key={ind}
                    px={2}
                    className="application-status-box"
                  >
                    <Box
                      display={"flex"}
                      // alignItems={"center"}
                      // justifyContent={"space-between"}
                      flexDirection={"column"}
                      my={1}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography className="view-page-value">
                          {formatDateTime(onboard.date)}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Button
                            sx={{
                              color: getColorForStatus(onboard.status),
                              textTransform: "capitalize",
                            }}
                          >
                            {getOnboardStatus(onboard.status)}
                          </Button>
                        </Box>
                      </Box>
                    </Box>

                    <Grid container display="flex" mb={0.5}>
                      <Grid item md={6}>
                        <Typography className="view-page-title">
                          Remarks
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography className="view-page-value">
                          {onboard.remarks || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewDetails;
