import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  editEventApi,
  departmentMasterApi,
  vlivApi,
} from "../../../../Constants/api";
import AlertPopUp from "../../../../Constants/AlertPopUp";

const EditEvents = ({
  editDialogOpen,
  editHandleDialogClose,
  editEventData,
  setEditEventData,
  previousdata,
}) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";

  const [allDepartment, setAllDepartment] = useState([]);

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditEventData({
      ...editEventData,
      [name]: value,
    });
  };

  const handleDeptChange = (event, newValue) => {
    setEditEventData({ ...editEventData, participant: newValue });
  };

  const handleSave = async () => {
    // console.log(previousdata,editEventData)
    try {
      let res = await axios.post(editEventApi, {
        previousdata,
        currentdata: editEventData,
      },{withCredentials:true});
      // console.log(res, "response");
      if (res.data.message) {
        setAlertType("success");
        setOpen(true);
        setMsg("Event Updated ");
        setTimeout(() => {
          editHandleDialogClose();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
      setTimeout(() => {
        editHandleDialogClose();
      }, 1000);
    }
  };

  const getDepartments = async () => {
    try {
      let res = await axios.post(`${vlivApi}/user/dropdown`, {
        companyid,
        companyemail,
      },{withCredentials:true});

      if (res.data) {
        let temp = res.data.department;
        setAllDepartment(temp);
      } else {
        setAllDepartment([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  // console.log(editEventData,"edit eventdata")

  return (
    <>
      <Dialog open={editDialogOpen} onClose={editHandleDialogClose}>
        <DialogTitle>
          {" "}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Edit Event</Typography>
            <IconButton onClick={editHandleDialogClose}>
              <CancelIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <Box className="drawer-form-container">
          <DialogContent>
            <TextField
              margin="dense"
              label="Event Name"
              type="text"
              fullWidth
              name="eventname"
              value={editEventData.eventname}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              label="Start Date"
              type="date"
              fullWidth
              name="startdate"
              value={editEventData.startdate}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ min: new Date().toISOString().split('T')[0] }}
            />
            <TextField
              margin="dense"
              label="End Date"
              type="date"
              fullWidth
              name="enddate"
              value={editEventData.enddate}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ min: new Date().toISOString().split('T')[0] }}
            />

            <FormControl fullWidth sx={{ margin: "10px 0" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start Time"
                  value={
                    editEventData.starttime
                      ? dayjs(editEventData.starttime, "hh:mm A")
                      : null
                  }
                  name="starttime"
                  onChange={(time) =>
                    handleInputChange({
                      target: {
                        name: "starttime",
                        value: time ? time.format("hh:mm A") : "",
                      },
                    })
                  }
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth sx={{ margin: "10px 0" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End Time"
                  value={
                    editEventData.endtime
                      ? dayjs(editEventData.endtime, "hh:mm A")
                      : null
                  }
                  name="endtime"
                  onChange={(time) =>
                    handleInputChange({
                      target: {
                        name: "endtime",
                        value: time ? time.format("hh:mm A") : "",
                      },
                    })
                  }
                />
              </LocalizationProvider>
            </FormControl>
            <TextField
              margin="dense"
              label="Location"
              type="text"
              fullWidth
              name="location"
              value={editEventData.location}
              onChange={handleInputChange}
            />
            <Autocomplete
              multiple
              freeSolo
              options={allDepartment.filter(
                (department) => !editEventData.participant.includes(department)
              )}
              value={editEventData.participant}
              onChange={handleDeptChange}
              renderTags={(value, eventProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...eventProps({ index })}
                    // style={{
                    //   backgroundColor: getChipColor(option),
                    //   color: "#fff",
                    // }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  margin="dense"
                  {...params}
                  variant="outlined"
                  label="Select Departments"
                  placeholder="Select Departments"
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={editHandleDialogClose}
              className="cancelBtn"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={handleSave} className="saveBtn">
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default EditEvents;
