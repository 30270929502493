const initialState = {
  announcement_loading: false,
  announcement_error: "",
  announcementData: [],
};

export const summaryAnnouncementReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_SUMM_ANCMNT_LOADING":
    case "ADD_SUMM_ANCMNT_LOADING":
    case "UPDATE_SUMM_ANCMNT_LOADING":
    case "DELETE_SUMM_ANCMNT_LOADING":
      return { ...state, announcement_loading: true };

    case "GET_SUMM_ANCMNT_SUCCESS": {
      //  console.log(payload,"pl");
      return {
        ...state,
        announcement_loading: false,
        announcementData: payload,
      };
    }

    case "ADD_SUMM_ANCMNT_SUCCESS":
      return {
        ...state,
        announcement_loading: false,
        announcementData: [...state.announcementData, payload],
      };

    case "UPDATE_SUMM_ANCMNT_SUCCESS":
      return {
        ...state,
        announcement_loading: false,
        announcementData: state.announcementData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case "DELETE_SUMM_ANCMNT_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        announcement_loading: false,
        announcementData: state.announcementData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_SUMM_ANCMNT_FAILED":
    case "UPDATE_SUMM_ANCMNT_FAILED":
    case "GET_SUMM_ANCMNT_FAILED":
    case "DELETE_SUMM_ANCMNT_FAILED":
      return {
        ...state,
        announcement_loading: false,
        announcement_error: payload,
      };

    default:
      return state;
  }
};
