import React from 'react'
import ListLeaveType from './ListLeaveType'

const LeaveType = () => {
  return (
    <>
    <ListLeaveType/>
    </>
  )
}

export default LeaveType