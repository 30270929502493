import {
    Box,
    Button,
    Drawer,
    Grid,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    IconButton,
    Divider,
    InputAdornment,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import CancelIcon from "@mui/icons-material/Cancel";
  import AlertPopUp from "../../../../Constants/AlertPopUp";
  import { allowanceObjects } from "../../../../Constants/constants";
  import axios from "axios";
  
  const EditAllowance = ({ editDrawerOpen, editData, handleEditDrawerClose, isSmallScreen,setEditData }) => {
    const [showEmptyError, setShowEmptyError] = useState(false);
    // const [data, setData] = useState({
    //   name: "",
    //   type: "Percentage",
    //   basic: "",
    //   houserent: "",
    //   special: "",
    //   education: "",
    //   medical: "",
    //   insurance: "",
    //   internet: "",
    //   vehicle: "",
    //   travel: "",
    //   conveyance: "",
    //   otherallowance: "",
    //   pfemployee: "",
    //   pfemployer: "",
    //   esicemployee: "",
    //   esicemployer: "",
    //   ptax: "",
    //   tds: "",
    //   otherdeduction: "",
    // });
  
    const [loading, setLoading] = useState(false);
  
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const [alertType, setAlertType] = useState("success");
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };
  
    const earningsFields = [
      "basic",
      "houserent",
      "special",
      "education",
      "medical",
      "insurance",
      "internet",
      "vehicle",
      "travel",
      "conveyance",
      "otherallowance",
    ];
  
    const deductionsFields = [
      "pfemployee",
      "pfemployer",
      "esicemployee",
      "esicemployer",
      "ptax",
      "tds",
      "otherdeduction",
    ];
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setEditData({ ...editData, [name]: value });
    };
  
    const handleSave = async () => {
      // Check if any mandatory fields are empty
      if (!editData.name || !editData.type) {
        setAlertType("error");
        setOpen(true);
        setMsg("You have empty fields");
        setShowEmptyError(true);
        return;
      }
  
      // Check if type is 'Percentage' and validate the sum
      if (editData.type === "Percentage") {
        const earningsSum = earningsFields.reduce(
          (acc, field) => acc + Number(editData[field] || 0),
          0
        );
        const deductionsSum = deductionsFields.reduce(
          (acc, field) => acc + Number(editData[field] || 0),
          0
        );
        const totalSum = earningsSum + deductionsSum;
  
        if (totalSum !== 100) {
          setAlertType("error");
          setOpen(true);
          setMsg("The sum of all percentage fields should be exactly 100%");
          return;
        }
      }
  
      // If all validations pass
      // console.log(data, "data save structure");
      try {
        let res = await axios.post(
          "https://hrms.vliv.app/payroll/config/update",
          editData,
          { withCredentials: true }
        );
        // console.log(res,"update response");
        if (res.data.message == "Updated successfully") {
          setAlertType("success");
          setOpen(true);
          setMsg("Payroll Structure edited");
          setTimeout(() => {
            handleEditDrawerClose();
          }, 1000);
        }
      } catch (error) {
        console.log(error, "payroll structure edit error");
        setAlertType("error");
        setOpen(true);
        setMsg(error.response.data.message);
      }
  
      // You can continue with further processing, such as sending the data to an API.
    };
  
   
  
    const getAdornment = () => {
      return editData && editData.type === "Amount" ? "₹" : "%";
    };
  
    return (
      <>
        <Drawer anchor="right" open={editDrawerOpen} onClose={handleEditDrawerClose}>
          <Box
            width={isSmallScreen ? "90vw" : 600}
            display="flex"
            flexDirection="column"
            height="100vh"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              px={2}
              py={1}
            >
              <Typography variant="h6">Edit payroll structure</Typography>
              <IconButton onClick={handleEditDrawerClose}>
                <CancelIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box
              px={2}
              py={1}
              className="drawer-form-container"
              flex={1}
              display="flex"
              flexDirection="column"
              overflow="auto"
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Structure Name"
                    name="name"
                    value={editData && editData.name  }
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    error={showEmptyError && !editData && !editData.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="structure-type-label">
                      Structure Type
                    </InputLabel>
                    <Select
                      labelId="structure-type-label"
                      name="type"
                      value={ editData && editData.type}
                      onChange={handleChange}
                      label="Structure Type"
                      error={showEmptyError && !editData && !editData.type}
                    >
                      <MenuItem value="Amount">Amount</MenuItem>
                      <MenuItem value="Percentage">Percentage</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
  
                {/* Earnings Fields */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" mb={1}>
                    Earnings
                  </Typography>
                  {earningsFields.map((field) => (
                    <TextField
                      label={allowanceObjects[field] || field}
                      name={field}
                      type="number"
                      value={ editData && editData[field]}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {getAdornment()}
                          </InputAdornment>
                        ),
                      }}
                      sx={{ mb: 2 }}
                    />
                  ))}
                </Grid>
  
                {/* Deductions Fields */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" mb={1}>
                    Deductions
                  </Typography>
                  {deductionsFields.map((field) => (
                    <TextField
                      label={allowanceObjects[field] || field}
                      name={field}
                      type="number"
                      value={editData && editData[field]}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {getAdornment()}
                          </InputAdornment>
                        ),
                      }}
                      sx={{ mb: 2 }}
                    />
                  ))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="flex-end"
                  gap={2}
                  flexWrap="wrap"
                >
                  <Button
                    onClick={handleEditDrawerClose}
                    className="cancelBtn"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button className="saveBtn" onClick={handleSave}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Drawer>
        <AlertPopUp
          open={open}
          msg={msg}
          handleClose={handleClose}
          type={alertType}
        />
      </>
    );
  };
  
  export default EditAllowance;
  