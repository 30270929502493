import axios from "axios";
import {
  hrmsAttendanceLeaveReport,
  hrmsAttendanceLeaveReportByID,
} from "../../../Constants/api";

export const getAttLeaveReport = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_ATT_LEAVE_RPT_LOADING" });
    let res = await axios.post(hrmsAttendanceLeaveReport, obj,{withCredentials:true});

    if (res.data) {
      dispatch({ type: "GET_ATT_LEAVE_RPT_SUCCESS", payload: res.data });
    } else {
      // console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_ATT_LEAVE_RPT_FAILED", payload: error.message });
  }
};
export const getAttLeaveReportByID = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_ATT_LEAVE_RPT_BY_ID_LOADING" });
    let res = await axios.post(hrmsAttendanceLeaveReportByID, obj,{withCredentials:true});

    if (res.data) {
      dispatch({ type: "GET_ATT_LEAVE_RPT_BY_ID_SUCCESS", payload: res.data });
    } else {
      // console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: "GET_ATT_LEAVE_RPT_BY_ID_FAILED",
      payload: error.message,
    });
  }
};
