import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import CancelIcon from "@mui/icons-material/Cancel";
import { addEmployeeAsset } from "../../../../redux/actions/actions.master/empAssetGroup.actions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["HR", "Admin"];
const assets = ["Laptop", "Data Card"];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddAsset = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const theme = useTheme();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    groupname: "",
    users: [],
    asset: [],
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSave = () => {
    // console.log("data to be save", data);
    dispatch(addEmployeeAsset(data));
    setData({
      groupname: "",
      users: [],
      asset: [],
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
    setOpenDrawer(false);
  };
  return (
    <>
      <Button
        className="saveBtn"
        variant="contained"
        onClick={() => setOpenDrawer(true)}
      >
        Add Group
      </Button>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box width={700} display="flex" flexDirection="column" height="100%">
          <Box p={2} flexGrow={1} overflow="auto">
            <Typography
              variant="h6"
              color={"#062C41"}
              display={"flex"}
              fontWeight={600}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={1}
            >
              Add Employee Assets
              <span>
                <IconButton
                  onClick={() => {
                    setData({
                      groupname: "",
                      users: [],
                      asset: [],
                      companyid: companyInfo.companyid,
                      companyemail: companyInfo.companyemail,
                    });
                    setOpenDrawer(false);
                  }}
                >
                  <CancelIcon sx={{ color: "black" }} />
                </IconButton>
              </span>
            </Typography>
            <Divider />
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Group Name</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="Enter Group Name"
                  value={data.groupname}
                  onChange={handleChange}
                  name="groupname"
                  error={!data.groupname && showEmptyError}
                  helperText={
                    !data.groupname &&
                    showEmptyError &&
                    "Group Name is required"
                  }
                />
              </Grid>
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>User</h4>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    className="formInputs"
                    inputProps={{
                      className: "selectInput",
                    }}
                    multiple
                    name="users"
                    value={data.users}
                    onChange={handleChange}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    error={data.users.length == 0 && showEmptyError}
                  >
                    {names.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, data.users, theme)}
                      >
                        <Checkbox checked={data.users.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Asset Type</h4>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    className="formInputs"
                    inputProps={{
                      className: "selectInput",
                    }}
                    multiple
                    name="asset"
                    value={data.asset}
                    onChange={handleChange}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    error={data.asset.length == 0 && showEmptyError}
                  >
                    {assets.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, data.asset, theme)}
                      >
                        <Checkbox checked={data.asset.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box
            mt="auto"
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            bgcolor={"background.paper"}
            p={2}
            display={"flex"}
            flexDirection={"row-reverse"}
          >
            <Button
              className="saveBtn"
              variant="contained"
              onClick={() => {
                if (
                  !data.groupname ||
                  data.users.length == 0 ||
                  data.asset.length == 0
                ) {
                  setShowEmptyError(true);
                  return;
                } else {
                  handleSave();
                }
              }}
            >
              Save
            </Button>
            <Button
              className="cancelBtn"
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setData({
                  groupname: "",
                  users: [],
                  asset: [],
                  companyid: companyInfo.companyid,
                  companyemail: companyInfo.companyemail,
                });
                setOpenDrawer(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AddAsset;
