import React from "react";
import ListLeavingReason from "./ListLeavingReason";

const LeavingReason = () => {
  return (
    <>
      <ListLeavingReason />
    </>
  );
};

export default LeavingReason;
