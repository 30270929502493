import axios from "axios";
import { getJobApplicationsApi } from "../../Constants/api";

export const interviewTypes = {
    GET_SHORTLISTED_JOBAPPLICATION_LOADING: "GET_SHORTLISTED_JOBAPPLICATION_LOADING",
    GET_SHORTLISTED_JOBAPPLICATION_SUCCESS: "GET_SHORTLISTED_JOBAPPLICATION_SUCCESS",
    GET_SHORTLISTED_JOBAPPLICATION_ERROR: "GET_SHORTLISTED_JOBAPPLICATION_ERROR",
  
    // ADD_JOBAPPLICATION_LOADING: "ADD_JOBAPPLICATION_LOADING",
    // ADD_JOBAPPLICATION_SUCCESS: "ADD_JOBAPPLICATION_SUCCESS",
    // ADD_JOBAPPLICATION_ERROR: "ADD_JOBAPPLICATION_ERROR",
  
    // EDIT_JOBAPPLICATION_LOADING: "EDIT_JOBAPPLICATION_LOADING",
    // EDIT_JOBAPPLICATION_SUCCESS: "EDIT_JOBAPPLICATION_SUCCESS",
    // EDIT_JOBAPPLICATION_ERROR: "EDIT_JOBAPPLICATION_ERROR",
  
    // DELETE_JOBAPPLICATION_LOADING: "DELETE_JOBAPPLICATION_LOADING",
    // DELETE_JOBAPPLICATION_SUCCESS: "DELETE_JOBAPPLICATION_SUCCESS",
    // DELETE_JOBAPPLICATION_ERROR: "DELETE_JOBAPPLICATION_ERROR",
  
    // STATUSCHANGE_JOBAPPLICATION_LOADING: "STATUSCHANGE_JOBAPPLICATION_LOADING",
    // STATUSCHANGE_JOBAPPLICATION_SUCCESS: "STATUSCHANGE_JOBAPPLICATION_SUCCESS",
    // STATUSCHANGE_JOBAPPLICATION_ERROR: "STATUSCHANGE_JOBAPPLICATION_ERROR",
  };


 //action for get
 export const getShortlistedApplications = (obj,setOpen,setMsg,setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: interviewTypes.GET_SHORTLISTED_JOBAPPLICATION_LOADING });
      let res = await axios.post(getJobApplicationsApi, obj,{withCredentials:true});
      // console.log(res,"response")
      if (res.data.data) {
        const filtered=res.data.data.filter((item)=>item.status=="Shortlist");
        dispatch({
          type: interviewTypes.GET_SHORTLISTED_JOBAPPLICATION_SUCCESS,
          payload: filtered,
        });
      } else {
        console.log(res, "eroor res");
      }
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something Went Wrong..")
      dispatch({
        type: interviewTypes.GET_SHORTLISTED_JOBAPPLICATION_ERROR,
        payload: error.message,
      });
    }
  };