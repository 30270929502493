import axios from "axios";
import {
  hrmsEmployeeRegistrationAdd,
  hrmsEmployeeRegistrationDelete,
  hrmsEmployeeRegistrationGet,
  hrmsEmployeeRegistrationUpdate,
} from "../../../Constants/api";

export const getEmpReg = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_EMP_REG_LOADING" });
    let res = await axios.post(hrmsEmployeeRegistrationGet, obj,{withCredentials:true});
    // console.log(res,"sbhsbhh")
    if (res.data) {
      dispatch({ type: "GET_EMP_REG_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_EMP_REG_FAILED", payload: error.message });
  }
};

export const addEmpReg =
  (obj, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: "ADD_EMP_REG_LOADING" });
      let res = await axios.post(hrmsEmployeeRegistrationAdd, obj,{withCredentials:true});
      // console.log("add res of emp", res);
      if (res.data.message == "Employee added successfully") {
        dispatch({ type: "ADD_EMP_REG_SUCCESS", payload: res.data.data });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "ADD_EMP_REG_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
    }
  };

export const updateEmpReg =
  (
    obj,
    setOpen,
    setMsg,
    setAlertType,
    setEditableAST,
    setEditableEC,
    setEditableDV,
    setEditablePA,
    setEditablePD,
    setEditableED,
    setEditableBnf,
    setEditableAprsl,
    setEditableRC,
    setEditableBond,
    setEditableNDA,
    setEditablePAN,
    setEditableADH,
    setEditablePP,
    setEditableEXPC,
    setEditablePS,
    setEditableBankS
  ) =>
    async (dispatch) => {
      try {
        dispatch({ type: "UPDATE_EMP_REG_LOADING" });
        let res = await axios.post(hrmsEmployeeRegistrationUpdate, obj,{withCredentials:true});
        // console.log(res.data,"update res")
        if (res.data.message == "Employee updated successfully") {
          dispatch({ type: "UPDATE_EMP_REG_SUCCESS", payload: res.data.data });
          setAlertType("success");
          setOpen(true);
          setMsg(res.data.message);
          setEditableAST(false);
          setEditableDV(false);
          setEditableEC(false);
          setEditablePA(false);
          setEditablePD(false);
          setEditableED(false);
          setEditableBnf(false);
          setEditableAprsl(false);
          setEditableRC(false);
          setEditableBond(false);
          setEditableNDA(false);
          setEditablePAN(false);
          setEditableADH(false);
          setEditablePP(false);
          setEditableEXPC(false);
          setEditablePS(false);
          setEditableBankS(false);

        } else {
          // console.log(res.response.data.message, "to get 400 error")
          setAlertType("error");
          setOpen(true);
          setMsg(res.data.message);


        }
      } catch (error) {
        // console.log(error.response.data.message,"to get 400 error" );
        dispatch({ type: "UPDATE_EMP_REG_FAILED", payload: error.message });
        setAlertType("error");
        setOpen(true);
        setMsg(error.response.data.message || "Something went wrong");

        // To make open condition the edit state
        setEditableAST(true);
        setEditableDV(true);
        setEditableEC(true);
        setEditablePA(true);
        setEditablePD(true);
        setEditableED(true);
        setEditableBnf(true);
        setEditableAprsl(true);
        setEditableRC(true);
        setEditableBond(true);
        setEditableNDA(true);
        setEditablePAN(true);
        setEditableADH(true);
        setEditablePP(true);
        setEditableEXPC(true);
        setEditablePS(true);
        setEditableBankS(true);
      }
    };

export const deleteEmpReg =
  (idArray, handleClose, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_EMP_REG_LOADING" });
      let res = await axios.post(hrmsEmployeeRegistrationDelete, idArray,{withCredentials:true});
      if (res.data.message == "Employee deleted successfully") {
        dispatch({ type: "DELETE_EMP_REG_SUCCESS", payload: idArray });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
        handleClose();
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_EMP_REG_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };
