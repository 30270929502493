import axios from "axios";
import {
  masterLeaveTypeAdd,
  masterLeaveTypeDelete,
  masterLeaveTypeGet,
  masterLeaveTypeMasterAdd,
  masterLeaveTypeMasterDelete,
  masterLeaveTypeMasterGet,
  masterLeaveTypeMasterUpdate,
  masterLeaveTypeUpdate,
} from "../../../Constants/api";

export const getLeaveType = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_LEAVE_TYPE_LOADING" });
    let res = await axios.post(masterLeaveTypeGet, obj,{withCredentials:true});
    // console.log(res,"get leave type");
    if (res.data) {
      dispatch({ type: "GET_LEAVE_TYPE_SUCCESS", payload: Array.isArray(res.data)?res.data:[] });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_LEAVE_TYPE_FAILED", payload: error.message });
  }
};

export const addLeaveType = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_LEAVE_TYPE_LOADING" });
    let res = await axios.post(masterLeaveTypeAdd, obj,{withCredentials:true});
    // console.log(res,"res leave type")
    if (res.data.message == "Leave type added successfully") {
      dispatch({ type: "ADD_LEAVE_TYPE_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_LEAVE_TYPE_FAILED", payload: error.message });

  }
};

export const updateLeaveType = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_LEAVE_TYPE_LOADING" });
    let res = await axios.post(masterLeaveTypeUpdate, obj,{withCredentials:true});
    if (res.data.message == "Leave type updated successfully") {
      dispatch({
        type: "UPDATE_LEAVE_TYPE_SUCCESS",
        payload: res.data.data,
      });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPDATE_LEAVE_TYPE_FAILED", payload: error.message });
  }
};

export const deleteLeaveType = (idArray, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_LEAVE_TYPE_LOADING" });
    let res = await axios.post(masterLeaveTypeDelete, idArray,{withCredentials:true});
    if (res.data.message == "Leave type deleted successfully") {
      dispatch({ type: "DELETE_LEAVE_TYPE_SUCCESS", payload: idArray });
      handleClose();
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "DELETE_LEAVE_TYPE_FAILED", payload: error.message });
  }
};

//Type Master
export const getType = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_TYPE_LOADING" });
    let res = await axios.post(masterLeaveTypeMasterGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_TYPE_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_TYPE_FAILED", payload: error.message });
  }
};

export const addType = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_TYPE_LOADING" });
    let res = await axios.post(masterLeaveTypeMasterAdd, obj,{withCredentials:true});
    if (res.data.message == "Leave type master added successfully") {
      dispatch({ type: "ADD_TYPE_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_TYPE_FAILED", payload: error.message });
  }
};

export const updateType = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_TYPE_LOADING" });
    let res = await axios.post(masterLeaveTypeMasterUpdate, obj,{withCredentials:true});
    if (res.data.message == "Leave type master updated successfully") {
      dispatch({
        type: "UPDATE_TYPE_SUCCESS",
        payload: res.data.data,
      });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPDATE_TYPE_FAILED", payload: error.message });
  }
};

export const deleteType = (idArray, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_TYPE_LOADING" });
    let res = await axios.post(masterLeaveTypeMasterDelete, idArray,{withCredentials:true});
    if (res.data.message == "Leave type master deleted successfully") {
      dispatch({ type: "DELETE_TYPE_SUCCESS", payload: idArray });
      handleClose();
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "DELETE_TYPE_FAILED", payload: error.message });
  }
};
