const valState = {
  loading: false,
  error: "",
  valueData: [],
};
const catState = {
  cloading: false,
  cerror: "",
  catData: [],
};
const subCatState = {
  sloading: false,
  serror: "",
  subCatData: [],
};
export const valueReducer = (state = valState, { type, payload }) => {
  switch (type) {
    case "GET_GEN_VALUE_LOADING":
    case "ADD_GEN_VALUE_LOADING":
    case "DELETE_GEN_VALUE_LOADING":
      return { ...state, loading: true };

    case "GET_GEN_VALUE_SUCCESS":
      return { ...state, loading: false, valueData: payload };
    case "ADD_GEN_VALUE_SUCCESS":
      return {
        ...state,
        loading: false,
        valueData: [...state.valueData, payload],
      };

    case "DELETE_GEN_VALUE_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        valueData: state.valueData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_GEN_VALUE_FAILED":
    case "GET_GEN_VALUE_FAILED":
    case "DELETE_GEN_VALUE_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

export const valueCatReducer = (state = catState, { type, payload }) => {
  switch (type) {
    case "GET_VALUE_CAT_LOADING":
    case "ADD_VALUE_CAT_LOADING":
    case "DELETE_VALUE_CAT_LOADING":
      return { ...state, cloading: true };

    case "GET_VALUE_CAT_SUCCESS":
      return { ...state, cloading: false, catData: payload };
    case "ADD_VALUE_CAT_SUCCESS":
      return {
        ...state,
        cloading: false,
        catData: [...state.catData, payload],
      };

    case "DELETE_VALUE_CAT_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        cloading: false,
        catData: state.catData.filter((item) => !idsToDelete.includes(item.id)),
      };
    }

    case "ADD_VALUE_CAT_FAILED":
    case "GET_VALUE_CAT_FAILED":
    case "DELETE_VALUE_CAT_FAILED":
      return { ...state, cloading: false, cerror: payload };

    default:
      return state;
  }
};

export const valueSubCatReducer = (state = subCatState, { type, payload }) => {
  switch (type) {
    case "GET_VALUE_SUBCAT_LOADING":
    case "ADD_VALUE_SUBCAT_LOADING":
    case "DELETE_VALUE_SUBCAT_LOADING":
      return { ...state, sloading: true };

    case "GET_VALUE_SUBCAT_SUCCESS":
      return { ...state, sloading: false, subCatData: payload };
    case "ADD_VALUE_SUBCAT_SUCCESS":
      return {
        ...state,
        sloading: false,
        subCatData: [...state.subCatData, payload],
      };

    case "DELETE_VALUE_SUBCAT_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        sloading: false,
        subCatData: state.subCatData.filter(
          (item) => !idsToDelete.includes(item.id)
        ),
      };
    }

    case "ADD_VALUE_SUBCAT_FAILED":
    case "GET_VALUE_SUBCAT_FAILED":
    case "DELETE_VALUE_SUBCAT_FAILED":
      return { ...state, sloading: false, serror: payload };

    default:
      return state;
  }
};
