import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import LeaveList from "./LeaveList/LeaveList";
import LeaveApprove from "./LeaveApprove/LeaveApprove";

const Leave = () => {
  const [tabvalue, setTabValue] = useState("leavelist");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      <Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabvalue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Leave List"
                  value="leavelist"
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="Leave Approval"
                  value="leaveapproval"
                  sx={{ textTransform: "none" }}
                />
              </TabList>
            </Box>
            <TabPanel value="leavelist" sx={{ padding: 1 }}>
              <Box sx={{ height: "auto", width: "100%" }}>
                <LeaveList />
              </Box>
            </TabPanel>
            <TabPanel value="leaveapproval" sx={{ padding: 1 }}>
              <Box sx={{ height: "auto", width: "100%" }}>
                <LeaveApprove />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};

export default Leave;
