import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import afternoonImage from "../../../../../assets/welcome_afternoon.png";
import morningImage from "../../../../../assets/welcome_morning.png";
import nightImage from "../../../../../assets/welcome_night.png";
import { BsBank2, BsDatabase } from "react-icons/bs";
import { TbH2, TbUserShield } from "react-icons/tb";
import { HiUserGroup } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { MdDoubleArrow } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IoSaveOutline } from "react-icons/io5";
import { IoIosTimer } from "react-icons/io";
import { GiPartyPopper } from "react-icons/gi";
import { BsCake2 } from "react-icons/bs";
import { TbClockX } from "react-icons/tb";
import { TbClockShield } from "react-icons/tb";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { PiDatabaseLight } from "react-icons/pi";
import { FaPersonCircleCheck } from "react-icons/fa6";
import { GiAnticlockwiseRotation } from "react-icons/gi";
import { FaArrowTrendUp } from "react-icons/fa6";
import axios from "axios";
import { getEvents, getPayrollInputsApi } from "../../../../../Constants/api";
import "../../Welcome/welcome.css";
import { useDispatch, useSelector } from "react-redux";
import { getEmpReg } from "../../../../../redux/actions/actions.hrms/empRegistration.actions";
import { formatDate } from "../../../../../Constants/dateFormat";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { LiaUserClockSolid } from "react-icons/lia";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import {
  addSummaryBirthday,
  deleteSummaryBirthday,
  getSummaryBirthday,
  updateSummaryBirthday,
} from "../../../../../redux/actions/actions.hrms/summaryBirthday.actions";
import Loader from "../../../../../Constants/Loader";
import { truncateText } from "../../../../../Constants/constants";
// import { HiUserGroup } from "react-icons/hi2";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const SalaryMonthYearSelector = ({
  salaryMonth,
  setSalaryMonth,
  salaryYear,
  setSalaryYear,
}) => {
  const handleMonthChange = (direction) => {
    const currentIndex = months.indexOf(salaryMonth);
    let newIndex = currentIndex + direction;
    let newYear = salaryYear;

    if (newIndex < 0) {
      newIndex = 11;
      newYear -= 1;
    } else if (newIndex > 11) {
      newIndex = 0;
      newYear += 1;
    }

    setSalaryMonth(months[newIndex]);
    setSalaryYear(newYear);
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Button onClick={() => handleMonthChange(-1)}>{"<"}</Button>
      <Typography>{`${salaryMonth} ${salaryYear}`}</Typography>
      <Button onClick={() => handleMonthChange(1)}>{">"}</Button>
    </Box>
  );
};

const LoanMonthYearSelector = ({
  loanMonth,
  setLoanMonth,
  loanYear,
  setLoanYear,
}) => {
  const handleMonthChange = (direction) => {
    const currentIndex = months.indexOf(loanMonth);
    let newIndex = currentIndex + direction;
    let newYear = loanYear;

    if (newIndex < 0) {
      newIndex = 11;
      newYear -= 1;
    } else if (newIndex > 11) {
      newIndex = 0;
      newYear += 1;
    }

    setLoanMonth(months[newIndex]);
    setLoanYear(newYear);
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Button onClick={() => handleMonthChange(-1)}>{"<"}</Button>
      <Typography>{`${loanMonth} ${loanYear}`}</Typography>
      <Button onClick={() => handleMonthChange(1)}>{">"}</Button>
    </Box>
  );
};

const MISWelcome = () => {
  const navigate = useNavigate();
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let userid = userData.userid ? userData.userid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let userRole = userData.userrole ? userData.userrole : "";
  let username = userData.username ? userData.username : "";
  const dispatch = useDispatch();
  const [presentEmployeeCount, setPresentEmployeeCount] = useState(0);
  const { empData } = useSelector((state) => state.empRegistrationReducer);
  const { birthdayData, birthday_error, birthday_loading } = useSelector(
    (state) => state.summaryBirthdayReducer
  );
  //for success alert
  const [tab, setTab] = useState("Reports");

  const [events, setEvents] = useState([]);
  const [commentID, setCommentID] = useState("");
  const [comments, setComments] = useState({});
  const [cmnt, setCmnt] = useState("");
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const getBackgroundImageAndMessage = () => {
    const currentHour = new Date().getHours();
    let backgroundImage, greetingMessage;
    if (currentHour >= 12 && currentHour < 18) {
      // Afternoon image and message
      backgroundImage = afternoonImage;
      greetingMessage = "Good Afternoon";
    } else if (currentHour >= 18) {
      // Night image and message
      backgroundImage = nightImage;
      greetingMessage = "Good Evening";
    } else {
      // Morning image and message
      backgroundImage = morningImage;
      greetingMessage = "Good Morning";
    }
    return { backgroundImage, greetingMessage };
  };

  const [backgroundImage, setBackgroundImage] = useState(() => {
    const { backgroundImage } = getBackgroundImageAndMessage();
    return backgroundImage;
  });
  const [greetingMessage, setGreetingMessage] = useState(() => {
    const { greetingMessage } = getBackgroundImageAndMessage();
    return greetingMessage;
  });

  const [salaryMonth, setSalaryMonth] = useState(months[new Date().getMonth()]);
  const [salaryYear, setSalaryYear] = useState(new Date().getFullYear());
  const [paidSalary, setPaidSalary] = useState(0);
  const [holdSalary, setHoldSalary] = useState(0);

  const [loanMonth, setLoanMonth] = useState(months[new Date().getMonth()]);
  const [loanYear, setLoanYear] = useState(new Date().getFullYear());

  // console.log(loanMonth, "loan month");
  // console.log(salaryMonth, "salary month");

  const [boxes, setBoxes] = useState([
    {
      name: "Total Employee",
      value: 0.0,
    },
    {
      name: "Total Manager",
      value: 0.0,
    },
    {
      name: "New Joiners",
      value: 0.0,
    },
    {
      name: "Total Left",
      value: 0.0,
    },
  ]);

  const [loan, setLoan] = useState([
    {
      name: "Total Loan",
      value: 200,
    },
    {
      name: "Loan Paid",
      value: 12,
    },
    {
      name: "Loan Pending",
      value: 8,
    },
  ]);

  const handleCommentChange = (e, empId) => {
    const { value } = e.target;
    setComments({ ...comments, [empId]: value });
  };

  const handleAddComments = (id, name) => {
    dispatch(
      addSummaryBirthday(
        {
          empid: id,
          empname: name,
          message: comments[id],
          userid: userid,
          username: username,
          companyid: companyid,
          companyemail: companyemail,
        },
        setOpen,
        setMsg,
        setAlertType
      )
    );
    setComments({ ...comments, [id]: "" });
  };
  const handleOpenComment = (id) => {
    // console.log(id, "comment");
    setCommentID(id);
    if (commentID === id) {
      // If the same comment is clicked again, close the editor
      setCommentID("");
    } else {
      setCommentID(id);
    }
  };
  const handleCommentEdit = () => {
    const selectedData = birthdayData.find(
      (birthday) => birthday.id == commentID
    );
    dispatch(updateSummaryBirthday({ ...selectedData, message: cmnt }));
    setCmnt("");
    setCommentID("");
  };
  const handleDeleteComment = (id) => {
    dispatch(deleteSummaryBirthday({ idarray: [id] }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const { backgroundImage, greetingMessage } =
        getBackgroundImageAndMessage();
      setBackgroundImage(backgroundImage);
      setGreetingMessage(greetingMessage);
    }, 1000 * 60 * 60);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    //Api call for Total employee
    axios
      .post(`https://hrms.vliv.app/employee/get`, { companyemail, companyid },{withCredentials:true})
      .then((res) => {
        console.log(res.data, "employe");
        // For new joiners
        axios
          .post(`https://hrms.vliv.app/employee/newemployeecount`, {
            companyemail,
            companyid,
          },{withCredentials:true})
          .then((newjoinRes) => {
            // console.log(newjoinRes.data.count, "new join response")
            // For total leaved
            axios
              .post(`https://hrms.vliv.app/exit/get`, {
                companyemail,
                companyid,
              },{withCredentials:true})
              .then((leavedRes) => {
                // console.log(leavedRes.data.length, "leaved res")
                // For total managers
                axios
                  .post(`https://hrms.vliv.app/employee/managercount`, {
                    companyemail,
                    companyid,
                  },{withCredentials:true})
                  .then((managersRes) => {
                    // console.log(managersRes.data.count, "managers Res")
                    setBoxes([
                      {
                        name: "Total Employee",
                        value: +res.data.length,
                      },
                      {
                        name: "Total Manager",
                        value: +managersRes.data.count,
                      },
                      {
                        name: "New Joiners",
                        value: +newjoinRes.data.count,
                      },
                      {
                        name: "Total Left",
                        value: +(leavedRes.data.length || "0"),
                      },
                    ]);
                  });
              });
          });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    dispatch(
      getEmpReg({
        companyid,
        companyemail,
      })
    );
    dispatch(
      getSummaryBirthday({
        companyid,
        companyemail,
      })
    );
  }, [dispatch]);

  const getPresentData = async () => {
    try {
      let res = await axios.post("https://hrms.vliv.app/atte/presentcount", {
        companyid,
        companyemail,
        date: formatDate(new Date()),
      },{withCredentials:true});
      if (res.data && res.data.data && typeof res.data.data=="number") {
        setPresentEmployeeCount(res.data.data);
      } else {
        setPresentEmployeeCount(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEvent = async () => {
    let reqBody = { companyid, companyemail };

    try {
      // setLoading(true);
      let res = await axios.post(getEvents, reqBody,{withCredentials:true});
      res.data && res.data.length > 0 ? setEvents(res.data) : setEvents([]);
      // setLoading(false);
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error While Fetching Events");
      // setLoading(false);
    }
  };

  const getPayrollData = async () => {
    try {
      // setLoading(true);
      let res = await axios.post(getPayrollInputsApi, {
        companyemail,
        companyid,
        month: salaryMonth,
        year: `${salaryYear}`,
      },{withCredentials:true});
      if (res.data && res.data.length > 0) {
        let totalHold = res.data
          .filter((ele) => ele.holdstatus == "hold")
          .reduce((acc, curr) => acc + Number(curr.totalgross || 0), 0);

        let totalPaid = res.data
          .filter((ele) => ele.status == "Paid")
          .reduce((acc, curr) => acc + Number(curr.totalgross || 0), 0);

        setHoldSalary(totalHold.toFixed(2));
        setPaidSalary(totalPaid.toFixed(2));
      } else {
        setHoldSalary(0);
        setPaidSalary(0);
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg("Error while getting payroll inputs");
      // setLoading(false);
    }
  };

  useEffect(() => {
    getPresentData();
    getEvent();
  }, []);

  useEffect(() => {
    getPayrollData();
  }, [salaryMonth, salaryYear]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log(boxes, "boxes");
  return (
    <>
      <Box>
        <Grid container>
          <Grid item lg={8} md={8} sm={12} xs={12} p={1}>
            <Box
              className="welcome-banner"
              style={{ backgroundImage: `url(${backgroundImage})` }}
              marginBottom={1}
            >
              <Box className="welcome-text2">
                <h1>{greetingMessage}</h1>
                <p>Howdy,{username}</p>
              </Box>
            </Box>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid md={5.95} sm={12} xs={12}>
                <Box borderRadius={3} p={1} className="bg-white">
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    gap={1.5}
                    mb={0.7}
                  >
                    <HiUserGroup fontSize={"larger"} />
                    <Typography className="view-page-value fw-b">
                      Employee
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={1}
                    my={1}
                  >
                    <Box
                      sx={{
                        background: "rgba(254, 240, 199, 1)",
                        borderTopRightRadius: "22px",
                        borderTopLeftRadius: "22px",
                      }}
                      display="flex"
                      // alignItems="center"
                      justifyContent="space-between"
                      flexDirection="column"
                      py={1.5}
                      gap={2}
                      flex={1}
                    >
                      <p
                        className="view-page-value fs-large"
                        style={{ textAlign: "center" }}
                      >
                        {boxes[0].name}
                      </p>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        gap={3}
                      >
                        <Box marginTop={1}>
                          <HiOutlineUserGroup
                            className="report-icon"
                            style={{ color: "rgba(244, 221, 154, 1)" }}
                          />
                        </Box>
                        <h1 className="report-value"> {boxes[0].value}</h1>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        background: "rgba(224, 242, 254, 1)",
                        borderTopRightRadius: "22px",
                        borderTopLeftRadius: "22px",
                      }}
                      display="flex"
                      // alignItems="center"
                      justifyContent="space-between"
                      flexDirection="column"
                      py={1.5}
                      gap={2}
                      flex={1}
                    >
                      <p
                        className="view-page-value fs-large"
                        style={{ textAlign: "center" }}
                      >
                        {boxes[1].name}
                      </p>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        gap={3}
                      >
                        <Box marginTop={1}>
                          <TbUserShield
                            className="report-icon"
                            style={{ color: "rgba(190, 228, 254, 1)" }}
                          />
                        </Box>
                        <h1 className="report-value"> {boxes[1].value}</h1>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={1}
                    mb={2}
                  >
                    <Box
                      sx={{
                        background: "rgba(244, 235, 255, 1)",
                        borderBottomLeftRadius: "22px",
                        borderBottomRightRadius: "22px",
                      }}
                      display="flex"
                      // alignItems="center"
                      justifyContent="space-between"
                      flexDirection="column"
                      py={1.5}
                      gap={2}
                      flex={1}
                    >
                      <p
                        className="view-page-value fs-large"
                        style={{ textAlign: "center" }}
                      >
                        {boxes[2].name}
                      </p>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        gap={3}
                      >
                        <Box marginTop={1}>
                          <MdDoubleArrow
                            className="report-icon"
                            style={{ color: "rgba(234, 217, 255, 1)" }}
                          />
                        </Box>
                        <h1 className="report-value"> {boxes[2].value}</h1>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        background: "rgba(254, 228, 226, 1)",
                        borderBottomLeftRadius: "22px",
                        borderBottomRightRadius: "22px",
                      }}
                      display="flex"
                      // alignItems="center"
                      justifyContent="space-between"
                      flexDirection="column"
                      py={1.5}
                      gap={2}
                      flex={1}
                    >
                      <p
                        className="view-page-value fs-large"
                        style={{ textAlign: "center" }}
                      >
                        {boxes[3].name}
                      </p>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        gap={3}
                      >
                        <Box marginTop={1}>
                          <TbUserShield
                            className="report-icon"
                            style={{ color: "rgba(254, 205, 202, 1)" }}
                          />
                        </Box>
                        <h1 className="report-value"> {boxes[3].value}</h1>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid md={5.95} sm={12} xs={12}>
                <Box borderRadius={3} p={1} className="bg-white">
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    gap={1.5}
                    mb={0.7}
                  >
                    <LiaUserClockSolid fontSize={"larger"} />
                    <Typography className="view-page-value fw-b">
                      Attendance
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={1}
                    my={1}
                  >
                    <Box
                      sx={{
                        background: "rgba(244, 235, 255, 1)",
                        borderTopRightRadius: "22px",
                        borderTopLeftRadius: "22px",
                      }}
                      display="flex"
                      // alignItems="center"
                      justifyContent="space-between"
                      flexDirection="column"
                      py={1.5}
                      gap={2}
                      flex={1}
                    >
                      <p
                        className="view-page-value fs-large"
                        style={{ textAlign: "center" }}
                      >
                        Total Employee
                      </p>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        gap={3}
                      >
                        <Box marginTop={1}>
                          <HiOutlineUserGroup
                            className="report-icon"
                            style={{ color: "rgba(234, 217, 255, 1)" }}
                          />
                        </Box>
                        <h1 className="report-value">{empData?.length}</h1>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        background: "rgba(209, 250, 223, 1)",
                        borderTopRightRadius: "22px",
                        borderTopLeftRadius: "22px",
                      }}
                      display="flex"
                      // alignItems="center"
                      justifyContent="space-between"
                      flexDirection="column"
                      py={1.5}
                      gap={2}
                      flex={1}
                    >
                      <p
                        className="view-page-value fs-large"
                        style={{ textAlign: "center" }}
                      >
                        Total Present
                      </p>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        gap={3}
                      >
                        <Box marginTop={1}>
                          <TbClockShield
                            className="report-icon"
                            style={{ color: "rgba(115, 252, 162, 0.8)" }}
                          />
                        </Box>
                        <h1 className="report-value">{presentEmployeeCount}</h1>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={1}
                    mb={2}
                  >
                    <Box
                      sx={{
                        background: "rgba(254, 228, 226, 1)",
                        borderBottomLeftRadius: "22px",
                        borderBottomRightRadius: "22px",
                      }}
                      display="flex"
                      // alignItems="center"
                      justifyContent="space-between"
                      flexDirection="column"
                      py={1.5}
                      gap={2}
                      flex={1}
                    >
                      <p
                        className="view-page-value fs-large"
                        style={{ textAlign: "center" }}
                      >
                        Total Absent
                      </p>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        gap={3}
                      >
                        <Box marginTop={1}>
                          <TbClockX
                            className="report-icon"
                            style={{ color: "rgba(254, 205, 202, 1)" }}
                          />
                        </Box>
                        <h1 className="report-value">
                          {empData.length - Number(presentEmployeeCount)}
                        </h1>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        background: "rgba(254, 240, 199, 1)",
                        borderBottomLeftRadius: "22px",
                        borderBottomRightRadius: "22px",
                      }}
                      display="flex"
                      // alignItems="center"
                      justifyContent="space-between"
                      flexDirection="column"
                      py={1.5}
                      gap={2}
                      flex={1}
                    >
                      <p
                        className="view-page-value fs-large"
                        style={{ textAlign: "center" }}
                      >
                        Late Arrival
                      </p>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        gap={3}
                      >
                        <Box marginTop={1}>
                          <RxCounterClockwiseClock
                            className="report-icon"
                            style={{ color: "rgba(244, 221, 154, 1)" }}
                          />
                        </Box>
                        <h1 className="report-value">{"0"}</h1>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid md={12} sm={12} xs={12} my={1}>
                <Box borderRadius={3} p={1} className="bg-white">
                  <Box
                    pl={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={1}
                    pt={1}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={1}
                    >
                      <span>
                        <BsDatabase />
                      </span>
                      <p className="view-page-title">Payroll</p>
                    </Box>
                    <Box
                      pl={1}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      gap={1}
                    >
                      <SalaryMonthYearSelector
                        salaryMonth={salaryMonth}
                        setSalaryMonth={setSalaryMonth}
                        salaryYear={salaryYear}
                        setSalaryYear={setSalaryYear}
                      />
                    </Box>
                  </Box>

                  <Box
                    flexGrow={1}
                    // className="commonShadow"
                    borderRadius={3}
                    sx={{ padding: 2 }}
                  >
                    <Box flex={1} height={"150px"}>
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        gap={1}
                        flexWrap={"wrap"}
                        display={"flex"}
                      >
                        <Grid
                          item
                          px={1}
                          py={2}
                          borderRadius={3}
                          textAlign={"center"}
                          flex={1}
                          className="cursor-pointer"
                          display={"flex"}
                          flexDirection={"column"}
                          alignItems={"center"}
                          justifyContent={"space-around"}
                          gap={3.5}
                          sx={{ backgroundColor: "rgba(244, 235, 255, 1)" }}
                        >
                          <Typography component={"h6"} variant="h6">
                            Total Salary
                          </Typography>
                          <h1 className="report-value">
                            {(Number(holdSalary) + Number(paidSalary)).toFixed(
                              2
                            )}
                          </h1>
                        </Grid>
                        <Grid
                          item
                          px={1}
                          py={2}
                          borderRadius={3}
                          textAlign={"center"}
                          flex={1}
                          className="cursor-pointer"
                          display={"flex"}
                          flexDirection={"column"}
                          alignItems={"center"}
                          justifyContent={"space-around"}
                          gap={3.5}
                          sx={{ backgroundColor: "rgba(209, 250, 223, 1)" }}
                        >
                          <Typography component={"h6"} variant="h6">
                            Hold Salary
                          </Typography>
                          <h1 className="report-value">{holdSalary}</h1>
                        </Grid>
                        <Grid
                          item
                          px={1}
                          py={2}
                          borderRadius={3}
                          textAlign={"center"}
                          flex={1}
                          className="cursor-pointer"
                          display={"flex"}
                          flexDirection={"column"}
                          alignItems={"center"}
                          justifyContent={"space-around"}
                          gap={3.5}
                          sx={{ backgroundColor: "rgba(254, 228, 226, 1)" }}
                        >
                          <Typography component={"h6"} variant="h6">
                            Paid Salary
                          </Typography>
                          <h1 className="report-value">{paidSalary}</h1>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} p={1}>
            {/* For the side bar reports approval etc.. */}

            <Box
              padding={"10px"}
              marginTop={"2px"}
              borderRadius={"15px"}
              display={"flex"}
              justifyContent={"space-between"}
              gap={2}
              className="bg-white"
            >
              <Button
                startIcon={<HiOutlineDocumentReport />}
                className={
                  tab == "Reports" ? "secondnav-active-btn" : "second-nav-btn"
                }
                onClick={() => setTab("Reports")}
              >
                Reports
              </Button>
              <Button
                startIcon={<IoIosTimer />}
                className={
                  tab == "Approval" ? "secondnav-active-btn" : "second-nav-btn"
                }
                onClick={() => setTab("Approval")}
              >
                Approval
              </Button>
              <Button
                startIcon={<GiPartyPopper />}
                className={
                  tab == "Events" ? "secondnav-active-btn" : "second-nav-btn"
                }
                onClick={() => setTab("Events")}
              >
                Events
              </Button>
              <Button
                startIcon={<BsCake2 />}
                className={
                  tab == "Birthday" ? "secondnav-active-btn" : "second-nav-btn"
                }
                onClick={() => setTab("Birthday")}
              >
                Birthday
              </Button>
            </Box>
            <Box
              sx={{ padding: 1, mt: 1, borderRadius: 5 }}
              className="bg-white"
            >
              {/* For reports */}
              {tab == "Reports" && (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                      mb: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BsBank2 style={{ width: "20px", height: "20px" }} /> Bank
                      Reports
                    </Typography>
                    <Button
                      // startIcon={
                      //   <FaDownload style={{ width: "16px", height: "16px" }} />
                      // }
                      sx={{
                        background: "#37668F",
                        "&:hover": {
                          //you want this to be the same as the backgroundColor above
                          backgroundColor: "#37668F",
                        },
                        color: "#ffff",
                        padding: 1.3,
                        borderRadius: 5,
                        fontSize: ".7rem",
                      }}
                      onClick={() => navigate("/hrm/payroll/reports")}
                    >
                      {/* Download{" "}
                      <span
                        style={{
                          fontSize: ".5rem",
                          marginLeft: 4,
                          color: "grey",
                        }}
                      >
                        {" "}
                        PDF
                      </span> */}
                      {`View Details >`}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                      mb: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <PiDatabaseLight
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      Payroll Reports
                    </Typography>
                    <Button
                      // startIcon={
                      //   <FaDownload style={{ width: "16px", height: "16px" }} />
                      // }
                      sx={{
                        background: "#37668F",
                        "&:hover": {
                          //you want this to be the same as the backgroundColor above
                          backgroundColor: "#37668F",
                        },
                        color: "#ffff",
                        padding: 1.3,
                        borderRadius: 5,
                        fontSize: ".7rem",
                      }}
                      onClick={() => navigate("/hrm/payroll/reports")}
                    >
                      {/* Download{" "}
                      <span
                        style={{
                          fontSize: ".5rem",
                          marginLeft: 4,
                          color: "grey",
                        }}
                      >
                        {" "}
                        PDF
                      </span> */}
                      {`View Details >`}
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                      mb: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <PiDatabaseLight
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      ESIC Reports
                    </Typography>
                    <Button
                      // startIcon={
                      //   <FaDownload style={{ width: "16px", height: "16px" }} />
                      // }
                      sx={{
                        background: "#37668F",
                        "&:hover": {
                          //you want this to be the same as the backgroundColor above
                          backgroundColor: "#37668F",
                        },
                        color: "#ffff",
                        padding: 1.3,
                        borderRadius: 5,
                        fontSize: ".7rem",
                      }}
                      onClick={() => navigate("/hrm/payroll/reports")}
                    >
                      {/* Download{" "}
                      <span
                        style={{
                          fontSize: ".5rem",
                          marginLeft: 4,
                          color: "grey",
                        }}
                      >
                        {" "}
                        PDF
                      </span> */}
                      {`View Details >`}
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                      mb: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <PiDatabaseLight
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      EPF Reports
                    </Typography>
                    <Button
                      // startIcon={
                      //   <FaDownload style={{ width: "16px", height: "16px" }} />
                      // }
                      sx={{
                        background: "#37668F",
                        "&:hover": {
                          //you want this to be the same as the backgroundColor above
                          backgroundColor: "#37668F",
                        },
                        color: "#ffff",
                        padding: 1.3,
                        borderRadius: 5,
                        fontSize: ".7rem",
                      }}
                      onClick={() => navigate("/hrm/payroll/reports")}
                    >
                      {/* Download{" "}
                      <span
                        style={{
                          fontSize: ".5rem",
                          marginLeft: 4,
                          color: "grey",
                        }}
                      >
                        {" "}
                        PDF
                      </span> */}
                      {`View Details >`}
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                      mb: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaPersonCircleCheck
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      Attendance Reports
                    </Typography>
                    <Button
                      // startIcon={
                      //   <FaDownload style={{ width: "16px", height: "16px" }}
                      //    />
                      // }
                      sx={{
                        background: "#37668F",
                        "&:hover": {
                          //you want this to be the same as the backgroundColor above
                          backgroundColor: "#37668F",
                        },
                        color: "#ffff",
                        padding: 1.3,
                        borderRadius: 5,
                        fontSize: ".7rem",
                      }}
                      onClick={() =>
                        navigate("/hrm/employeemanagement/timeandattendance")
                      }
                    >
                      {/* Download{" "}
                      <span
                        style={{
                          fontSize: ".5rem",
                          marginLeft: 4,
                          color: "grey",
                        }}
                      >
                        {" "}
                        PDF
                      </span> */}
                      {`View Details >`}
                    </Button>
                  </Box>
                </Box>
              )}

              {/* For Approval */}
              {tab == "Approval" && (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <PiDatabaseLight
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      Pay Roll
                    </Typography>
                    <Button
                      sx={{
                        background: "#37668F",
                        "&:hover": {
                          //you want this to be the same as the backgroundColor above
                          backgroundColor: "#37668F",
                        },
                        color: "#ffff",
                        padding: 1.3,
                        borderRadius: 5,
                        fontSize: ".7rem",
                      }}
                      onClick={() => navigate("/hrm/payroll/payrollinputs")}
                    >
                      {`View Details >`}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <HiUserGroup style={{ width: "20px", height: "20px" }} />{" "}
                      Recruitment
                    </Typography>
                    <Button
                      sx={{
                        background: "#37668F",
                        "&:hover": {
                          //you want this to be the same as the backgroundColor above
                          backgroundColor: "#37668F",
                        },
                        color: "#ffff",
                        padding: 1.3,
                        borderRadius: 5,
                        fontSize: ".7rem",
                      }}
                      onClick={() => navigate("/hrm/recruitmanagement/recruit")}
                    >
                      {`View Details >`}
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <PiDatabaseLight
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      Performance
                    </Typography>
                    <Button
                      sx={{
                        background: "#37668F",
                        "&:hover": {
                          //you want this to be the same as the backgroundColor above
                          backgroundColor: "#37668F",
                        },
                        color: "#ffff",
                        padding: 1.3,
                        borderRadius: 5,
                        fontSize: ".7rem",
                      }}
                      onClick={() => navigate("/hrm/performance/summary")}
                    >
                      {`View Details >`}
                    </Button>
                  </Box>
                </Box>
              )}

              {tab == "Events" && (
                <Box
                  flex={1}
                  maxHeight={"800px"}
                  className="events"
                  sx={{ overflowY: "auto", padding: "10px" }}
                >
                  {events.map((event, index) => (
                    <Box
                      key={index}
                      sx={{
                        marginBottom: "10px",
                        padding: "15px",
                        borderRadius: "8px",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        {event.eventname}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={1}
                      >
                        <Typography variant="body2">
                          <strong>Start:</strong> {event.startdate}{" "}
                          {event.starttime}
                        </Typography>
                        <Typography variant="body2">
                          <strong>End:</strong> {event.enddate} {event.endtime}
                        </Typography>
                      </Box>
                      <Typography variant="body2" mb={1}>
                        <strong>Location:</strong> {event.location}
                      </Typography>
                      <Box mt={1}>
                        {event.participant.map((participant, idx) => (
                          <Chip
                            key={idx}
                            label={participant}
                            sx={{
                              marginRight: "5px",
                              marginBottom: "5px",
                              backgroundColor: "#e0e0e0",
                              color: "#000",
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}

              {tab == "Birthday" && (
                <Box>
                  <Stack>
                    {empData.length > 0 &&
                      empData
                        .filter((emp) => {
                          const dob = new Date(emp.dob);
                          const today = new Date();
                          return (
                            dob.getMonth() === today.getMonth() &&
                            dob.getDate() >= today.getDate()
                          );
                        })
                        .sort((a, b) => {
                          const dayA = new Date(a.dob).getDate();
                          const dayB = new Date(b.dob).getDate();
                          return dayA - dayB;
                        })
                        .map((ele, i) => {
                          const dob = new Date(ele.dob);
                          const today = new Date();
                          const isToday =
                            dob.getDate() === today.getDate() &&
                            dob.getMonth() === today.getMonth();
                          return (
                            <Box
                              boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                              padding={"10px"}
                              key={i}
                              marginBottom={"10px"}
                            >
                              <Typography
                                variant="h5"
                                style={{
                                  fontSize: isToday ? "24px" : "inherit",
                                }}
                              >
                                {isToday
                                  ? `Happy Birthday ${ele.name}`
                                  : `Upcoming Birthday of ${ele.name}`}
                              </Typography>
                              <br />
                              <p>{!isToday && ele.dob}</p>
                              {isToday && (
                                <Box>
                                  <Typography variant="h6">
                                    Send your wishes:
                                  </Typography>
                                  <TextField
                                    className="formInputs"
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Write your message"
                                    margin="normal"
                                    name="message"
                                    value={comments[ele.id] || ""}
                                    onChange={(e) =>
                                      handleCommentChange(e, ele.id)
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            style={{
                                              color: "var(--common-color)",
                                            }}
                                            onClick={() =>
                                              handleAddComments(
                                                ele.id,
                                                ele.name
                                              )
                                            }
                                          >
                                            <SendIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <Accordion defaultExpanded>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel2-content"
                                      id="panel2-header"
                                      sx={{
                                        backgroundColor: "#b2d8d8",
                                        border: "none",
                                        borderRadius: "6px",
                                      }}
                                    >
                                      <Typography>Wishes</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {birthday_loading ? (
                                        <Loader />
                                      ) : (
                                        birthdayData.length > 0 &&
                                        birthdayData
                                          .filter((bth) => bth.empid === ele.id)
                                          .map((comnt) => (
                                            <Typography
                                              key={comnt.id}
                                              display={"flex"}
                                              justifyContent={"space-between"}
                                            >
                                              <Tooltip title={comnt.role} arrow>
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {truncateText(
                                                    comnt.username,
                                                    18
                                                  )}
                                                </span>
                                              </Tooltip>

                                              {commentID === comnt.id ? (
                                                <TextField
                                                  fullWidth
                                                  className="formInputs"
                                                  value={cmnt}
                                                  onChange={(e) =>
                                                    setCmnt(e.target.value)
                                                  }
                                                />
                                              ) : (
                                                <Tooltip
                                                  title={comnt.message}
                                                  arrow
                                                >
                                                  <span>
                                                    {truncateText(
                                                      comnt.message,
                                                      18
                                                    )}
                                                  </span>
                                                </Tooltip>
                                              )}

                                              <span
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  gap: "5px",
                                                }}
                                              >
                                                {commentID === comnt.id &&
                                                comnt.userid === userid ? (
                                                  <IconButton
                                                    onClick={handleCommentEdit}
                                                    color="primary"
                                                  >
                                                    <IoSaveOutline />
                                                  </IconButton>
                                                ) : (
                                                  comnt.userid === userid && (
                                                    <IconButton
                                                      onClick={() =>
                                                        handleOpenComment(
                                                          comnt.id
                                                        )
                                                      }
                                                      color="primary"
                                                    >
                                                      <EditIcon />
                                                    </IconButton>
                                                  )
                                                )}
                                                {comnt.userid === userid && (
                                                  <IconButton
                                                    onClick={() =>
                                                      handleDeleteComment(
                                                        comnt.id
                                                      )
                                                    }
                                                    color="error"
                                                  >
                                                    <DeleteIcon />
                                                  </IconButton>
                                                )}
                                              </span>
                                            </Typography>
                                          ))
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                  </Stack>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default MISWelcome;
