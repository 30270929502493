const initialState = {
  loading: false,
  error: "",
  empData: [],
};
export const empRegistrationReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_EMP_REG_LOADING":
    case "ADD_EMP_REG_LOADING":
    case "UPDATE_EMP_REG_LOADING":
    case "DELETE_EMP_REG_LOADING":
      return { ...state, loading: true };

    case "GET_EMP_REG_SUCCESS": {
      //  console.log(payload,"pl");
      return { ...state, loading: false, empData: payload };
    }

    case "ADD_EMP_REG_SUCCESS":
      return {
        ...state,
        loading: false,
        empData: [...state.empData, payload],
      };

    case "UPDATE_EMP_REG_SUCCESS":
      return {
        ...state,
        loading: false,
        empData: state.empData.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case "DELETE_EMP_REG_SUCCESS": {
      const idsToDelete = payload.idarray;
      return {
        ...state,
        loading: false,
        empData: state.empData.filter((item) => !idsToDelete.includes(item.id)),
      };
    }

    case "ADD_EMP_REG_FAILED":
    case "UPDATE_EMP_REG_FAILED":
    case "GET_EMP_REG_FAILED":
    case "DELETE_EMP_REG_FAILED":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
