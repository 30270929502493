import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Cookies from "js-cookie";
import { addEmpSeries } from "../../../../redux/actions/actions.master/empSeries.action";
import { useDispatch } from "react-redux";

const AddSeries = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [prefix, setPrefix] = useState("");
  const [format, setFormat] = useState("");
  const [suffix, setSuffix] = useState("");
  const [data, setData] = useState({
    seriesname: "",
    seriesno: "",
    employeetype: "",
    status: false,
    format: "",
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (name == "status") {
      setData({ ...data, [name]: e.target.checked });
    }
  };

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      format: `${prefix}${format}${suffix}`,
    }));
  }, [prefix, format, suffix]);

  const handleSave = () => {
    // console.log("data to be save", data);
    dispatch(addEmpSeries(data));
    setData({
      seriesname: "",
      seriesno: "",
      employeetype: "",
      status: "",
      format: "",
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
    setSuffix("");
    setFormat("");
    setPrefix("");
    setOpenDrawer(false);
  };

  // console.log("data", data);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  return (
    <>
      <Button
        className="saveBtn"
        variant="contained"
        onClick={() => setOpenDrawer(true)}
      >
        Add New
      </Button>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box width={700} display="flex" flexDirection="column" height="100%">
          <Box p={2} flexGrow={1} overflow="auto">
            <Typography
              variant="h6"
              color={"#062C41"}
              display={"flex"}
              fontWeight={600}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={1}
            >
              Add Employee Series
              <span>
                <IconButton onClick={() => setOpenDrawer(false)}>
                  <CancelIcon sx={{ color: "black" }} />
                </IconButton>
              </span>
            </Typography>
            <Divider />
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Series Name</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="Enter Series Name"
                  value={data.seriesname}
                  onChange={handleChange}
                  name="seriesname"
                  error={!data.seriesname && showEmptyError}
                  helperText={
                    !data.seriesname &&
                    showEmptyError &&
                    "Series Name is required"
                  }
                />
              </Grid>
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Serial No</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="Enter Serial No"
                  type="number"
                  value={data.seriesno}
                  onChange={handleChange}
                  name="seriesno"
                  error={!data.seriesno && showEmptyError}
                  helperText={
                    !data.seriesno && showEmptyError && "Serial No is required"
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>Employee Type</h4>
                <Select
                  fullWidth
                  className="formInputs"
                  inputProps={{
                    className: "selectInput",
                  }}
                  value={data.employeetype}
                  onChange={handleChange}
                  name="employeetype"
                  error={!data.employeetype && showEmptyError}
                >
                  <MenuItem value="Confirmed">Confirmed</MenuItem>
                  <MenuItem value="Contract">Contract</MenuItem>
                  <MenuItem value="Probation">Probation</MenuItem>
                </Select>
              </Grid>
              <Grid item md={5.9}></Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <span style={{ fontWeight: "bold", marginRight: "15px" }}>
                  Active Status
                </span>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      name="status"
                      color="success"
                      checked={data.status}
                      onChange={handleChange}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                    />
                  }
                  label={
                    data.status ? (
                      <span style={{ fontWeight: "bold", marginLeft: "15px" }}>
                        Enabled
                      </span>
                    ) : (
                      <span style={{ fontWeight: "bold", marginLeft: "15px" }}>
                        Disabled
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item md={5.9}></Grid>
            </Grid>
            <br />
            <h3 style={{ marginBottom: "5px" }}>Employee Series Format</h3>
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={2.9}>
                <h4 style={{ marginBottom: "5px" }}>Prefix</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={prefix}
                  onChange={(e) => setPrefix(e.target.value)}
                />
              </Grid>
              <Grid item md={2.9}>
                <h4 style={{ marginBottom: "5px" }}>Format</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={format}
                  onChange={(e) => setFormat(e.target.value)}
                />
              </Grid>
              <Grid item md={2.9}>
                <h4 style={{ marginBottom: "5px" }}>Suffix</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={suffix}
                  onChange={(e) => setSuffix(e.target.value)}
                />
              </Grid>
              <Grid item md={2.9}>
                <h4 style={{ marginBottom: "5px" }}>Result</h4>
                <TextField
                  disabled
                  fullWidth
                  className="formInputs"
                  placeholder="-"
                  value={data.format}
                  error={!data.format && showEmptyError}
                  helperText={
                    !data.format && showEmptyError && "Prefix+Format+Suffix"
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            mt="auto"
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            bgcolor={"background.paper"}
            p={2}
            display={"flex"}
            flexDirection={"row-reverse"}
          >
            <Button
              className="saveBtn"
              variant="contained"
              onClick={() => {
                if (
                  !data.seriesname ||
                  !data.seriesno ||
                  !data.employeetype ||
                  !data.format
                ) {
                  setShowEmptyError(true);
                  return;
                } else {
                  handleSave();
                }
              }}
            >
              Save
            </Button>
            <Button
              className="cancelBtn"
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setData({
                  seriesname: "",
                  seriesno: "",
                  employeetype: "",
                  status: "",
                  format: "",
                  companyid: companyInfo.companyid,
                  companyemail: companyInfo.companyemail,
                });
                setSuffix("");
                setFormat("");
                setPrefix("");
                setOpenDrawer(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AddSeries;
