import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  formatAttendanceTime,
  parseDateString,
} from "../../../../../Constants/dateFormat";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { calculateTotalHours } from "../../../../../Constants/calculateTotalHrs";
import { attendanceRequestApi } from "../../../../../Constants/api";

dayjs.extend(customParseFormat);

const AttendanceRequest = ({
  openRequestModal,
  setOpenRequestModal,
  empInfo,
  setEmpInfo,
  selectedRow,
  setSelectedRow,
}) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";

  const [data, setData] = useState({
    employeeid: empInfo.empid,
    tableemployeeid:empInfo.id,
    name: empInfo.name,
    date: selectedRow.date,
    day: selectedRow.day,
    month: formatAttendanceTime(parseDateString(selectedRow.date)).month,
    year: formatAttendanceTime(parseDateString(selectedRow.date)).year,
    reportingto: empInfo.reportmanager,
    checkin: "",
    checkout: "",
    totalhours: "",
    status: "",
    reason: "",
    attachment:"",
    requesttype: selectedRow.data.status,
    companyemail,
    companyid,
  });

  const [showEmptyError, setShowEmptyError] = useState(false);

  // For success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  //for file upload
  const handleFileChange = (e) => {
    const files = e.target.files;
    let url = "https://hrms.vliv.app/atte/attendancerequpload";

    if (files.length > 0) {
      handleUploadFile(files, url, e.target.name);
    } else {
      // console.log("choose file");
      setOpen(true);
      setAlertType("error");
      setMsg("Choose a file to upload..");
    }
  };

  const handleUploadFile = async (files, url, name) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const response = await axios.post(url, formData,{withCredentials:true}, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data.file) {
        setData((prev) => ({ ...prev, [name]: response.data.file }));
        setAlertType("success");
        setOpen(true);
        setMsg("Attachment Uploaded..");
      } else {
        setData((prev) => ({ ...prev, [name]: "" }));
        setAlertType("error");
        setOpen(true);
        setMsg("Error in uploading file..");
      }
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error in uploading file..");
      console.error("Error uploading file:", error.message);
    }
  };

  const handleRequest = async () => {
    if (!data.checkin || !data.checkout) {
      setShowEmptyError(true);
      setOpen(true);
      setAlertType("error");
      setMsg("You have empty fields");
      return;
    }

    // Get today's date
    const todayDate = dayjs().format("YYYY-MM-DD");

    // Parse data.date to match the format of todayDate
    const parsedDate = dayjs(data.date, "DD/MM/YYYY").format("YYYY-MM-DD");

    // Check if the selected date is today
    if (parsedDate === todayDate) {
      // Get the current time
      const currentTime = dayjs();

      // Parse the checkout time
      const selectedCheckoutTime = dayjs(data.checkout, "hh:mm A");

      // Check if the checkout time is in the future
      if (selectedCheckoutTime.isAfter(currentTime)) {
        setShowEmptyError(true);
        setOpen(true);
        setAlertType("error");
        setMsg("Future times cannot be marked");
        return;
      }
    }

    try {
      const totalhours = calculateTotalHours(data.checkin, data.checkout);
      const res = await axios.post(attendanceRequestApi, {
        ...data,
        totalhours,
      },{withCredentials:true});

      if (res.data.message === "Attendancereq added successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("Attendance Request Raised");
        setTimeout(() => {
          setOpenRequestModal(false);
          setSelectedRow({});
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
      // setTimeout(() => {
      //   setOpenRequestModal(false);
      //   setSelectedRow({});
      // }, 1000);
    }
  };

  return (
    <>
      <Dialog
        open={openRequestModal}
        onClose={() => {
          setOpenRequestModal(false);
          setSelectedRow({});
        }}
      >
        <DialogTitle>
          {" "}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Attendance Request</Typography>
            <IconButton
              onClick={() => {
                setOpenRequestModal(false);
                setSelectedRow({});
              }}
            >
              <CancelIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <Box className="drawer-form-container">
          <DialogContent>
            <FormControl fullWidth sx={{ margin: "10px 0" }}>
              <TextField fullWidth value={data.date} readOnly label="Date" />
            </FormControl>
            <FormControl fullWidth sx={{ margin: "10px 0" }}>
              <TextField
                fullWidth
                value={data.name}
                name="name"
                readOnly
                label="Employee Name"
              />
            </FormControl>
            <FormControl fullWidth sx={{ margin: "10px 0" }}>
              <TextField
                label="Request Type"
                fullWidth
                value={data.requesttype}
                name="requesttype"
                readOnly
              />
            </FormControl>
            <FormControl fullWidth sx={{ margin: "10px 0" }}>
              <TextField
                fullWidth
                value={data.reportingto}
                readOnly
                label="Report Manager"
              />
            </FormControl>
            <Box>
              <FormControl fullWidth sx={{ margin: "10px 0" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Clock In"
                    value={data.checkin ? dayjs(data.checkin, "hh:mm A") : null}
                    name="checkin"
                    onChange={(time) =>
                      handleEditChange({
                        target: {
                          name: "checkin",
                          value: time ? time.format("hh:mm A") : "",
                        },
                      })
                    }
                    error={!data.checkin && showEmptyError}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth sx={{ margin: "10px 0" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Clock Out"
                    value={
                      data.checkout ? dayjs(data.checkout, "hh:mm A") : null
                    }
                    name="checkout"
                    onChange={(time) =>
                      handleEditChange({
                        target: {
                          name: "checkout",
                          value: time ? time.format("hh:mm A") : "",
                        },
                      })
                    }
                    error={!data.checkout && showEmptyError}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
            <Grid item xs={12} my={2}>
              <TextField
                label="Attach File"
                fullWidth
                type="file"
                InputLabelProps={{
                  shrink: true,
                }}
                name="attachment"
                onChange={handleFileChange}
              />
            </Grid>
            <textarea
              rows={3}
              style={{
                width: "100%",
                borderRadius: "5px",
                padding: "5px ",
                fontSize: "large",
              }}
              placeholder="Describe Reason.."
              value={data.reason}
              name="reason"
              onChange={handleEditChange}
            />
            
          </DialogContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
            mt={2}
          >
            <Button
              onClick={() => {
                setOpenRequestModal(false);
                setSelectedRow({});
              }}
              className="cancelBtn"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={handleRequest} className="saveBtn">
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default AttendanceRequest;
