import { Avatar, Box } from "@mui/material";
import React from "react";

const ImagePlaceHolder = ({ name, size = 100 }) => {
  function getInitials(name) {
    const words = name.split(" ");
    return words.map((word) => word.charAt(0).toUpperCase()).join("");
  }
  const initials = getInitials(name);

  return <Box sx={{display:"flex",alignItems:"center",justifyContent:"start",gap:"1rem"}}>
    <Avatar >{initials}</Avatar>
    <h1 className="heading">{name}</h1>
  </Box>;
};

export default ImagePlaceHolder;
