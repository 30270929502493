import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { GoArrowRight } from "react-icons/go";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImagePlaceHolder from "./ImagePlaceHolder";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DomainIcon from "@mui/icons-material/Domain";
import EastIcon from "@mui/icons-material/East";
import { EmailOutlined, PhoneOutlined } from "@mui/icons-material";
import WestIcon from "@mui/icons-material/West";
import MuiPhoneNumber from "mui-phone-number";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import { addJobApplicationApi } from "../../../../Constants/api";
import { useDispatch, useSelector } from "react-redux";
import { addJobApplication } from "../../../../redux/JobApplication/actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formatDate } from "../../../../Constants/dateFormat";

const JobApplications = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, loading } = useSelector(
    (state) => state.jobApplicationReducer
  );

  const companyid = queryParams.get("key");
  const recruitmentid = queryParams.get("recid");

  const [fileName, setFileName] = useState("");

  const [companyDetails, setCompanyDetails] = useState({});
  const [allRecruitment, setAllRecruitment] = useState([]);
  // const [progressValue, setProgressValue] = useState(50);
  const [tabvalue, setTabValue] = React.useState("");
  const [showEmptyError, setShowEmptyError] = useState(false);

  const [data, setData] = useState({
    fullname: "",
    position: "",
    currentlocation: "",
    company: "",
    workexperience: "",
    workexperiencemonth: "",
    cv: "",
    currentctc: "",
    expectedctc: "",
    linkedin: "",
    additionalinfo: "",
    status: "Applied",
    recruitmentid: "",
    candidateid: "",
    companyemail: "",
    companyid,
  });

  const [formData, setFormData] = useState({
    phoneno: "",
    contactno: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    phoneno: false,
    contactno: false,
    email: false,
  });

  // Regex patterns for validation
  const regexPatterns = {
    phoneno: /^[6-9]\d{9}$/, // Valid Indian mobile number pattern
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Basic email pattern
    contactno: /^[6-9]\d{9}$/,
  };

  const errorMessages = {
    phoneno:
      "Invalid phone number. It should be a 10-digit number starting with 6-9.",
    contactno:
      "Invalid phone number. It should be a 10-digit number starting with 6-9.",
    email:
      "Invalid email address. Please enter a valid email in the format example@domain.com.",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Only validate ESIC if it's not empty (since it's optional)
    if (regexPatterns[name].test(value)) {
      setErrors({
        ...errors,
        [name]: false,
      });
    } else {
      setErrors({
        ...errors,
        [name]: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "position") {
      if (value) {
        const temp = value.split("$");
        setData((prev) => ({
          ...prev,
          recruitmentid: temp[0],
          position: temp[1],
        }));
      } else {
        setData((prev) => ({ ...prev, recruitmentid: "", position: "" }));
      }
    } else if (name === "workexperience" || name === "workexperiencemonth") {
      // Ensure non-negative values for work experience fields
      setData((prev) => ({
        ...prev,
        [name]: value >= 0 ? value : 0,
      }));
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const isFormValid = () => {
    // Check if there are any errors or empty fields
    return (
      Object.values(errors).every((error) => !error) &&
      Object.values(formData).every((field) => field !== "")
    );
  };

  const fileInputRef = useRef();

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    let url = "https://hrms.vliv.app/jobapplication/cvupload";

    if (files.length > 0) {
      handleUploadFile(files, url, e.target.name);
      setFileName(files[0].name);
    } else {
      setOpen(true);
      setAlertType("error");

      setMsg("Choose a file to upload..");
    }
  };

  const handleUploadFile = async (files, url, name) => {
    try {
      // setJdLoading(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const response = await axios.post(url, formData,{withCredentials:true}, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data.file) {
        setData((prev) => ({ ...prev, [name]: response.data.file }));
        setAlertType("success");
        setOpen(true);
        setMsg("CV/Resume Uploaded..");
      } else {
        setData((prev) => ({ ...prev, [name]: "" }));
        setAlertType("error");
        setOpen(true);
        setMsg("Error in uploading file..");
      }

      // setJdLoading(false);
    } catch (error) {
      // setJdLoading(false);
      setAlertType("error");
      setOpen(true);
      setMsg("Error in uploading file..");
      console.error("Error uploading file:", error.message);
      // throw error;
    }
  };

  const handleSave = () => {
    if (
      !data.fullname ||
      !data.position ||
      !data.currentctc ||
      !data.expectedctc ||
      !data.workexperience
    ) {
      setShowEmptyError(true);
      setAlertType("error");
    } else if (!data.cv) {
      setAlertType("error");
      setOpen(true);
      setMsg("CV Needs to Upload");
    } else {
      const obj = {
        ...data,...formData,
        companyemail: companyDetails.clientemail
          ? companyDetails.clientemail
          : "",
      };

      // console.log(obj,"object")
      dispatch(
        addJobApplication(obj, setOpen, setMsg, setAlertType, setData, navigate)
      );
    }
  };

  //for success alert
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    axios
      .post("https://hrms.vliv.app/recruitment/getdata", {
        companyid,
        recruitmentid,
      },{withCredentials:true})
      .then((res) => {
        res.data.data
          ? setAllRecruitment(res.data.data)
          : setAllRecruitment([]);
        res.data.company && res.data.company.length > 0
          ? setCompanyDetails(res.data.company[0])
          : setCompanyDetails({});
      })
      .catch((err) => console.log(err));
  }, [companyid]);

  // Use media query hook to check screen size
  const isLargeScreen = useMediaQuery("(min-width: 1024px)");
  const isMediumScreen = useMediaQuery("(min-width: 768px)");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // Determine width of TabPanel based on screen size
  const tabPanelWidth = isLargeScreen ? "60%" : isMediumScreen ? "80%" : "100%";

  if (message) {
    return (
      <Alert severity="success" sx={{ my: 5 }}>
        <AlertTitle>Success</AlertTitle>
        Thank you for applying,your application have been submitted
        successfully.
      </Alert>
    );
  }

  return (
    <>
      <Box>
        <Box className="jobapplication-bg" py="10px">
          <Box
            mt={1}
            width={companyDetails.companyimage ? "250px" : "auto"}
            height={"80px"}
            display="inline-block"
          >
            {companyDetails.companyimage ? (
              <img
                src={companyDetails.companyimage}
                alt="logo"
                className="jobapplication-logo"
              ></img>
            ) : (
              // <Typography color={"white"} textAlign={"center"}>{companyDetails.company}</Typography>
              <Box
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderTopRightRadius: "24px",
                  borderBottomRightRadius: "24px",
                }}
                width={"auto"}
                p={2}
                color={"white"}
              >
                <ImagePlaceHolder
                  name={companyDetails.company || ""}
                  size={200}
                />
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems="center"
            justifyContent="center"
            gap={2}
            my={1}
          >
            <Typography
              variant={isSmallScreen ? "h5" : "h4"}
              color="white"
              textAlign="center"
            >
              {allRecruitment.length > 0 && allRecruitment[0].positions
                ? `${allRecruitment[0].positions}`
                : ""}
            </Typography>
            {!tabvalue && (
              <Button
                className="cancelBtn"
                variant="outlined"
                endIcon={<GoArrowRight />}
                textAlign="center"
                sx={{
                  borderRadius: "25px",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  },
                }}
                onClick={() => setTabValue("step1")}
              >
                Apply Now
              </Button>
            )}
          </Box>
        </Box>

        {allRecruitment.length > 0 && allRecruitment[0].jdtext && !tabvalue ? (
          <Grid container gap={1} my={2} justifyContent={"center"}>
            <Grid item md={8} sm={12} xs={12} className="common-hover-box">
              <ReactQuill
                value={
                  allRecruitment.length > 0 ? allRecruitment[0].jdtext : ""
                }
                readOnly={true}
                theme="bubble" // Use "bubble" or "snow" theme for read-only content
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              className="common-bg-color common-hover-box"
              maxHeight={"500px"}
              borderRadius={2}
              p={2}
            >
              <Typography variant="h5" mb={2}>
                Job Information
              </Typography>
              <Box my={0.3}>
                <Typography className="view-page-value" my={0.3}>
                  Posted On
                </Typography>
                <Typography className="view-page-title" my={0.3}>
                  {allRecruitment.length > 0 && allRecruitment[0].createdAt
                    ? formatDate(allRecruitment[0].createdAt)
                    : "-"}
                </Typography>
              </Box>
              <Box my={0.3}>
                <Typography className="view-page-value" my={0.3}>
                  Experience
                </Typography>
                <Typography className="view-page-title" my={0.3}>
                  {allRecruitment.length > 0 && allRecruitment[0].experience
                    ? `${allRecruitment[0].experience} Year`
                    : "-"}
                </Typography>
              </Box>
              <Box my={0.3}>
                <Typography className="view-page-value" my={0.3}>
                  Contract Type
                </Typography>
                <Typography className="view-page-title" my={0.3}>
                  {allRecruitment.length > 0 && allRecruitment[0].employeetype
                    ? allRecruitment[0].employeetype
                    : "-"}
                </Typography>
              </Box>
              <Box my={0.3}>
                <Typography className="view-page-value" my={0.3}>
                  Branch
                </Typography>
                <Typography className="view-page-title" my={0.3}>
                  {allRecruitment.length > 0 && allRecruitment[0].branch
                    ? allRecruitment[0].branch
                    : "-"}
                </Typography>
              </Box>
              <Box my={0.3}>
                <Typography className="view-page-value" my={0.3}>
                  Position
                </Typography>
                <Typography className="view-page-title" my={0.3}>
                  {allRecruitment.length > 0 && allRecruitment[0].positions
                    ? allRecruitment[0].positions
                    : "-"}
                </Typography>
              </Box>
              <Box my={0.3}>
                <Typography className="view-page-value" my={0.3}>
                  Budget
                </Typography>
                <Typography className="view-page-title" my={0.3}>
                  {allRecruitment.length > 0 && allRecruitment[0].frombudget
                    ? `${allRecruitment[0].frombudget}-${allRecruitment[0].tobudget} LPA`
                    : "-"}
                </Typography>
              </Box>
              <Box mt={2.3}>
                <Button
                  className="saveBtn"
                  variant="outlined"
                  endIcon={<GoArrowRight />}
                  textAlign="center"
                  sx={{
                    borderRadius: "25px",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  onClick={() => setTabValue("step1")}
                >
                  Apply Now
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box mt={1}>
            <h1
              style={{ textAlign: "center" }}
              className="heading primarycolor"
            >
              Job Application Form
            </h1>
            {/* <LinearProgress
              variant="determinate"
              value={progressValue}
              sx={{ marginY: 2 }}
            /> */}
            {tabvalue == "step1" ||
            !allRecruitment.length ||
            !allRecruitment[0].jdtext ? (
              <Box
                sx={{
                  width: tabPanelWidth, // Set width based on screen size
                  margin: "auto",
                  padding: "0.4rem",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <PersonOutlineIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Full Name</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Your Full Name"
                          variant="outlined"
                          value={data.fullname}
                          name="fullname"
                          onChange={handleChange}
                          error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <WorkOutlineIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Position</p>
                        <TextField
                          fullWidth
                          select
                          // value={data.position}
                          name="position"
                          onChange={handleChange}
                          placeholder="Choose Position"
                          variant="outlined"
                          error={!data.position && showEmptyError}
                        >
                          {allRecruitment?.map((recruit) => (
                            <MenuItem
                              key={recruit.id}
                              value={recruit.id + "$" + recruit.positions}
                            >
                              RID-{recruit.id} {recruit.positions}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <PhoneOutlined sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Phone Number</p>
                        <TextField
                          type="number"
                          fullWidth
                          placeholder="Enter Phone Number"
                          variant="outlined"
                          name="phoneno"
                          value={formData.phoneno}
                          onChange={handleInputChange}
                          error={errors.phoneno}
                          helperText={errors.phoneno && errorMessages.phoneno}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <PhoneOutlined sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Emergency Contact Number</p>
                        <TextField
                          type="number"
                          fullWidth
                          placeholder="Enter Contact Number"
                          variant="outlined"
                          name="contactno"
                          value={formData.contactno}
                          onChange={handleInputChange}
                          error={errors.contactno}
                          helperText={errors.contactno && errorMessages.contactno}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <EmailOutlined sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Email</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Your Mail ID"
                          variant="outlined"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          error={errors.email}
                          helperText={errors.email && errorMessages.email}
              
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <LocationOnIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Current Location</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Your Current Location"
                          variant="outlined"
                          value={data.currentlocation}
                          name="currentlocation"
                          onChange={handleChange}
                          error={!data.currentlocation && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <Button
                        onClick={handleClick}
                        endIcon={<AttachFileIcon />}
                        sx={{ textTransform: "none", color: "#00b0ff" }}
                      >
                        Attach CV/Resume
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        name="cv"
                      />

                      {data.cv && (
                        <span style={{ color: "#00b0ff", marginLeft: "10px" }}>
                          {fileName}
                        </span>
                      )}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Button
                      onClick={() => {
                        // setProgressValue(0);
                        setTabValue("");
                      }}
                      startIcon={<WestIcon />}
                      className="cancelBtn"
                      variant="outlined"
                    >
                      Back
                    </Button>
                    <Button
                      className="saveBtn"
                      endIcon={<EastIcon />}
                      onClick={() => {
                        // setProgressValue(100);
                        setTabValue("step2");
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{
                  width: tabPanelWidth, // Set width based on screen size
                  margin: "auto",
                  padding: "0.4rem",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <DomainIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Company</p>
                        <TextField
                          fullWidth
                          placeholder="Last/Current Company"
                          variant="outlined"
                          value={data.company}
                          name="company"
                          onChange={handleChange}
                          // error={!data.contactno && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <WorkHistoryIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Work Experience</p>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          gap={2}
                        >
                          <TextField
                            type="number"
                            fullWidth
                            placeholder="Years"
                            variant="outlined"
                            value={data.workexperience}
                            name="workexperience"
                            onChange={handleChange}
                            error={!data.workexperience && showEmptyError}
                          />

                          <TextField
                            type="number"
                            fullWidth
                            placeholder="Months"
                            variant="outlined"
                            value={data.workexperiencemonth}
                            name="workexperiencemonth"
                            onChange={handleChange}
                            error={!data.workexperiencemonth && showEmptyError}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <CurrencyRupeeIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Current CTC in LPA</p>
                        <TextField
                          type="number"
                          fullWidth
                          placeholder="Enter your current ctc in LPA"
                          variant="outlined"
                          value={data.currentctc}
                          name="currentctc"
                          onChange={handleChange}
                          error={!data.currentctc && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <CurrencyRupeeIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Expected CTC in LPA</p>
                        <TextField
                          type="number"
                          fullWidth
                          placeholder="Enter your expected ctc in LPA"
                          variant="outlined"
                          value={data.expectedctc}
                          name="expectedctc"
                          onChange={handleChange}
                          error={!data.expectedctc && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <LinkedInIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Linkedin</p>
                        <TextField
                          fullWidth
                          placeholder="Enter linkedin profile url"
                          variant="outlined"
                          value={data.linkedin}
                          name="linkedin"
                          onChange={handleChange}
                          // error={!data.linked && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <NextWeekIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">How did you hear about us</p>
                        <TextField
                          fullWidth
                          select
                          placeholder="Choose Position"
                          variant="outlined"
                          value={data.additionalinfo}
                          name="additionalinfo"
                          onChange={handleChange}
                        >
                          <MenuItem value="Career Page">Career Page</MenuItem>
                          <MenuItem value="From Linkedin">
                            From Linkedin
                          </MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={() => {
                        // setProgressValue(50);
                        setTabValue("step1");
                      }}
                      startIcon={<WestIcon />}
                      className="cancelBtn"
                      variant="outlined"
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6} textAlign={"right"}>
                    <Button className="saveBtn" onClick={handleSave} disabled={!isFormValid()}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default JobApplications;
