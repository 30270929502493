import {
  Box,
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Divider,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import { allowanceObjects } from "../../../../Constants/constants";
import axios from "axios";

const AddAllowance = ({ drawerOpen, handleDrawerClose, isSmallScreen }) => {
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    name: "",
    type: "Percentage",
    basic: "",
    houserent: "",
    special: "",
    education: "",
    medical: "",
    insurance: "",
    internet: "",
    vehicle: "",
    travel: "",
    conveyance: "",
    otherallowance: "",
    pfemployee: "",
    pfemployer: "",
    esicemployee: "",
    esicemployer: "",
    ptax: "",
    tds: "",
    otherdeduction: "",
  });

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const earningsFields = [
    "basic",
    "houserent",
    "special",
    "education",
    "medical",
    "insurance",
    "internet",
    "vehicle",
    "travel",
    "conveyance",
    "otherallowance",
  ];

  const deductionsFields = [
    "pfemployee",
    "pfemployer",
    "esicemployee",
    "esicemployer",
    "ptax",
    "tds",
    "otherdeduction",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSave = async () => {
    // Check if any mandatory fields are empty
    if (!data.name || !data.type) {
      setAlertType("error");
      setOpen(true);
      setMsg("You have empty fields");
      setShowEmptyError(true);
      return;
    }

    // Check if type is 'Percentage' and validate the sum
    if (data.type === "Percentage") {
      const earningsSum = earningsFields.reduce(
        (acc, field) => acc + Number(data[field] || 0),
        0
      );
      const deductionsSum = deductionsFields.reduce(
        (acc, field) => acc + Number(data[field] || 0),
        0
      );
      const totalSum = earningsSum + deductionsSum;

      if (totalSum !== 100) {
        setAlertType("error");
        setOpen(true);
        setMsg("The sum of all percentage fields should be exactly 100%");
        return;
      }
    }

    // If all validations pass
    // console.log(data, "data save structure");
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/payroll/config/add",
        data,
        { withCredentials: true }
      );
      if (res.data.message == "Added successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("New payroll structure added");
        setTimeout(() => {
          drawerClose()
        }, 1000);
      }
    } catch (error) {
      console.log(error, "payroll structure adding error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message);
    }

    // You can continue with further processing, such as sending the data to an API.
  };

  const drawerClose = () => {
    setData({
      name: "",
      type: "Percentage",
      basic: "",
      houserent: "",
      special: "",
      education: "",
      medical: "",
      insurance: "",
      internet: "",
      vehicle: "",
      travel: "",
      conveyance: "",
      otherallowance: "",
      pfemployee: "",
      pfemployer: "",
      esicemployee: "",
      esicemployer: "",
      ptax: "",
      tds: "",
      otherdeduction: "",
    });
    handleDrawerClose();
  };

  const getAdornment = () => {
    return data.type === "Amount" ? "₹" : "%";
  };

  return (
    <>
      <Drawer anchor="right" open={drawerOpen} onClose={drawerClose}>
        <Box
          width={isSmallScreen ? "90vw" : 600}
          display="flex"
          flexDirection="column"
          height="100vh"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            py={1}
          >
            <Typography variant="h6">Add payroll structure</Typography>
            <IconButton onClick={drawerClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box
            px={2}
            py={1}
            className="drawer-form-container"
            flex={1}
            display="flex"
            flexDirection="column"
            overflow="auto"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Structure Name"
                  name="name"
                  value={data.name}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  error={showEmptyError && !data.name}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="structure-type-label">
                    Structure Type
                  </InputLabel>
                  <Select
                    labelId="structure-type-label"
                    name="type"
                    value={data.type}
                    onChange={handleChange}
                    label="Structure Type"
                    error={showEmptyError && !data.type}
                  >
                    <MenuItem value="Amount">Amount</MenuItem>
                    <MenuItem value="Percentage">Percentage</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Earnings Fields */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" mb={1}>
                  Earnings
                </Typography>
                {earningsFields.map((field) => (
                  <TextField
                    label={allowanceObjects[field] || field}
                    name={field}
                    type="number"
                    value={data[field]}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {getAdornment()}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ mb: 2 }}
                  />
                ))}
              </Grid>

              {/* Deductions Fields */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" mb={1}>
                  Deductions
                </Typography>
                {deductionsFields.map((field) => (
                  <TextField
                    label={allowanceObjects[field] || field}
                    name={field}
                    type="number"
                    value={data[field]}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {getAdornment()}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ mb: 2 }}
                  />
                ))}
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                gap={2}
                flexWrap="wrap"
              >
                <Button
                  onClick={drawerClose}
                  className="cancelBtn"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button className="saveBtn" onClick={handleSave}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default AddAllowance;
