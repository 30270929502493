import axios from "axios";
import {
  masterGeneralValueAdd,
  masterGeneralValueCategoryAdd,
  masterGeneralValueCategoryDelete,
  masterGeneralValueCategoryGet,
  masterGeneralValueDelete,
  masterGeneralValueGet,
  masterGeneralValueSubCategoryAdd,
  masterGeneralValueSubCategoryDelete,
  masterGeneralValueSubCategoryGet,
} from "../../../Constants/api";

export const getGeneralValue = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_GEN_VALUE_LOADING" });
    let res = await axios.post(masterGeneralValueGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_GEN_VALUE_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_GEN_VALUE_FAILED", payload: error.message });
  }
};

export const addGeneralValue = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_GEN_VALUE_LOADING" });
    let res = await axios.post(masterGeneralValueAdd, obj,{withCredentials:true});
    // console.log("add res of position", res);
    if (res.data.message == "Value added successfully") {
      dispatch({ type: "ADD_GEN_VALUE_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_GEN_VALUE_FAILED", payload: error.message });
  }
};

export const deleteGeneralValue =
  (idArray, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_GEN_VALUE_LOADING" });
      let res = await axios.post(masterGeneralValueDelete, idArray,{withCredentials:true});
      if (res.data.message == "Value deleted successfully") {
        dispatch({ type: "DELETE_GEN_VALUE_SUCCESS", payload: idArray });
        handleClose();
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_GEN_VALUE_FAILED", payload: error.message });
    }
  };

//Category Master
export const getValueCategory = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_VALUE_CAT_LOADING" });
    let res = await axios.post(masterGeneralValueCategoryGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_VALUE_CAT_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_VALUE_CAT_FAILED", payload: error.message });
  }
};

export const addValueCategory = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_VALUE_CAT_LOADING" });
    let res = await axios.post(masterGeneralValueCategoryAdd, obj,{withCredentials:true});
    if (res.data.message == "Added successfully") {
      dispatch({ type: "ADD_VALUE_CAT_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_VALUE_CAT_FAILED", payload: error.message });
  }
};
export const deleteValueCategory =
  (idArray, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_VALUE_CAT_LOADING" });
      let res = await axios.post(masterGeneralValueCategoryDelete, idArray,{withCredentials:true});
      if (res.data.message == "Deleted successfully") {
        dispatch({ type: "DELETE_VALUE_CAT_SUCCESS", payload: idArray });
        handleClose();
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_VALUE_CAT_FAILED", payload: error.message });
    }
  };

//Sub-Category Master
export const getValueSubCategory = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_VALUE_SUBCAT_LOADING" });
    let res = await axios.post(masterGeneralValueSubCategoryGet, obj,{withCredentials:true});
    if (res.data) {
      dispatch({ type: "GET_VALUE_SUBCAT_SUCCESS", payload: res.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_VALUE_SUBCAT_FAILED", payload: error.message });
  }
};

export const addValueSubCategory = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_VALUE_SUBCAT_LOADING" });
    let res = await axios.post(masterGeneralValueSubCategoryAdd, obj,{withCredentials:true});
    if (res.data.message == "Added successfully") {
      dispatch({ type: "ADD_VALUE_SUBCAT_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "ADD_VALUE_SUBCAT_FAILED", payload: error.message });
  }
};
export const deleteValueSubCategory =
  (idArray, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_VALUE_SUBCAT_LOADING" });
      let res = await axios.post(masterGeneralValueSubCategoryDelete, idArray,{withCredentials:true});
      if (res.data.message == "Deleted successfully") {
        dispatch({ type: "DELETE_VALUE_SUBCAT_SUCCESS", payload: idArray });
        handleClose();
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_VALUE_SUBCAT_FAILED", payload: error.message });
    }
  };
