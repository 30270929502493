import { recruitmentActionTypes } from "./actions";

const initialState = {
  loading: false,
  error: "",
  data: [],
};

export const recruitmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case recruitmentActionTypes.GET_RECRUITMENT_LOADING:
    case recruitmentActionTypes.ADD_RECRUITMENT_LOADING:
    case recruitmentActionTypes.EDIT_RECRUITMENT_LOADING:
    case recruitmentActionTypes.DELETE_RECRUITMENT_LOADING:
    case recruitmentActionTypes.STATUSCHANGE_RECRUITMENT_LOADING:
      return { ...state, loading: true };

    case recruitmentActionTypes.GET_RECRUITMENT_SUCCESS:
      return { ...state, loading: false, data: payload };

    case recruitmentActionTypes.ADD_RECRUITMENT_SUCCESS:
      return { ...state, loading: false, data: [...state.data, payload] };

    case recruitmentActionTypes.EDIT_RECRUITMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.map((recruit) =>
          recruit.id == payload.id ? payload : recruit
        ),
      };

    case recruitmentActionTypes.DELETE_RECRUITMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((recruit) => !payload.includes(recruit.id)),
      };

    case recruitmentActionTypes.STATUSCHANGE_RECRUITMENT_SUCCESS: {
      const lookupForPayload = payload.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      const updatedStatusState = state.data.map((item) =>
        lookupForPayload[item.id]
          ? { ...item, ...lookupForPayload[item.id] }
          : item
      );

      return {
        ...state,
        loading: false,
        data: updatedStatusState,
      };
    }

    case recruitmentActionTypes.GET_RECRUITMENT_ERROR:
    case recruitmentActionTypes.ADD_RECRUITMENT_ERROR:
    case recruitmentActionTypes.EDIT_RECRUITMENT_ERROR:
    case recruitmentActionTypes.DELETE_RECRUITMENT_ERROR:
    case recruitmentActionTypes.STATUSCHANGE_RECRUITMENT_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
