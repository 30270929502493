import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { ImProfile } from "react-icons/im";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  addEmpDirectory,
  getEmpDirectory,
  updateEmpDirectory,
} from "../../../../redux/actions/actions.master/empDirectory.actions";
import Loader from "../../../../Constants/Loader";
import { CiImageOn } from "react-icons/ci";

const EmployeeDirectory = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { directoryData, error, loading } = useSelector(
    (state) => state.empDirectoryReducer
  );

  const [isAdd, setIsAdd] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    type: "",
    field: "",
    status: false,
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  const handleAdd = () => {
    if (!data.type || !data.field) {
      setShowEmptyError(true);
      return;
    }
    dispatch(addEmpDirectory(data));
    setData({
      type: "",
      field: "",
      status: false,
      companyid: companyInfo.companyid,
      companyemail: companyInfo.companyemail,
    });
    setIsAdd(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleStatusChange = (id, currentStatus) => {
    const newStatus = !currentStatus;
    dispatch(updateEmpDirectory({ id, status: newStatus }));
  };

  useEffect(() => {
    dispatch(
      getEmpDirectory({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Container maxWidth="xl">
        <Box
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
          padding={"10px 60px"}
          marginTop={"20px"}
          borderRadius={"15px"}
        >
          <Typography className="colored-heading">
            Configure Employee Directory
          </Typography>
          <Divider />
          <br />
          {isAdd ? (
            <Stack direction="row" gap={2}>
              <FormControl className="formInputs" sx={{ minWidth: 300 }}>
                <Select
                  fullWidth
                  className="formInputs"
                  inputProps={{
                    className: "selectInput",
                  }}
                  value={data.type}
                  onChange={handleChange}
                  name="type"
                  error={!data.type && showEmptyError && "Choose a Type"}
                >
                  <MenuItem value="Contact Details">Contact Details</MenuItem>
                  <MenuItem value="Category">Category</MenuItem>
                  <MenuItem value="Other Information">
                    Other Information
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl className="formInputs" sx={{ minWidth: 300 }}>
                <TextField
                  fullWidth
                  name="field"
                  className="formInputs"
                  placeholder="Enter a field Name"
                  value={data.field}
                  onChange={handleChange}
                  error={
                    !data.type && showEmptyError && "Field name is mandatory"
                  }
                />
              </FormControl>
              <IconButton onClick={handleAdd}>
                <CheckBoxIcon />
              </IconButton>
            </Stack>
          ) : (
            <Button
              className="saveBtn"
              variant="contained"
              onClick={() => setIsAdd(true)}
            >
              Add
            </Button>
          )}
          <br />
          <br />
          <Stack direction="row" spacing={50}>
            <Box>
              <Typography
                fontWeight={"bold"}
                display={"flex"}
                alignItems={"center"}
                gap={2}
              >
                <span>
                  <ContactPageIcon />
                </span>
                Contact Details
              </Typography>
              <FormGroup>
                {loading ? (
                  <Loader />
                ) : (
                  directoryData.length > 0 &&
                  directoryData
                    .filter((ele) => ele.type == "Contact Details")
                    .map((ele) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={ele.status}
                            onClick={() =>
                              handleStatusChange(ele.id, ele.status)
                            }
                          />
                        }
                        label={ele.field}
                        labelPlacement="start"
                      />
                    ))
                )}
              </FormGroup>
              <br />
              <Typography
                fontWeight={"bold"}
                display={"flex"}
                alignItems={"center"}
                gap={2}
              >
                <span>
                  <DashboardIcon />
                </span>
                Category
              </Typography>
              <FormGroup>
                {loading ? (
                  <Loader />
                ) : (
                  directoryData.length > 0 &&
                  directoryData
                    .filter((ele) => ele.type == "Category")
                    .map((ele) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={ele.status}
                            onClick={() =>
                              handleStatusChange(ele.id, ele.status)
                            }
                          />
                        }
                        label={ele.field}
                        labelPlacement="start"
                      />
                    ))
                )}
              </FormGroup>
              <br />
              <Typography
                fontWeight={"bold"}
                display={"flex"}
                alignItems={"center"}
                gap={2}
              >
                <span>
                  <ImProfile />
                </span>
                Other Information
              </Typography>
              <FormGroup>
                {loading ? (
                  <Loader />
                ) : (
                  directoryData.length > 0 &&
                  directoryData
                    .filter((ele) => ele.type == "Other Information")
                    .map((ele) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={ele.status}
                            onClick={() =>
                              handleStatusChange(ele.id, ele.status)
                            }
                          />
                        }
                        label={ele.field}
                        labelPlacement="start"
                      />
                    ))
                )}
              </FormGroup>
            </Box>
            <Box
              borderRadius={"15px"}
              boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
              padding={"20px"}
            >
              <Box width={500} textAlign={"center"}>
                <Box
                  height={100}
                  width={100}
                  border={"1px solid rgb(170,240,209)"}
                  borderRadius={50}
                  margin={"auto"}
                >
                  <IconButton
                    sx={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#E0E0E0",
                    }}
                  >
                    <CiImageOn fontSize={100} />
                  </IconButton>
                </Box>
                <Typography variant="h5">Employee Name</Typography>
                <Typography variant="h6">Employee ID</Typography>
                <Typography>Mail ID</Typography>
                <br />
                <br />
                <hr />
                <Typography variant="h6">Contact Details</Typography>
                <br />
                {loading ? (
                  <Loader />
                ) : (
                  directoryData.length > 0 &&
                  directoryData
                    .filter(
                      (ele) =>
                        ele.type == "Contact Details" && ele.status == true
                    )
                    .map((ele) => (
                      <Typography>
                        {ele.field} -<span>xxxxx-xxxx-xxxx</span>
                      </Typography>
                    ))
                )}
                <br />
                <hr />
                <Typography variant="h6">Category</Typography>
                <br />
                {loading ? (
                  <Loader />
                ) : (
                  directoryData.length > 0 &&
                  directoryData
                    .filter(
                      (ele) => ele.type == "Category" && ele.status == true
                    )
                    .map((ele) => (
                      <Typography>
                        {ele.field} -<span>xxxxx-xxxx-xxxx</span>
                      </Typography>
                    ))
                )}
                <br />
                <hr />
                <Typography variant="h6">Other Information</Typography>
                <br />
                {loading ? (
                  <Loader />
                ) : (
                  directoryData.length > 0 &&
                  directoryData
                    .filter(
                      (ele) =>
                        ele.type == "Other Information" && ele.status == true
                    )
                    .map((ele) => (
                      <Typography>
                        {ele.field} -<span>xxxxx-xxxx-xxxx</span>
                      </Typography>
                    ))
                )}
                <br />
              </Box>
            </Box>
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default EmployeeDirectory;
