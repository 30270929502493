// import * as React from "react";
// import Button from "@mui/material/Button";
// import CssBaseline from "@mui/material/CssBaseline";
// import TextField from "@mui/material/TextField";
// import Link from "@mui/material/Link";
// import Paper from "@mui/material/Paper";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import LoginLeftImage from "../../assets/LoginLeftImage.png";
// import venturesathi from "../../assets/venturesathi-logo.png";
// import venturesathi_white from "../../assets/vsathiWhitelogo.png";
// import { useState } from "react";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";
// import moment from "moment-timezone";
// import { json, useNavigate } from "react-router-dom";
// import {
//   Alert,
//   Dialog,
//   IconButton,
//   InputAdornment,
//   MenuItem,
//   OutlinedInput,
//   Select,
// } from "@mui/material";
// import { Country, State, City } from "country-state-city";
// import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
// import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
// import axios from "axios";
// import Cookies from "js-cookie";

// import AlertPopUp from "../../Constants/AlertPopUp";
// import { LoginApi } from "../../Constants/api";

// const allCountries = Country.getAllCountries();

// const defaultTheme = createTheme({
//   typography: {
//     fontFamily: ["Kollektif", "sans-serif"].join(","),
//   },
// });

// function SignIn() {
//   // const login = useSelector((state) => state.loginReducer);
//   const isProduction = process.env.NODE_ENV === 'production';
//   const navigate = useNavigate();
//   // const dispatch = useDispatch();

//   const [showPassword, setShowPassword] = useState(false);
//   const [data, setData] = useState({
//     email: "",
//     password: "",
//   });

//   const [showEmptyError, setShowEmptyError] = useState(false);
//   const [ispwdMissMatch, setIspwdMissMatch] = useState(false);

//   //for success alert
//   const [open, setOpen] = React.useState(false);
//   const [msg, setMsg] = useState("");
//   const [alertType, setAlertType] = useState("success");

//   const handleClose = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }

//     setOpen(false);
//   };

//   const handleClickShowPassword = () => setShowPassword((show) => !show);
//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     setData({ ...data, [name]: value });
//   };

//   const handleSubmit = async () => {
//     //  dispatch(userLogin(data, navigate));
//     if (!data.email) {
//       setShowEmptyError(true);
//       setAlertType("error");
//       setOpen(true);
//       setMsg("You have empty fields");
//     } else {
//       try {
//         let res = await axios.post(`${LoginApi}/user/loginuser`, data);
//         console.log(res, "response");
//         if (res.data.message == "Login Successful") {
//           Cookies.set("companytoken", res.data.token, {
//             domain: isProduction ? '.vliv.app' : 'localhost',
//             path: '/',
//             secure: isProduction,
//             sameSite: isProduction ? 'None' : 'Lax'
//           });
//           const {
//             password,
//             confirmpassword,
//             yearlypremium,
//             yearlystandard,
//             yearlybasic,
//             premium,
//             standard,
//             basic,
//             ...userinfo
//           } = res.data.data;
//           Cookies.set("userinfo", JSON.stringify(userinfo), {
//             domain: isProduction ? '.vliv.app' : 'localhost',
//             path: '/',
//             secure: isProduction,
//             sameSite: isProduction ? 'None' : 'Lax'
//           });

//           const cookieData = Cookies.get("userinfo");
//           let userData = cookieData ? JSON.parse(cookieData) : {};
//           let role = userData.userrole ? userData.userrole : "";
//           if (role == "User") {
//             setAlertType("success");
//             setOpen(true);
//             setMsg(res.data.message);
//             setTimeout(() => {
//               navigate("/hrm/employeeselfservice/summary");
//             }, 1000);
//           } else {
//             setAlertType("success");
//             setOpen(true);
//             setMsg(res.data.message);
//             setTimeout(() => {
//               navigate("/hrm/summary/welcome");
//             }, 1000);
//           }
//         } else {
//           setAlertType("error");
//           setOpen(true);
//           setMsg(res.data.message);
//         }
//       } catch (error) {
//         console.log(error);
//         setAlertType("error");
//         setOpen(true);
//         setMsg(error.response.data.message || "Something went wrong");
//       }
//     }
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Grid container component="main" sx={{ height: "100vh" }}>
//         <CssBaseline />
//         <Grid
//           item
//           xs={false}
//           sm={false}
//           md={6}
//           display={"flex"}
//           alignItems={"center"}
//           justifyContent={"center"}
//           sx={{
//             backgroundImage: `url(${LoginLeftImage})`,
//             backgroundRepeat: "no-repeat",
//             backgroundColor: (t) =>
//               t.palette.mode === "light"
//                 ? t.palette.grey[50]
//                 : t.palette.grey[900],
//             backgroundSize: "cover",
//             backgroundPosition: "top",
//           }}
//         >
//           <Box>
//             <img
//               src={venturesathi_white}
//               alt="vliv_logo"
//               width={"30%"}
//               height={"20%"}
//               style={{ textAlign: "center" }}
//             />
//             <Typography
//               component="h5"
//               variant="h6"
//               fontWeight={"300"}
//               color={"white"}
//             >
//               Sign in into your account
//             </Typography>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={6}
//
//           // elevation={6}
//           square
//         >
//           <Box
//             sx={{
//               my: 2,
//               mx: 4,
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             {/* <Box sx={{width:"100%" }}>
//               <img
//                 src={venturesathi}
//                 alt="Venturesathi Logo"
//                 style={{ width: "15%", height: "15%", marginBottom: 20 }}
//               />
//             </Box> */}
//             <Box>
//               <Typography
//                 className="colored-heading"
//                 sx={{ textAlign: "center", marginTop: 10 }}
//               >
//                 HR Management System
//               </Typography>
//               <Typography
//                 className="heading"
//                 sx={{ textAlign: "center", marginY: 1 }}
//               >
//                 Welcome
//               </Typography>
//               <Typography
//                 className="span-color"
//                 sx={{ textAlign: "center", marginY: 1, fontSize: "larger" }}
//               >
//                 Please Signin with Your Official Account
//               </Typography>
//             </Box>
//             <TextField
//               className="loginfields"
//               margin="normal"
//               required
//               fullWidth
//               id="outlined-required"
//               label="Mail ID"
//               name="email"
//               value={data.email}
//               onChange={handleChange}
//               error={!data.email && showEmptyError}
//             />
//             <FormControl className="loginfields" variant="outlined" required>
//               <InputLabel htmlFor="outlined-adornment-password">
//                 Password
//               </InputLabel>
//               <OutlinedInput
//                 id="outlined-adornment-password"
//                 type={showPassword ? "text" : "password"}
//                 value={data.password}
//                 onChange={handleChange}
//                 error={!data.password && showEmptyError}
//                 name="password"
//                 endAdornment={
//                   <InputAdornment position="end">
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={handleClickShowPassword}
//                       onMouseDown={handleMouseDownPassword}
//                       edge="end"
//                     >
//                       {showPassword ? (
//                         <VisibilityOffOutlined />
//                       ) : (
//                         <VisibilityOutlined />
//                       )}
//                     </IconButton>
//                   </InputAdornment>
//                 }
//                 label="Password"
//               />
//             </FormControl>
//             <Button
//               className="loginfields commonBtn"
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ textAlign: "center" }}
//               onClick={handleSubmit}
//             >
//               Login
//             </Button>
//           </Box>
//         </Grid>
//         <AlertPopUp
//           open={open}
//           msg={msg}
//           handleClose={handleClose}
//           type={alertType}
//         />
//       </Grid>
//     </ThemeProvider>
//   );
// }

// export default SignIn;

//// kuchh bhi ......

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import React, { useContext, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { CgMicrosoft } from "react-icons/cg";
import { FaApple } from "react-icons/fa6";
// import { Country, State, City } from "country-state-city";
import vliv_logo from "../../assets/venturesathi-logo.png";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import axios from "axios";
// import { adminLogin, companySignup } from "../../../src/constants/api";
// import moment from "moment-timezone";
import Cookies from "js-cookie";
import { LoginApi } from "../../Constants/api";
import AlertPopUp from "../../Constants/AlertPopUp";
import { AuthContext } from "../../Context/AuthContextProvider";
// import { GoogleLogin } from "@react-oauth/google";
// import { notifyError, notifySuccess } from "../../Constrant/toastAlert";
// import { notifyError, notifySuccess } from "../../../src/constants/toastAlerts";
// import { useDispatch } from "react-redux";
// import { userLogin } from "../../redux/actions/login.action";
// import { LOGIN_SUCCCESS } from "../../redux/actionTypes";
// const allCountries = Country.getAllCountries();

const SignIn = () => {
  const {authContext,setAuthContext} = useContext(AuthContext)
  //   // const login = useSelector((state) => state.loginReducer);
  const isProduction = process.env.NODE_ENV === "production";
  const navigate = useNavigate();
  //   // const dispatch = useDispatch();
  const [cred, setCred] = useState({
    email: "",
    password: "",
  });
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const handleClickShowLoginPassword = () =>
    setShowLoginPassword((show) => !show);
  const handleMouseDownLoginPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;

    setCred({ ...cred, [name]: value });
  };

  const [showEmptyError, setShowEmptyError] = useState(false);

  //for alert msg
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //for alert msg end

  const handleSubmit = async () => {
    //  dispatch(userLogin(data, navigate));
    if (!cred.email || !cred.password) {
      setShowEmptyError(true);
      setAlertType("error");
      setOpen(true);
      setMsg("You have empty fields");
    } else {
      try {
        let res = await axios.post(`${LoginApi}`, cred);
        // console.log(res, "response");
        if (res.data.message == "Login Successful") {
          Cookies.set("companytoken", res.data.token, {
            domain: isProduction ? ".vliv.app" : "localhost",
            path: "/",
            secure: true,
            sameSite: isProduction ? "None" : "Lax",
          });

          setAuthContext({token:res.data.token,userData:res.data.data})
         
          const {
            password,
            confirmpassword,
            yearlypremium,
            yearlystandard,
            yearlybasic,
            premium,
            standard,
            basic,
            addonpack,
            featureslist,
            purchaseaddonpack,
            ...userinfo
          } = res.data.data;
          Cookies.set("userinfo", JSON.stringify(userinfo), {
            domain: isProduction ? ".vliv.app" : "localhost",
            path: "/",
            secure: true,
            sameSite: isProduction ? "None" : "Lax",
          });

          let role = res.data.data.userrole ? res.data.data.userrole : "";
          if (role == "User") {
            setAlertType("success");
            setOpen(true);
            setMsg(res.data.message);
            setTimeout(() => {
              navigate("/hrm/employeeselfservice/summary");
              window.location.reload();
            }, 1000);

            // Will think about it later
            // setTimeout(() => {

            // }, 0);
          } else {
            setAlertType("success");
            setOpen(true);
            setMsg(res.data.message);
            setTimeout(() => {
              navigate("/hrm/summary/welcome");
              window.location.reload();
            }, 1000);
          }
        } else {
          setAlertType("error");
          setOpen(true);
          setMsg(res.data.message);
        }
      } catch (error) {
        console.log(error);
        setAlertType("error");
        setOpen(true);
        setMsg(error.response.data.message || "Something went wrong");
      }
    }
  };

  const handleGoogle = () => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "http://206.189.128.35:6001/auth";

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <>
      <Box className="signin-main">
        <Box
          className="signin-left"
          width={"35%"}
          height={"100%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box textAlign="center">
            {/* <img src={vliv_logo} alt="logo" className="logo_img" />
            <p style={{ fontSize: "1.3rem" }}>Sign in an Account</p> */}
          </Box>
        </Box>
        <Box
          className="signin-right"
          width={"60%"}
          height={"100%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width="100%"
            maxWidth="430px"
            p={3}
            boxShadow={3}
            borderRadius={4}
            bgcolor="white"
          >
            <Box mb={1}>
              <img
                src={vliv_logo}
                alt="logo"
                className="logo_img"
                style={{ marginRight: "10px" }}
              />
            </Box>
            <Typography
              component="h1"
              variant="h3"
              fontWeight={"700"}
              sx={{ fontSize: "1.5rem" }}
            >
              (HRMS)
            </Typography>
            <Box>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ my: "0.6rem" }}
                size="small"
              >
                <FormLabel sx={{ fontSize: "1.2rem", my: 0.4 }}>
                  Email Address
                </FormLabel>
                <OutlinedInput
                  name="email"
                  value={cred.email}
                  onChange={handleLoginChange}
                  placeholder="Enter Your Mail Address"
                  sx={{
                    borderRadius: "25px",
                    fontSize: "1rem",
                    padding: 0.5,
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "1rem", // Set the placeholder font size
                    },
                  }}
                  error={!cred.email && showEmptyError}
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ my: "0.6rem", mb: 1 }}
              >
                <FormLabel sx={{ fontSize: "1.2rem", my: 0.4 }}>
                  Password
                </FormLabel>
                <OutlinedInput
                  size="small"
                  name="password"
                  type={showLoginPassword ? "text" : "password"}
                  value={cred.password}
                  onChange={handleLoginChange}
                  placeholder="Enter Your Password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowLoginPassword}
                        onMouseDown={handleMouseDownLoginPassword}
                      >
                        {showLoginPassword ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    borderRadius: "25px",
                    fontSize: "1rem",
                    padding: 0.5,
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "1rem", // Set the placeholder font size
                    },
                  }}
                  error={!cred.password && showEmptyError}
                />
              </FormControl>
              <div
                className="button-container"
                style={{
                  margin: "2rem 0rem 1rem 0rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleSubmit}
                  sx={{
                    width: "100%",
                    mb: 1,
                    borderRadius: "25px",
                    border: "0.1px solid #98A2B3",
                    p: 1,
                    fontSize: "1rem",
                    textTransform: "none",
                    color: "#ffff",
                    backgroundColor: "#175376",
                    "&:hover": {
                      backgroundColor: "#175376", // Prevent background color change on hover
                    },
                  }}
                >
                  Sign In
                </Button>
              </div>
              <Box
                variant="body2"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1.3}
                my={2}
              >
                <Box flex={2}>
                  <hr />
                </Box>
                <Box fontSize={"1rem"}>Or</Box>
                <Box flex={2}>
                  <hr />
                </Box>
              </Box>
              <Button
                fullWidth
                // variant="outlined"

                onClick={handleGoogle}
                startIcon={<FcGoogle />}
                sx={{
                  mb: 1,
                  borderRadius: "25px",
                  border: "0.1px solid #98A2B3",
                  p: 1,
                  fontSize: "1.2rem",
                  textTransform: "none",
                  color: "black",
                }}
              >
                Continue with Google
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default SignIn;
