import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import Cookies from "js-cookie";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import { LiaEdit } from "react-icons/lia";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import axios from "axios";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import { getAllAttendancesByEmpID } from "../../../../../Constants/api";
import {
  formatDate,
  getAttendanceStatus,
  getAttendanceStatusColor,
} from "../../../../../Constants/dateFormat";
import AttendanceRequest from "./AttendanceRequest";

dayjs.extend(isSameOrAfter);
const getDaysInMonth = (year, month) => {
  return new Array(dayjs(`${year}-${month + 1}-01`).daysInMonth())
    .fill(null)
    .map((_, index) => ({
      field: `${index + 1}`,
      headerName: `${index + 1}`,
      width: 220,
    }));
};

const prepareGridData = (attendanceData, year) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const gridData = months.map((month, monthIndex) => {
    const daysInMonth = dayjs(`${year}-${monthIndex + 1}-01`).daysInMonth();
    const monthData = { id: month, month };

    for (let day = 1; day <= daysInMonth; day++) {
      const dayData = attendanceData.find((item) => {
        const date = dayjs(item.date, "D/M/YYYY");
        return (
          date.year() === year &&
          date.month() === monthIndex &&
          date.date() === day
        );
      });

      if (dayData) {
        const isSuccessful = parseFloat(dayData.totalhours) >= 9;
        monthData[day] = {
          checkin: dayData.checkin,
          checkout: dayData.checkout,
          totalhours: dayData.totalhours,
          isSuccessful,
          data: dayData,
        };
      } else {
        monthData[day] = "--";
      }
    }
    return monthData;
  });

  return gridData;
};

const renderStatusIcon = (params) => {
  if (!params.value) {
    return "N/A";
  } else {
    if (params.value === "--") {
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={1}
        >
          {" "}
          <span>-</span>
          <AccessTimeIcon color="error" sx={{ textAlign: "center" }} />
        </Box>
      );
    }
    return params.value && params.value.isSuccessful ? (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={1}
      >
        <span>
          {params.value && params.value.checkin} -{" "}
          {params.value && params.value.checkout}
        </span>
        <AccessTimeIcon color="success" />
      </Box>
    ) : (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={1}
      >
        <span>
          {params.value && params.value.checkin} -{" "}
          {params.value && params.value.checkout}
        </span>
        <AccessTimeIcon color="warning" />
      </Box>
    );
  }
};

const getMonthDetails = (attendanceData, year, monthIndex) => {
  const daysInMonth = dayjs(`${year}-${monthIndex + 1}-01`).daysInMonth();
  const monthDetails = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const dayData = attendanceData.find((item) => {
      const date = dayjs(item.date, "D/M/YYYY");
      return (
        date.year() === year &&
        date.month() === monthIndex &&
        date.date() === day
      );
    });

    if (dayData) {
      monthDetails.push({
        date: dayjs(`${year}-${monthIndex + 1}-${day}`).format("DD/MM/YYYY"),
        checkin: dayData.checkin,
        checkout: dayData.checkout,
        totalhours: dayData.totalhours,
        status:
          parseFloat(dayData.totalhours) >= 9 ? "Completed" : "Incomplete",
      });
    } else {
      monthDetails.push({
        date: dayjs(`${year}-${monthIndex + 1}-${day}`).format("DD/MM/YYYY"),
        checkin: "--",
        checkout: "--",
        totalhours: "--",
        status: "No Data",
      });
    }
  }
  return monthDetails;
};

const TimeAndAttendance = () => {
  const navigate = useNavigate();
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";
  let userRole = userData.userrole ? userData.userrole : "";
  let username = userData.username ? userData.username : "";

  const [n, setN] = useState("");
  const [empInfo, setEmpInfo] = useState({});
  const [attendanceData, setAttendanceData] = useState([]);
  const [year, setYear] = useState(dayjs().year());
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [monthDetails, setMonthDetails] = useState([]);

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //for date filter
  const [modalOpen, setModalOPen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRows, setDateRows] = useState([]);
  const handleModalOpen = () => setModalOPen(true);
  const handleModalClose = () => setModalOPen(false);

  const handleApply = () => {
    if (startDate && endDate) {
      const dates = [];
      let currentDate = dayjs(startDate);

      while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "day")
      ) {
        const formattedDate = currentDate.format("DD/MM/YYYY");
        const findData = attendanceData.find(
          (data) => data.date == formattedDate
        );

        dates.push({
          // id:formattedDate,
          date: formattedDate,
          day: currentDate.format("dddd"),
          data: {
            ...(findData || {}),
            status: getAttendanceStatus({
              day: currentDate.format("dddd"),
              data: findData || {},
            }),
          },
        });

        currentDate = currentDate.add(1, "day");
      }

      setDateRows(dates);
      setModalOPen(false); // Close modal after applying
    }
  };

  //for attendance request
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    if (empInfo.empid) {
      axios
        .post(getAllAttendancesByEmpID, {
          companyemail,
          companyid,
          employeeid: empInfo.empid,
          tableemployeeid: empInfo.id,
        },{withCredentials:true})
        .then((res) => {
          if (res.data && res.data.data) {
            setAttendanceData(res.data.data);
          } else {
            setAttendanceData([]);
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
    }
  }, [empInfo]);

  useEffect(() => {
    let daysColumns = getDaysInMonth(year, 0);
    daysColumns = daysColumns.map((day) => ({
      ...day,
      renderCell: renderStatusIcon,
      data: day,
    }));
    setColumns([
      {
        field: "month",
        headerName: "Month",
        width: 150,
        position: "sticky",
        left: 0,
        renderCell: (params) => (
          <Box m={"auto"}>
            <Typography mt={1.5} className="view-page-value">{params.row.month}</Typography>
          </Box>
        ),
      },
      ...daysColumns,
    ]);

    const gridData = prepareGridData(attendanceData, year);
    setRows(gridData);
  }, [attendanceData, year]);

  useEffect(() => {
    axios
      .post("https://hrms.vliv.app/employee/get", { companyemail, companyid },{withCredentials:true})
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let data = res.data.find((el) => el.email === email);
          if (data) {
            setEmpInfo(data);
          } else {
            setEmpInfo({});
          }
        } else {
          setEmpInfo({});
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setAlertType("error");
        setOpen(true);
        setMsg(err.message);
      });

    axios
      .post("https://hrms.vliv.app/attedance/lastdate/get", {
        companyemail,
        companyid,
      },{withCredentials:true})
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setN(Number(res.data[0].lastdate) || 31);
        } else {
          setN(31);
        }
      })
      .catch((err) => {
        console.log(err, "error in get regularization master");
      });
  }, []);

  // const handleCellClick = (params) => {
  //   if (
  //     params.field !== "month" &&
  //     params.value !== "--" &&
  //     params.value !== "N/A"
  //   ) {
  //     const monthIndex = dayjs().month(params.row.id).month();
  //     const details = getMonthDetails(attendanceData, year, monthIndex);
  //     setMonthDetails(details);
  //     setSelectedMonth(params.row.id);
  //   }
  // };

  function stringAvatar(name, small = false) {
    return {
      sx: {
        bgcolor: "grey",
        width: small ? "40px" : "70px",
        height: small ? "40px" : "70px",
      },
      children: name ? `${name.split(" ")[0][0]}${name.split(" ")[0][1]}` : "",
    };
  }

  // For responsive design
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // console.log(dateRows, "filtered date rows....");

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={0.5}>
        <Box sx={{ width: "100%", maxWidth: "100%" }}>
          <Box p={1.5}>
            <Typography
              variant={isSmallScreen ? "h6" : "h5"}
              className="primarycolor"
            >
              Time And Attendance
            </Typography>
          </Box>
          <Box p={1.5}>
            <Stack
              direction={isSmallScreen ? "column" : "row"}
              gap={isSmallScreen ? 2 : 5}
              flexWrap="wrap"
              justifyContent="space-between"
            >
              <Box display={"flex"} gap={2}>
                <Avatar {...stringAvatar(empInfo && empInfo.name)} />
                <Box>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: isSmallScreen ? "16px" : "18px",
                    }}
                  >
                    {empInfo && empInfo.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: isSmallScreen ? "14px" : "16px",
                    }}
                  >
                    {empInfo && empInfo.role}
                  </Typography>
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: isSmallScreen ? "14px" : "16px",
                    }}
                  >
                    {empInfo && empInfo.email}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: isSmallScreen ? "14px" : "16px",
                  }}
                >
                  Employee ID
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: isSmallScreen ? "16px" : "18px",
                  }}
                >
                  {empInfo && empInfo.empid}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: isSmallScreen ? "14px" : "16px",
                  }}
                >
                  Contact Number
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: isSmallScreen ? "16px" : "18px",
                  }}
                >
                  {empInfo && empInfo.contactno ? empInfo.contactno : "--"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: isSmallScreen ? "14px" : "16px",
                  }}
                >
                  Department
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: isSmallScreen ? "16px" : "18px",
                  }}
                >
                  {empInfo && empInfo.department}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: isSmallScreen ? "14px" : "16px",
                  }}
                >
                  Sub-Department
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: isSmallScreen ? "16px" : "18px",
                  }}
                >
                  {empInfo && empInfo.subdepartment
                    ? empInfo.subdepartment
                    : "--"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: isSmallScreen ? "14px" : "16px",
                  }}
                >
                  Employee Type
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: isSmallScreen ? "16px" : "18px",
                  }}
                >
                  {empInfo && empInfo.emptype}
                </Typography>
              </Box>
              <Box display={"flex"} gap={2} alignItems={"flex-end"}>
                <Avatar
                  {...stringAvatar(empInfo && empInfo.reportmanager, true)}
                />
                <Box>
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: isSmallScreen ? "14px" : "16px",
                    }}
                  >
                    Reporting Manager
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: isSmallScreen ? "16px" : "18px",
                    }}
                  >
                    {empInfo && empInfo.reportmanager}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box px={2}>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Select
              value={year}
              onChange={(e) => setYear(e.target.value)}
              size="small"
            >
              {[...Array(10)].map((_, index) => {
                const yr = dayjs().year() - index;
                return (
                  <MenuItem key={yr} value={yr}>
                    {yr}
                  </MenuItem>
                );
              })}
            </Select>
            <Tooltip title="Select Date Range" placement="top-end">
              <Button
                className="cancelBtn"
                variant="outlined"
                onClick={handleModalOpen}
                sx={{ textTransform: "capitalize" }}
                startIcon={<RestorePageIcon />}
              >
                Regularization
              </Button>
            </Tooltip>
          </Box>
          <Box height={600}>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              disableSelectionOnClick
              headerClassName="datagrid-header"
              // onCellClick={handleCellClick}
            />
          </Box>
        </Box>
      </Box>

      {/* for filtered date modal  */}
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {" "}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Select Date Range</Typography>
            <IconButton onClick={handleModalClose}>
              <CancelIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <Box className="drawer-form-container">
          <DialogContent>
            <Box mt={2} display="flex" gap={2} flexDirection="column">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={dayjs()}
                />
                <DatePicker
                  label="To"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={dayjs()}
                />
              </LocalizationProvider>
            </Box>
          </DialogContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap={1}
            mt={2}
          >
            <Button
              onClick={handleModalClose}
              className="cancelBtn"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={handleApply} color="primary" className="saveBtn">
              Apply
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Box mt={4}>
        {dateRows.length > 0 && (
          <Dialog
            open={dateRows.length > 0}
            onClose={() => setDateRows([])}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              Attendance ({formatDate(startDate)} - {formatDate(endDate)})
            </DialogTitle>
            <DialogContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Day</TableCell>
                      <TableCell>In time</TableCell>
                      <TableCell>Out time</TableCell>
                      <TableCell>Total Hrs</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dateRows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>{row.day}</TableCell>
                        <TableCell>
                          {row.data.checkin ? row.data.checkin : "-"}
                        </TableCell>
                        <TableCell>
                          {row.data.checkout ? row.data.checkout : "-"}
                        </TableCell>
                        <TableCell>
                          {row.data.totalhours ? row.data.totalhours : "-"}
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            color: getAttendanceStatusColor(row.data.status),
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div>{row.data.status}</div>

                          {row.data.status == "Regularize" ||
                          row.data.status == "Absent" ? (
                            <IconButton
                              color="black"
                              onClick={() => {
                                setSelectedRow(row);
                                setOpenRequestModal(true);
                              }}
                            >
                              <LiaEdit />
                            </IconButton>
                          ) : null}
                        </TableCell> */}

                        {/* Restricting regularisation  */}
                        <TableCell
                          sx={{
                            color: getAttendanceStatusColor(row.data.status),
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div>{row.data.status}</div>

                          {/* Condition to check if the date should show the edit icon */}
                          {(row.data.status === "Regularize" ||
                            row.data.status === "Absent") &&
                          (() => {
                            const currentDate = dayjs(); // Get the current date
                            const rowDate = dayjs(row.date, "DD/MM/YYYY"); // Parse row date from DD/MM/YYYY format
                            // Check if the row date is within the current month and
                            // if the current date is less than or equal to `n`
                            return (
                              currentDate.isSame(rowDate, "month") &&
                              currentDate.date() <= n
                            );
                          })() ? (
                            <IconButton
                              color="black"
                              onClick={() => {
                                setSelectedRow(row);
                                setOpenRequestModal(true);
                              }}
                            >
                              <LiaEdit />
                            </IconButton>
                          ) : null}
                        </TableCell>
                        {/* Restricting regularisation  */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setDateRows([])}
                className="cancelBtn"
                variant="outlined"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
      {selectedRow && openRequestModal && (
        <AttendanceRequest
          openRequestModal={openRequestModal}
          setOpenRequestModal={setOpenRequestModal}
          empInfo={empInfo}
          setEmpInfo={setEmpInfo}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      )}
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default TimeAndAttendance;
