import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Loader from "../../../../Constants/Loader";
import { getPayrollStatusColor } from "../../../../Constants/Colors";
import { getPayrollInputsApi } from "../../../../Constants/api";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const BankReport = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";

  const [format, setFormat] = useState("pdf");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Initialize the current month and year
  const [currentDate, setCurrentDate] = useState(dayjs());

  const handleChange = (event) => {
    setFormat(event.target.value);
  };

  const handleDownload = () => {
    switch (format) {
      case "excel":
        downloadExcel();
        break;
      case "pdf":
        downloadPDF();
        break;
      default:
        break;
    }
  };

const downloadExcel = () => {
  const worksheetData = [
    [
      "Beneficiary Name (Mandatory)\nFull name of the customer - eg: Bruce Wayne",
      "Beneficiary Account number (Mandatory)\nBeneficiary Account number to which the money should be transferred",
      "IFSC code (Mandatory)\nIFSC code of beneficiary's bank. eg:KKBK0000958",
      "Amount (Mandatory)\nAmount that needs to be transferred. Eg:100.00",
      "Description / Purpose (Optional)\nFor Internal Reference eg: For salary",
    ],
    ...data.map((row) => [
      row.name,
      row.accountno,
      row.ifsc,
      row.totalgross,
      "",
    ]),
  ];

  const ws = XLSX.utils.aoa_to_sheet(worksheetData);

  // Apply styles to the header
  const headerStyle = {
    font: {
      bold: true,
    },
    fill: {
      fgColor: { rgb: 'D3D3D3' },  // Gray background color
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,  // Enable text wrapping
    },
    border: {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    },
  };

  // Loop through the header cells to apply styles
  const range = XLSX.utils.decode_range(ws['!ref']);
  for (let col = range.s.c; col <= range.e.c; col++) {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
    if (!ws[cellAddress]) continue;
    ws[cellAddress].s = headerStyle;
  }

  // Set custom column widths
  ws['!cols'] = [
    { wpx: 200 }, // Width of 200 pixels for each column
    { wpx: 200 },
    { wpx: 200 },
    { wpx: 200 },
    { wpx: 200 },
    { wpx: 200 },
  ];

  // Enable automatic row height
  ws['!rows'] = [{ hpx: 40 }]; // Add a sample row height to ensure correct initial rendering

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFile(
    wb,
    `Bank Report ${currentDate.format("MMMM")}-${currentDate.format("YYYY")}.xlsx`
  );
}; 
  
  const downloadPDF = () => {
    const doc = new jsPDF();
  
    doc.autoTable({
      head: [
        [
          "Beneficiary Name (Mandatory)\nFull name of the customer - eg: Bruce Wayne",
          "Beneficiary Account number (Mandatory)\nBeneficiary Account number to which the money should be transferred",
          "IFSC code (Mandatory)\nIFSC code of beneficiary's bank. eg:KKBK0000958",
          "Amount (Mandatory)\nAmount that needs to be transferred. Eg:100.00",
          "Description / Purpose (Optional)\nFor Internal Reference eg: For salary",
        ],
      ],
      body: data.map((row) => [
        row.name,
        row.accountno,
        row.ifsc,
        row.totalgross,
        "",
      ]),
      styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
      headStyles: {
        fillColor: [211, 211, 211],  // Gray color for header background
        textColor: [0, 0, 0],
        fontSize: 8,
        halign: 'center',
        valign: 'middle',
        cellPadding: 1,  // Padding for header cells
        overflow: 'linebreak',
        
      },
      columnStyles: {
        0: { cellWidth: 'auto' },  // Auto adjust column width
        1: { cellWidth: 'auto' },
        2: { cellWidth: 'auto' },
        3: { cellWidth: 'auto' },
        4: { cellWidth: 'auto' },
        5: { cellWidth: 'auto' },
      },
      didDrawCell: (data) => {
        data.cell.styles.lineWidth = 0.1;  // Apply border width
      },
      theme: 'grid',  // Ensures the table takes the full width
      tableWidth: 'auto',  // Auto adjust table width
    });
  
    doc.save(
      `Bank Report ${currentDate.format("MMMM")}-${currentDate.format("YYYY")}.pdf`
    );
  };
  

  // Handler to go to the previous month
  const handlePreviousMonth = () => {
    const newDate = currentDate.subtract(1, "month");
    setCurrentDate(newDate);
  };

  // Handler to go to the next month
  const handleNextMonth = () => {
    const newDate = currentDate.add(1, "month");
    setCurrentDate(newDate);
  };

  // Handler to go to the previous year
  const handlePreviousYear = () => {
    const newDate = currentDate.subtract(1, "year");
    setCurrentDate(newDate);
  };

  // Handler to go to the next year
  const handleNextYear = () => {
    const newDate = currentDate.add(1, "year");
    setCurrentDate(newDate);
  };

  const getData = async () => {
    try {
      setLoading(true);
      let res = await axios.post(getPayrollInputsApi, {
        companyemail,
        companyid,
        month: currentDate.format("MMMM"),
        year: currentDate.format("YYYY"),
      },{withCredentials:true});
      res.data && res.data.length > 0 ? setData(res.data) : setData([]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg("Error while getting payroll inputs");
      setLoading(false);
    }
  };

  useEffect(() => {
    const filteredRows = data
      //   .filter((row) =>
      //     row.name.toLowerCase().includes(searchKeyword.toLowerCase())
      //   )
      .filter(
        (row) =>
          row.month === currentDate.format("MMMM") &&
          row.year === currentDate.format("YYYY")
      );
    setFilteredData(filteredRows);
  }, [data, currentDate]);

  const columns = [
    { field: "employeeid", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "accountno", headerName: "A/C No", flex: 1 },
    { field: "ifsc", headerName: "IFSC", flex: 1 },
    { field: "totalgross", headerName: "Amount", flex: 1 },

  ];

  useEffect(() => {
    getData();
  }, [currentDate]);

  return (
    <>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          p={2}
        >
          <Box display="flex" alignItems="center" gap={2} flexWrap={"wrap"}>
            <Typography variant="h6">Bank Report</Typography>

            {/* Month Navigation */}
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              border={"1px solid #0B4A6F"}
              borderRadius={2}
              flexWrap={"wrap"}
            >
              <IconButton onClick={handlePreviousMonth}>
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant="body1" component="span" color={"#0B4A6F"}>
                {currentDate.format("MMMM")}
              </Typography>
              <IconButton onClick={handleNextMonth}>
                <NavigateNextIcon />
              </IconButton>
            </Box>

            {/* Year Navigation */}
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              border={"1px solid #0B4A6F"}
              borderRadius={2}
              flexWrap={"wrap"}
            >
              <IconButton onClick={handlePreviousYear}>
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant="body1" component="span" color={"#0B4A6F"}>
                {currentDate.year()}
              </Typography>
              <IconButton onClick={handleNextYear}>
                <NavigateNextIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Search Field */}
          <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
            <Select
              labelId="format-select-label"
              id="format-select"
              value={format}
              onChange={handleChange}
              label="Format"
              displayEmpty
              sx={{
                border: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              <MenuItem value="pdf">PDF</MenuItem>
              <MenuItem value="excel">Excel</MenuItem>
            </Select>
            <IconButton onClick={handleDownload}>
              <FiDownload />
            </IconButton>
          </Box>
        </Box>

        {/* DataGrid */}
        <Box my={1}>
          <Paper style={{ height: "auto", width: "100%" }}>
            <DataGrid
              rows={loading ? [] : filteredData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 8 },
                },
              }}
              pageSizeOptions={[8, 16, 24, 32, data.length]}
              autoHeight
              components={{
                LoadingOverlay: Loader,
              }}
              loading={loading}
            />
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default BankReport;
