import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../../../Constants/Loader";
import { DataGrid } from "@mui/x-data-grid";
import { Search as SearchIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getLeaveType } from "../../../../../redux/actions/actions.master/leaveType.actions";
import {
  getAttLeaveReport,
  getAttLeaveReportByID,
} from "../../../../../redux/actions/actions.hrms/attLeaveReport.actions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const boxStyle = {
  margin: "10px",
  padding: "10px",
  borderRadius: "5px",
  backgroundColor: "#f9fafb",
};
const typoHeading = {
  color: "black",
  fontSize: "18px",
};
const typoValue = {
  color: "grey",
  fontSize: "16px",
};
const empInfoBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
};
function stringAvatar(name) {
  return {
    sx: {
      bgcolor: "grey",
      width: "90px",
      height: "90px",
    },
    children: name ? `${name.split(" ")[0][0]}` : "",
  };
}
function stringAvatar2(name) {
  return {
    sx: {
      bgcolor: "grey",
      width: "50px",
      height: "50px",
    },
    children: name ? `${name.split(" ")[0][0]}` : "",
  };
}

const LeaveList = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    attLeaveReportData,
    attLeaveReport_error,
    attLeaveReport_loading,
    attLeaveReportByIDData,
  } = useSelector((state) => state.attLeaveReportReducer);

  const { leaveTypeData, error, loading } = useSelector(
    (state) => state.leaveTypeReducer
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [rowID, setRowID] = useState("");
  const [empId, setEmpID] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchValue, setSearchValue] = useState("")

  const CL =
    leaveTypeData.length > 0 &&
    leaveTypeData.find((data) => data.leavetype === "Casual Leave")
      ? leaveTypeData.find((data) => data.leavetype === "Casual Leave").count ||
        0
      : 0;
  const SL =
    leaveTypeData.length > 0 &&
    leaveTypeData.find((data) => data.leavetype === "Sick Leave")
      ? leaveTypeData.find((data) => data.leavetype === "Sick Leave").count || 0
      : 0;
  const CO =
    leaveTypeData.length > 0 &&
    leaveTypeData.find((data) => data.leavetype === "Comm. Off")
      ? leaveTypeData.find((data) => data.leavetype === "Comm. Off").count || 0
      : 0;
  const EL =
    leaveTypeData.length > 0 &&
    leaveTypeData.find((data) => data.leavetype === "Earned Leave")
      ? leaveTypeData.find((data) => data.leavetype === "Earned Leave").count ||
        0
      : 0;
  const RL =
    leaveTypeData.length > 0 &&
    leaveTypeData.find((data) => data.leavetype === "Restricted Leave")
      ? leaveTypeData.find((data) => data.leavetype === "Restricted Leave")
          .count || 0
      : 0;
  const PL =
    leaveTypeData.length > 0 &&
    leaveTypeData.find((data) => data.leavetype === "Paternity Leave")
      ? leaveTypeData.find((data) => data.leavetype === "Paternity Leave")
          .count || 0
      : 0;
  const ML =
    leaveTypeData.length > 0 &&
    leaveTypeData.find((data) => data.leavetype === "Maternity Leave")
      ? leaveTypeData.find((data) => data.leavetype === "Maternity Leave")
          .count || 0
      : 0;
  const OL =
    leaveTypeData.length > 0 &&
    leaveTypeData.find((data) => data.leavetype === "Other Leave")
      ? leaveTypeData.find((data) => data.leavetype === "Other Leave").count ||
        0
      : 0;

  const TL =
    Number(CL) +
    Number(SL) +
    Number(CO) +
    Number(EL) +
    Number(RL) +
    Number(PL) +
    Number(ML) +
    Number(OL);

  const columns = [
    {
      field: "empid",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "empname",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
    },
    {
      field: "totalleave",
      flex: 1,
      renderHeader: () => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>Total Leave ({TL})</Box>
      ),
    },
    {
      field: "casual",
      flex: 1,
      renderHeader: () => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>Casual Leave ({CL})</Box>
      ),
    },
    {
      field: "sick",
      flex: 1,
      renderHeader: () => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>Sick Leave ({SL})</Box>
      ),
    },
    {
      field: "lop",
      headerName: "Loss of Pay",
      flex: 1,
    },
    {
      field: "commoff",
      flex: 1,
      renderHeader: () => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>Comm. off ({CO})</Box>
      ),
    },
    {
      field: "earned",
      flex: 1,
      renderHeader: () => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>Earned Leave ({EL})</Box>
      ),
    },
    {
      field: "restricted",
      flex: 1,
      renderHeader: () => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>Restricted Leave ({RL})</Box>
      ),
    },
    {
      field: "paternity",
      flex: 1,
      renderHeader: () => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>Paternity Leave ({PL})</Box>
      ),
    },
    {
      field: "maternity",
      flex: 1,
      renderHeader: () => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>Maternity Leave ({ML})</Box>
      ),
    },
    {
      field: "other",
      flex: 1,
      renderHeader: () => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>Other Leave ({OL})</Box>
      ),
    },
  ];
  const handleSearch = (event) => {
    // Handle search logic here
    setSearchValue(event.target.value)

  };

  const filterData = () => {
    return attLeaveReportData&& attLeaveReportData.length > 0 && attLeaveReportData?.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();
  

  const handleRowClick = (params) => {
    //navigate(`/hrm/employeemanagement/registration/update/${params.row.id}`);
    setModalOpen(true);
    setRowID(params.row.id);
    setEmpID(params.row.empid);
    setSelectedRow(params.row);
  };

  useEffect(() => {
    dispatch(
      getLeaveType({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );

    dispatch(
      getAttLeaveReport({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  useEffect(() => {
   if(rowID){
    dispatch(
      getAttLeaveReportByID({
        id: rowID,
        employeeid: empId,
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
   }
  }, [dispatch, rowID]);
  // console.log(
  //   attLeaveReportByIDData.leavearray.filter(
  //     (ele) => ele.leavetype == "Casual Leave"
  //   ),
  //   "attLeaveReportByIDData"
  // );


  // console.log(filteredData,"filtered Data");

  return (
    <>
      <Box>
        <Box className="searchbar-container">
          <Hidden smDown>
            <Box>
              {/* Content to be shown only on screens larger than or equal to sm (600px) */}
            </Box>
          </Hidden>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <TextField
              placeholder="Search…"
              variant="outlined"
              className="searchbar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </Box>
        </Box>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={attLeaveReport_loading ? [] : filteredData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            onRowClick={handleRowClick}
            pageSizeOptions={[
              5,10,15,20
            ]}
            autoHeight
            components={{
              LoadingOverlay: <Loader />,
            }}
            loading={attLeaveReport_loading}
            //onRowClick={handleRowClick}
            // disableRowSelectionOnClick
            // onRowSelectionModelChange={(newSelection) => {
            //   handleSelectionModelChange(newSelection);
            // }}
          />
        </Box>
      </Box>

      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent>
          <Box sx={boxStyle}>
            <Stack direction="row" gap={5} mb={1}>
              <Box display={"flex"} gap={2}>
                <Avatar {...stringAvatar(selectedRow && selectedRow.name)} />
                <Box>
                  <Typography sx={typoHeading}>
                    {selectedRow && selectedRow.empname}
                  </Typography>
                  <Typography sx={typoValue}>
                    {selectedRow && selectedRow.position}
                  </Typography>
                  <Typography sx={typoValue}>
                    {selectedRow && selectedRow.email}
                  </Typography>
                </Box>
              </Box>
              <Box sx={empInfoBox}>
                <Typography sx={typoValue}>Employee ID</Typography>
                <Typography sx={typoHeading}>
                  {selectedRow && selectedRow.empid}
                </Typography>
              </Box>
              <Box sx={empInfoBox}>
                <Typography sx={typoValue}>Department</Typography>
                <Typography sx={typoHeading}>
                  {selectedRow && selectedRow.department}
                </Typography>
              </Box>
              <Box sx={empInfoBox}>
                <Typography sx={typoValue}>Sub-Department</Typography>
                <Typography sx={typoHeading}>
                  {selectedRow && selectedRow.subdepartment
                    ? selectedRow.subdepartment
                    : "--"}
                </Typography>
              </Box>
              <Box display={"flex"} gap={2} alignItems={"flex-end"}>
                <Avatar
                  {...stringAvatar2(selectedRow && selectedRow.reportmanager)}
                />
                <Box>
                  <Typography sx={typoValue}>Reporting Manager</Typography>
                  <Typography sx={typoHeading}>
                    {selectedRow && selectedRow.reportmanager}
                  </Typography>
                </Box>
              </Box>
            </Stack>
            <Divider />
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Casual Leave ({CL})
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Balance Leave</TableCell>
                        <TableCell align="center">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attLeaveReportByIDData &&
                        attLeaveReportByIDData.leavearray?.length &&
                        attLeaveReportByIDData.leavearray
                          .filter((ele) => ele.leavetype == "Casual Leave")
                          .map((ele, i) => (
                            <TableRow key={ele.id}>
                              <TableCell align="center">{ele.date}</TableCell>
                              <TableCell align="center">
                                {ele.fromdate}
                              </TableCell>
                              <TableCell align="center">{ele.todate}</TableCell>
                              <TableCell align="center">
                                {ele.leavebalance}
                              </TableCell>
                              <TableCell align="center">{ele.reason}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Sick Leave ({SL})
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Balance Leave</TableCell>
                        <TableCell align="center">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attLeaveReportByIDData &&
                        attLeaveReportByIDData.leavearray?.length &&
                        attLeaveReportByIDData.leavearray
                          .filter((ele) => ele.leavetype == "Sick Leave")
                          .map((ele, i) => (
                            <TableRow key={ele.id}>
                              <TableCell align="center">{ele.date}</TableCell>
                              <TableCell align="center">
                                {ele.fromdate}
                              </TableCell>
                              <TableCell align="center">{ele.todate}</TableCell>
                              <TableCell align="center">
                                {ele.leavebalance}
                              </TableCell>
                              <TableCell align="center">{ele.reason}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                Loss of Pay
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Balance Leave</TableCell>
                        <TableCell align="center">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attLeaveReportByIDData &&
                        attLeaveReportByIDData.leavearray?.length &&
                        attLeaveReportByIDData.leavearray
                          .filter((ele) => ele.leavetype == "Loss of Pay")
                          .map((ele, i) => (
                            <TableRow key={ele.id}>
                              <TableCell align="center">{ele.date}</TableCell>
                              <TableCell align="center">
                                {ele.fromdate}
                              </TableCell>
                              <TableCell align="center">{ele.todate}</TableCell>
                              <TableCell align="center">
                                {ele.leavebalance}
                              </TableCell>
                              <TableCell align="center">{ele.reason}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                Comm. Off ({CO})
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Balance Leave</TableCell>
                        <TableCell align="center">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attLeaveReportByIDData &&
                        attLeaveReportByIDData.leavearray?.length &&
                        attLeaveReportByIDData.leavearray
                          .filter((ele) => ele.leavetype == "Comm. Off")
                          .map((ele, i) => (
                            <TableRow key={ele.id}>
                              <TableCell align="center">{ele.date}</TableCell>
                              <TableCell align="center">
                                {ele.fromdate}
                              </TableCell>
                              <TableCell align="center">{ele.todate}</TableCell>
                              <TableCell align="center">
                                {ele.leavebalance}
                              </TableCell>
                              <TableCell align="center">{ele.reason}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5-content"
                id="panel5-header"
              >
                Earned Leave ({EL})
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Balance Leave</TableCell>
                        <TableCell align="center">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attLeaveReportByIDData &&
                        attLeaveReportByIDData.leavearray?.length &&
                        attLeaveReportByIDData.leavearray
                          .filter((ele) => ele.leavetype == "Earned Leave")
                          .map((ele, i) => (
                            <TableRow key={ele.id}>
                              <TableCell align="center">{ele.date}</TableCell>
                              <TableCell align="center">
                                {ele.fromdate}
                              </TableCell>
                              <TableCell align="center">{ele.todate}</TableCell>
                              <TableCell align="center">
                                {ele.leavebalance}
                              </TableCell>
                              <TableCell align="center">{ele.reason}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6-content"
                id="panel6-header"
              >
                Restricted Leave ({RL})
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Balance Leave</TableCell>
                        <TableCell align="center">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attLeaveReportByIDData &&
                        attLeaveReportByIDData.leavearray?.length &&
                        attLeaveReportByIDData.leavearray
                          .filter((ele) => ele.leavetype == "Restricted Leave")
                          .map((ele, i) => (
                            <TableRow key={ele.id}>
                              <TableCell align="center">{ele.date}</TableCell>
                              <TableCell align="center">
                                {ele.fromdate}
                              </TableCell>
                              <TableCell align="center">{ele.todate}</TableCell>
                              <TableCell align="center">
                                {ele.leavebalance}
                              </TableCell>
                              <TableCell align="center">{ele.reason}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7-content"
                id="panel7-header"
              >
                Paternity Leave ({PL})
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Balance Leave</TableCell>
                        <TableCell align="center">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attLeaveReportByIDData &&
                        attLeaveReportByIDData.leavearray?.length &&
                        attLeaveReportByIDData.leavearray
                          .filter((ele) => ele.leavetype == "Paternity Leave")
                          .map((ele, i) => (
                            <TableRow key={ele.id}>
                              <TableCell align="center">{ele.date}</TableCell>
                              <TableCell align="center">
                                {ele.fromdate}
                              </TableCell>
                              <TableCell align="center">{ele.todate}</TableCell>
                              <TableCell align="center">
                                {ele.leavebalance}
                              </TableCell>
                              <TableCell align="center">{ele.reason}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8-content"
                id="panel8-header"
              >
                Maternity Leave ({ML})
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Balance Leave</TableCell>
                        <TableCell align="center">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attLeaveReportByIDData &&
                        attLeaveReportByIDData.leavearray?.length &&
                        attLeaveReportByIDData.leavearray
                          .filter((ele) => ele.leavetype == "Maternity Leave")
                          .map((ele, i) => (
                            <TableRow key={ele.id}>
                              <TableCell align="center">{ele.date}</TableCell>
                              <TableCell align="center">
                                {ele.fromdate}
                              </TableCell>
                              <TableCell align="center">{ele.todate}</TableCell>
                              <TableCell align="center">
                                {ele.leavebalance}
                              </TableCell>
                              <TableCell align="center">{ele.reason}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9-content"
                id="panel9-header"
              >
                Other Leave ({OL})
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Balance Leave</TableCell>
                        <TableCell align="center">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attLeaveReportByIDData &&
                        attLeaveReportByIDData.leavearray?.length &&
                        attLeaveReportByIDData.leavearray
                          .filter((ele) => ele.leavetype == "Other Leave")
                          .map((ele, i) => (
                            <TableRow key={ele.id}>
                              <TableCell align="center">{ele.date}</TableCell>
                              <TableCell align="center">
                                {ele.fromdate}
                              </TableCell>
                              <TableCell align="center">{ele.todate}</TableCell>
                              <TableCell align="center">
                                {ele.leavebalance}
                              </TableCell>
                              <TableCell align="center">{ele.reason}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LeaveList;
