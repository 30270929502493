import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SlCalender } from "react-icons/sl";
import Cookies from "js-cookie";
import afternoonImage from "../../../../../../assets/assets/hrmswelcomeimageAfternoon.png";
import morningImage from "../../../../../../assets/assets/hrmswelcomeimageMorning.png";
import nightImage from "../../../../../../assets/assets/hrmswelcomeimageNight.png";
import { IoExitOutline } from "react-icons/io5";
import { PiUserCircleCheckThin } from "react-icons/pi";
import { IoWalletOutline } from "react-icons/io5";
import { BsCake2, BsTicketPerforatedFill } from "react-icons/bs";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { GiExitDoor, GiPartyPopper } from "react-icons/gi";
import {
  formatDate,
  getBoxColor,
  performanceBgColor,
  performanceTextColor,
} from "../../../../../../Constants/dateFormat";
import { useNavigate } from "react-router-dom";
import { MdCelebration } from "react-icons/md";
import Chatbot from "../Dashboard/Chatbot";
import { getEvents, getTasksApi } from "../../../../../../Constants/api";
import axios from "axios";
import { getChipColor } from "../../../../../../Constants/Colors";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { IoIosTimer } from "react-icons/io";
import Announcements from "./Announcements";
import Holidays from "./Holidays";
import BirthDays from "./BirthDays";

const Welcome = () => {
  const [tab, setTab] = useState("Announcements");

  const navigate = useNavigate();
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";
  let department = userData.department ? userData.department : "";
  let userRole = userData.userrole ? userData.userrole : "";
  let username = userData.username ? userData.username : "";

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [tasks, setTasks] = useState([]);
  const [events, setEvents] = useState([]);

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getBackgroundImageAndMessage = () => {
    const currentHour = new Date().getHours();
    let backgroundImage, greetingMessage;
    if (currentHour >= 12 && currentHour < 18) {
      backgroundImage = afternoonImage;
      greetingMessage = "Good Afternoon";
    } else if (currentHour >= 18) {
      backgroundImage = nightImage;
      greetingMessage = "Good Evening";
    } else {
      backgroundImage = morningImage;
      greetingMessage = "Good Morning";
    }
    return { backgroundImage, greetingMessage };
  };

  const [backgroundImage, setBackgroundImage] = useState(() => {
    const { backgroundImage } = getBackgroundImageAndMessage();
    return backgroundImage;
  });

  const [greetingMessage, setGreetingMessage] = useState(() => {
    const { greetingMessage } = getBackgroundImageAndMessage();
    return greetingMessage;
  });

  const boxes = [
    {
      name: "Leave",
      icon: <IoExitOutline />,
      path: "/hrm/employeeselfservice/leave",
    },
    {
      name: "Attendance",
      icon: <PiUserCircleCheckThin />,
      path: "/hrm/employeeselfservice/timendattendance",
    },
    {
      name: "Reimbursement",
      icon: <IoWalletOutline />,
      path: "/hrm/employeeselfservice/reimbursement",
    },
    {
      name: "Ticket",
      icon: <BsTicketPerforatedFill />,
      path: "/ticket",
    },
    {
      name: "Employee Exit",
      icon: <GiExitDoor />,
      path: "/hrm/employeeselfservice/employee-exit",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const { backgroundImage, greetingMessage } =
        getBackgroundImageAndMessage();
      setBackgroundImage(backgroundImage);
      setGreetingMessage(greetingMessage);
    }, 1000 * 60 * 60);

    return () => clearInterval(interval);
  }, []);

  const getTasks = async () => {
    let body = {};
    if (userRole !== "User") {
      body = {
        companyemail,
        companyid,
        useremail: email,
      };
    } else {
      body = {
        companyemail,
        companyid,
        empemail: email,
      };
    }
    try {
      setLoading(true);
      let res = await axios.post(getTasksApi, body,{withCredentials:true});
      if (res.data && res.data.length > 0) {
        setTasks(res.data);
      } else {
        setTasks([]);
      }
      setLoading(false);
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error while fetching tasks.");
      setLoading(false);
    }
  };

  const getEvent = async () => {
    let reqBody;
    if (userRole == "User") {
      reqBody = { companyid, companyemail, department };
    } else {
      reqBody = { companyid, companyemail };
    }

    try {
      setLoading(true);
      let res = await axios.post(getEvents, reqBody,{withCredentials:true});
      res.data && res.data.length > 0 ? setEvents(res.data) : setEvents([]);
      setLoading(false);
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error While Fetching Events");
      setLoading(false);
    }
  };

  useEffect(() => {
    getTasks();
    getEvent();
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <IconButton
        sx={{
          position: "fixed",
          bottom: 16,
          right: 20,
          backgroundColor: "#25D366",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#25D366",
          },
        }}
        onClick={() => setDrawerOpen(true)}
      >
        <TfiHeadphoneAlt />
      </IconButton>
      <Chatbot
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        isSmallScreen={isSmallScreen}
      />
      <Box className="common-bg-color">
        <Grid container>
          <Grid item lg={8} md={8} sm={12} xs={12} p={1}>
            <Box
              className="welcome-banner"
              style={{ backgroundImage: `url(${backgroundImage})` }}
              marginBottom={1}
            >
              <Box className="welcome-text">
                <h1>{greetingMessage}</h1>
                <p>Howdy, {username}</p>
              </Box>
            </Box>
            <Box borderRadius={3} p={1} className="bg-white">
              <p className="view-page-title fw-b">My Favourites</p>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={1}
                flexWrap={"wrap"}
              >
                {boxes.map((box, ind) => (
                  <Grid
                    item
                    p={1}
                    key={ind}
                    borderRadius={3}
                    sx={{ backgroundColor: getBoxColor(box.name) }}
                    flexGrow={1}
                    className="cursor-pointer"
                    onClick={() => navigate(box.path)}
                  >
                    <IconButton sx={{ marginBottom: 5 }}>{box.icon}</IconButton>
                    <p>{box.name}</p>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box my={1}>
              <Grid container display={"flex"}  flexWrap={"wrap"} justifyContent={"space-between"}>
                <Grid item md={5.9} sm={12} flexGrow={1} className="bg-white" borderRadius={3}>
                  <Box pl={1}>
                    <p className="view-page-title">Tasks</p>
                  </Box>
                  <Divider />
                  <Box
                    flex={1}
                    height={"300px"}
                    className="tasks"
                    sx={{
                      overflowY: "auto",
                      padding: "10px",
                    }}
                  >
                    {tasks.slice(0, 3).map((task, index) => (
                      <Box
                        key={index}
                        sx={{
                          marginBottom: "10px",
                          padding: "15px",
                          borderRadius: "8px",
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                        >
                          <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                            {task.taskname}
                          </p>
                          <Button
                            sx={{ color: performanceTextColor(task.status) }}
                          >
                            {task.status}
                          </Button>
                        </Box>
                        <Box mb={1}>
                          <p>
                            <strong>Description:</strong> {task.description}
                          </p>
                          <p>
                            <strong>Due Date:</strong> {task.duedate?formatDate(task.duedate):"-"}
                          </p>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                        >
                          <p>
                            <strong>Assigned by:</strong> {task.assigneedby}
                          </p>
                          <Button
                            sx={{
                              color: performanceTextColor(task.priority),
                              backgroundColor: performanceBgColor(
                                task.priority
                              ),
                            }}
                          >
                            {task.priority}
                          </Button>
                        </Box>
                        <Box mt={1}>
                          {task.tag.map((tag, idx) => (
                            <Chip
                              key={idx}
                              label={tag}
                              style={{
                                backgroundColor: getChipColor(tag),
                                color: "#fff",
                                margin: "0px 2px",
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    ))}
                    {tasks.length > 3 && (
                      <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button
                          color="primary"
                          onClick={() => navigate("/hrm/task&events/tasks")}
                          sx={{textTransform:"capitalize"}}
                        >
                          View More
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item md={5.9} sm={12} flexGrow={1} className="bg-white" borderRadius={3}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={1}
                    pl={1}
                  >
                    <MdCelebration /> <p className="view-page-title">Events</p>
                  </Box>
                  <Divider />
                  <Box
                    flex={1}
                    height={"300px"}
                    className="events"
                    sx={{ overflowY: "auto", padding: "10px" }}
                  >
                    {events.map((event, index) => (
                      <Box
                        key={index}
                        sx={{
                          marginBottom: "10px",
                          padding: "15px",
                          borderRadius: "8px",
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          {event.eventname}
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                        >
                          <Typography variant="body2">
                            <strong>Start:</strong> {event.startdate?formatDate(event.startdate):"N/A"}{" "}
                            {event.starttime}
                          </Typography>
                          <Typography variant="body2">
                            <strong>End:</strong> {event.enddate?formatDate(event.enddate):"N/A"}{" "}
                            {event.endtime}
                          </Typography>
                        </Box>
                        <Typography variant="body2" mb={1}>
                          <strong>Location:</strong> {event.location}
                        </Typography>
                        <Box mt={1}>
                          {event.participant.map((participant, idx) => (
                            <Chip
                              key={idx}
                              label={participant}
                              sx={{
                                marginRight: "5px",
                                marginBottom: "5px",
                                backgroundColor: "#e0e0e0",
                                color: "#000",
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            mt={1}
            borderRadius={2}
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className="commonShadow bg-white"
            p={1}
            display="flex"
            flexDirection="column"
            sx={{ height: "800px", overflowY: "auto" }}
          >
            <Box
             className="common-bg-color"
              padding={"10px"}
              marginTop={"2px"}
              borderRadius={"15px"}
              display={"flex"}
              justifyContent={"space-between"}
              gap={2}
            >
              <Button
                startIcon={<GiPartyPopper />}
                className={
                  tab == "Announcements"
                    ? "secondnav-active-btn bg-white"
                    : "second-nav-btn bg-white"
                }
                onClick={() => setTab("Announcements")}
              >
                Announcements
              </Button>
              <Button
                startIcon={<BsCake2 />}
                className={
                  tab == "Birthday" ? "secondnav-active-btn bg-white" : "second-nav-btn bg-white"
                }
                onClick={() => setTab("Birthday")}
              >
                Birthday
              </Button>
              <Button
                startIcon={<SlCalender />}
                className={
                  tab == "Calender" ? "secondnav-active-btn bg-white" : "second-nav-btn bg-white"
                }
                onClick={() => setTab("Calender")}
              >
                Calender
              </Button>
            </Box>
            <Box
              sx={{
                padding: 1,
                mt: 1,
                borderRadius: 5,
                background: "#F0F9FF",
                flex: 1,
              }}
            >
              {tab == "Announcements" && <Announcements />}
              {tab == "Calender" && <Holidays />}
              {tab == "Birthday" && <BirthDays />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Welcome;
