import {
  legacy_createStore,
  combineReducers,
  compose,
  applyMiddleware,
} from "redux";
import { thunk } from "redux-thunk";
import { logoReducer } from "./reducers/reducers.master/logo.reducers";
import { recruitmentReducer } from "./recruitmentprocess/reducres";
import { valueCatReducer, valueReducer, valueSubCatReducer } from "./reducers/reducers.master/value.reducers";
import { empSeriesReducer } from "./reducers/reducers.master/empSeries.reducers";
import { empPositionReducer, positionReducer } from "./reducers/reducers.master/empPosition.reducers";
import { empLeaveCategoryReducer } from "./reducers/reducers.master/empLeaveCategory.reducers";
import { empDirectoryReducer } from "./reducers/reducers.master/empDirectory.reducers";
import { jobApplicationReducer } from "./JobApplication/reducers";
import { prEWFReducer } from "./reducers/reducers.master/payrollEWF.reducers";
import { prFILLINGReducer, typeReducer } from "./reducers/reducers.master/payrollFilling.reducers";
import { prFBPReducer } from "./reducers/reducers.master/payrollFBPPolicy.reducers";
import { policyReducer } from "./reducers/reducers.master/policies.reducers";
import { interViewReducer } from "./InterView/reducers";
import { attendanceShiftReducer } from "./reducers/reducers.master/attendanceShift.reducers";
import { leaveTypeReducer, typeMasterReducer } from "./reducers/reducers.master/leaveType.reducers";
import { prRemReviewReducer, prRemTypeReducer } from "./reducers/reducers.master/payrollReimbursement.reducers";
import { employeeAssetReducer } from "./reducers/reducers.master/empAssetGroup.reducers";
import { empRegistrationReducer } from "./reducers/reducers.hrms/empRegistration.reducers";
import { summaryHolidayReducer } from "./reducers/reducers.hrms/summaryHoliday.reducers";
import { summaryBirthdayReducer } from "./reducers/reducers.hrms/summaryBirthday.reducers";
import { summaryAnnouncementReducer } from "./reducers/reducers.hrms/summaryAnnouncement.reducers";
import { attLeaveReducer } from "./reducers/reducers.hrms/attLeave.reducers";
import { attLeaveReportReducer } from "./reducers/reducers.hrms/attLeaveReport.reducers";
import { timeAttendanceReducer } from "./reducers/reducers.hrms/timeAttendance.reducers";
import { empExitReducer } from "./reducers/reducers.hrms/employeeExit.reducers";

// root reducer
const root_reducer = combineReducers({
  logoReducer,
  recruitmentReducer,
  valueReducer,valueCatReducer,valueSubCatReducer,
  empSeriesReducer,empPositionReducer,positionReducer,empLeaveCategoryReducer,empDirectoryReducer,employeeAssetReducer,
  jobApplicationReducer,
  prEWFReducer,prFILLINGReducer,typeReducer,prFBPReducer,prRemReviewReducer,prRemTypeReducer,
  policyReducer,
  interViewReducer,
  attendanceShiftReducer,
  leaveTypeReducer,typeMasterReducer,
  empRegistrationReducer,attLeaveReducer,attLeaveReportReducer,timeAttendanceReducer,empExitReducer,
  summaryHolidayReducer,summaryBirthdayReducer,summaryAnnouncementReducer,
});

//for redux devtool extension on chrome
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// store
export const store = legacy_createStore(
  root_reducer,
  composeEnhancers(applyMiddleware(thunk))
);
