import axios from "axios";
import {
  hrmsSummaryBirthdayAdd,
  hrmsSummaryBirthdayDelete,
  hrmsSummaryBirthdayGet,
  hrmsSummaryBirthdayUpdate,
} from "../../../Constants/api";

export const getSummaryBirthday = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_SUMM_BTH_LOADING" });
    let res = await axios.post(hrmsSummaryBirthdayGet, obj,{withCredentials:true});
    // console.log(res.data,"birthday data");
    if (res.data) {
      dispatch({ type: "GET_SUMM_BTH_SUCCESS", payload: Array.isArray(res.data)?res.data:[] });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_SUMM_BTH_FAILED", payload: error.message });
  }
};

export const addSummaryBirthday =
  (obj, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: "ADD_SUMM_BTH_LOADING" });
      let res = await axios.post(hrmsSummaryBirthdayAdd, obj,{withCredentials:true});
      // console.log("add res of emp", res);
      if (res.status == 200) {
        dispatch({ type: "ADD_SUMM_BTH_SUCCESS", payload: res.data.data });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
      } else {
        console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "ADD_SUMM_BTH_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

export const updateSummaryBirthday = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_SUMM_BTH_LOADING" });
    let res = await axios.post(hrmsSummaryBirthdayUpdate, obj,{withCredentials:true});
    if (res.status == 200) {
      dispatch({ type: "UPDATE_SUMM_BTH_SUCCESS", payload: res.data.data });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPDATE_SUMM_BTH_FAILED", payload: error.message });
  }
};

export const deleteSummaryBirthday = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_SUMM_BTH_LOADING" });
    let res = await axios.post(hrmsSummaryBirthdayDelete, obj,{withCredentials:true});
    if (res.data.message == "Birthday deleted successfully") {
      dispatch({
        type: "DELETE_SUMM_BTH_SUCCESS",
        payload: obj,
      });
    } else {
      console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "DELETE_SUMM_BTH_FAILED", payload: error.message });
  }
};
