import React from "react";
import ListSeries from "./ListSeries";

const Series = () => {
  return (
    <>
      <ListSeries />
    </>
  );
};

export default Series;
